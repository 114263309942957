import React, { memo, useMemo, useState } from "react";
import Styles from "../../UnitOverviewComponent.module.scss";
import { getDownloadableLink } from "../../../../../../../../../../Helper";
import Button from "@material-ui/core/Button";
import dotsGrid from '../../../../../../../../../../assets/images/Propxicon/dots-grid.svg';
import ImageGridDialog from "../ImageGridDialog/ImageGridDialog";

const ImagesCourasel = memo(({ couraselImages, unitId }) => {

  const [isImageGridOpen, setIsImageGridOpen] = useState(false);
  const unitImgURLs = useMemo(() => convertImageIdsToURLs(), [couraselImages]);


  function convertImageIdsToURLs() {
    return couraselImages
      ? couraselImages.map(item => 
          item?.fileId 
            ? getDownloadableLink(item.fileId) 
            : getDownloadableLink(item)
        )
      : [];
  }

  return (
    <>
      <div className={Styles.Courasel}>
        <div className={Styles.Courasel_Image}>
          {unitImgURLs[0] ?
            <img src={unitImgURLs[0]} alt="unit-image" />
            : null
          }
        </div>
        <div className={Styles.Courasel_Image}>
          {unitImgURLs[1] ?
            <img src={unitImgURLs[1]} alt="unit-image" />
            : null
          }
        </div>
        <div className={Styles.Image_Wrap}>
          <div className={Styles.Courasel_Image}>
            {unitImgURLs[2] ?
              <img src={unitImgURLs[2]} alt="unit-image" />
              : null
            }
          </div>
          <div className={Styles.Courasel_Image}>
            {unitImgURLs[3] ?
              <img src={unitImgURLs[3]} alt="unit-image" />
              : null
            }
          </div>
        </div>
        {couraselImages?.length > 0 ?
          <Button
            className={`btns theme-propx outlined ${Styles.Courasel_button}`}
            id="btn-save"
            onClick={() => setIsImageGridOpen(true)}
          >
            <span className="mxr-2"><img src={dotsGrid} alt="dotsGrid-Icon" /></span>
            <span>{`Show all photos`}</span>
          </Button>
          : null}
      </div>
      {isImageGridOpen &&
        <ImageGridDialog
          isOpen={isImageGridOpen}
          onclose={() => setIsImageGridOpen(false)}
          unitId={unitId}
        />}
    </>
  );
});

export default ImagesCourasel;
