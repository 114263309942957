import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import {
  AutocompleteComponent,
  DialogComponent,
  Inputs,
  Spinner,
} from '../../../../Components';
import { PaymentPlansLocalePath } from '../i18n/PaymentPlansLocale';
import LabeledField from '../../InquiryRotationView/InquirySchemeManagement/components/LabeledField';
import StatusRadio from './StatusRadio';
import IOSSwitch from '../../InquiryRotationView/InquirySchemeManagement/components/IOSSwitch';
import { useTranslation } from 'react-i18next';
import { GetAllDevelopersContactsAPI } from '../../../../Services';
import { useDebouncedAction } from '../../../../Hooks/DebouncedAction';
import SelectedChip from '../../InquiryRotationView/InquirySchemeManagement/components/SelectedChip';
import { PropertyPaymentPlan } from '../../../../Enums';
import { LookupSelect } from '../../ActivitiesTypeView/ActivitiesTypeManagementView/DialogManagementViewComponent/DialogManagementConrtolComponent/LookupSelect';
import Attachment from './Attachment';
import { Button, Chip } from '@material-ui/core';
import InstallmentsDetails from './InstallmentsDetails';
import PostHandoverInstallments from './PostHandoverInstallments';
import { actionTypes, initialPaymentPlan } from '../constants';
import {
  mapActiveItemToState,
  mapStateToCreatePaymentPlanDto,
} from '../models';
import { showError } from '../../../../Helper';
import {
  CreatePaymentPlan,
  UpdatePaymentPlan,
} from '../../../../Services/PaymentPlanServices';

const reducer = (
  state = initialPaymentPlan(),
  { field, value, index, nestedField, action = actionTypes.ADD }
) => {
  if (Array.isArray(state[field])) {
    if (action === actionTypes.ADD) {
      state[field] = state[field].concat(value);
    } else if (action === actionTypes.EDIT) {
      state[field][index][nestedField] = value;
    } else if (action === actionTypes.REMOVE) {
      state[field] = state[field].filter((item) => item[nestedField] !== value);
    } else if (action === actionTypes.FILL) {
      state[field] = value;
    }
  } else if (action === actionTypes.SET_STATE) {
    return value;
  } else {
    if (action === actionTypes.ADD) {
      state[field] = value;
    } else if (action === actionTypes.REMOVE) {
      state[field] = null;
    }
  }
  return { ...state };
};

function PaymentPlanManagementDialog({
  activeItem,
  open,
  onClose,
  reload,
  forProperty,
  propertyId,
}) {
  const { t } = useTranslation(PaymentPlansLocalePath);
  const [state, dispatch] = useReducer(reducer, initialPaymentPlan(propertyId));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    masterDevelopers: [],
    secondaryDevelopers: [],
  });
  const [loadings, setLoadings] = useState({
    developers: false,
  });
  const getDevelopers = async (searchValue) => {
    const res = await GetAllDevelopersContactsAPI({
      pageIndex: 1,
      pageSize: 50,
      search: searchValue,
    });
    if (!(res?.status && res.status !== 200)) {
      return res?.result;
    }
    return [];
  };
  const getAllDevelopers = async (searchValue, type) => {
    setLoadings((prev) => ({ ...prev, developers: true }));
    const res = await getDevelopers(searchValue);
    if (!(res && res.status && res.status !== 200)) {
      let developersList = [];
      if (Array.isArray(res)) {
        res.forEach((element) => {
          const developerContact =
            element?.contact && JSON.parse(element.contact);
          developersList.push({
            developerId: element.contactsId,
            developerName: developerContact?.contact?.company_name,
          });
        });
      }
      setData((prevData) => ({
        ...prevData,
        [type]: developersList,
      }));
    }
    setLoadings((prev) => ({ ...prev, developers: false }));
  };
  const searchDevelopersDebounced = useDebouncedAction(getAllDevelopers, 1000);
  const totalPercentage = useMemo(() => {
    let percentage = 0;
    percentage += +state.downPaymentAmountPercentage;
    percentage += +state.handoverAmountPercentage;
    state.installment.forEach((installment) => {
      if (installment.numberOfInstallment) {
        percentage +=
          installment.amountPercentage * 100 * +installment.numberOfInstallment;
      } else {
        percentage += installment.amountPercentage * 100;
      }
    });
    state.postHandoverInstallment.forEach((installment) => {
      percentage += installment.amountPercentage * 100;
    });
    return percentage;
  }, [
    state.downPaymentAmountPercentage,
    state.handoverAmountPercentage,
    state.installment,
    state.postHandoverInstallment,
  ]);
  const submitHandler = async () => {
    if (totalPercentage !== 100) {
      return showError(t('total_installments_error'));
    }
    try {
      setLoading(true);
      const body = mapStateToCreatePaymentPlanDto(state);
      if (activeItem) {
        await UpdatePaymentPlan(body);
      } else {
        await CreatePaymentPlan(body);
      }
      onClose();
      reload();
    } catch (error) {
      showError('error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeItem) {
      dispatch({
        action: actionTypes.SET_STATE,
        value: mapActiveItemToState(activeItem),
      });
    }
  }, [activeItem]);

  useEffect(() => {
    return () => {
      if (!open) {
        dispatch({
          action: actionTypes.SET_STATE,
          value: initialPaymentPlan(propertyId),
        });
      }
    };
  }, [open]);
  if (!open) return null;
  return (
    <DialogComponent
      isOpen={open}
      titleText={t(activeItem ? 'edit_payment_plan' : 'add_payment_plan')}
      parentTranslationPath={PaymentPlansLocalePath}
      onCloseClicked={onClose}
      maxWidth={'sm'}
      dialogContent={
        <div className='payment-plan-management-dialog inquiry-management-container pb-3 flex flex-col gap-1'>
          <Spinner isActive={loading} isAbsolute />
          <div className='pb-3 total-installment'>
            <Chip
              label={
                <h3 className='m-0'>
                  <strong>{t('total_installments')}:</strong> {totalPercentage}%
                </h3>
              }
            />
          </div>
          <LabeledField
            label={t('status')}
            component={
              <StatusRadio
                status={state.propertyPlanStatus}
                dispatch={dispatch}
              />
            }
            hideDivider
          />
          {!forProperty && (
            <>
              <LabeledField
                component={
                  <div>
                    <IOSSwitch
                      checked={state.isForSpecificDeveloper}
                      onChange={(e) =>
                        dispatch({
                          field: 'isForSpecificDeveloper',
                          value: e.target.checked,
                        })
                      }
                    />
                    <label className='special-label'>
                      {t('specific_developer')}
                    </label>
                  </div>
                }
              />
              {state.isForSpecificDeveloper && (
                <>
                  <LabeledField
                    className='gap-32'
                    label={t('master_developer')}
                    component={
                      <div>
                        <AutocompleteComponent
                          data={data.masterDevelopers}
                          onOpen={() => {
                            if (!data.masterDevelopers.length) {
                              getAllDevelopers(undefined, 'masterDevelopers');
                            }
                          }}
                          withoutSearchButton
                          onInputKeyUp={(e) => {
                            e.persist();
                            searchDevelopersDebounced(
                              e.target.value,
                              'masterDevelopers'
                            );
                          }}
                          isLoading={loadings.developers}
                          selectedValues={state.masterDevelopers}
                          onChange={(_e, newValue) => {
                            dispatch({
                              field: 'masterDevelopers',
                              action: actionTypes.FILL,
                              value: newValue,
                            });
                          }}
                          getOptionSelected={(option, value) =>
                            option.developerId === value.developerId
                          }
                          displayLabel={(option) =>
                            option?.name ?? option?.developerName ?? ''
                          }
                          renderOption={(option) =>
                            option?.name ?? option?.developerName ?? ''
                          }
                          renderTags={(e, getTagProps) => (
                            <div className='flex chips-container p-1'>
                              {e.map((option, index) => (
                                <SelectedChip {...getTagProps({ index })}>
                                  {option.developerName}
                                </SelectedChip>
                              ))}
                            </div>
                          )}
                        />
                      </div>
                    }
                  />
                  <LabeledField
                    className='gap-32'
                    hideDivider
                    label={t('secondary_developer')}
                    component={
                      <div>
                        <AutocompleteComponent
                          data={data.secondaryDevelopers}
                          onOpen={() => {
                            if (!data.secondaryDevelopers.length) {
                              getAllDevelopers(
                                undefined,
                                'secondaryDevelopers'
                              );
                            }
                          }}
                          withoutSearchButton
                          onInputKeyUp={(e) => {
                            e.persist();
                            searchDevelopersDebounced(
                              e.target.value,
                              'secondaryDevelopers'
                            );
                          }}
                          isLoading={loadings.developers}
                          selectedValues={state.secondaryDevelopers}
                          onChange={(_e, newValue) => {
                            dispatch({
                              field: 'secondaryDevelopers',
                              action: actionTypes.FILL,
                              value: newValue,
                            });
                          }}
                          getOptionSelected={(option, value) =>
                            option.developerId === value.developerId
                          }
                          displayLabel={(option) =>
                            option?.name ?? option?.developerName ?? ''
                          }
                          renderOption={(option) =>
                            option?.name ?? option?.developerName ?? ''
                          }
                          renderTags={(e, getTagProps) => (
                            <div className='flex chips-container p-1'>
                              {e.map((option, index) => (
                                <SelectedChip {...getTagProps({ index })}>
                                  {option.developerName}
                                </SelectedChip>
                              ))}
                            </div>
                          )}
                        />
                      </div>
                    }
                  />
                </>
              )}
            </>
          )}
          <LabeledField
            className='gap-32'
            label={t('payment_plan_name')}
            component={
              <Inputs
                parentTranslationPath={PaymentPlansLocalePath}
                inputPlaceholder={
                  "Enter plan name (e.g., ‘50/50 Payment Plan')"
                }
                value={state.paymentPlanName}
                onInputChanged={(e) =>
                  dispatch({
                    field: 'paymentPlanName',
                    value: e.currentTarget.value,
                  })
                }
              />
            }
          />
          <LabeledField
            className='gap-32'
            label={t('plan_type')}
            component={
              <LookupSelect
                lookupName={PropertyPaymentPlan.PlanType.lookupTypeName}
                renderValue={(_value, item) => (
                  <strong>{item?.lookupItemName}</strong>
                )}
                value={state.paymentTypeId}
                onSelectChanged={(value) =>
                  dispatch({ field: 'paymentTypeId', value })
                }
              />
            }
          />
          <LabeledField
            className='gap-32'
            label={t('description')}
            component={
              <Inputs
                inputPlaceholder='Add details or comments'
                rows={5}
                multiline
                value={state.description}
                onInputChanged={(e) =>
                  dispatch({
                    field: 'description',
                    value: e.currentTarget.value,
                  })
                }
              />
            }
          />
          <LabeledField
            className='gap-32'
            label={t('attachment')}
            component={
              <Attachment
                dispatch={dispatch}
                documents={state.paymentPlanDocuments}
              />
            }
          />
          <LabeledField
            className='gap-32'
            label={t('down_payment')}
            component={
              <Inputs
                type={'number'}
                startAdornment={<span className='p-1'>%</span>}
                inputPlaceholder={'Enter upfront payment (e.g., 20%)'}
                wrapperClasses={'px-2'}
                value={state.downPaymentAmountPercentage}
                onInputChanged={(e) =>
                  dispatch({
                    field: 'downPaymentAmountPercentage',
                    value: e.currentTarget.valueAsNumber,
                  })
                }
              />
            }
          />
          <InstallmentsDetails
            dispatch={dispatch}
            installments={state.installment}
          />
          <LabeledField
            label={t('handover_payment')}
            component={
              <Inputs
                startAdornment={'%'}
                inputPlaceholder={'Enter handover percentage (e.g., 30%)'}
                type={'number'}
                value={state.handoverAmountPercentage}
                onInputChanged={(e) =>
                  dispatch({
                    field: 'handoverAmountPercentage',
                    value: e.currentTarget.valueAsNumber,
                  })
                }
              />
            }
          />
          <PostHandoverInstallments
            dispatch={dispatch}
            installments={state.postHandoverInstallment}
          />
        </div>
      }
      dialogActions={
        <div className='payment-plan-dialog-footer flex fj-end w-100 gap-1'>
          <Button className='cancel-btn' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button onClick={submitHandler} className='add-btn'>
            {t(activeItem ? 'edit_payment_plan' : 'add_payment_plan')}
          </Button>
        </div>
      }
    />
  );
}

export default PaymentPlanManagementDialog;
