import React from "react";
import { Box } from "@material-ui/core";
import AddIndividualForm from "../AddIndividualForm/AddIndividualForm";
import AddCorporateForm from "../AddCorporateForm/AddCorporateForm";
import { ContactFormTypeEnum } from "../../../../Enums";
import { useQuery } from "../../../../Hooks";

// Styles
import useStyles from "./styles";

function AddContactView() {
  const styles = useStyles();

  const query = useQuery();
  const formType = query.get("formType");

  const isIndividualForm = ContactFormTypeEnum.INDIVIDUAL.value === +formType;
  const isCorporateForm = ContactFormTypeEnum.CORPORATE.value === +formType;

  return (
    <Box>
      {isIndividualForm && <AddIndividualForm />}
      {isCorporateForm && <AddCorporateForm />}
    </Box>
  );
}

export default AddContactView;
