import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Switch, Typography } from "@material-ui/core";

// Style
import useStyles from "./styles";

const CustomSwitch = ({ size, label, checked, onChange, isDisabled }) => {
  const styles = useStyles();

  const handleChange = (e) => {
    onChange(e);
  };

  const className = clsx({
    [styles.root]: true,
    disabled: isDisabled,
    default: !checked && !isDisabled,
  });

  return (
    <Box className={styles.containerSwitchItem}>
      <Switch
        checked={checked}
        onChange={handleChange}
        size={size === "sm" ? "small" : "medium"}
        classes={{
          root: className,
        }}
        disabled={isDisabled}
        disableRipple
      />
      <Typography className={styles.label}>{label}</Typography>
    </Box>
  );
};

CustomSwitch.propTypes = {
  size: PropTypes.oneOf(["sm", "md"]),
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

CustomSwitch.defaultProps = {
  size: "sm",
  label: "",
  isDisabled: false,
};

export default CustomSwitch;
