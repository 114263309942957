
import { config } from '../config';
import { HttpServices } from '../Helper';


export const GetAllKycConfigration = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Kyc/GetAllKycConfigration`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllRiskRatingConfigration = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Kyc/GetAllRiskRatingConfigration`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateRiskRatingConfigration = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Kyc/UpdateRiskRatingConfigration`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetKycFormDetailsByContactId = async (contactId, contactsTypeId ) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Kyc/GetKycFormDetailsByContactId/${contactId}/${contactsTypeId}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateKycDetails = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Kyc/UpdateKycDetails`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ShareKycDocument = async (body) => {
 const  {isManualKYC} = body
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Kyc/ShareKycDocument?isManualKYC=${isManualKYC}`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateKycRiskRating = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Kyc/UpdateKycRiskRating`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllSharedKycDocuments = async ({contactId, pageIndex, pageSize}) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Kyc/GetAllSharedKycDocuments/${contactId}/${pageIndex}/${pageSize }`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateNationalityConfigration = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Kyc/UpdateNationalityConfigration`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const GetNationalityConfigurations = async ({searchValue}) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Kyc/GetNationalityConfigurations?search=${searchValue}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ReadKycHtmlJQueryContant = async (sharedKycDocumentId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Kyc/ReadKycHtmlJQueryContant?sharedKycDocumentId=${sharedKycDocumentId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const ApproveOrRejectKycForm = async (isFromAgent, body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Kyc/ApproveOrRejectKycForm?isFromAgent=${isFromAgent}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};