import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { bottomBoxComponentUpdate } from "../../../../../../../../Helper";
import {
  NoContentComponent,
  Spinner,
  Tables,
} from "../../../../../../../../Components";
import { GetLeaseReservationTenants } from "../../../../../../../../Services";

export const AssignedAgentsDetails = ({
  parentTranslationPath,
  translationPath,
  unitId,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [isLoading, setIsLoading] = useState(false);
  const [leaseReservations, setLeaseReservations] = useState({
    assignedAgents: [],
  });
  const [filter] = useState({
    pageSize: 9999999,
    pageIndex: 0,
    search: "",
  });
  const getLeaseReservationClient = useCallback(async () => {
    setIsLoading(true);
    const leaseTransactionDetailsId = localStorage.getItem(
      "leaseTransactionDetailsId"
    );
    const res = await GetLeaseReservationTenants({
      id: leaseTransactionDetailsId,
      isForAccountTab: true,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      let list = [] ; 
      const landLords =
        (res?.landLords &&
          res?.landLords?.transactionLandlordDetails
            ?.transactionReferralDetails && [
            ...res.landLords.transactionLandlordDetails
              .transactionReferralDetails,
          ]) ||
        [];

      res?.tenants &&  res.tenants.map((t)=> {
        console.log('@@@@ 111' , t );

        if(t.transactionTenantDetails &&  t.transactionTenantDetails?.transactionReferralDetails )
          {

            console.log('@@@@ 222' , t.transactionTenantDetails?.transactionReferralDetails  );

            t.transactionTenantDetails.transactionReferralDetails.map((s)=>
            {
              console.log('@@@@' , t );
              
              list.push({...s}) ;

            })



          }
      });

      res?.landLords &&  res.landLords.map((t)=> {
        console.log('33333333  111' , t );

        if(t.transactionLandlordDetails &&  t.transactionLandlordDetails?.transactionReferralDetails )
          {

            console.log('@@@@ 222' , t.transactionLandlordDetails?.transactionReferralDetails  );

            t.transactionLandlordDetails.transactionReferralDetails.map((s)=>
            {
              console.log('@@@@' , t );
              
              list.push({...s}) ;

            })



          }
      });
    
        console.log('############'   , list    )
 

      setLeaseReservations({
        assignedAgents: [...list],
      });
    } else {
      setLeaseReservations({
        assignedAgents: [],
      });
    }
    setIsLoading(false);
  }, [unitId]);

  useEffect(() => {
    if (unitId) getLeaseReservationClient();
  }, [getLeaseReservationClient, unitId]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  return (
    <div className="transactions-details-wrapper childs-wrapper mt-3">
      <Spinner isActive={isLoading} isAbsolute />

      {leaseReservations &&
      leaseReservations.assignedAgents &&
      leaseReservations.assignedAgents.length === 0 ? (
        <NoContentComponent />
      ) : (
        <Tables
          data={leaseReservations?.assignedAgents || []}
          headerData={[
            {
              id: 1,
              isSortable: false,
              label: "lease-agent",
              input: "leaseAgent",
            },
            {
              id: 2,
              isSortable: false,
              label: "lease-listing-agent",
              input: "leaseListingAgent",
            },
            {
              id: 3,
              isSortable: false,
              label: "agent-team-manager",
              input: "teamName",
            },
            {
              id: 4,
              isSortable: false,
              label: "agent-team-lead",
              input: "teamLeadName",
            },
            {
              id: 5,
              isSortable: false,
              label: "agent-branch",
              input: "agentBranch",
            },
          ]}
          defaultActions={[]}
          activePage={1}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={leaseReservations?.assignedAgents?.length}
        />
      )}
    </div>
  );
};

AssignedAgentsDetails.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
AssignedAgentsDetails.defaultProps = {};
