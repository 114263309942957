import React from "react";

const MessageIcon = ({ fill, ...restProps }) => {
  return (
    <svg  viewBox="0 0 21 20"
    fill={fill ?? ""}
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}>
      <path
        d="M5.198 1.72c-.891.099-1.514.399-2.088 1.004-.457.482-.693.957-.827 1.667-.056.292-.06.822-.06 6.827v6.51l.08.199c.107.265.346.525.595.647.174.086.24.099.515.099.474 0 .551-.048 2.113-1.296 1.613-1.29 1.701-1.356 1.956-1.458l.201-.081 4.025-.021c4.101-.021 4.191-.024 4.742-.16a2.999 2.999 0 0 0 1.377-.778c.57-.548.852-1.139.977-2.048.033-.239.043-1.267.043-4.133-.002-4.124-.003-4.155-.193-4.772a3.207 3.207 0 0 0-1.259-1.675c-.435-.294-.935-.46-1.603-.532-.44-.048-10.167-.046-10.594.001m10.77 1.684a1.575 1.575 0 0 1 1.152 1.164c.038.169.048.976.048 4.2 0 3.721-.005 4.005-.063 4.216a1.622 1.622 0 0 1-.752.951c-.368.211-.329.209-4.803.236l-4.077.025-.298.092c-.407.126-.641.235-.928.431A92.478 92.478 0 0 0 3.91 16.55c-.06.051-.062-.183-.05-5.86.01-4.572.023-5.956.057-6.105.135-.595.65-1.092 1.228-1.187.374-.062.392-.062 5.583-.057 4.503.005 5.028.011 5.24.063"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
};

export default MessageIcon;
