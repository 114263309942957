import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      height: 24,
      borderRadius: "50%",
      backgroundColor: theme.palette.background.disabled_subtle, // Inactive color
      border: `1.5px solid ${theme.palette.border.disabled_subtle}`,
      zIndex: 2,
      "&::after": {
          content: '""',
          display: "inline-block",
          width: 8,
          height: 8,
          borderRadius: "50%",
          backgroundColor: theme.palette.foreground.disabled_subtle,
      }
    },
    active: {
      backgroundColor: theme.palette.background.brand_solid, // Active color
      border: "none",
      boxShadow: theme.shadows[8],
      "&::after": {
        backgroundColor: "white",
      }
    },
    completed: {
      backgroundColor: theme.palette.background.brand_solid, // Completed color
      border: "none",
      "&::after": {
        display: "none",
      }
    },
    stepLabel: {
      fontWeight: 600,
      fontSize: "14px",
      color: theme.palette.text.secondary,
      "&$activeLabel": {
        color: theme.palette.text.brand_secondary,
      },
      "&$completedLabel": {
        color: theme.palette.text.secondary, // completed color
      },
      textAlign: "center",
    },
    activeLabel: {},
    completedLabel: {},
  };
});
