import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    container_content: {
      display: "flex",
      flexDirection: "column",
      // gap: "20px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
      },
    },
    container_Fileds_Doucoment: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      // width: "635px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    divider: {
      backgroundColor: theme.palette.background.quarterary,
    },
    container_Title: {
      display: "flex",
      flexDirection: "column"
    },
    titleItem: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
      width: "200px",
    },
    Sub_Description: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
    container_Image_Doucoment: {
      backgroundColor: theme.palette.background.gray_50,
      width: "100%",
      height: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: 'relative',
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    container_Attatchment_Filed: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    imagePreview: {
      width: "321px",
      height: "200px",
      objectFit: "contain",
    },
    container_Link_Filed: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    InputLink: {},
    inputFullWidth: {
      width: "100% !important",
      maxWidth: "100% !important",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    containerInput: {
      width: "100%",
    },containerAttachmentUploadAndData:{
      width:'100%',
      display:'flex',
      flexDirection:'column',
      gap:'16px'
    },containerAttatchmentAndImages:{
      width:'100%',
      display:'flex',
      flexDirection:'row',
      gap:'32px'
    },deleteIcon:{
      position: 'absolute', 
      top:'16px',
      right:'16px'
    }
  };
});
