import React, { useState , useEffect } from 'react';
import PropTypes from 'prop-types';
import { TabsComponent } from '../Controls';
import { HistoryComponentTabs } from './TabsData/HistoryComponentTabs';
import { DialogComponent } from '../DialogComponent/DialogComponent';

const parentTranslationPath = 'HistoryView';
const translationPath = '';

export const HistoryTabsComponent = ({ isOpen, isOpenChanged , activeItem , typeId , formType , operationType  , isPropertyManagementView , rotationSchemeId  }) => {
  const pathName = window.location.pathname.split('/home/')[1].split('/')[1];
  const [activeTab, setActiveTab] = useState(0);
  const [tabsData , setTabsData] = useState([]) ;
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };


  useEffect(() => {
    if(typeId ===  'contact')
      setTabsData(HistoryComponentTabs.contactHistory) ;
      else if(typeId === 'property' && !isPropertyManagementView)
            setTabsData(HistoryComponentTabs.propertyHistory) ;
      else  if(typeId === 'property' &&  isPropertyManagementView )
           setTabsData(HistoryComponentTabs.propertyManagmentHistory) ;
      else if (typeId === 'unit' &&  operationType && operationType === 'sale')
             setTabsData(HistoryComponentTabs.saleUnitHistory) ;
      else if(typeId === 'unit' &&  operationType && operationType === 'lease')
           setTabsData(HistoryComponentTabs.leaseUnitHistory) ;
      else if(typeId === 'unit' &&  operationType && operationType === 'unitPropertyManagment')
           setTabsData(HistoryComponentTabs.unitPropertyManagmentHistory) ;
      else if(typeId === 'lead' &&  operationType && operationType === 'sale')
           setTabsData(HistoryComponentTabs.saleLeadsHistory) ;
      else if(typeId === 'lead' &&  operationType && operationType === 'lease')
            setTabsData(HistoryComponentTabs.leaseLeadHistory) ;
     else if(typeId === 'lead' &&  operationType && operationType === 'callCenter')
            setTabsData(HistoryComponentTabs.callCenterLeadHistory) ;
     else if(typeId === 'lead' &&  operationType && operationType === 'leadPropertyManagment')
            setTabsData(HistoryComponentTabs.propertyManagmentLeadHistory) ;
     else if(typeId === 'lead' &&  operationType && operationType === 'LeadsMortgage')
            setTabsData(HistoryComponentTabs.LeadsMortgageHistory) ;
     else if(typeId === 'lead' &&  operationType && operationType === 'leadFromContact')
            setTabsData(HistoryComponentTabs.contactLeadHistory) ;
            
      else if(typeId === 'leadOwnerUnitSale')
        setTabsData(HistoryComponentTabs.leadOwnerUnitSaleHistory) ;

     else if(typeId === 'leadOwnerUnitLease')
        setTabsData(HistoryComponentTabs.leadOwnerUnitLeaseHistory) ;

      else if ( typeId === 'leadOwnerLeads' )
        setTabsData(HistoryComponentTabs.leadOwnerLeadHistory) ;

      else if ( typeId === 'rotationSchema' )
        setTabsData(HistoryComponentTabs.rotationSchema) ;
      
      else if (typeId === 'manageAgents')
         setTabsData(HistoryComponentTabs.manageAgents) ;

          
  }, [HistoryComponentTabs]);

  
  return (
    <DialogComponent
      titleText='history'
      maxWidth='lg'
      dialogContent={(
        <div className='d-flex-column-center'>
          <TabsComponent
            wrapperClasses='w-100'
            data={tabsData}
            labelInput='label'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            themeClasses='theme-solid'
            currentTab={activeTab}
            onTabChanged={onTabChanged}
            dynamicComponentProps={{
              parentTranslationPath,
              translationPath,
              typeId ,
              formType , 
              rotationSchemeId ,  
              
            }}
          />
        </div>
      )}
      isOpen={isOpen}
      onCloseClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};

HistoryTabsComponent.propTypes = {
   isOpen: PropTypes.bool.isRequired,
   isOpenChanged: PropTypes.func.isRequired,
   typyId : PropTypes.string.isRequired, 
   formType : PropTypes.string,
   operationType:PropTypes.string , 
   isPropertyManagementView:PropTypes.bool , 
};

HistoryTabsComponent.defaultProps ={
   formType:undefined , 
   operationType:undefined , 
   isPropertyManagementView:false , 

}
