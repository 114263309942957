import React from 'react';
import { PaymentPlansLocalePath } from '../i18n/PaymentPlansLocale';
import PostInstallmentItem from './PostInstallmentItem';
import { Tables } from '../../../../Components';
import { isDate } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TableActions } from '../../../../Enums';
import { actionTypes } from '../constants';

const PostHandoverInstallments = ({ installments, dispatch }) => {
  const { t } = useTranslation(PaymentPlansLocalePath);
  const onActionClicked = (_enumKey, item) => {
    dispatch({
      value: item.id,
      action: actionTypes.REMOVE,
      nestedField: 'id',
      field: 'postHandoverInstallment',
    });
  };
  return (
    <div className='p-3 flex gap-1 flex-col'>
      <PostInstallmentItem dispatch={dispatch} />
      <Tables
        headerData={[
          {
            label: 'installment %',
            input: 'amountPercentage',
            component: (row) => `${row.amountPercentage * 100}%`,
          },
          {
            label: 'frequency',
            input: 'frequencyValue',
            component: (row) =>
              isDate(new Date(row.frequencyValue)) && !+row.frequencyValue
                ? t('by_specific_date')
                : t('by_duration'),
          },
          { label: 'duration', input: 'frequencyValue' },
        ]}
        data={installments}
        bodyRowId={'postHandover-installment'}
        defaultActions={[{ enum: TableActions.delete.key }]}
        activePage={1}
        actionsOptions={{ onActionClicked }}
        parentTranslationPath={PaymentPlansLocalePath}
      />
    </div>
  );
};

export default PostHandoverInstallments;
