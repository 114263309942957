import { TransactionsDetails, ContactDetails } from '../Sections';
import {  AssignedAgentsDetails } from '../../../LeasingTransactionsView/LeasingTransactionsProfile/Sections/Details/Sections/AssignedAgentsDetails/AssignedAgentsDetails';


export const LeasingTransactionsDetailsTabs = [
  {
    label: "transaction-details",
    component: TransactionsDetails,
  },
  {
    label: "contact-details",
    component: ContactDetails,
  },
  {
    label: "assigned-agents",
    component: AssignedAgentsDetails,
  },
];
