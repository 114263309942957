import React, { createContext, useState, useEffect, useCallback } from "react";
import {
  GetAdvanceSearchContacts,
  GetAllUserSearchCriteria,
} from "../../../Services";
import { ContactsMapper } from "../../../Views/Home/ContactsView/ContactsUtilities";
import { SearchCriteriaEnum } from "../../../Enums";

// Create the context
export const ContactLayoutContext = createContext();

// Create the provider component
export const AdvancedSearchProvider = ({ children }) => {
  const [advancedSearchBody, setAdvancedSearchBody] = useState({
    criteria: {},
    filterBy: "createdOn",
    orderBy: 2,
    LeadClasses: [],
    contactClasses: [],
  });

  const [kycHistoryActions, setKycHistoryActions] = useState({
    activeItem: null,
    isShareOpen: false,
    isRiskRatingClicked: false,
    isViewOpen: false,
  });

  const [actionableItems, setActionableItems] = useState({
    selectedIds: [],
    action: null,
    // contacts => bulk_update, merge, pull
    // leads => unqualified, reassignOwner, reassignSeeker, clone, closeLead
  });
  const [isAddTaskDialogOpen, setIsAddTaskDialogOpen] = useState(false);
  const [addActivity, setAddActivity] = useState(false);
  const [contactsData, setContactsData] = useState({
    result: [],
    totalCount: 0,
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 25,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [savedFiltersCriteria, setSavedFiltersCriteria] = useState({
    isOpen: false,
    selectedValue: undefined,
    selectedId: undefined,
    data: [],
    filterDataToAdd: {},
  });
  const [isCriteriaLoading, setIsCriteriaLoading] = useState([]);
  const [filterModalData, setFilterModalData] = useState({
    isOpen: false,
    selectedValue: undefined,
    selectedId: undefined,
    data: [],
    selectedColumns: [],
  });

  const fetchContacts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await GetAdvanceSearchContacts(
        {
          pageIndex: pagination.currentPage - 1,
          pageSize: pagination.itemsPerPage,
        },
        advancedSearchBody
      );

      if (!(response && response.status && response.status !== 200)) {
        setContactsData({
          result: ((response && response.result) || []).map(
            (item) =>
              item.contactJson &&
              ContactsMapper(item, JSON.parse(item.contactJson).contact)
          ),
          totalCount: (response && response.totalCount) || 0,
        });
      } else {
        setContactsData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [pagination, advancedSearchBody]);

  const getSearchCriteria = useCallback(async () => {
    setIsCriteriaLoading(true);

    try {
      const res = await GetAllUserSearchCriteria(
        SearchCriteriaEnum.Contact.typeId
      );

      if (!(res && res.status && res.status !== 200)) {
        setSavedFiltersCriteria((prevData) => ({
          ...prevData,
          data: res,
        }));
      } else {
        setSavedFiltersCriteria((prevData) => ({
          ...prevData,
          data: [],
        }));
      }
    } catch (error) {
      console.error("Failed to fetch search criteria:", error);
    } finally {
      setIsCriteriaLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchContacts();
  }, [pagination, advancedSearchBody]);

  useEffect(() => {
    getSearchCriteria();
  }, []);

  return (
    <ContactLayoutContext.Provider
      value={{
        advancedSearchBody,
        setAdvancedSearchBody,
        contactsData,
        pagination,
        setPagination,
        isLoading,
        savedFiltersCriteria,
        setSavedFiltersCriteria,
        isCriteriaLoading,
        filterModalData,
        setFilterModalData,
        isAddTaskDialogOpen,
        setIsAddTaskDialogOpen,
        addActivity,
        setAddActivity,
        actionableItems,
        setActionableItems,
        setContactsData,
        kycHistoryActions,
        setKycHistoryActions,
      }}
    >
      {children}
    </ContactLayoutContext.Provider>
  );
};
