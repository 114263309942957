import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  customSelect: {
    "&.MuiSelect-select:focus": {
      backgroundColor: `transparent !important`,
    },
  },
  select: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    height: "44px",
    padding: "10px 14px",
    width: (props) => props.width ?? "320px",
    color: theme.palette.text.primary,
    borderRadius: "8px",
    background: theme.palette.background.paper,
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    border: `1px solid ${theme.palette.border.brand}`,
  },
  inputContainerFocused: {
    boxShadow:
      "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(140, 115, 75, 0.24) !important",
    border: `1px solid ${theme.palette.border.brand} !important`,
  },
  defaultSelect: {
    cursor: "pointer",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    border: `1px solid ${theme.palette.border.primary}`,
  },
  dropdownIcon: {
    cursor: "pointer",
    color: theme.palette.foreground.quarterary,
    fontSize: "20px",
  },
  avatarImg: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginRight: 8,
  },
  customImg: {
    width: "16px",
    height: "16px",
  },
  renderValueBox: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    marginLeft: "-8px",
  },
  textEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
    width: "100%",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  optionBox: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    width: "100%",
    justifyContent: "space-between",
  },
  placeholder: {
    color: theme.palette.text.placeholder,
    fontWeight: 400,
  },
  custom: {
    width: "95px",
    padding: "10px 0px 10px 14px",
    border: "none",
    boxShadow: "none",
    height: "40px",
  },
  menuPaper: {
    maxHeight: "250px !important",
    borderRadius: `${theme.borderRadius[4]} !important`,
    border: `1px solid ${theme.palette.border.secondary} !important`,
    boxShadow: `${theme.shadows[4]} !important`,
    fontWeight: "500 !important",
  },
  selectedBg: {
    backgroundColor: theme.palette.background.hover,
  },
  flexRow: {
    display: "flex",
    gap: "4px",
    color: theme.palette.text.secondary,
    alignItems: "center",
  },
  customVariant: {
    gap: "2px",
    fontWeight: 400,
  },
}));
