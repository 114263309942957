import React from "react";

function CheckCircleIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.333.021C6.397.24 3.78 1.659 2.01 3.991.999 5.325.369 6.847.091 8.633c-.102.653-.102 2.081 0 2.734.428 2.743 1.754 4.994 3.903 6.625 1.328 1.007 2.858 1.639 4.639 1.917.653.102 2.081.102 2.734 0 2.43-.379 4.496-1.474 6.046-3.207 1.374-1.535 2.168-3.233 2.496-5.335.102-.653.102-2.081 0-2.734-.278-1.781-.91-3.311-1.917-4.639C16.338 1.815 13.975.443 11.25.083 10.86.031 9.7-.006 9.333.021m4.857 6.591a.97.97 0 0 1 .549.886c0 .434.139.275-2.815 3.226-2.597 2.594-2.679 2.672-2.855 2.723a1.038 1.038 0 0 1-.638 0c-.173-.051-.243-.115-1.604-1.473-1.583-1.578-1.566-1.557-1.566-1.976 0-.387.197-.703.556-.891.139-.073.21-.087.433-.087.413 0 .457.032 1.533 1.111.513.515.948.936.967.936.018 0 1.016-.984 2.217-2.186 1.312-1.314 2.236-2.213 2.316-2.255a1.43 1.43 0 0 1 .234-.097c.173-.046.5-.005.673.083"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default CheckCircleIcon;
