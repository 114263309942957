import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box } from "@material-ui/core";
import {
  CustomDialog,
  CustomPagination,
  DocumentsContactCard,
  DocumentsContactCardSkeleton,
  NoDataFound,
} from "../../../../../../Components";
import { GetAllScopeDocuments } from "../../../../../../Services";
import { ScopeDocumentEnum } from "../../../../../../Enums";
import { useTranslate } from "../../../../../../Hooks";
import ContactShareDocumentsDialogContent from "./UI/ContactShareDocumentsDialogContent";
import { StaticLookupsIds } from "../../../../../../assets/json/StaticLookupsIds";
import ContactUploadDocument from "../ContactUploadDocument/ContactUploadDocument";
import { GetParams } from "../../../../../../Helper";

// Styles
import useStyles from "./styles";

const ContactDocuments = forwardRef(({ contactId }, ref) => {
  const styles = useStyles();

  const { translate } = useTranslate("NewContactsView");

  const [isShareConfirmed, setIsShareConfirmed] = useState(false);
  const [isLoadingDoucoment, setIsLoadingDoucoment] = useState(true);
  const [isOpenAddNewDoucoumentDialog, setIsOpenAddNewDoucoumentDialog] =
    useState(false);
  const [updatedGetAllScopeDocuments, setUpdatedGetAllScopeDocuments] =
    useState(false);

  useImperativeHandle(ref, () => ({
    handleAddDoucomentContact: () => {
      setIsOpenAddNewDoucoumentDialog(true);
    },
  }));

  const [documents, setDocuments] = useState({
    result: [],
    totalCount: 0,
  });
  const [pagination, setPagination] = useState({
    pageSize: 25,
    pageIndex: 1,
  });
  const [inputValue, setInputValue] = useState(pagination.pageIndex);
  const [selectedDocumentToShare, setSelectedDocumentToShare] = useState(null);
  const [isShareDocumentsDialogOpen, setIsShareDocumentsDialogOpen] =
    useState(false);

  const getAllScopeDocuments = async () => {
    setIsLoadingDoucoment(true);
    const body = {
      scopeId: contactId,
      pageSize: pagination.pageSize,
      pageIndex: pagination.pageIndex - 1,
      scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
    };
    try {
      const res = await GetAllScopeDocuments(body);

      if (!(res?.status && res.status !== 200))
        setDocuments({
          result: res.result,
          totalCount: res.totalCount,
        });
      else setDocuments({ result: [], totalCount: 0 });
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    } finally {
      setIsLoadingDoucoment(false);
      setUpdatedGetAllScopeDocuments(false);
    }
  };

  const onShareClickHandler = (document) => {
    setIsShareDocumentsDialogOpen(true);
    setSelectedDocumentToShare(document);
  };

  const onProceedClicked = () => {
    setIsShareConfirmed(true);
  };

  useEffect(() => {
    if (contactId) {
      getAllScopeDocuments();
    }
  }, [contactId, pagination]);

  useEffect(() => {
    if (updatedGetAllScopeDocuments) {
      getAllScopeDocuments();
    }
  }, [updatedGetAllScopeDocuments]);
  return (
    <Box>
      <Box className={styles.gridContainer}>
        {isLoadingDoucoment ? (
          <DocumentsContactCardSkeleton CardSkeletonNumber={8} />
        ) : documents?.result?.length > 0 ? (
          documents?.result?.map((document) => (
            <DocumentsContactCard
              key={document}
              documentItem={document}
              onShareClickHandler={onShareClickHandler}
              setUpdatedGetAllScopeDocuments={setUpdatedGetAllScopeDocuments}
              documents={documents}
            />
          ))
        ) : (
          <NoDataFound title="documents contacts" />
        )}
      </Box>
      {documents.result.length > 0 && (
        <CustomPagination
          hideInMobile
          totalItems={documents.totalCount}
          itemsPerPage={pagination.pageSize}
          currentPage={pagination.pageIndex}
          inputValue={inputValue}
          setInputValue={setInputValue}
          onPageChange={(page) =>
            setPagination((prev) => ({ ...prev, pageIndex: page }))
          }
          onItemsPerPageChange={(items) =>
            setPagination((prev) => ({ ...prev, pageSize: items }))
          }
          isLoading={isLoadingDoucoment}
        />
      )}
      <ContactUploadDocument
        open={isOpenAddNewDoucoumentDialog}
        close={() => {
          setIsOpenAddNewDoucoumentDialog(false);
        }}
        lookupTypeId={
          GetParams("formType") == "2"
            ? StaticLookupsIds.CompanyUploadDocumentsType
            : StaticLookupsIds.ContactUploadDocumentsType
        }
        translate={translate}
        contactId={contactId}
        setUpdatedGetAllScopeDocuments={setUpdatedGetAllScopeDocuments}
      />

      {isShareDocumentsDialogOpen && (
        <CustomDialog
          open={true}
          width="688px"
          title={translate("share-document")}
          subtitle={translate("share-document-dialog-sub-title")}
          confirmText={translate("proceed")}
          onConfirm={() => onProceedClicked()}
          cancelText={translate("cancel")}
          onClose={() => setIsShareDocumentsDialogOpen(false)}
          isDisabled={false}
          content={
            <React.Fragment>
              <ContactShareDocumentsDialogContent
                translate={translate}
                document={selectedDocumentToShare}
                isShareConfirmed={isShareConfirmed}
                setIsShareConfirmed={setIsShareConfirmed}
                setIsShareDocumentsDialogOpen={setIsShareDocumentsDialogOpen}
              />
            </React.Fragment>
          }
        />
      )}
    </Box>
  );
});

export default ContactDocuments;
