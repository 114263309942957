import moment from 'moment';
import {
    ContactTypeEnum,
    UnitsOperationTypeEnum,
    UnitsStatusEnum,
} from '../../../../Enums';
import { GlobalTranslate, NumberWithCommas } from '../../../../Helper';

export const UnitMapper = (item, unit) => {
    if (unit) {
        const operationType = unit.operation_type && unit.operation_type.lookupItemId;
        let unitStatus;
        for (const el in UnitsStatusEnum)
            if (UnitsStatusEnum[el].key === item.status) unitStatus = UnitsStatusEnum[el];

        return {
            ...unit,
            allunitImages: item.unitImages,
            createdBy: item.createdBy,
            updatedBy: item.updatedBy,
            isBulkUpload: item.isBulkUpload,
            isFavorite: item.isFavorite,
            marketComparison :item.marketComparison || '', 
            lastActivityDate: item.lastActivityDate,
            lastActivtyTypeName: item.lastActivtyTypeName,
            portals:item.portalsJson && JSON.parse(item.portalsJson) ,
            assignedBy: item.assignedBy,
            updatedOwnerDate: moment(item.updatedOwnerDate).isValid() && moment(item.updatedOwnerDate).format('YYYY-MM-DD') || null,
            statusDate: moment(item.statusDate).isValid() && moment(item.statusDate).format('YYYY-MM-DD') || null,
            statusCreatedByName: item.statusCreatedByName,
            refNo: unit.unit_ref_no || 'N/A',
            id: item.unitId,
            type: ContactTypeEnum.corporate.value,
            name: `${(unit.unit_type && unit.unit_type.lookupItemName) || ''} ${unit.property_name ? unit.property_name.name || unit.property_name : ''
                } ${unit.unit_number || ''}`,
            propertyName:
                (unit.property_name && unit.property_name.name) ||
                unit.property_name ||
                '',
            createdOn: item.createdOn,
            status: item.status,
            updateDate: item.updateOn,
            isPublishUnitSale: item.isPublishUnitSale,
            lastSalePublishDate: item.lastSalePublishDate,
            financeValue: unit.finance_value,
            matchingLeads: (unit.matching_leads && unit.matching_leads) || [],
            matchingLeadsNumber: (unit.matching_leads && unit.matching_leads.length) || 0,
            matchUnit: item.matchUnit || 0,
            unitTypeId: unit.unit_type_id,
            unitTransactionId: unit.unitTransactionId,
            unitType:
                unit.unit_type && unit.unit_type !== '[object Object]' ?
                    unit.unit_type.lookupItemName ||
                    (typeof unit.unit_type !== 'object' && unit.unit_type) ||
                    '' :
                    '',
            progress:
                unit.data_completed && typeof unit.data_completed === 'string' &&
                    unit.data_completed.includes('%') ?
                    +unit.data_completed.substr(0, unit.data_completed.length - 1) :
                    +unit.data_completed,
            progressWithPercentage:
                unit.data_completed && typeof unit.data_completed !== 'string' ?
                    `${unit.data_completed}%` :
                    unit.data_completed,
            operationType,
            unitOperationType:
                operationType === UnitsOperationTypeEnum.rent.key &&
                    operationType !== UnitsOperationTypeEnum.rentAndSale.key ?
                    GlobalTranslate.t('Shared:actions-buttons.rent') :
                    GlobalTranslate.t('Shared:actions-buttons.sale'),
            unitStatus: unitStatus || item.status,

            rent_price_fees:
                operationType === UnitsOperationTypeEnum.rent.key ||
                    operationType === UnitsOperationTypeEnum.rentAndSale.key ?
                    (unit.rent_price_fees && unit.rent_price_fees.rentPerYear
                    ) :
                    'N/A',
            selling_price_agency_fee:
                operationType === UnitsOperationTypeEnum.sale.key ||
                    operationType === UnitsOperationTypeEnum.rentAndSale.key ?
                    (
                        unit.selling_price_agency_fee
                        &&
                        unit.selling_price_agency_fee.salePrice
                    ) :
                    'N/A',

            owner:
                unit && unit.owner && unit.owner.length > 0 ?
                    unit.owner.map((el) => `${el.name}, `) :
                    [],
            unitItem: unit,
            // views: unit.createdOn,
            listing_expiry_date: moment(unit.listing_expiry_date).isValid() && unit.listing_expiry_date,
            listing_date: moment(unit.listing_date).isValid() && unit.listing_date,
            upcoming_sale_vacating_date: moment(unit.upcoming_sale_vacating_date).isValid() && unit.upcoming_sale_vacating_date,
            rent_listing_date: moment(unit.rent_listing_date).isValid() && unit.rent_listing_date,
            rent_listing_expiry_date: moment(unit.rent_listing_expiry_date).isValid() && unit.rent_listing_expiry_date,
            upcoming_lease_vacating_date: moment(unit.upcoming_lease_vacating_date).isValid() && unit.upcoming_lease_vacating_date,
            last_available_for_sale: moment(unit.last_available_for_sale).isValid() && unit.last_available_for_sale,
            last_available_for_rent: moment(unit.last_available_for_rent).isValid() && unit.last_available_for_rent,
            amenities: unit.amenities && unit.amenities.length > 0 ? unit.amenities.map((e) => `${e && e.lookupItemName}, `) : [],
            fitting_and_fixtures: unit.fitting_and_fixtures && unit.fitting_and_fixtures.length > 0 ? unit.fitting_and_fixtures.map((e) => `${e && e.lookupItemName}, `) : [],

            flatContent: [
                {
                    iconClasses: 'mdi mdi-bed-outline',
                    title: null,
                    value: unit.bedrooms ? unit.bedrooms : GlobalTranslate.t('Shared:any'),
                },
                {
                    iconClasses: 'mdi mdi-shower',
                    title: null,
                    value: unit.bathrooms ? unit.bathrooms : GlobalTranslate.t('Shared:any'),
                },
                {
                    iconClasses: 'mdi mdi-ruler-square',
                    title: 'sqf',
                    value: unit.builtup_area_sqft ? unit.builtup_area_sqft : 'N/A',
                },
                {
                    iconClasses: 'mdi mdi-broom',
                    title: '',
                    value: unit.maid_rooms ? unit.maid_rooms : 'N/A',
                },
            ],
            details: [
                {
                    iconClasses: 'mdi mdi-domain',
                    title: 'unit-type',
                    value:
                        unit.unit_type && unit.unit_type !== '[object Object]' ?
                            unit.unit_type.lookupItemName ||
                            (typeof unit.unit_type !== 'object' && unit.unit_type) ||
                            'N/A' :
                            'N/A',
                },
                {
                    iconClasses: 'mdi mdi-point-of-sale',
                    title: 'furnished',
                    value: unit.furnished ? unit.furnished : 'N/A',
                },
                {
                    iconClasses: 'mdi mdi-point-of-sale',
                    title: 'listing-agent',
                    value: unit.listing_agent ? unit.listing_agent.name : 'N/A',
                },
                {
                    iconClasses: 'mdi mdi-window-open-variant',
                    title: 'views',
                    value:
                        (unit && unit.primary_view && unit.primary_view.length > 0 ? unit.primary_view.map((el, index) => `${el.lookupItemName}${unit.primary_view.length - 1 !== index ? ' , ' : ' '}`) : 'N/A')

                },
                {
                    iconClasses: 'mdi mdi-point-of-sale',
                    title: 'unit-model',
                    value: unit.unit_model ? unit.unit_model : 'N/A',
                },
                {
                    iconClasses: 'mdi mdi-point-of-sale',
                    title: 'sale-type',
                    value: unit.sale_type ?
                        unit.sale_type.lookupItemName || unit.sale_type :
                        'N/A',
                },
                {
                    iconClasses: 'mdi mdi-point-of-sale',
                    title: 'floor-number',
                    value: unit.floor_number ? unit.floor_number : 'N/A',
                },
                {
                    iconClasses: 'mdi mdi-point-of-sale',
                    title: 'rating',
                    value: unit.rating ? unit.rating : 'N/A',
                },

                {
                    iconClasses: 'mdi mdi-point-of-sale',
                    title:
                        operationType === UnitsOperationTypeEnum.sale.key ||
                            operationType === UnitsOperationTypeEnum.rentAndSale.key ?
                            'sale-roi' :
                            'rent-roi',
                    value:
                        operationType === UnitsOperationTypeEnum.sale.key ||
                            operationType === UnitsOperationTypeEnum.rentAndSale.key ?
                            (unit.sale_roi && unit.sale_roi) || 'N/A' :
                            (unit.rent_roi && unit.rent_roi) || 'N/A' || 'N/A',
                },
                {
                    iconClasses: 'mdi mdi-window-open-variant',
                    title: 'lead-owner',
                    value:
                        (unit && unit.lead_owner ? unit.lead_owner.name : 'N/A')

                },
            ],
        };
    }
};
