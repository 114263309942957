import React, { useEffect, useState } from 'react';
import { SelectComponet } from '../../../../../../Components';
import {
  GetLookupItemsByLookupTypeName,
  lookupItemsGetId,
} from '../../../../../../Services';

export const LookupSelect = ({
  lookupTypeId,
  multiple,
  id,
  labelValue,
  placeholder,
  parentTranslationPath,
  translationPath,
  onSelectChanged,
  value,
  excludeLookupByName = [],
  lookupName,
  renderValue,
  className,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getLookupItems = async () => {
    setIsLoading(true);
    let result;
    if (lookupTypeId) {
      result = await lookupItemsGetId({ lookupTypeId });
    }
    if (lookupName) {
      const res = await GetLookupItemsByLookupTypeName({
        lookUpName: lookupName,
        pageIndex: 1,
        pageSize: 100,
      });
      result = res.result;
    }
    if (Array.isArray(result)) {
      setData(
        result.filter((item) =>
          typeof excludeLookupByName === 'string'
            ? item.lookupItemName.toLowerCase() !==
              excludeLookupByName?.toLowerCase()
            : !excludeLookupByName.includes(item.lookupItemName.toLowerCase())
        )
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getLookupItems();
  }, []);
  return (
    <div>
      <SelectComponet
        idRef={id}
        multiple={multiple}
        data={data}
        valueInput='lookupItemId'
        textInput='lookupItemName'
        labelValue={labelValue}
        value={value}
        placeholder={placeholder}
        wrapperClasses={className ? className : 'my-2'}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        isLoading={isLoading}
        onSelectChanged={(value) =>
          onSelectChanged(
            value,
            data.find((item) => item.lookupItemId === value)
          )
        }
        renderValue={
          renderValue &&
          !multiple &&
          ((value) =>
            renderValue(
              value,
              data.find((item) => item.lookupItemId === value)
            ))
        }
      />
    </div>
  );
};
