import _ from 'lodash';
import { config } from '../../../config';

export const mapStateToCreatePaymentPlanDto = ({
  masterDevelopers,
  secondaryDevelopers,
  downPaymentAmountPercentage,
  handoverAmountPercentage,
  postHandoverInstallment,
  installment,
  ...state
}) => {
  const paymentPlanDevelopers = [];
  masterDevelopers.forEach((masterDeveloper) => {
    paymentPlanDevelopers.push({
      developerId: masterDeveloper.developerId,
      isSecondary: false,
    });
  });
  secondaryDevelopers.forEach((secondaryDeveloper) => {
    paymentPlanDevelopers.push({
      developerId: secondaryDeveloper.developerId,
      isSecondary: true,
    });
  });

  const propertyPlanInstallments = [...postHandoverInstallment, ...installment];
  propertyPlanInstallments.push({
    amountPercentage: downPaymentAmountPercentage / 100,
    installmentTypeId: config.InstallmentTypes.DownPayment,
  });
  propertyPlanInstallments.push({
    amountPercentage: handoverAmountPercentage / 100,
    installmentTypeId: config.InstallmentTypes.HandoverPayment,
  });
  return {
    paymentPlanName: state.paymentPlanName,
    description: state.description,
    paymentTypeId: state.paymentTypeId,
    propertyPlanStatus: state.propertyPlanStatus,
    isForSpecificProperty: state.isForSpecificProperty,
    propertyId: state.propertyId,
    selectedConfigrationId: state.selectedConfigurationId,
    paymentPlanDocuments: state.paymentPlanDocuments,
    id: state.id,
    paymentPlanDevelopers,
    propertyPlanInstallments,
  };
};

export const mapActiveItemToState = ({
  paymentPlanDevelopers,
  ...activeItem
}) => {
  const masterDevelopers = paymentPlanDevelopers.filter(
    (developer) => !developer.isSecondary
  );
  const secondaryDevelopers = paymentPlanDevelopers.filter(
    (developer) => developer.isSecondary
  );
  const isForSpecificDeveloper = !!(
    masterDevelopers.length || secondaryDevelopers.length
  );
  const plans = _.groupBy(
    activeItem.propertyPlanInstallments,
    'installmentTypeId'
  );
  const downPaymentAmountPercentage =
    plans[config.InstallmentTypes.DownPayment]?.[0]?.amountPercentage * 100;
  const handoverAmountPercentage =
    plans[config.InstallmentTypes.HandoverPayment]?.[0]?.amountPercentage * 100;
  const installment = plans[config.InstallmentTypes.Installment] ?? [];
  installment.forEach((item) => {
    item.amountPercentage = item.amountPercentage;
    item.frequencyValue = item.instalmentDate;
    item.id = crypto.randomUUID();
    item.numberOfInstallment = item.installmentNumber;
  });
  const postHandoverInstallment =
    plans[config.InstallmentTypes.PostHandoverInstallment] ?? [];
  postHandoverInstallment.forEach((item) => {
    item.amountPercentage = item.amountPercentage;
    item.frequencyValue = item.instalmentDate;
    item.id = crypto.randomUUID();
  });
  return {
    paymentPlanName: activeItem.paymentPlanName,
    description: activeItem.description,
    propertyPlanStatus: activeItem.propertyPlanStatusId,
    isConfigurationSelected: activeItem.isConfigurationSelected,
    isForSpecificProperty: activeItem.isForSpecificProperty,
    paymentPlanDocuments: activeItem.paymentPlanDocuments,
    paymentTypeId: activeItem.planTypeId,
    id: activeItem.propertyPaymentPlanId,
    isForSpecificDeveloper,
    secondaryDevelopers,
    masterDevelopers,
    downPaymentAmountPercentage,
    handoverAmountPercentage,
    installment,
    postHandoverInstallment,
    isForSpecificProperty: activeItem.isForSpecificProperty,
    propertyId: activeItem.propertyId,
    selectedConfigrationId: activeItem.selectedConfigrationId,
  };
};

const installmentFormatter = (item) => {
  if (item.frequencyName?.toLowerCase() === 'date') {
    return `${(item.amountPercentage * 100).toFixed()}% on ${
      item.instalmentDate
    }`;
  }
  if (item.installmentNumber) {
    return `${(item.amountPercentage * 100).toFixed()}% X ${
      item.installmentNumber
    } installment (every ${item.instalmentDate} ${
      +item.instalmentDate > 1 ? 'months' : 'month'
    })`;
  }
  return `${(item.amountPercentage * 100).toFixed()}% over ${
    item.instalmentDate
  } ${item.frequencyName}${+item.instalmentDate > 1 ? 's' : ''}`;
};

export const formatPaymentPlansForTableFields = (plans = []) => {
  const formattedPlans = plans.map((plan) => {
    const installments = _.groupBy(
      plan.propertyPlanInstallments,
      'installmentTypeId'
    );
    const monthlyInstallment = installments[config.InstallmentTypes.Installment]
      ?.map(installmentFormatter)
      .join(', ');
    const downPayment =
      installments[config.InstallmentTypes.DownPayment]?.[0]
        ?.amountPercentage &&
      `${
        +installments[config.InstallmentTypes.DownPayment]?.[0]
          ?.amountPercentage * 100 ?? 0
      }%`;
    const handoverPayment =
      installments[config.InstallmentTypes.HandoverPayment]?.[0]
        ?.amountPercentage &&
      `${
        +installments[config.InstallmentTypes.HandoverPayment]?.[0]
          ?.amountPercentage * 100 ?? 0
      }%`;

    const postHandover = installments[
      config.InstallmentTypes.PostHandoverInstallment
    ]
      ?.map(installmentFormatter)
      .join(', ');
    if (installments[config.InstallmentTypes.Installment]) {
      installments[config.InstallmentTypes.Installment].forEach((item) => {
        item.frequencyValue = item.instalmentDate;
        item.numberOfInstallment = item.installmentNumber;
      });
    }
    if (installments[config.InstallmentTypes.PostHandoverInstallment]) {
      installments[config.InstallmentTypes.PostHandoverInstallment].forEach(
        (item) => {
          item.frequencyValue = item.instalmentDate;
        }
      );
    }
    plan.propertyPlanStatus = plan.propertyPlanStatusId;
    plan.paymentTypeId = plan.propertyPlanStatusId;
    return {
      ...plan,
      downPayment,
      handoverPayment,
      monthlyInstallment,
      postHandover,
    };
  });
  return formattedPlans;
};

export const createPropertyPaymentPlanDto = (paymentPlan) => {
  const { propertyPlanInstallments } = paymentPlan;
  return {
    propertyPlanInstallments,
    propertyPlanStatus: paymentPlan.propertyPlanStatusId,
    paymentTypeId: paymentPlan.planTypeId,
    description: paymentPlan.description,
    isForSpecificProperty: true,
    propertyId: paymentPlan.propertyId,
    selectedConfigrationId: paymentPlan.propertyPaymentPlanId,
    paymentPlanDocuments: paymentPlan.paymentPlanDocuments,
    paymentPlanName: paymentPlan.paymentPlanName,
  };
};
