import React from "react";
import { useTranslation } from "react-i18next";
import { NumberWithCommas } from '../../../../../../Helper' ; 


export const GaugeChart = ({
  minPrice,
  maxPrice,
  medianPrice,
  psiPrice,
  translationPath,
  parentTranslationPath,
}) => { 
    const { t } = useTranslation(parentTranslationPath, "Shared");
  

  const rawHTML = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">

    <title>Gauge Chart</title>
    <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
    <style>
      body {
        font-family: Arial, sans-serif;
        background-color: '#E0E0E0';
        color: '#2b570f';
        margin: 10px ;
        padding: 10px;
        text-align: center;

       
      }
      h3 {
        margin-bottom: 15px;
      }
      .gauge-container {
        max-width: 250px;
        max-height: 250px;
      }
    .info-line {
      display : flex , 
      flex-diraction : column , 
      margin-top: 15px;
      font-size: 14px;
      line-height: .8;
      info-values
      { 




}}
          .info-line div {
      margin-bottom: 10px; 
    }
       .avg {
        font-family: Arial, sans-serif;
        font-weight: bold;
        font-size: 18px;
        text-align: center ;
        margin-bottom: 10px;

    }
         .psi-price {
        margin-top: 10px;
        font-family: Arial, sans-serif;
        color: #2153b1;
        font-weight: bold;
        font-size: 14;
        text-align: center ;

    }
        .price {
         margin:0 auto ; 
         max-width: 200px;
         max-height:200px;

    }
   
    </style>
    </head>
    <body>
      <h3> 
      ${t(`${translationPath}gauge-chart`)}
      </h3>
        <div id="psi-price" class="psi-price">

        </div>
                   <span class="mdi mdi-home-currency-usd" />

      <div class="gauge-container">
        <canvas class= 'price' id="gauge-chart"></canvas>
      </div>
      <div id="avg" class="avg"></div>
        <div class="info-line" id="info-line">
    <div id="min-value"></div>
    <div id="max-value"></div>
  </div>
          

   
      <script>
      
        const pointerPlugin = {
          id: 'pointerPlugin',
          afterDatasetDraw: (chart, args, options) => {
            const {
              ctx,
              chartArea
            } = chart;
            const {
              width,
              height
            } = chartArea;
            const minPrice = options.minPrice || ${minPrice};
            const maxPrice = options.maxPrice ||  ${maxPrice};
            const medianPrice = options.medianPrice || ${medianPrice};

            const pointerValue = options.psiPrice || ${psiPrice};

            const centerX = width / 2 + chartArea.left ;
            const centerY = height -  54  + chartArea.top;
            const radius = chart.getDatasetMeta(0).data[0].outerRadius;
            const getCoordinates = (value) => {
              const angle = Math.PI * (value - minPrice) / (maxPrice - minPrice);
              return {
                x: centerX + radius * Math.cos(angle - Math.PI),
                y: centerY + radius * Math.sin(angle - Math.PI),
              };
            };
            const pointerCoords = getCoordinates(pointerValue);
            const xValue =  ${
              psiPrice === minPrice || psiPrice === maxPrice
            }?Number(0):Number(-60); 
             const lineToX =  ${
               psiPrice === minPrice || psiPrice === maxPrice
             }?Number(-1):Number(0); 
             const arcY =  ${
               psiPrice === minPrice || psiPrice === maxPrice
             }?Number(15):Number(15); 
             const arcX =  ${psiPrice === maxPrice}? Number(-8) : ${
             psiPrice === minPrice
          }  ? Number(8):Number(-2); 



            const textAlignType =  ${
              psiPrice === minPrice
            }? 'left'  :  'right'  ; 
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(pointerCoords.x + lineToX   ,pointerCoords.y + 15 );
        ctx.lineWidth = 3; // تقليل سماكة الخط
        ctx.strokeStyle = 'yellow';
        ctx.stroke(); 
        ctx.restore();

        // Draw Pointer Circle
        ctx.beginPath();
        ctx.arc(pointerCoords.x +  arcX , pointerCoords.y +  arcY , 6, 0, 2 * Math.PI);
        ctx.fillStyle = 'yellow';
        ctx.fill();
        ctx.restore();

         // Display Pointer Value (PSI Price)
        ctx.font = '12px Arial';
        ctx.fillStyle = 'black';
        ctx.textAlign = textAlignType;
        // ctx.fillText("${psiPrice}", pointerCoords.x -(xValue)  , pointerCoords.y  );
         
          },
        };
         let priceValues = {
          psiPrice: ${psiPrice},
          maxPrice:${maxPrice},
          minPrice: ${minPrice},
          medianPrice:${medianPrice}
        };
        if(priceValues.psiPrice !== null &&  priceValues.psiPrice !== undefined &&  priceValues.psiPrice !== "" && priceValues.psiPrice !== 0 && priceValues.maxPrice !== priceValues.minPrice  )
        Chart.register(pointerPlugin);
       

        
        const priceValuesColors = () => {

          let chartColorsAndValues = null  ;


      

       
         if (priceValues.psiPrice === 0 ) 
           {

            chartColorsAndValues = {
             data: [priceValues.maxPrice - (priceValues.maxPrice * .95)  ,  priceValues.maxPrice - (priceValues.maxPrice - (priceValues.maxPrice * .95)) ],
              backgroundColor: ['#ADF802', '#E0E0E0'],
              hoverBackgroundColor:  ['#ADF802', '#E0E0E0'],
            };
            return  chartColorsAndValues; 

          }
          
          else   if (priceValues.psiPrice > priceValues.medianPrice) {
            chartColorsAndValues = {
              data: [priceValues.maxPrice, 0],
              backgroundColor: ['#ef0433', '#E0E0E0'],
              hoverBackgroundColor: ['#ef0433', '#E0E0E0'],
            };
            return  chartColorsAndValues; 

          }
           else   if (priceValues.psiPrice  < priceValues.minPrice && priceValues.minPrice === priceValues.maxPrice) {
            chartColorsAndValues = {
              data: [priceValues.minPrice  -  priceValues.psiPrice    , priceValues.maxPrice -(priceValues.minPrice  -  priceValues.psiPrice ) ],
              backgroundColor: ['#59cc0e', '#E0E0E0'],
              hoverBackgroundColor:  ['#59cc0e', '#E0E0E0'],
            };
            return  chartColorsAndValues; 

          }
          else   if (priceValues.psiPrice  ===  priceValues.medianPrice && priceValues.minPrice === priceValues.maxPrice) {
            chartColorsAndValues = {
               data: [priceValues.psiPrice / 2  , priceValues.psiPrice / 2 ],
              backgroundColor: ['#bedffe', '#E0E0E0'],
              hoverBackgroundColor: ['#bedffe', '#E0E0E0'],
            };
            return  chartColorsAndValues; 

          }
          else  if (priceValues.minPrice === priceValues.maxPrice) 
           {
              chartColorsAndValues = {
              data: [priceValues.maxPrice ,0],
              backgroundColor: ['#FFC0CB','#FFC0CB'],
              hoverBackgroundColor: ['#FFC0CB', '#FFC0CB'],
            };
             return  chartColorsAndValues; 

          }
             else if (priceValues.psiPrice === priceValues.minPrice &&  priceValues.psiPrice ===  priceValues.medianPrice ) {
            chartColorsAndValues = {
              data: [priceValues.psiPrice , priceValues.maxPrice - priceValues.psiPrice],
              backgroundColor: ['#bedffe', '#E0E0E0'],
              hoverBackgroundColor: ['#bedffe', '#E0E0E0'],
            };
            return  chartColorsAndValues; 
            
          }
            else if (priceValues.psiPrice === priceValues.minPrice &&  priceValues.psiPrice <   priceValues.medianPrice ) {
            chartColorsAndValues = {
              data: [priceValues.psiPrice  , priceValues.maxPrice - priceValues.psiPrice],
              backgroundColor: ['#59cc0e', '#E0E0E0'],
              hoverBackgroundColor: ['#59cc0e', '#E0E0E0']
            };
             return  chartColorsAndValues; 

            
          }
             else if (priceValues.psiPrice !== priceValues.minPrice  &&  priceValues.psiPrice === priceValues.medianPrice ) {
            chartColorsAndValues = {  data: [priceValues.psiPrice - priceValues.minPrice, priceValues.maxPrice - priceValues.psiPrice],
              backgroundColor: ['#bedffe', '#E0E0E0'],
              hoverBackgroundColor: ['#bedffe', '#E0E0E0'],
            };
            return  chartColorsAndValues; 
            
          }
            else {
            chartColorsAndValues = {
            data: [priceValues.psiPrice - priceValues.minPrice, priceValues.maxPrice - priceValues.psiPrice],
            backgroundColor: ['#59cc0e', '#E0E0E0'],
            hoverBackgroundColor: ['#59cc0e', '#E0E0E0'] 
          };
          }
          return chartColorsAndValues;
        };
        const dataOfUnit = priceValuesColors();
        const data = {
          labels: [],
          datasets: [{
            data: dataOfUnit?.data ?  dataOfUnit?.data  : [ priceValues.maxPrice  , 0 ] ,
            backgroundColor: dataOfUnit?.backgroundColor,
            hoverBackgroundColor: dataOfUnit?.hoverBackgroundColor,
            borderWidth: [0],
          }],
        };
        const config = {
          type: 'doughnut',
          data: data,
          options: {
            rotation: -90,
            circumference: 180,
            cutout: '70%',
            plugins: {
              legend: {
                display: true
              },
              tooltip: {
                enabled: false
              },
              pointerPlugin: {
                value: priceValues.psiPrice,
                minPrice: priceValues.minPrice,
                maxPrice: priceValues.maxPrice,
                medianPrice: priceValues.medianPrice,
              },
            },
          },
        };
        const ctx = document.getElementById('gauge-chart').getContext('2d');
        new Chart(ctx, config);
         document.getElementById('psi-price').textContent = "${t(`${translationPath}psi-price`)} : ${psiPrice && NumberWithCommas(psiPrice)} AED 💰";

        document.getElementById('avg').textContent = "${t(`${translationPath}avrege`)} : ${NumberWithCommas(medianPrice)}";
        document.getElementById('min-value').textContent = "${t(`${translationPath}min-price`)} : ${NumberWithCommas(minPrice)}";
        document.getElementById('max-value').textContent = "${t(`${translationPath}max-price`)}  : ${NumberWithCommas(maxPrice)} ";

      </script>
    </body>
    </html>
  `;

  return (
    <iframe
    className="mb-2"
      title="Gauge Chart"
      srcDoc={rawHTML}
      style={{ width: "100%", height: "400px", border: "none" }}
    />
  );
};

export default GaugeChart;
