import React from "react";
import PropTypes from "prop-types";
import { ButtonGroup, Button } from "@material-ui/core";

import { useSelectedTheme, useTranslate } from "../../../Hooks";

// Icons
import { FileShieldIcon, FileCheck02Icon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

// Constants for view modes
export const DNCR_TAB_MODES = {
  DNCR: "DNCRView",
  CONSENT: "ConsentView",
};

function DNCRSwitcher({ dncrMode, onChangeViewMode }) {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewContactsView");

  return (
    <ButtonGroup
      className={styles.viewModeBtnGroup}
      size="medium"
      disableRipple
    >
      <Button
        className={dncrMode === DNCR_TAB_MODES.DNCR ? styles.isActiveBg : ""}
        onClick={() => onChangeViewMode(DNCR_TAB_MODES.DNCR)}
      >
        <FileShieldIcon
          width="20"
          height="20"
          fill={palette.button.secondary_fg}
        />
        <span>{translate("DNCR")}</span>
      </Button>
      <Button
        className={dncrMode === DNCR_TAB_MODES.CONSENT ? styles.isActiveBg : ""}
        onClick={() => onChangeViewMode(DNCR_TAB_MODES.CONSENT)}
      >
        <FileCheck02Icon
          width="20"
          height="20"
          fill={palette.button.secondary_fg}
        />
        <span>{translate("CONSENT")}</span>
      </Button>
    </ButtonGroup>
  );
}

DNCRSwitcher.propTypes = {
  dncrMode: PropTypes.oneOf([DNCR_TAB_MODES.DNCR, DNCR_TAB_MODES.CONSENT]),
  onChangeViewMode: PropTypes.func.isRequired,
};

DNCRSwitcher.defaultProps = {
  dncrMode: DNCR_TAB_MODES.CONSENT,
};

export default DNCRSwitcher;
