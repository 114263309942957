import { config } from "../config";
import { HttpServices } from "../Helper";

export const CreateFavoriteFolders = async (body) => {
  try {
    const url = `${config.server_address}/CrmDfm/FavoriteFolders/CreateFavoriteFolder`;
    const result = await HttpServices.post(url, body);
    return result;
  } catch (error) {
    throw error.response;
  }
};

// export const GetAllFavoriteFolders = async ({ favoriteFolderType, search }) => {
//   const queryList = [];
//   if (search) queryList.push(`search=${search}`);

//   const result = await HttpServices.get(
//     `${
//       config.server_address
//     }/CrmDfm/FavoriteFolders/GetAllFavoriteFolders/${favoriteFolderType}?${queryList.join(
//       "&"
//     )}`
//   )
//     .then((data) => data)
//     .catch((error) => error.response);
//   return result;
// };
export const GetAllFavoriteFolders = async ({ favoriteFolderType, search }) => {
  try {
    const queryList = [];

    if (search) queryList.push(`search=${search}`);
    
    const url = `${config.server_address}/CrmDfm/FavoriteFolders/GetAllFavoriteFolders/${favoriteFolderType}?${queryList.join("&")}`;
    const result = await HttpServices.get(url);
    
    return result;  
  } catch (error) {
    throw error.response;
  }
};

export const DeleteFavoriteFolder = async (favoriteFolderId) => {
  try {
    const url = `${config.server_address}/CrmDfm/FavoriteFolders/DeleteFavoriteFolder/${favoriteFolderId}`;
    const result = await HttpServices.delete(url);
    return result;
  } catch (error) {
    throw error.response;
  }
};

export const StoreFavoriteUnits = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/FavoriteFolders/StoreFavoriteUnits`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const StoreFavoriteLeads = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/FavoriteFolders/StoreFavoriteLeads`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const StoreFavoriteContacts = async (body) => {
  try {
    const url = `${config.server_address}/CrmDfm/FavoriteFolders/StoreFavoriteContacts`;
    const result = await HttpServices.post(url, body);
    return result;
  } catch (error) {
    throw error.response;
  }
};

export const UpdateFavoriteFolder = async (body) => {
  const result = await HttpServices.patch(
    `${config.server_address}/CrmDfm/FavoriteFolders/UpdateFavoriteFolder`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RemoveLeadsOrUnitsFromFolder = async (type, ids, folderId) => {
  const endpoint = type === 'LEAD' ? 'RemoveLeadsFromFolder' : 'RemoveUnitsFromFolder';
  const body = type === 'LEAD' 
    ? { leadIds: ids, folderId: folderId }
    : { unitIds: ids, folderId: folderId };

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/FavoriteFolders/${endpoint}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  
  return result;
};

export const RemoveContactsFromFolder = async (body) => {
  try {
    const url = `${config.server_address}/CrmDfm/FavoriteFolders/RemoveContactsFromFolder`;
    const result = await HttpServices.post(url, body);
    return result;  
  } catch (error) {
    throw error.response;
  }
};
