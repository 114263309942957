export const actionTypes = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  FILL: 'FILL',
  EDIT: 'EDIT',
  SET_STATE: 'SET_STATE',
};

export const initialPaymentPlan = (propertyId) => ({
  propertyPlanStatus: 1,
  paymentPlanName: '',
  paymentTypeId: null,
  description: '',
  isForSpecificProperty: !!propertyId,
  isForSpecificDeveloper: false,
  selectedConfigurationId: null,
  masterDevelopers: [],
  secondaryDevelopers: [],
  paymentPlanDocuments: [],
  downPaymentAmountPercentage: 0,
  handoverAmountPercentage: 0,
  postHandoverInstallment: [],
  installment: [],
  propertyId,
});
