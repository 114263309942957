import React from "react";

function key01Icon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M14.54 2.023c-.825.079-1.29.177-1.92.404-2.41.868-4.129 2.968-4.545 5.553-.069.428-.069 1.667 0 2.114l.054.354-2.879 2.886c-2.766 2.773-2.885 2.898-3.025 3.186-.08.165-.163.397-.185.515-.066.358-.05 3.051.021 3.384.086.408.237.685.536.984.299.299.576.45.984.536.387.083 3.521.085 3.749.004.221-.079.414-.241.539-.454.107-.182.111-.212.124-.839l.014-.65.586-.002c.385-.001.639-.02.737-.055.221-.079.414-.241.539-.454.107-.182.111-.212.124-.839l.014-.65h.569c.868 0 .778.052 1.971-1.131l1.007-.998.353.054c.445.069 1.685.069 2.113 0 3.088-.496 5.408-2.816 5.906-5.905.028-.176.051-.635.051-1.02 0-.944-.101-1.538-.404-2.38-.858-2.383-2.992-4.147-5.471-4.522-.376-.057-1.289-.101-1.562-.075m1.012 2.02a5.008 5.008 0 0 1 4.128 3.205c.412 1.072.405 2.472-.017 3.545a5.098 5.098 0 0 1-2.451 2.683c-.944.464-1.822.599-3.033.464-.703-.077-.97-.067-1.306.052-.357.126-.556.287-1.456 1.175l-.842.833h-.841c-.922 0-1.1.029-1.352.221-.08.061-.195.194-.254.295-.103.176-.109.212-.121.834l-.014.65h-.55c-.795 0-1.077.111-1.315.516-.103.176-.109.212-.121.834l-.014.65H4v-2.578l2.834-2.841c1.559-1.563 2.887-2.922 2.952-3.021.22-.337.333-.688.334-1.04 0-.121-.03-.49-.067-.82-.154-1.382.136-2.547.902-3.62a4.961 4.961 0 0 1 4.597-2.037m-.857 2.011a.997.997 0 0 0-.497 1.537c.171.236.41.362.774.408.383.049.537.113.727.302.19.19.253.344.302.73.048.379.183.616.451.797.471.316 1.102.17 1.407-.325.115-.188.121-.218.119-.62-.001-.324-.023-.493-.093-.723-.299-.976-1.076-1.756-2.022-2.03-.4-.115-.941-.151-1.168-.076"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default key01Icon;
