import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  CustomButton,
  CustomDialog,
  CustomInput,
  DropzoneComponent,
  GetFileIconbyDocumentName,
} from "../../../../../../Components";
import {
  useQuery,
  useSelectedTheme,
  useTranslate,
} from "../../../../../../Hooks";
import { Box, Typography } from "@material-ui/core";
import { DeleteIcon, Download01 } from "../../../../../../assets/icons";
import {
  getDownloadableLink,
  getIsValidDataURL,
  getIsValidURL,
} from "../../../../../../Helper";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { UpdateScopeDocument } from "../../../../../../Services";
import { ScopeDocumentEnum } from "../../../../../../Enums";

//styles
import useStyles from "./styles";

function ContactEditDocumentDialog({
  open,
  onClose,
  scopeCategoryDocumentData,
  setUpdatedGetAllScopeDocuments,
  editUrl,
}) {
  const query = useQuery();
  const contactId = query.get("id");
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { setAlertBoxContent } = useVerticalNav();
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");

  const reducerEditCategoryDocument = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [imageUrl, setImageUrl] = useState("");
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [urlInput, setUrlInput] = useState(null);
  const [categoryDocumentData, setCategoryDocumentData] = useReducer(
    reducerEditCategoryDocument,
    {
      documentLink: null,
      categoryName: null,
      scopeDocumentId: null,
      categoryId: null,
      documentName: null,
      documentId: null,
    }
  );
  const fetchImage = (uploadedFileId) => {
    setImageUrl(getDownloadableLink(uploadedFileId));
  };

  const isImage = [
    "png",
    "jpg",
    "jpeg",
    "gif",
    "bmp",
    "tiff",
    "webp",
    "svg",
  ].includes(
    categoryDocumentData?.documentName?.split(".")?.pop()?.toLowerCase()
  );

  const handleFilesAccepted = (files) => {
    setCategoryDocumentData({ id: "documentName", value: files[0].name });
  };
  const handleValueUrlChange = (value) => {
    setIsUrlValid(false);
    setUrlInput(value);
  };
  const validUrl = () => {
    const linkToValidate = urlInput;
    if (!linkToValidate) {
      return;
    }
    const isValidUrl =
      getIsValidURL(linkToValidate) || getIsValidDataURL(linkToValidate);
    if (isValidUrl) {
      setCategoryDocumentData({
        id: "documentLink",
        value: urlInput,
      });
      setIsUrlValid(false);
    } else {
      setIsUrlValid(true);
    }
  };

  const restFiled = () => {
    setCategoryDocumentData({
      value: {
        documentLink: null,
        categoryName: null,
        scopeDocumentId: null,
        documentName: null,
        documentId: null,
      },
    });
    setUploadedFileId(null);
    setImageUrl("");
  };

  const updateScopeDocument = useCallback(async () => {
    validUrl();
    try {
      const body = {
        scopeId: +contactId,
        scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
        categoryFiles: [
          {
            categoryId: categoryDocumentData?.categoryId,
            files: [
              {
                fileId: uploadedFileId,
                fileName: categoryDocumentData?.documentName,
                documentLink: categoryDocumentData?.documentLink,
              },
            ],
          },
        ],
      };
      const res = await UpdateScopeDocument(body);
      if (!(res && res.status && res.status !== 200)) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: translate("updated-Doucoment-successfully!"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
        setCategoryDocumentData({
          id: "edit",
          value: {
            documentLink: null,
            categoryName: null,
            categoryId: null,
            scopeDocumentId: null,
            documentName: null,
            documentId: null,
          },
        });
        setUploadedFileId(null);
        setUpdatedGetAllScopeDocuments(true);
        onClose();
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: translate("Failed-to-updated-the-document-Please-try-again"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } catch (error) {
      console.error("updated-failed:", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: translate("Something-went-wrong-Please-try-again"),
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    }
  });
  const handleValidationDepandOnViewEdit = () => {
    if (editUrl) {
      return isUrlValid || !urlInput;
    } 
    return false;
  };
  useEffect(() => {
    if (open && scopeCategoryDocumentData) {
      setCategoryDocumentData({
        id: "edit",
        value: {
          documentLink: scopeCategoryDocumentData?.documentLink || null,
          categoryName: scopeCategoryDocumentData?.categoryName || null,
          categoryId: scopeCategoryDocumentData?.categoryId || null,
          scopeDocumentId: scopeCategoryDocumentData?.scopeDocumentId || null,
          documentName: scopeCategoryDocumentData?.documentName || null,
          documentId: scopeCategoryDocumentData?.documentId || null,
        },
      });
      setUrlInput(scopeCategoryDocumentData?.documentLink || null);
      setUploadedFileId(scopeCategoryDocumentData?.documentId || null);
    }
  }, [open,scopeCategoryDocumentData]);

  useEffect(() => {
    setCategoryDocumentData({ id: "documentId", value: uploadedFileId });
    fetchImage(uploadedFileId);
  }, [uploadedFileId]);

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        onClose();
        restFiled(); 
      }}
      title={translate("Edit-document")}
      confirmText={translate("Save-change")}
      onConfirm={() => {
        updateScopeDocument();
      }}
      cancelText={translate("Cancle")}
      width={"688px"}
      subtitle={translate(
        "Create-and-schedule-new-activity-to-enhance-engagement-with-your-contacts-and-leads"
      )}
      isDisabled={handleValidationDepandOnViewEdit()}
      content={
        <Box className={styles.container_content}>
          <Box className={styles.container_Fileds_Doucoment}>
            {/* first design to one link */}

            {editUrl && (
              <Box className={styles.container_Link_Filed}>
                <Box className={styles.container_Title}>
                  <Typography className={styles.titleItem}>
                    {translate("Link")}
                  </Typography>
                </Box>
                <Box className={styles.containerInput}>
                  <CustomInput
                    inputContainerOverrideStyles={styles.inputFullWidth}
                    placeholder={translate("Enter-Url-Link")}
                    hasSearchIcon={false}
                    value={urlInput || null}
                    handleOnBlur={() => {
                      validUrl();
                    }}
                    onChange={(e) => {
                      handleValueUrlChange(e.target.value);
                    }}
                    errorMessage={translate("Invalid_Url")}
                    hasError={isUrlValid}
                    submitted={isUrlValid}
                  />
                </Box>
              </Box>
            )}
          </Box>

        </Box>
      }
    />
  );
}
export default ContactEditDocumentDialog;
