import { useRef } from 'react';

/**
 * 
 * @param {Function} action 
 * @param {number} delay 
 *        @default 700
 * @param {boolean} disableFirstFetch 
    @default false
 * 
 * @example
 * useDebouncedAction(someFunction) and remaining params has default values 
 * @returns {Function}
 */
export const useDebouncedAction = (
  action,
  delay = 700,
  disableFirstFetch = false
) => {
  const timerRef = useRef();
  const isFirstFetch = useRef(true);
  const debouncedAction = (...payload) => {
    if (disableFirstFetch && isFirstFetch.current) {
      isFirstFetch.current = false;
      return;
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => action(...payload), delay);
  };
  return debouncedAction;
};
