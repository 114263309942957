import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    textField: {
      "& .MuiInputBase-root": {
        fontSize: "16px",
        height: "44px", 
        lineHeight: "24px",
        padding: "0px 14px",
        backgroundColor: theme.palette.background.primary,
        boxSizing: "border-box",
        position: "relative",

        "& fieldset": {
          borderRadius: theme.borderRadius.md,
          borderColor: theme.palette.border.primary,
          borderWidth: "1px",
          pointerEvents: "none",
        },
        "&:hover fieldset": {
          borderColor: theme.palette.border.primary,
        },
        "&.Mui-focused:not(.Mui-error) fieldset": {
          borderColor: theme.palette.border.brand,
          borderWidth: "1px",
          boxShadow: theme.shadows[12],
          zIndex: 1
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.background.disabled_subtle,
          "& fieldset": {
            borderColor: theme.palette.border.disabled,
          },
        },
        "&.Mui-error": {
          backgroundColor: theme.palette.background.primary,
          
          "&.Mui-focused": {
            boxShadow: theme.shadows[16],
          },
          "& fieldset": {
            borderColor: theme.palette.border.error,
          },
        },
      },
      "& .MuiFormHelperText-root": {
        color: theme.palette.text.tertiary + " !important",
        marginRight: 0,
        marginLeft: 0,
        padding: 0,
        marginTop: "6px",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
      },

      "& .MuiFormHelperText-root.Mui-error": {
        color : theme.palette.text.error_primary + " !important",
      },
      
      "& .MuiInputBase-input": {
        color: theme.palette.text.primary,
        padding: "0 !important", // Ensure no additional padding inside input
        height: "100%", // Matches parent height
        backgroundColor: "transparent !important", // No additional background
        "&.Mui-disabled": {
          backgroundColor: "orange",
          color: theme.palette.text.disabled,
          "&::placeholder": {
            color: theme.palette.text.disabled,
            opacity: 1,
          },
        },
      },
      "& .MuiInputBase-input::placeholder": {
        color: theme.palette.text.placeholder,
        opacity: 1,
      },
    },
    wrapperSide: {
      gap: "32px",
    },
  };
});
