import React, { useMemo } from 'react';
import { UploadCloudIcon } from '../../../../assets/icons';
import { Cancel } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { uploadFile } from '../../../../Services/FileManagerServices/FileManagerServices.jsx';
import { actionTypes } from '../constants.js';
import { showError } from '../../../../Helper/Tostify.Helper.jsx';

const Attachment = ({ documents, dispatch }) => {
  const files = useMemo(() => documents, [documents]);
  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer?.files) {
      uploadFileHandler(Array.from(event.dataTransfer.files));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const uploadFileHandler = async (files) => {
    try {
      const res = await uploadFile({ file: files.shift() });
      dispatch({
        field: 'paymentPlanDocuments',
        value: [{ fileId: res.uuid, fileName: res.fileName }],
        action: actionTypes.FILL,
      });
    } catch (error) {
      showError('upload_error');
    }
  };

  return files.length > 0 ? (
    <span>
      {files.map((file) => file.fileName).join(',')}
      <IconButton
        onClick={() =>
          dispatch({
            field: 'paymentPlanDocuments',
            value: [],
            action: actionTypes.FILL,
          })
        }
      >
        <Cancel />
      </IconButton>
    </span>
  ) : (
    <>
      <label
        className='attachment-container pointer'
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        htmlFor='file'
      >
        <span className='upload-icon pointer'>
          <UploadCloudIcon />
        </span>
        <p>
          <strong>Click tp upload</strong> or drag and drop
        </p>
        <span>SVG, PNG, JPG or GIF (max. 800x400px)</span>
      </label>
      <input
        type='file'
        className='hidden'
        hidden
        name='file'
        id='file'
        onChange={(e) => uploadFileHandler(Array.from(e.target.files || []))}
      />
    </>
  );
};

export default Attachment;
