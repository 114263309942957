import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wrapperOriginalHistory: {
      border: `1px solid ${theme.palette.background.gray_200}`,
      borderRadius: "12px",
      background: theme.palette.background.paper,
      width: "100%",
    },
    containerHeaderofOriginal: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      alignItems: "center",
      padding: "12px 20px 12px 16px",
      borderBottom: `1px solid ${theme.palette.background.gray_200}`,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    containerTitle: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },
    containerImage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid rgba(0, 0, 0, 0.08)`,
      borderRadius: "99999px",
    },
    imageWrapper: {
      height: "24px",
      width: "24px",
      borderRadius: "99999px",
    },
    containerInformationSction: {
      borderBottom: `1px solid ${theme.palette.background.gray_200}`,
      padding: "12px 20px 12px 16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "&:last-child": {
        borderBottom: "none",
      },
    },
    informationWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    valueInfo: {
      color: theme.palette.text.primary,
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
    },
    titleInfo: {
      color: theme.palette.text.tertiary,
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
    },
  };
});
