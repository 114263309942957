import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => {
  return {
    customMenu: {
      display: "flex",
      flexDirection: "column",
      width: "254px",
      borderRadius: "8px",
      border: `1px solid ${theme.palette.border.secondary}`,
      background: theme.palette.background.primary,
      boxShadow: theme.shadows.lg,
    },
    customMenuList: {
      padding: "0px",
    },
    menuSection: {
      padding: "4px 6px",
      [theme.breakpoints.down("xs")]: {
        padding: "1px",
      },
    },
    labelWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "9px 10px",
      flex: "1 0 0",
      gap: "8px",
    },
    label: {
      display: "flex",
    },
    orderWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    actionsWrapper: {
      display: "flex",
      padding: "16px",
      justifyContent: "center",
      alignItems: "center",
      gap: " 12px",
    },
    buttonSortWrapper: {
      width: "254px",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px 16px",
      "& .MuiIconButton-label": {
        gap: "6px",
        padding: "0px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    buttonContainer: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("376")]: {
        gap: "1px",
      },
    },
    buttonLabel: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      opacity: 0.5,
      width: "56px",
      color: theme.palette.button.tertiaryGray_fg,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.down("376")]: {
        fontSize: "12px",
        lineHeight: "20px",
      },
    },
    selectedValueWrapper: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      color: theme.palette.button.secondary_fg,
      width: "131px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.down("376")]: {
        gap: "1px",
        fontSize: "13px",
      },
    },
    selectedValue: {
      width: "103px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    customMenuItem: {
      paddingBottom: "0px",
      marginBottom: "1px",
      paddingLeft: "6px",
      paddingRight: "6px",
      "&.Mui-selected": {
        backgroundColor: theme.palette.background.primary_hover,
        paddingLeft: "6px",
        paddingRight: "6px",
        borderRadius: "6px",
        "&:hover": {
          backgroundColor: theme.palette.background.primary_hover,
          paddingLeft: "6px",
          paddingRight: "6px",
          borderRadius: "6px",
        },
      },
      "&:hover": {
        backgroundColor: theme.palette.background.primary_hover,
        paddingLeft: "6px",
        paddingRight: "6px",
        borderRadius: "6px",
      },
    },
    menuDivider: {
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      padding: "1px 0px",
    },
  };
});
