import React, { useEffect, useState } from 'react';
import CheckCircle from './CheckCircle';
import { useTranslation } from 'react-i18next';
import { PaymentPlansLocalePath } from '../../../../PaymentPlans/i18n/PaymentPlansLocale';
import { Check } from '@material-ui/icons';

const PaymentPlanCard = ({
  paymentPlanName,
  description,
  handoverPayment,
  monthlyInstallment,
  postHandover,
  downPayment,
  isConfigurationSelected,
  propertyPlanStatusId,
  onSelect,
}) => {
  const { t } = useTranslation(PaymentPlansLocalePath);
  const [selected, setSelected] = useState(isConfigurationSelected);
  useEffect(() => {
    if (isConfigurationSelected) {
      setTimeout(() => setSelected(false), 1000);
    }
  }, [isConfigurationSelected]);
  return (
    <div className='payment-plan-card'>
      <div className='info-container'>
        <span className='title'>{paymentPlanName}</span>
        <span className='text'>{description}</span>
      </div>
      <div className='informative-container'>
        <div className='details-container'>
          <div className='installment-detail'>
            <CheckCircle className='detail-icon' />
            <span className='installment-text'>
              {downPayment} {t('down_payment')}
            </span>
          </div>
          <div className='installment-detail'>
            <CheckCircle className='detail-icon' />
            <span className='installment-text'>
              {monthlyInstallment} {t('monthly_installment')}
            </span>
          </div>
          <div className='installment-detail'>
            <CheckCircle className='detail-icon' />
            <span className='installment-text'>
              {handoverPayment} {t('handover_payment')}{' '}
            </span>
          </div>
          <div className='installment-detail'>
            <CheckCircle className='detail-icon' />
            <span className='installment-text'>
              {postHandover} {t('post_handover')}
            </span>
          </div>
        </div>
        <div className='action-btn-container'>
          {selected ? (
            <button className='selected-btn'>
              <Check fontSize='small' />
              {t('selected')}
            </button>
          ) : (
            <button
              className='select-btn'
              onClick={onSelect}
              disabled={propertyPlanStatusId !== 1}
            >
              {t('select')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPlanCard;
