import Team from "../../Views/Home/Administration/TeamView/Team.View";
import React from "react";

import BusinessGroupsView from "../../Views/Home/Administration/BusinessGroupsView/BusinessGroups.View";
import { LookupsLayout } from "../../Layouts/Home/LookupsLayout/LookupsLayout";
import { PortfolioLayout } from "../../Layouts/Home/PortfolioLayout/PortfolioLayout";
import {
  UserView,
  GroupsView,
  UsersManagementView,
  UserApprovalsView,
} from "../../Views/Home/Administration";
import { BranchesLayout } from "../../Layouts/Home/BranchesLayout/BranchesLayout";
import { FormBuilderLayout } from "../../Layouts/Home/FormBuilder/FormBuilderLayout";
import { RolesLayout } from "../../Layouts/Home/RolesLayout/RolesLayout";
import { LeadsLayout } from "../../Layouts/Home/Leads/LeadsLayout";
import { UnitsSalesLayout } from "../../Layouts/Home/UnitsSalesLayout/UnitsSalesLayout";
import { UnitsLeaseLayout } from "../../Layouts/Home/UnitsLeaseLayout/UnitsLeaseLayout";
import { LeadsSalesLayout } from "../../Layouts/Home/LeadsSalesLayout/LeadsSalesLayout";
import { LeadsPropertyManagementLayout } from "../../Layouts/Home/LeadsPropertyManagementLayout/LeadsPropertyManagementLayout";
import { LeadsLeaseLayout } from "../../Layouts/Home/LeadsLeaseLayout/LeadsLeaseLayout";
import { ResaleUnitsLayout, DevelopersLayout, SalesTransactionsWithAMLLayout, LeasingTransactionsWithAMLLayout } from "../../Layouts/Home";
import {
  CampaignRequestsLayout,
  KenbanBoardLayout,
} from "../../Layouts/Home/MarketingCampaignsLayout";
import { ActivitiesSalesView } from "../../Views/Home/ActivitiesSalesView/ActivitiesSalesView";
import { OperatingCostsLayout } from "../../Layouts/Home/OperatingCostsLayout/OperatingCostsLayout";
import { MergeLogLayout } from "../../Layouts/Home/MergeLogLayout/MergeLogLayout";
import { TemplateShareLogView } from "../../Views/Home/TemplateShareLogView/TemplateShareLogView";
import { QAView } from "../../Views/Home/QAView/QAView";
import {
  ImportDetailsView,
  EditUserView,
  ActivitiesView,
  ActivitiesLeaseView,
  MonthlyCalendarView,
  UserNotificationDetails,
  MyImportDetailsActions,
  ImportDetailsActions,
  LostLeadsView,
  CampaignConfigurationView,
  UnqualifiedLeadView,
  ProjectTasksKanbanBoard,
  PrimaryJourneyView,
  LeaseTransactionJourney,
  ActivityBuilderView,
  HubSpotContactsView,
} from "../../Views/Home";
import { ActivitiesTypesBulkUpdateView } from "../../Views/Home/ActivitiesTypeView/ActivityTypeUpdateBulkView/ActivitiesTypesBulkUpdateView";
import {
  ContactsPermissions,
  UserLoginPermissions,
  BusinessGroupsPermissions,
  TeamPermissions,
  RolesPermissions,
  ImageGalleryPermissions,
  ReportsPermissions,
  LocationsPermissions,
  AdminDashboardPermissions,
  BranchesPermissions,
  SaleTeamLeadDashboardPermissions,
  LeaseTeamLeadDashboardPermissions,
  LeaseListingTeamLeadDashboardPermissions,
  SaleListingTeamLeadDashboardPermissions,
  SaleAgentDashboardPermissions,
  SaleListingAgentDashboardPermissions,
  LeaseListingAgentDashboardPermissions,
  LeaseAgentDashboardPermissions,
  SystemNotificationsPermissions,
  AccountsDashboardPermissions,
  MarketingDashboardPermissions,
  AgentsRotationLogPermissions,
  CallCenterAgentDashboardPermissions,
  CallCenterTeamLeadDashboardPermissions,
  ActivitiesMortgagesPermissions,
  LeadsMortgagesPermissions,
  ExternalDashboardPermissions,
  ClientsSegmentationPermissions,
  ReportBuilderPermissions,
  BranchesDashboardPermissions,
  SalesDirectorDashboardPermissions,
  LeaseDirectorDashboardPermissions,
  ImportDetailsPermissions,
  ZeroMatchingSalePermissions,
  ZeroMatchingLeasePermissions,
  ListingShortagePermissions,
  PolicyPermissions,
  UsersLoginSystemLogPermissions,
  MergeContactLogPermissions,
  LostLeadsLogPermissions,
  ReportBuilderLogPermissions,
  AMLViewPermissions,
  UserAMLViewPermissions,
  BranchAMLPermissions,
  LeadOwnerLeadsPermissions,
  LeadOwnerActivityPermissions,
  LeadOwnerAdminAssignAgentPermissions,
  LeadOwnerAssignAgentPermissions,
  LeadOwnerUnitSalePermissions,
  LeadOwnerUnitLeasePermissions,
  ExternalLinksPermissions,
  AdminExternalLinksPermissions,
  LeadOwnerDashboardPermissions,
  PortalsDashboardPermissions,
  ActivatedLeadsAgentDashboardPermissions,
  ActivatedLeadsTeamLeadDashboardPermissions,
  CEODashboardPermissions,
  CommunicationActivitiesLogPermissions,
  CampaignRequestsPermissions,
  CampaignConfigurationPermissions,
  CampaignsPermissions,
  KanbanBoardPermissions,
  PortalsConfigurationPermissions,
  RotationConfigurationPermissions,
  RotationSchemesBranchDashboardPermissions,
  RotationSchemesTeamLeadDashboardPermissions,
  CampaignCostDashboardPermissions,
  LeadOwnerTeamLeadDashboardPermissions,
  TransactionConfigurationPermissions,
  LandmarksPermissions,
  LeadOwnerUnqualifiedLeadsPermissions,
  UnqualifiedLeadsCallCenterPermissions,
  PrimaryUnitsPermissions,
  GroupsPermissions,
  UsersManagementPermissions,
  CoursesPermissions,
  PropertyRatingPermissions,
  AutoCorrespondencePermissions,
  ActiveUserLogPermissions,
  DeactiveUserLogPermission,
  OwnerRotationPermissions,
  ConvoloRotationPermissions,
  ApprovalsConfigurationPermissions,
  SharedDocumentLogPermissions,
  LostInquiryLogPermissions,
  ApprovalsHistoryLogPermissions,
  ResaleUnitsPermissions,
  InquiryRotationPermissions,
  ConsentLogPermissions,
  AMLContactOpportunityPermissions,
  HubspotContactsPermissions,
  LeadOpportunitiesLogsPermissions,
  TemplateShareLogPermissions,
  RiskRatingConfigurationsPermissions,
  DevelopersPermissions,
  UserTrainingInsightsPermissions,
  ProfileAndAchievementstPermissions,
  LearningHubPermissions,
  SalesTransactionsWithAMLPermissions,
  LeaseTransactionsWithAMLPermissions,
  PaymentPlansConfigurationsPermissions,
} from "../../Permissions";
import {
  PortfolioPermissions,
  PropertyManagementListPermissions,
  UnitPermissions,
  OperatingCostsPermissions,
  MaintenanceContractsPermissions,
  LeadsPermissions,
  IncidentsPermissions,
  AssetsPermissions,
  ActivitesPermissions,
  WorkOrdersPermissions,
} from "../../Permissions/PropertyManagement";
import { WorkFlowView } from "../../Views/Home/WorkFlow/WorkFlowView";
import { WorkOrdersLayout } from "../../Layouts/Home/WorkOrdersLayout/WorkOrdersLayout";
import { IncidentsLayout } from "../../Layouts/Home/IncidentsLayout/IncidentsLayout";
import { AssetsLayout } from "../../Layouts/Home/AssetsLayout/AssetsLayout";
import { MaintenanceContractsLayout } from "../../Layouts/Home/MaintenanceContractsLayout/MaintenanceContractsLayout";
import { SalesTransactionsLayout } from "../../Layouts/Home/SalesTransactionsLayout/SalesTransactionsLayout";
import { LeasingTransactionsLayout } from "../../Layouts/Home/LeasingTransactionsLayout/LeasingTransactionsLayout";
import { InvoicesLayout } from "../../Layouts/Home/InvoicesLayout/InvoicesLayout";
import { PropertiesLayout } from "../../Layouts/Home/Properties/PropertiesLayout";
import { UnitsPropertyManagementLayout } from "../../Layouts/Home/UnitsPropertyManagement/UnitsPropertyManagement";
import { TemplatesLayout } from "../../Layouts/Home/TemplatesLayout/TemplatesLayout";
import { TaskConfigurationLayout } from "../../Layouts/Home/TaskConfigurationLayout/TaskConfigurationLayout";
import { ContactsCrmLayout } from "../../Layouts/Home/ContactsCrmLayout/ContactsCrmLayout";
import { PropertiesCrmLayout } from "../../Layouts/Home/PropertiesCrmLayout/PropertiesCrmLayout";
import { GalleryCityLayout } from "../../Layouts/Home/CityLayout/CityLayout";
import { GalleryDistrictLayout } from "../../Layouts/Home/DistrictLayout/DistrictLayout";
import { GalleryCommunityLayout } from "../../Layouts/Home/CommunityLayout/CommunityLayout";
import { GallerySubCommunityLayout } from "../../Layouts/Home/SubCommunityLayout/SubCommunityLayout";
import { PropertiesPermissionsCRM } from "../../Permissions/PropertiesPermissions";
import { AgentsView } from "../../Views/Home/AgentsView/AgentsView";
import { CampaignView } from "../../Views/Home/CampaignView/CampaignView";
import { AgentsRotationCriteriaLayout } from "../../Layouts/Home/AgentsRotationCriteriaLayout/AgentsRotationCriteriaLayout";
import { ReportsCRMLayout } from "../../Layouts/Home/ReportsLayout/ReportsCRMLayout";
import { ReportsBuilderLayout } from "../../Layouts/Home/ReportsBuilderLayout/ReportsBuilderLayout";
import { ClientsSegmentationLayout } from "../../Layouts/Home/ClientsSegmentationLayout/ClientsSegmentationLayout";
import {
  LeadOwnerActivityLayout,
  LeadOwnerAdminAssignAgentLayout,
  LeadOwnerLeadsLayout,
  LeadOwnerAssignAgentLayout,
  LeadOwnerUnitSaleLayout,
  LeadOwnerUnitLeaseLayout,
} from "../../Layouts/Home/LeadOwnerLayout";

import { PortalLayout, PropertyRatingLayout } from "../../Layouts/Home";

import { ActivitiesTypeView } from "../../Views/Home/ActivitiesTypeView";
import {
  ActivitiesSalesPermissions,
  LeadsPoolSalesPermissions,
  LeadsSalesPermissions,
  UnitsSalesPermissions,
} from "../../Permissions/Sales";
import {
  SalesTransactionsPermissions,
  LeasingTransactionsPermissions,
  InvoicesPermissions,
} from "../../Permissions/Accounts";
import {
  ActivitiesLeasePermissions,
  UnitsLeasePermissions,
  LeadsLeasePermissions,
  LeadsPoolLeasePermissions,
} from "../../Permissions/Lease";
import {
  LeadsCAllCenterPermissions,
  ActivitiesCallCenterPermissions,
  QACallCenterPermissions,
  LeadsPoolCallCenterPermissions,
} from "../../Permissions/CallCenter";
import { CountryLayout } from "../../Layouts/Home/CountryLayout/CountryLayout";
import { CitysLayout } from "../../Layouts/Home/CitysLayout/CitysLayout";
import { DistrictsLayout } from "../../Layouts/Home/DistrictsLayout/DistrictsLayout";
import { CommunitiesLayout } from "../../Layouts/Home/CommunitiesLayout/CommunitiesLayout";
import { SubCommunitiesLayout } from "../../Layouts/Home/SubCommunitiesLayout/SubCommunitiesLayout";
import { LandmarksLocationsLayout } from "../../Layouts/Home/LandmarksLocationsLayout/LandmarksLocationsLayout";
import {
  AgentsPermissions,
  RotationSchemaPermissions,
} from "../../Permissions/AgentManagementPermissions";
import {
  LookupsPermissions,
  ActivityTypePermissions,
  TemplatesPermissions,
  FormBuilderPermissions,
} from "../../Permissions/SystemParametersPermissions";
import { DashboardLayout } from "../../Layouts/Home/DashboardLayout/DashboardLayout";
import { ExceptionPermissionsHelper } from "../../Helper/ExceptionPermissions.Helper";
import { LeaseListingTeamlead } from "../../Views/Home/Dashboard/LeaseListingTeamlead/LeaseListingTeamlead";
import { SalesListingTeamlead } from "../../Views/Home/Dashboard/SalesListingTeamlead/SalesListingTeamlead";
import { AgentSalesListing } from "../../Views/Home/Dashboard/AgentSalesListing";
import { AgentLeaseListing } from "../../Views/Home/Dashboard/AgentLeaseListing";
import { SystemNotificationLayout } from "../../Layouts/Home/SystemNotificationLayout/SystemNotificationLayout";
import { AgentRotaionLogLayout } from "../../Layouts/Home/AgentRotaionLogLayout/AgentRotaionLogLayout";
import { CallCenterTeamlead } from "../../Views/Home/Dashboard/CallCenterTeamlead/CallCenterTeamlead";
import { CallCenterAgent } from "../../Views/Home/Dashboard/CallCenterAgent/CallCenterAgent";
import { AccountDashboard } from "../../Views/Home/Dashboard/AccountDashboard/AccountDashboard";
import { MarketingDashboard } from "../../Views/Home/Dashboard/MarketingDashboard";
import { SystemConfigrationLayout } from "../../Layouts/Home/SystemConfigrationLayout/SystemConfigrationLayout";
import { LeadsMortgageLayout } from "../../Layouts/Home/MortgageLayout/LeadsMortgageLayout";
import { ActivitiesMortgageLayout } from "../../Layouts/Home/MortgageLayout/ActivitiesMortgageLayout";
import { ExternalDashboard } from "../../Views/Home/Dashboard/ExternalDashboard";
import { BrancheBybrancheId } from "../../Views/Home/Dashboard/BrancheBybrancheId";
import { SalesDirector } from "../../Views/Home/Dashboard/SalesDirector/SalesDirector";
import { LeaseDirector } from "../../Views/Home/Dashboard/LeaseDirector/LeaseDirector";
import { PoliciesLayout } from "../../Layouts/Home/PoliciesLayout/PoliciesLayout";
import { ZeroMatchingInquiresLayout } from "../../Layouts/Home/ZeroMatchingInquiresLayout/ZeroMatchingInquiresLayout";
import { ZeroMatchingLeaseInquiresLayout } from "../../Layouts/Home/ZeroMatchingLeaseInquiresLayout/ZeroMatchingLeaseInquiresLayout";
import { ListingShortageInquiresLayout } from "../../Layouts/Home/ListingShortageInquiresLayout/ListingShortageInquiresLayout";
import { UsersLoginHistoryView } from "../../Views/Home/UsersLoginHistoryView/UsersLoginHistoryView";
import { RotationLogView } from "../../Views/Home/RotationLogView/RotationLogView";
import { ReportBuilderLogView } from "../../Views/Home/ReportBuilderLogView/ReportBuilderLogView";
import { AMLView } from "../../Views/Home/AMLView/AML/AMLView";
import { UserAMLView } from "../../Views/Home/AMLView/UserAML/UserAMLView";
import { ExternalLinksView } from "../../Views/Home/ExternalLinks/ExternalLinksView";
import { AdminExternalLinksView } from "../../Views/Home/ExternalLinks/AdminExternalLinksView";
import { LeadOwner } from "../../Views/Home/Dashboard/LeadOwner";
import { UserDashboard } from "../../Views/Home/Dashboard/UserDashboard";
import { ActivatedLeads } from "../../Views/Home/Dashboard/ActivatedLeads";
import { AgentActivatedLeads } from "../../Views/Home/Dashboard/AgentActivatedLeads";
import { PortalDashboard } from "../../Views/Home/Dashboard/PortalDashboard";
import { SaleAgentDashboardTabCmp } from "../../Views/Home/Dashboard/SaleAgentDashboardTabCmp/SaleAgentDashboardTabCmp";
import { SaleTeamsDashboardTabCmp } from "../../Views/Home/Dashboard/SaleTeamsDashboardTabCmp";
import { CeoDashboardTabCmp } from "../../Views/Home/Dashboard/CeoDashboardTabCmp";
import { LeaseAgentDashboardTabCmp } from "../../Views/Home/Dashboard/LeaseAgentDashboardTabCmp/LeaseAgentDashboardTabCmp";
import { LeaseTeamsDashboardTabCmp } from "../../Views/Home/Dashboard/LeaseTeamsDashboardTabCmp/LeaseTeamsDashboardTabCmp";
import { ActivitiesCommunicationLogsLayout } from "../../Layouts/Home/ActivitiesCommunicationLogsLayout/ActivitiesCommunicationLogsLayout";
import { RotationSchemesBranch } from "../../Views/Home/Dashboard/RotationSchemesBranch/RotationSchemesBranch";
import { RotationSchemesTeamLead } from "../../Views/Home/Dashboard/RotationSchemesTeamLead/RotationSchemesTeamLead";
import { TransactionConfigurationLayout } from "../../Layouts/Home/TransactionConfigurationLayout/TransactionConfigurationLayout";
import { LearningAndDevelopmentLayout } from "../../Layouts/Home/LearningAndDevelopmentLayout/LearningAndDevelopmentLayout";
import { LeadOwnerTeam } from "../../Views/Home/Dashboard/LeadOwnerTeam";
import { CampaigncostByBranch } from "../../Views/Home/Dashboard/CampaigncostByBranch";
import { OwnerRotationLayout } from "../../Layouts/Home/OwnerRotationLayout/OwnerRotationLayout";
import { CampaignTracker } from "../../Views/Home/Dashboard/CampaignTracker";
import { CampaignTrackerDashboardPermissions } from "../../Permissions/DashboardsPermissions/CampaignTrackerDashboardPermissions";
import { ActiveUserLog } from "../../Layouts/Home/ActiveUserLog/ActiveUserLog";
import { AutoCorrespondenceView } from "../../Views/Home/AutoCorrespondenceView/AutoCorrespondenceView";
import { NewContactsCrmLayout } from "../../Layouts/Home/NewContactsCrmLayout/NewContactsCrmLayout";
import { BillingLayout } from "../../Views/Settings/Billing/BillingLayout";
import { UsersManagementLayout } from "../../Views/Settings/UserManagement/UsersManagementLayout";
import { Aml } from "../../Views/Home/Dashboard/Aml";
import { AmlDashboardPermissions } from "../../Permissions/DashboardsPermissions/AmlDashboardPermissions";
import { DeactiveUserLog } from "../../Layouts/Home/DeactiveUserLog/DeactiveUserLog";
import { ConvoloAgentsLayout } from "../../Layouts/Home/ConvoloAgentsLayout/ConvoloAgentsLayout";
import { ProjectTask } from "../../Views/Home/Dashboard/ProjectTask";
import { LeadsPoolView } from "../../Views/Home/LeadsPool";
import { ProjectTaskPermissions } from "../../Permissions/DashboardsPermissions/ProjectTaskPermissions";
import { ApprovalsConfigurationLayout } from "../../Layouts/Home/ApprovalsConfigurationLayout/ApprovalsConfigurationLayout";
import { AdminSalesLeaseLead } from "../../Views/Home/Dashboard/AdminSalesLeaseLead";
import { PowerBiEnum } from "../../Enums";
import { AdminSalesDashboards } from "../../Permissions/DashboardsPermissions/AdminSalesDashboards/AdminSalesDashboards";
import { AdminLeaseDashboards } from "../../Permissions/DashboardsPermissions/AdminLeaseDashboards/AdminLeaseDashboards";
import { AdminCallCenter } from "../../Views/Home/Dashboard/AdminCallCenter/AdminCallCenter";
import { AdminCallCenterDashboards } from "../../Permissions/DashboardsPermissions/AdminCallCenterDashboards/AdminCallCenterDashboards";
import { AdminMarketing } from "../../Views/Home/Dashboard/AdminMarketing";
import { AdminMarketingDashboards } from "../../Permissions/DashboardsPermissions/AdminMarketingDashboards/AdminMarketingDashboards";
import { AdminPSIDailyDashboards } from "../../Permissions/DashboardsPermissions/AdminPSIDailyDashboards/AdminPSIDailyDashboards";
import { AdminDashboards } from "../../Views/Home/Dashboard/AdminDashboards";
import { AdminSalesListingDashboards } from "../../Permissions/DashboardsPermissions/AdminSalesListingDashboards/AdminSalesListingDashboards";
import { AdminSalesLeaseListing } from "../../Views/Home/Dashboard/AdminSalesLeaseListing";
import { AdminLeaseListingDashboards } from "../../Permissions/DashboardsPermissions/AdminLeaseListingDashboards/AdminLeaseListingDashboards";
import { AdminListingUnits } from "../../Views/Home/Dashboard/AdminListingUnits/AdminListingUnits";
import { AdminListingUnitsDashboards } from "../../Permissions/DashboardsPermissions/AdminListingUnitsDashboards/AdminListingUnitsDashboards";
import { AdminIntentionalDashboards } from "../../Permissions/DashboardsPermissions/AdminIntentionalDashboards/AdminIntentionalDashboards";
import { AdminBranchesDashboards } from "../../Permissions/DashboardsPermissions/AdminBranchesDashboards/AdminBranchesDashboards";
import { AdminLeasedetailsDashboards } from "../../Permissions/DashboardsPermissions/AdminLeasedetailsDashboards/AdminLeasedetailsDashboards";
import { AdminCustomersRiskDashboards } from "../../Permissions/DashboardsPermissions/AdminCustomersRiskDashboards/AdminCustomersRiskDashboards";
import { AdminCampaignCostDashboards } from "../../Permissions/DashboardsPermissions/AdminCampaignCostDashboards/AdminCampaignCostDashboards";
import { AdminLeadOwnerDashboards } from "../../Permissions/DashboardsPermissions/AdminLeadOwnerDashboards/AdminLeadOwnerDashboards";
import { AdminLeadAutomation } from "../../Views/Home/Dashboard/AdminLeadAutomation";
import { AdminLeadAutomationPermissions } from "../../Permissions/DashboardsPermissions/AdminLeadAutomationPermissions/AdminLeadAutomationPermissions";
import { AdminUnqualifiedLeadsPermissions } from "../../Permissions/DashboardsPermissions/AdminUnqualifiedLeadsPermissions/AdminUnqualifiedLeadsPermissions";
import { AdminUnqualifiedLeads } from "../../Views/Home/Dashboard/AdminUnqualifiedLeads";
import { AdminInternationalSalesYTDMTD } from "../../Views/Home/Dashboard/AdminInternationalSalesYTDMTD";
import { AdminInterytdPermissions } from "../../Permissions/DashboardsPermissions/AdminInterytdPermissions/AdminInterytdPermissions";
import { AdminUsersDetails } from "../../Views/Home/Dashboard/AdminUsersDetails";
import { AdminUsersDetailsPermissions } from "../../Permissions/DashboardsPermissions/AdminUsersDetailsPermissions/AdminUsersDetailsPermissions";
import { AdminAccountsPermissions } from "../../Permissions/DashboardsPermissions/AdminAccountsPermissions/AdminAccountsPermissions";
import { AdminCampaignRequestPermissions } from "../../Permissions/DashboardsPermissions/AdminCampaignRequestPermissions/AdminCampaignRequestPermissions";
import { LeadPoolFilter } from "../../Views/Home/Dashboard/LeadPoolFilter/LeadPoolFilter";
import OpporunitiesLogsView from "../../Views/Home/OpportunitiesLog/OpportunitiesLogs/OpporunitiesLogsView";
import { AdminLeadPoolPermissions } from "../../Permissions/DashboardsPermissions/AdminLeadPoolPermissions/AdminLeadPoolPermissions";
import { AdminLeadPoolCmp } from "../../Views/Home/Dashboard/AdminLeadPoolCmp/AdminLeadPoolCmp";
import { LeadPoolTeampermissions } from "../../Permissions/DashboardsPermissions/LeadPoolTeampermissions/LeadPoolTeampermissions";
import { ShareUnitView } from "../../Views/Home/ShareUnit/ShareUnitView";
import { MyShareUnitView } from "../../Views/Home/ShareUnit/MyShareUnitView";
import { Zeromatching } from "../../Views/Home/Dashboard/Zeromatching";
import { ZeromatchingByBranch } from "../../Views/Home/Dashboard/ZeromatchingByBranch";
import { ZeromatchingAdmin } from "../../Views/Home/Dashboard/ZeromatchingAdmin";
import { InquiryRotationLayout, RiskRatingLayout } from "../../Layouts/Home";
import { BranchAMLView } from "../../Views/Home/AMLView/BranchAML/BranchAMLView";
import { ApprovalsHistoryView } from "../../Views/Home/ApprovalsHistory";
import { AdminZeromatchingDashboards } from "../../Permissions/DashboardsPermissions/AdminZeromatchingDashboards/AdminZeromatchingDashboards";
import { TeamZeromatchingDashboards } from "../../Permissions/DashboardsPermissions/TeamZeromatchingDashboards/TeamZeromatchingDashboards";
import { BranchZeromatchingDashboards } from "../../Permissions/DashboardsPermissions/BranchZeromatchingDashboards/BranchZeromatchingDashboards";
import { SharedDocumentLogView } from "../../Views/Home/SharedDocumentLog";
import InquiryLogView from "../../Views/Home/InquiryLog/InquiryLog/InquiryLogView";
import { LostInquiryLogView } from "../../Views/Home/LostInquiryLogView/LostInquiryLogView";
import { InquiryLogPermissions } from "../../Permissions/SystemLogPermissions/InquiryLogPermissions";
import ContactsOpportunity from "../../Views/Home/Dashboard/ContactsOpportunity/ContactsOpportunity";
import ConsentLogView from "../../Views/Home/ConsentLog/ConsentLogView.jsx/ConsentLogView";
import { AdminDeveloper } from "../../Views/Home/Dashboard/AdminDeveloper/AdminDeveloper";
import { AdminActivity } from "../../Views/Home/Dashboard/AdminActivity";
import { AdminDeveloperDashboards } from "../../Permissions/DashboardsPermissions/AdminDeveloperDashboards/AdminDeveloperDashboards";
import { ActivityTeamLead } from "../../Views/Home/Dashboard/ActivityTeamLead";
import { AdminActivityDashboards } from "../../Permissions/DashboardsPermissions/AdminActivityDashboards/AdminActivityDashboards";
import { ActivityTeamLeadPermissions } from "../../Permissions/DashboardsPermissions/ActivityTeamLeadPermissions";
import UsersBulkUpdate from "../../Views/Home/Administration/UsersView/UserManagementView/UsersBulkUpdate";
import { AICallInsights } from "../../Views/Home/Dashboard/AICallInsights/AICallInsights";
import { AICallInsightsFilter } from "../../Views/Home/Dashboard/AICallInsightsFilter/AICallInsightsFilter";
import { AdminCallInsightsDashboards } from "../../Permissions/DashboardsPermissions/AdminCallInsightsDashboards/AdminCallInsightsDashboards";
import { CallInsightsTeamsDashboards } from "../../Permissions/DashboardsPermissions/CallInsightsTeamsDashboards";
import { ActivityAgents } from "../../Views/Home/Dashboard/ActivityAgents";
import { ActivityAgentPermissions } from "../../Permissions/DashboardsPermissions/ActivityAgentPermissions";
import LearningHub from "../../Views/Home/LearningHub/LearningHub";
import UserTrainingInsights from "../../Views/Home/UserTrainingInsights/UserTrainingInsights";
import LearningUserProfile from "../../Views/Home/LearningUserProfile/LearningUserProfile";
import { ProposalTrackingAgent } from "../../Views/Home/Dashboard/ProposalTrackingAgent";
import { ProposalTrackingTeam } from "../../Views/Home/Dashboard/ProposalTrackingTeam";
import { ProposalTrackingAdmin } from "../../Views/Home/Dashboard/ProposalTrackingAdmin/ProposalTrackingAdmin";
import { ExistingWorkFlowView } from "../../Views/Home/WorkFlow/ExistingWorkFlowView";
import { MainWorkFlowView } from "../../Views/Home/WorkFlow/MainWorkFlowView";
import { Inventory } from "../../Views/Home/Dashboard/Inventory";
import { AICallInsightsAgentFilter } from "../../Views/Home/Dashboard/AICallInsightsAgentFilter";
import { CallInsightsAgentDashboards } from "../../Permissions/DashboardsPermissions/CallInsightsAgentDashboards";
import { AdminProposalTracking } from "../../Permissions/DashboardsPermissions/AdminProposalTracking/AdminProposalTracking";
import { Admininventory } from "../../Permissions/DashboardsPermissions/Admininventory/Admininventory";
import { ProposalTrackingAgentPermissions } from "../../Permissions/DashboardsPermissions/ProposalTrackingAgentPermissions";
import { ProposalTrackingTeamPermissions } from "../../Permissions/DashboardsPermissions/ProposalTrackingTeamPermissions";
import NewChangePasswordView from "../../Views/Home/NewChangePasswordView/NewChangePasswordView";
import DuplicatedUnitOverview from "../../Views/Home/DuplicatedUnitOverview/DuplicatedUnitOverview";
import PaymentPlansView from '../../Views/Home/PaymentPlans/PaymentPlansView';
import { PaymentPlansLocalePath } from '../../Views/Home/PaymentPlans/i18n/PaymentPlansLocale';

const contactExceptionPermission = ExceptionPermissionsHelper() || false;

export const HomeRoutes = [
  {
    id: 10,
    path: "/leads",
    name: "LeadsView:leads",
    component: LeadsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(LeadsCAllCenterPermissions),
    groupId: 2,
    order: 1,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "LeadsView:leads",
        isDisabled: false,
        route: "/home/leads",
        groupName: "contact-center",
      },
    ],
  },
  {
    id: 30,
    path: "/edit",
    name: "UsersView:UsersManagementView.edit-user",
    component: EditUserView,
    layout: "/home/Users",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UserLoginPermissions),
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "SideMenuView.Administration.Users",
        isDisabled: false,
        route: "/home/Users",
        groupName: "system-admin",
      },
      {
        name: "UsersView:UsersManagementView.edit-user",
        isDisabled: false,
        route: "/home/Users/edit",
      },
    ],
  },
  {
    id: 30,
    path: "/bulk-update",
    name: "UsersView:UsersManagementView.edit-user",
    component: UsersBulkUpdate,
    layout: "/home/Users",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UserLoginPermissions),
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "SideMenuView.Administration.Users",
        isDisabled: false,
        route: "/home/Users",
        groupName: "system-admin",
      },
      {
        name: "UsersView:UsersManagementView.users-bulk-update",
        isDisabled: false,
        route: "/home/Users/bulk-update",
      },
    ],
  },
  {
    id: 30,
    path: "/Users",
    name: "SideMenuView.Administration.Users",
    component: UserView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UserLoginPermissions),
    groupId: 4,
    order: 1,
    icon: "mdi mdi-badge-account-outline",
    iconActive: "mdi mdi-badge-account",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.Administration.Users",
        isDisabled: false,
        route: "/home/Users",
        groupName: "system-admin",
      },
    ],
  },
  {
    id: 31,
    path: "/Administration/BusinessGroups",
    name: "SideMenuView.Administration.BusinessGroups",
    component: BusinessGroupsView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(BusinessGroupsPermissions),
    groupId: 4,
    order: 3,
    icon: "mdi mdi-file-tree-outline",
    iconActive: "mdi mdi-file-tree-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "SideMenuView.Administration.BusinessGroups",
        isDisabled: false,
        route: "/home/Administration/BusinessGroups",
        groupName: "system-admin",
      },
    ],
  },
  {
    id: 33,
    path: "/Roles",
    name: "SideMenuView.Administration.Roles",
    component: RolesLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(RolesPermissions),
    groupId: 4,
    order: 8,
    icon: "mdi mdi-checkbox-multiple-marked",
    iconActive: "mdi mdi-checkbox-multiple-marked c-primary",
    // routerLink: '/home/contacts',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },

  {
    id: 32,
    path: "/Administration/Team",
    name: "SideMenuView.Administration.Team",
    component: Team,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(TeamPermissions),
    groupId: 4,
    order: 3,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.Administration.Team",
        isDisabled: false,
        route: "/home/Administration/Team",
        groupName: "system-admin",
      },
    ],
  },
  {
    id: 11,
    path: "/Activities",
    name: "SideMenuView.ActivitiesView.ActivitiesView",
    component: ActivitiesView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(ActivitiesCallCenterPermissions),
    groupId: 2,
    order: 2,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.ActivitiesView.ActivitiesView",
        isDisabled: false,
        route: "/home/Activities",
        groupName: "contact-center",
      },
    ],
  },
  {
    id: 41,
    path: "/QA",
    name: "SideMenuView.QAQAView.QAView",
    component: QAView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(QACallCenterPermissions),
    groupId: 2,
    order: 3,
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.QAQAView.QAView",
        isDisabled: false,
        route: "/home/QA",
        groupName: "contact-center",
      },
    ],
  },
  {
    id: 21,
    path: "/portfolio",
    name: "PortfolioView:portfolio",
    component: PortfolioLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(PortfolioPermissions)],
    groupId: 11,
    order: 7,
    icon: "mdi mdi-domain c-white",
    iconActive: "mdi mdi-domain c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "PortfolioView:portfolio",
        isDisabled: false,
        route: "/home/portfolio",
        groupName: "property-management",
      },
    ],
  },
  {
    id: 15,
    path: "/work-orders",
    name: "WorkOrdersView:work-orders",
    component: WorkOrdersLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(WorkOrdersPermissions)],
    groupId: 11,
    order: 4,
    icon: "mdi mdi-domain c-white",
    iconActive: "mdi mdi-domain c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "WorkOrdersView:work-orders",
        isDisabled: false,
        route: "/home/work-orders",
        groupName: "property-management",
      },
    ],
  },
  {
    id: 37,
    path: "/FormBuilder",
    name: "SideMenuView.FormBuilder",
    component: FormBuilderLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(FormBuilderPermissions)],
    groupId: 16,
    order: 10,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 90,
    path: "/import-details",
    name: "ImportDetailsView:import-details",
    component: ImportDetailsView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 3,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "ImportDetailsView:import-details",
        isDisabled: false,
        route: "/home/import-details",
      },
    ],
  },
  {
    id: 4,
    path: "/units-sales",
    name: "UnitsView:units",
    component: UnitsSalesLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UnitsSalesPermissions),
    groupId: 6,
    order: 1,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 7,
    path: "/units-lease",
    name: "UnitsView:units",
    component: UnitsLeaseLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UnitsLeasePermissions),
    groupId: 7,
    order: 3,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 5,
    path: "/lead-sales",
    name: "LeadsView:leads",
    component: LeadsSalesLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(LeadsSalesPermissions),
    groupId: 6,
    order: 2,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 8,
    path: "/lead-lease",
    name: "LeadsView:leads",
    component: LeadsLeaseLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(LeadsLeasePermissions),
    groupId: 7,
    order: 4,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 8,
    path: "/lease:leads-pool",
    name: "UnitsView:leads-pool",
    component: LeadsPoolView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(LeadsPoolLeasePermissions),
    groupId: 7,
    order: 7,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "UnitsView:leads-pool",
        isDisabled: true,
        route: "/home/lease:leads-pool",
      },
    ],
  },
  {
    id: 6,
    path: "/activities-sales",
    name: "ActivitiesView:activities",
    component: ActivitiesSalesView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(ActivitiesSalesPermissions),
    groupId: 6,
    order: 3,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ActivitiesView:activities",
        isDisabled: false,
        route: "/home/activities-sales",
        groupName: "sales",
      },
    ],
  },

  {
    id: 9,
    path: "/activities-lease",
    name: "ActivitiesView:activities",
    component: ActivitiesLeaseView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(ActivitiesLeasePermissions),
    groupId: 7,
    order: 5,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ActivitiesView:activities",
        isDisabled: false,
        route: "/home/activities-lease",
        groupName: "leasing",
      },
    ],
  },

  {
    id: 22,
    path: "/activities-management",
    name: "ActivitiesView:activities",
    component: ActivitiesView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ActivitesPermissions)],
    groupId: 11,
    order: 7,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ActivitiesView:activities",
        isDisabled: false,
        route: "/home/activities-management",
        groupName: "property-management",
      },
    ],
  },

  {
    id: 17,
    path: "/Incidents",
    name: "IncidentsView:Incidents",
    component: IncidentsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(IncidentsPermissions)],
    groupId: 11,
    order: 5,
    icon: "mdi mdi-domain c-white",
    iconActive: "mdi mdi-domain c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "WorkOrdersView:Incidents",
        isDisabled: false,
        route: "/home/Incidents",
        groupName: "property-management",
      },
    ],
  },
  {
    id: 16,
    path: "/operating-costs",
    name: "OperatingCostsView:operating-costs",
    component: OperatingCostsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(OperatingCostsPermissions)],
    groupId: 11,
    order: 4,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "OperatingCostsView:operating-costs",
        isDisabled: false,
        route: "/home/operating-costs",
        groupName: "property-management",
      },
    ],
  },
  {
    id: 20,
    path: "/assets",
    name: "AssetsView:assets",
    component: AssetsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AssetsPermissions)],
    groupId: 11,
    order: 6,
    icon: "mdi mdi-domain c-white",
    iconActive: "mdi mdi-domain c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "AssetsView:assets",
        isDisabled: false,
        route: "/home/assets",
        groupName: "property-management",
      },
    ],
  },
  {
    id: 18,
    path: "/Maintenance-Contracts",
    name: "MaintenanceContracts:Maintenance-Contracts",
    component: MaintenanceContractsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(MaintenanceContractsPermissions)],
    groupId: 11,
    order: 5,
    icon: "mdi mdi-domain c-white",
    iconActive: "mdi mdi-domain c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "MaintenanceContracts:Maintenance-Contracts",
        isDisabled: false,
        route: "/home/MaintenanceContractsView",
        groupName: "property-management",
      },
    ],
  },
  {
    id: 23,
    path: "/sales-transactions",
    name: "SalesTransactionsView:sales-transactions",
    component: SalesTransactionsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(SalesTransactionsPermissions)],
    groupId: 12,
    order: 1,
    isDisabled: false,
    icon: "mdi mdi-currency-usd c-blue-lighter",
    iconActive: "mdi mdi-currency-usd c-white",
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "SalesTransactionsView:sales-transactions",
        isDisabled: false,
        route: "/home/sales-transactions/view",
        groupName: "accounts",
      },
    ],
  },
  {
    id: 24,
    path: "/leasing-transactions",
    name: "LeasingTransactionsView:leasing-transactions",
    component: LeasingTransactionsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeasingTransactionsPermissions)],
    groupId: 12,
    order: 2,
    icon: "mdi mdi-account-key-outline c-blue-lighter",
    iconActive: "mdi mdi-account-key-outline c-white",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "LeasingTransactionsView:leasing-transactions",
        isDisabled: false,
        route: "/home/leasing-transactions/view",
        groupName: "accounts",
      },
    ],
  },

  {
    id: 25,
    path: "/invoices",
    name: "InvoicesView:invoices",
    component: InvoicesLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(InvoicesPermissions)],
    groupId: 12,
    order: 2,
    icon: "mdi mdi-receipt c-blue-lighter",
    iconActive: "mdi mdi-receipt c-white",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "InvoicesView:invoices",
        isDisabled: false,
        route: "/home/invoices/view",
        groupName: "accounts",
      },
    ],
  },

  {
    id: 12,
    path: "/properties",
    name: "PropertiesView:properties",
    component: PropertiesLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(PropertyManagementListPermissions)],
    groupId: 11,
    order: 2,
    icon: "mdi mdi-domain c-white",
    iconActive: "mdi mdi-domain c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "PropertiesView:properties",
        isDisabled: false,
        route: "/home/properties",
        groupName: "property-management",
      },
    ],
  },
  {
    id: 13,
    path: "/units-property-management",
    name: "UnitsView:units",
    component: UnitsPropertyManagementLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(UnitPermissions)],
    groupId: 11,
    order: 3,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "UnitsView:units",
        isDisabled: false,
        route: "/home/units-property-management/view",
        groupName: "property-management",
      },
    ],
  },
  {
    id: 89,
    path: "/monthly-calendar-view",
    name: "MonthlyCalendarView:monthly-calendar-view",
    component: MonthlyCalendarView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 11,
    order: 3,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "MonthlyCalendarView:monthly-calendar-view",
        isDisabled: false,
        route: "/home/monthly-calendar-view",
      },
    ],
  },
  {
    id: 14,
    path: "/Leads-property-management",
    name: "LeadsView:leads",
    component: LeadsPropertyManagementLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadsPermissions)],
    groupId: 11,
    order: 3,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },

  // Note :
  //  Temporarily commented  my-lead part  we needs permissions
  // {
  //     id: 86,
  //     path: '/my-lead',
  //     name: 'MyLeadView:my-leads',
  //     component: MyLeadsView,
  //     layout: '/home',
  //     default: false,
  //     isRoute: true,
  //     authorize: true,
  //     roles: [],
  //     groupId: 14,
  //     order: 1,
  //     icon: 'mdi mdi-account-outline c-white',
  //     iconActive: 'mdi mdi-account-outline c-primary',
  //     isDisabled: false,
  //     showInMenu: true,
  //     isExact: false,
  //     breadcrumbs: [
  //       {
  //         name: 'MyLeadView:my-leads',
  //         isDisabled: false,
  //         route: '/home/my-lead',
  //         groupName: 'my-lead',
  //       },
  //     ],
  //   },
  // {
  //   id: 83,
  //   path: '/my-referrals',
  //   name: 'MyReferralsView:my-referrals',
  //   component: MyReferralsView,
  //   layout: '/home',
  //   default: false,
  //   isRoute: true,
  //   authorize: true,
  //   roles: [],
  //   groupId: 14,
  //   order: 2,
  //   icon: 'mdi mdi-account-outline c-white',
  //   iconActive: 'mdi mdi-account-outline c-primary',
  //   isDisabled: false,
  //   showInMenu: true,
  //   isExact: false,
  //   breadcrumbs: [
  //     {
  //       name: 'MyReferralsView:my-referrals',
  //       isDisabled: false,
  //       route: '/home/my-referrals',
  //       groupName: 'my-lead',
  //     },
  //   ],
  // },
  // {
  //   id: 82,
  //   path: '/sales-availability',
  //   name: 'SalesAvailabilityView:sales-availability',
  //   component: SalesAvailabilityView,
  //   layout: '/home',
  //   default: false,
  //   isRoute: true,
  //   authorize: true,
  //   roles: [],
  //   groupId: 14,
  //   order: 3,
  //   icon: 'mdi mdi-account-outline c-white',
  //   iconActive: 'mdi mdi-account-outline c-primary',
  //   isDisabled: false,
  //   showInMenu: true,
  //   isExact: false,
  //   breadcrumbs: [
  //     {
  //       name: 'SalesAvailabilityView:sales-availability',
  //       isDisabled: false,
  //       route: '/home/sales-availability',
  //       groupName: 'my-lead',
  //     },
  //   ],
  // },
  // {
  //   id: 81,
  //   path: '/leasing-availability',
  //   name: 'LeasingAvailabilityView:leasing-availability',
  //   component: LeasingAvailabilityView,
  //   layout: '/home',
  //   default: false,
  //   isRoute: true,
  //   authorize: true,
  //   roles: [],
  //   groupId: 14,
  //   order: 4,
  //   icon: 'mdi mdi-account-outline c-white',
  //   iconActive: 'mdi mdi-account-outline c-primary',
  //   isDisabled: false,
  //   showInMenu: true,
  //   isExact: false,
  //   breadcrumbs: [
  //     {
  //       name: 'LeasingAvailabilityView:leasing-availability',
  //       isDisabled: false,
  //       route: '/home/leasing-availability',
  //       groupName: 'my-lead',
  //     },
  //   ],
  // },
  {
    id: 1,
    path: "/Contacts-CRM",
    name: "ContactsView:contacts",
    component: ContactsCrmLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(ContactsPermissions),
    groupId: 15,
    order: 1,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-primary",
    isDisabled: false,
    showInMenu: !contactExceptionPermission,
    // showInMenu:true ,
    isExact: false,
    breadcrumbs: [
      {
        name: "ContactsView:contacts",
        isDisabled: false,
        route: "/home/contacts-crm",
        groupName: "crm",
      },
    ],
    hasNewLayout: true,
  },
  {
    id: 2,
    path: "/Properties-CRM",
    name: "PropertiesView:properties",
    component: PropertiesCrmLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(PropertiesPermissionsCRM),
    groupId: 15,
    order: 2,
    icon: "mdi mdi-domain c-white",
    iconActive: "mdi mdi-domain c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "PropertiesView:properties",
        isDisabled: false,
        route: "/home/Properties-CRM",
        groupName: "crm",
      },
    ],
  },
  {
    id: 34,
    path: "/lookups",
    name: "homeLayout.lookupsView.lookups",
    component: LookupsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LookupsPermissions)],
    groupId: 16,
    order: 1,
    icon: "mdi mdi-menu -multiple-marked",
    iconActive: "mdi mdi-menu -multiple-marked c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "homeLayout.lookupsView.lookups",
        isDisabled: false,
        route: "/home/lookups",
        groupName: "system-parameters",
      },
    ],
  },
  {
    id: 36,
    path: "/templates",
    name: "TemplatesView:templates-builder",
    component: TemplatesLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(TemplatesPermissions)],
    groupId: 16,
    order: 3,
    icon: "mdi mdi-newspaper-variant-multiple c-blue-lighter",
    iconActive: "mdi mdi-newspaper-variant-multiple c-white",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "TemplatesView:templates-builder",
        isDisabled: false,
        route: "/home/templates/view",
        groupName: "system-parameters",
      },
    ],
  },
  {
    id: 203,
    path: "/task-configuration",
    name: "TaskConfiguration:task-configuration",
    component: TaskConfigurationLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 16,
    order: 5,
    icon: "mdi mdi-view-list-outline c-blue-lighter",
    iconActive: "mdi mdi-view-list-outline c-white",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "TaskConfiguration:task-configuration",
        isDisabled: false,
        route: "/home/task-configuration/view",
        groupName: "system-parameters",
      },
    ],
  },
  {
    id: 204,
    path: "/my-tasks",
    name: "TaskConfiguration:my-tasks",
    component: ProjectTasksKanbanBoard,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: "",
    iconActive: "",
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "TaskConfiguration:my-tasks",
        isDisabled: false,
        route: "/home/my-tasks",
      },
    ],
  },
  {
    id: 26,
    path: "/city-gallery",
    name: "ImagesGalleryGroup:city-gallery",
    component: GalleryCityLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-camera-image",
    iconActive: "mdi mdi-camera-image",
    roles: Object.values(ImageGalleryPermissions),
    groupId: 17,
    order: 1,
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ImagesGalleryGroup:city-gallery",
        isDisabled: false,
        route: "/home/city-gallery/view",
        groupName: "gallery",
      },
    ],
  },
  {
    id: 27,
    path: "/district-gallery",
    name: "ImagesGalleryGroup:district-gallery",
    component: GalleryDistrictLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-file-image",
    iconActive: "mdi mdi-file",
    roles: Object.values(ImageGalleryPermissions),
    groupId: 17,
    order: 2,
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ImagesGalleryGroup:district-gallery",
        isDisabled: false,
        route: "/home/district-gallery/view",
        groupName: "gallery",
      },
    ],
  },
  {
    id: 28,
    path: "/community-gallery",
    name: "ImagesGalleryGroup:community-gallery",
    component: GalleryCommunityLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-folder-multiple-image",
    iconActive: "mdi mdi-folder-multiple-image",
    roles: Object.values(ImageGalleryPermissions),
    groupId: 17,
    order: 3,
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ImagesGalleryGroup:community-gallery",
        isDisabled: false,
        route: "/home/community-gallery/view",
        groupName: "gallery",
      },
    ],
  },
  {
    id: 29,
    path: "/subCommunity-gallery",
    name: "ImagesGalleryGroup:sub-community-gallery",
    component: GallerySubCommunityLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(ImageGalleryPermissions),
    icon: "mdi mdi-image-multiple",
    iconActive: "mdi mdi-image-multiple",
    groupId: 17,
    order: 4,
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ImagesGalleryGroup:sub-community-gallery",
        isDisabled: false,
        route: "/home/subCommunity-gallery/view",
        groupName: "gallery",
      },
    ],
  },
  {
    id: 39,
    path: "/agent-management/Agents",
    name: "agent",
    component: AgentsView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AgentsPermissions)],
    groupId: 18,
    order: 1,
    icon: "mdi mdi-google-street-view c-white",
    iconActive: "mdi mdi-google-street-view c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "agent",
        isDisabled: false,
        route: "/home/agent-management/Agents",
        groupName: "agent-management",
      },
    ],
  },
  {
    id: 40,
    path: "/rotation-criteria",
    name: "Agents:rotation-schema",
    icon: "mdi mdi-receipt c-white",
    iconActive: "mdi mdi-receipt c-blue-dark",
    component: AgentsRotationCriteriaLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(RotationSchemaPermissions)],
    groupId: 18,
    order: 2,
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "Agents:rotation-schema",
        isDisabled: false,
        route: "/home/rotation-criteria/view",
        groupName: "agent-management",
      },
    ],
  },
  {
    id: 35,
    path: "/activity-type",
    name: "homeLayout.activity-type.activity-type",
    component: ActivitiesTypeView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ActivityTypePermissions)],
    groupId: 16,
    order: 1,
    icon: "mdi mdi-upload-network",
    iconActive: "mdi mdi-upload-network c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "homeLayout.activity-type.activity-type",
        isDisabled: false,
        route: "/home/activity-type",
        groupName: "system-parameters",
      },
    ],
  },
  {
    id: 42,
    path: "/country",
    name: "LocationView:country",
    component: CountryLayout,
    layout: "/home",
    default: false,
    icon: "mdi mdi-map-marker-radius-outline",
    iconActive: "mdi mdi-map-marker-radius",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LocationsPermissions)],
    groupId: 19,
    order: 5,
    breadcrumbs: [
      {
        name: "LocationView:country",
        isDisabled: false,
        route: "/home/country",
        groupName: "locations",
      },
    ],
  },
  {
    id: 43,
    path: "/city",
    name: "LocationView:city",
    component: CitysLayout,
    layout: "/home",
    icon: "mdi mdi-map-marker-down",
    iconActive: "mdi mdi-map-marker-down",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LocationsPermissions)],
    groupId: 19,
    order: 5,
    breadcrumbs: [
      {
        name: "LocationView:city",
        isDisabled: false,
        route: "/home/city",
        groupName: "locations",
      },
    ],
  },
  {
    id: 46,
    path: "/District",
    name: "LocationView:District",
    component: DistrictsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LocationsPermissions)],
    groupId: 19,
    order: 5,
    icon: "mdi mdi-map-marker-multiple",
    iconActive: "mdi mdi-map-marker-multiple",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "LocationView:District",
        isDisabled: false,
        route: "/home/District",
        groupName: "locations",
      },
    ],
  },
  {
    id: 44,
    path: "/Communitie",
    name: "LocationView:Communitie",
    component: CommunitiesLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LocationsPermissions)],
    groupId: 19,
    icon: "mdi mdi-map-marker-distance",
    iconActive: "mdi mdi-map-marker-distance",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    order: 5,
    breadcrumbs: [
      {
        name: "LocationView:Communitie",
        isDisabled: false,
        route: "/home/Communitie",
        groupName: "locations",
      },
    ],
  },
  {
    id: 45,
    path: "/SubCommunitie",
    name: "LocationView:SubCommunitie",
    component: SubCommunitiesLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-map-marker-path",
    iconActive: "mdi mdi-map-marker-path",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(LocationsPermissions)],
    groupId: 19,
    order: 5,
    breadcrumbs: [
      {
        name: "LocationView:SubCommunitie",
        isDisabled: false,
        route: "/home/SubCommunitie",
        groupName: "locations",
      },
    ],
  },
  {
    id: 47,
    path: "/dashboard",
    name: "Dashboard:admin-dashboard",
    component: DashboardLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-chart-bell-curve-cumulative c-white",
    iconActive: "mdi mdi-chart-bell-curve-cumulative c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(AdminDashboardPermissions)],
    groupId: 1,
    order: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-dashboard",
        isDisabled: false,
        route: "/home/dashboard",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 48,
    path: "/agent-dashboard",
    name: "Dashboard:agent-dashboard",
    component: SaleAgentDashboardTabCmp,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(SaleAgentDashboardPermissions)],
    groupId: 6,
    order: 6,
    breadcrumbs: [
      {
        name: "Dashboard:agent-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 55,
    path: "/lease-agent-dashboard",
    name: "Dashboard:lease-agent-dashboard",
    component: LeaseAgentDashboardTabCmp,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(LeaseAgentDashboardPermissions)],
    groupId: 7,
    order: 8,
    breadcrumbs: [
      {
        name: "Dashboard:lease-agent-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 49,
    path: "/teamlead-dashboard",
    name: "Dashboard:lease-listing-teamlead-dashboard",
    component: LeaseListingTeamlead,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(LeaseListingTeamLeadDashboardPermissions)],
    groupId: 7,
    order: 10,
    breadcrumbs: [
      {
        name: "Dashboard:lease-listing-teamlead-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 51,
    path: "/sales-teamlead-dashboard",
    name: "Dashboard:salesteamlead-dashboard",
    component: SaleTeamsDashboardTabCmp,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(SaleTeamLeadDashboardPermissions)],
    groupId: 6,
    order: 7,
    breadcrumbs: [
      {
        name: "Dashboard:salesteamlead-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 175,
    path: "/leadower-dashboard",
    name: "Dashboard:leadower-dashboard",
    component: LeadOwner,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(LeadOwnerDashboardPermissions)],
    groupId: 20,
    order: 8,
    breadcrumbs: [
      {
        name: "Dashboard:leadower-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 184,
    path: "/leadower-team-dashboard",
    name: "Dashboard:leadower-team-dashboard",
    component: LeadOwnerTeam,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(LeadOwnerTeamLeadDashboardPermissions)],
    groupId: 20,
    order: 9,
    breadcrumbs: [
      {
        name: "Dashboard:leadower-team-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 185,
    path: "/campaigncost-dashboard",
    name: "Dashboard:CampaignCost",
    component: CampaigncostByBranch,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-monitor-cellphone-star c-white",
    iconActive: "mdi mdi-monitor-cellphone-star c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(CampaignCostDashboardPermissions)],
    groupId: 26,
    order: 7,
    breadcrumbs: [
      {
        name: "Dashboard:CampaignCost",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },

  {
    id: 176,
    path: "/userlogin-dashboard",
    name: "Dashboard:userlogin-dashboard",
    component: UserDashboard,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-login-variant c-white",
    iconActive: "mdi mdi-login-variant c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [],
    groupId: 1,
    order: 2,
    breadcrumbs: [
      {
        name: "Dashboard:userlogin-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 177,
    path: "/activatedLead-dashboard",
    name: "Dashboard:activatedLead-dashboard",
    component: ActivatedLeads,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(ActivatedLeadsTeamLeadDashboardPermissions)],
    groupId: 1,
    order: 9,
    breadcrumbs: [
      {
        name: "Dashboard:activatedLead-dashboard",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 178,
    path: "/agent-activatedLead-dashboard",
    name: "Dashboard:agent-activatedLead-dashboard",
    component: AgentActivatedLeads,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(ActivatedLeadsAgentDashboardPermissions)],
    groupId: 1,
    order: 8,
    breadcrumbs: [
      {
        name: "Dashboard:agent-activatedLead-dashboard",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 179,
    path: "/portal-dashboard",
    name: "Dashboard:portal-dashboard",
    component: PortalDashboard,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-web c-white",
    iconActive: "mdi mdi-web c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(PortalsDashboardPermissions)],
    groupId: 1,
    order: 7,
    breadcrumbs: [
      {
        name: "Dashboard:portal-dashboard",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 180,
    path: "/ceo-dashboard",
    name: "Dashboard:ceo-dashboard",
    component: CeoDashboardTabCmp,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group c-white",
    iconActive: "mdi mdi-account-group c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(CEODashboardPermissions)],
    groupId: 1,
    order: 3,
    breadcrumbs: [
      {
        name: "Dashboard:ceo-dashboard",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 181,
    path: "/rotation-branch-dashboard",
    name: "Dashboard:RotationSchemesBranch-dashboard",
    component: RotationSchemesBranch,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group c-white",
    iconActive: "mdi mdi-account-group c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(RotationSchemesBranchDashboardPermissions)],
    groupId: 18,
    order: 10,
    breadcrumbs: [
      {
        name: "Dashboard:RotationSchemesBranch-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 182,
    path: "/rotation-team-dashboard",
    name: "Dashboard:RotationSchemesTeamlead-dashboard",
    component: RotationSchemesTeamLead,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group c-white",
    iconActive: "mdi mdi-account-group c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(RotationSchemesTeamLeadDashboardPermissions)],
    groupId: 18,
    order: 11,
    breadcrumbs: [
      {
        name: "Dashboard:RotationSchemesTeamlead-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 52,
    path: "/lease-teamlead-dashboard",
    name: "Dashboard:leaseteamlead-dashboard",
    component: LeaseTeamsDashboardTabCmp,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(LeaseTeamLeadDashboardPermissions)],
    groupId: 7,
    order: 9,
    breadcrumbs: [
      {
        name: "Dashboard:leaseteamlead-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 56,
    path: "/saleslisting-teamlead",
    name: "Dashboard:saleslistingteamlead-dashboard",
    component: SalesListingTeamlead,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(SaleListingTeamLeadDashboardPermissions)],
    groupId: 6,
    order: 8,
    breadcrumbs: [
      {
        name: "Dashboard:saleslistingteamlead-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 53,
    path: "/agentsaleslisting",
    name: "Dashboard:agentsaleslisting-dashboard",
    component: AgentSalesListing,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(SaleListingAgentDashboardPermissions)],
    groupId: 6,
    order: 9,
    breadcrumbs: [
      {
        name: "Dashboard:agentsaleslisting-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 54,
    path: "/agentleaselisting",
    name: "Dashboard:agentleaselisting-dashboard",
    component: AgentLeaseListing,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(LeaseListingAgentDashboardPermissions)],
    groupId: 7,
    order: 11,
    breadcrumbs: [
      {
        name: "Dashboard:agentleaselisting-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 57,
    path: "/callcenter-teamlead",
    name: "Dashboard:callcenterteamlead-dashboard",
    component: CallCenterTeamlead,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(CallCenterTeamLeadDashboardPermissions)],
    groupId: 2,
    order: 6,
    breadcrumbs: [
      {
        name: "Dashboard:callcenterteamlead-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 58,
    path: "/callcenter-agent",
    name: "Dashboard:callcenteragent-dashboard",
    component: CallCenterAgent,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(CallCenterAgentDashboardPermissions)],
    groupId: 2,
    order: 7,
    breadcrumbs: [
      {
        name: "Dashboard:callcenteragent-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 59,
    path: "/account",
    name: "Dashboard:account-dashboard",
    component: AccountDashboard,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(AccountsDashboardPermissions)],
    groupId: 12,
    order: 4,
    breadcrumbs: [
      {
        name: "Dashboard:account-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 60,
    path: "/marketing",
    name: "Dashboard:marketing-dashboard",
    component: MarketingDashboard,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-cellphone-link c-white",
    iconActive: "mdi mdi-cellphone-link c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(MarketingDashboardPermissions)],
    groupId: 26,
    order: 8,
    breadcrumbs: [
      {
        name: "Dashboard:marketing-dashboard",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 61,
    path: "/external-dashboard",
    name: "Dashboard:external-dashboard",
    component: ExternalDashboard,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-link-variant c-white",
    iconActive: "mdi mdi-link-variant c-blue-dark",
    isDisabled: false,
    roles: [...Object.values(ExternalDashboardPermissions)],
    showInMenu: true,
    isExact: false,
    groupId: 1,
    order: 10,
    breadcrumbs: [
      {
        name: "Dashboard:external-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 62,
    path: "/branche-dashboard",
    name: "Dashboard:branches",
    component: BrancheBybrancheId,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-source-branch c-white",
    iconActive: "mdi mdi-source-branch c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(BranchesDashboardPermissions)],
    groupId: 1,
    order: 4,
    breadcrumbs: [
      {
        name: "Dashboard:branches",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 63,
    path: "/sales-director-dashboard",
    name: "Dashboard:sales-director-dashboard",
    component: SalesDirector,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(SalesDirectorDashboardPermissions)],
    groupId: 6,
    order: 10,
    breadcrumbs: [
      {
        name: "Dashboard:sales-director-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 64,
    path: "/lease-director-dashboard",
    name: "Dashboard:lease-director-dashboard",
    component: LeaseDirector,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    roles: [...Object.values(LeaseDirectorDashboardPermissions)],
    isExact: false,
    groupId: 7,
    order: 12,
    breadcrumbs: [
      {
        name: "Dashboard:lease-director-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },

  {
    id: 80,
    path: "/branches",
    name: "BranchView:branches",
    component: BranchesLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(BranchesPermissions)],
    groupId: 4,
    order: 2,
    icon: "mdi mdi-badge-account-outline",
    iconActive: "mdi mdi-badge-account",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "Branches.Branches:branches",
        isDisabled: false,
        route: "/home/branches",
        groupName: "system-admin",
      },
    ],
  },
  {
    id: 87,
    path: "/rotation-configration/view",
    name: "general-configration",
    component: SystemConfigrationLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(RotationConfigurationPermissions)],
    groupId: 31,
    order: 1,
    icon: "mdi mdi-badge-account-outline",
    iconActive: "mdi mdi-badge-account",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "general-configration",
        isDisabled: false,
        route: "/home/rotation-configration/view",
        groupName: "system-configuration",
      },
    ],
  },
  {
    id: 81,
    path: "/view",
    name: "Reports:crm-reports",
    component: ReportsCRMLayout,
    layout: "/home/Reports-CRM",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ReportsPermissions)],
    groupId: 23,
    order: 1,
    icon: "mdi mdi-view-list-outline c-white",
    iconActive: "mdi mdi-view-list-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "Reports:reports",
        isDisabled: false,
        route: "/home/Reports-CRM/view",
        groupName: "Reports",
      },
    ],
  },
  {
    id: 82,
    path: "/view",
    name: "system-notifications",
    component: SystemNotificationLayout,
    layout: "/home/system-notifications",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(SystemNotificationsPermissions)],
    groupId: 24,
    order: 1,
    icon: "mdi  mdi-bell-ring c-white",
    iconActive: "mdi  mdi-bell-ring c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "system-notifications",
        isDisabled: false,
        route: "/home/system-notifications/view",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 830,
    path: "/view",
    name: "shared-document-log",
    component: SharedDocumentLogView,
    layout: "/home/shared-document-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(SharedDocumentLogPermissions)],
    groupId: 24,
    order: 1,
    icon: "mdi  mdi-bell-ring c-white",
    iconActive: "mdi  mdi-bell-ring c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "shared-document-log",
        isDisabled: false,
        route: "/home/shared-document-log/view",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 831,
    path: "/view",
    name: "UsersView:UserApprovals.approvals-log",
    component: ApprovalsHistoryView,
    layout: "/home/approvals-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ApprovalsHistoryLogPermissions)],
    groupId: 24,
    order: 1,
    icon: "mdi  mdi-bell-ring c-white",
    iconActive: "mdi  mdi-bell-ring c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "UsersView:UserApprovals.approvals-log",
        isDisabled: false,
        route: "/home/approvals-log/view",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 83,
    path: "/view",
    name: "agent-rotation-log",
    component: AgentRotaionLogLayout,
    layout: "/home/agent-rotation",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AgentsRotationLogPermissions)],
    groupId: 24,
    order: 1,
    icon: "mdi  mdi-bell-ring c-white",
    iconActive: "mdi  mdi-bell-ring c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "agent-rotation-log",
        isDisabled: false,
        route: "/home/agent-rotation/view",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 304,
    path: "/view",
    name: "active-user-log",
    component: ActiveUserLog,
    layout: "/home/active-user",
    default: true,
    isRoute: true,
    authorize: true,
    groupId: 24,
    roles: [...Object.values(ActiveUserLogPermissions)],
    order: 10,
    icon: "mdi  mdi-account-key c-white",
    iconActive: "mdi  mdi-account-key c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "active-user-log",
        isDisabled: false,
        route: "/home/active-user/view",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 307,
    path: "/view",
    name: "deactive-user-log",
    component: DeactiveUserLog,
    layout: "/home/deactive-user",
    default: true,
    isRoute: true,
    authorize: true,
    groupId: 24,
    order: 10,
    roles: [...Object.values(DeactiveUserLogPermission)],
    icon: "mdi  mdi-account-key c-white",
    iconActive: "mdi  mdi-account-key c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "deactive-user-log",
        isDisabled: false,
        route: "/home/deactive-user/view",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 150,
    path: "/import-details-actions",
    name: "ImportDetailsView:import-details-actions",
    component: MyImportDetailsActions,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ImportDetailsPermissions)],
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "ImportDetailsView:import-details-actions",
        isDisabled: false,
        route: "/home/import-details-actions",
      },
    ],
  },
  {
    id: 152,
    path: "/user-notification-details",
    name: "UserNotificationDetails:user-notification-details",
    component: UserNotificationDetails,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "SystemNotifications:user-notification-details",
        isDisabled: false,
        route: "/home/user-notification-details",
      },
    ],
  },
  {
    id: 151,
    path: "/mortgage-leads",
    name: "LeadsView:leads",
    component: LeadsMortgageLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadsMortgagesPermissions)],
    groupId: 25,
    order: 1,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "LeadsView:leads",
        isDisabled: false,
        route: "/home/mortgage-leads",
        groupName: "mortgage",
      },
    ],
  },
  {
    id: 140,
    path: "/lead-owner-admin-assign-agent",
    name: "leadOwnerView:AdminAssign",
    component: LeadOwnerAdminAssignAgentLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadOwnerAdminAssignAgentPermissions)],
    groupId: 20,
    order: 1,
    icon: "mdi mdi-folder-account c-white",
    iconActive: "mdi mdi-folder-account c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "leadOwnerView:AdminAssign",
        isDisabled: false,
        route: "/home/lead-owner-admin-assign-agent",
        groupName: "lead-owner",
      },
    ],
  },
  {
    id: 141,
    path: "/lead-owner-assign-agent",
    name: "leadOwnerView:AssignAgent",
    component: LeadOwnerAssignAgentLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadOwnerAssignAgentPermissions)],
    groupId: 20,
    order: 1,
    icon: "mdi mdi-account-key c-white",
    iconActive: "mdi mdi-account-key c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "leadOwnerView:AssignAgent",
        isDisabled: false,
        route: "/home/lead-owner-assign-agent",
        groupName: "lead-owner",
      },
    ],
  },
  {
    id: 142,
    path: "/lead-owner-units-sale",
    name: "leadOwnerView:units-sale",
    component: LeadOwnerUnitSaleLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadOwnerUnitSalePermissions)],
    groupId: 20,
    order: 1,
    icon: "mdi  mdi-home-variant c-white",
    iconActive: "mdi  mdi-home-variant c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "leadOwnerView:units-sale",
        isDisabled: false,
        route: "/home/lead-owner-units-sale",
        groupName: "lead-owner",
      },
    ],
  },
  {
    id: 143,
    path: "/lead-owner-units-lease",
    name: "leadOwnerView:units-lease",
    component: LeadOwnerUnitLeaseLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadOwnerUnitLeasePermissions)],
    groupId: 20,
    order: 1,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "leadOwnerView:units-lease",
        isDisabled: false,
        route: "/home/lead-owner-units-lease",
        groupName: "lead-owner",
      },
    ],
  },
  {
    id: 144,
    path: "/lead-owner-lead",
    name: "leadOwnerView:leads",
    component: LeadOwnerLeadsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadOwnerLeadsPermissions)],
    groupId: 20,
    order: 1,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "leadOwnerView:leads",
        isDisabled: false,
        route: "/home/lead-owner-lead",
        groupName: "lead-owner",
      },
    ],
  },
  {
    id: 145,
    path: "/lead-owner-activity",
    name: "leadOwnerView:activity",
    component: LeadOwnerActivityLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadOwnerActivityPermissions)],
    groupId: 20,
    order: 1,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "leadOwnerView:activity",
        isDisabled: false,
        route: "/home/lead-owner-activity",
        groupName: "lead-owner",
      },
    ],
  },
  {
    id: 152,
    path: "/activities-mortgage",
    name: "ActivitiesView:activities",
    component: ActivitiesMortgageLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ActivitiesMortgagesPermissions)],
    groupId: 25,
    order: 2,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ActivitiesView:activities",
        isDisabled: false,
        route: "/home/activities-mortgage",
        groupName: "mortgage",
      },
    ],
  },
  {
    id: 153,
    path: "/clients-segmentation",
    name: "ClientsSegmentation:clients-segmentation",
    component: ClientsSegmentationLayout,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ClientsSegmentationPermissions)],
    groupId: 26,
    order: 1,
    icon: "mdi mdi-chart-box-plus-outline c-white",
    iconActive: "mdi mdi-chart-box-plus-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "ClientsSegmentation:clients-segmentation",
        isDisabled: false,
        route: "/home/clients-segmentation/view",
        groupName: "marketing",
      },
    ],
  },
  {
    id: 158,
    path: "/campaign",
    name: "Campaign:Campaigns",
    component: CampaignView,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(CampaignsPermissions)],
    groupId: 26,
    order: 1,
    icon: "mdi mdi-bullhorn-outline c-white",
    iconActive: "mdi mdi-bullhorn-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Campaign:Campaign",
        isDisabled: false,
        route: "/home/campaign/view",
        groupName: "marketing",
      },
    ],
  },
  {
    id: 159,
    path: "/campaignTracker",
    name: "Campaign:CampaignTracker",
    component: CampaignTracker,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(CampaignTrackerDashboardPermissions)],

    groupId: 26,
    order: 1,
    icon: "mdi mdi-bullhorn-outline c-white",
    iconActive: "mdi mdi-bullhorn-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Campaign:CampaignTracker",
        isDisabled: false,
        route: "/home",
        groupName: "marketing",
      },
    ],
  },
  {
    id: 160,
    path: "/AmlDashboard",
    name: "Dashboard:Aml",
    component: Aml,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AmlDashboardPermissions)],
    groupId: 29,
    order: 4,
    icon: "mdi mdi-bullhorn-outline c-white",
    iconActive: "mdi mdi-bullhorn-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Dashboard:Aml",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 160,
    path: "/aml-contacts-opportunity",
    name: "Dashboard:contacts-opportunity",
    component: ContactsOpportunity,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AMLContactOpportunityPermissions)],
    groupId: 29,
    order: 4,
    icon: "mdi mdi-bullhorn-outline c-white",
    iconActive: "mdi mdi-bullhorn-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Dashboard:contacts-opportunity",
        isDisabled: false,
        route: "/home",
        groupName: "AML",
      },
    ],
  },
  {
    id: 161,
    path: "/TaskDashboard",
    name: "Dashboard:Task",
    component: ProjectTask,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ProjectTaskPermissions)],

    groupId: 1,
    order: 5,
    icon: "mdi mdi-bullhorn-outline c-white",
    iconActive: "mdi mdi-bullhorn-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Dashboard:Task",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 330,
    path: "/leadPool",
    name: "Dashboard:leadpool",
    component: LeadPoolFilter,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadPoolTeampermissions)],

    groupId: 1,
    order: 6,
    icon: "mdi mdi-bullhorn-outline c-white",
    iconActive: "mdi mdi-bullhorn-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Dashboard:leadpool",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 86,
    path: "/Reports-Builder",
    name: "ReportsBuilderg:reportsbuilder",
    component: ReportsBuilderLayout,
    layout: "/home",
    default: false,
    icon: "mdi mdi-chart-box-plus-outline c-white",
    iconActive: "mdi mdi-chart-box-plus-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ReportBuilderPermissions)],
    groupId: 23,
    order: 2,
    breadcrumbs: [
      {
        name: "ReportsBuilderg:reportsbuilder",
        isDisabled: false,
        route: "/home/country",
        groupName: "Reports",
      },
    ],
  },
  {
    id: 87,
    path: "/import-details-actions",
    name: "ImportDetailsView:import-details-actions",
    component: ImportDetailsActions,
    layout: "/home/system-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ImportDetailsPermissions)],
    groupId: 24,
    order: 1,
    icon: "mdi mdi-badge-account-outline",
    iconActive: "mdi mdi-badge-account",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "ImportDetailsView:import-details-actions",
        isDisabled: false,
        route: "/home/system-log/import-details-actions",
        groupName: "system-log",
      },
    ],
  },

  {
    id: 65,
    path: "/policies",
    name: "Policies:policies",
    component: PoliciesLayout,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(PolicyPermissions)],
    groupId: 4,
    order: 9,
    icon: "mdi mdi-shield-check-outline",
    iconActive: "mdi mdi-shield-check-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Policies:policies",
        isDisabled: false,
        route: "/home/policies/view",
        groupName: "system-admin",
      },
    ],
  },

  {
    id: 66,
    path: "/zero-matching-sale",
    name: "Inquires:zero-matching-sale",
    component: ZeroMatchingInquiresLayout,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ZeroMatchingSalePermissions)],
    groupId: 27,
    order: 1,
    icon: "mdi mdi-equal-box c-white",
    iconActive: "mdi mdi-equal-box c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Inquires:zero-matching-sale",
        isDisabled: false,
        route: "/home/zero-matching-sale/view",
        groupName: "inquiries",
      },
    ],
  },
  {
    id: 333,
    path: "/zero-matching-dashboards",
    name: "Inquires:zero-matching-dashboards",
    component: Zeromatching,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(TeamZeromatchingDashboards)],

    groupId: 27,
    order: 4,
    icon: "mdi mdi-equal-box c-white",
    iconActive: "mdi mdi-equal-box c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Inquires:zero-matching-dashboards",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 335,
    path: "/zero-matching-dashboards-branch",
    name: "Inquires:zero-matching-dashboards-branch",
    component: ZeromatchingByBranch,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(BranchZeromatchingDashboards)],

    groupId: 27,
    order: 5,
    icon: "mdi mdi-equal-box c-white",
    iconActive: "mdi mdi-equal-box c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Inquires:zero-matching-dashboards-branch",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 67,
    path: "/listing-shortage",
    name: "Inquires:listing-shortage",
    component: ListingShortageInquiresLayout,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ListingShortagePermissions)],
    groupId: 27,
    order: 3,
    icon: "mdi mdi-view-list c-white",
    iconActive: "mdi mdi-view-list c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Inquires:listing-shortage",
        isDisabled: false,
        route: "/home/listing-shortage/view",
        groupName: "inquiries",
      },
    ],
  },

  {
    id: 68,
    path: "/zero-matching-lease",
    name: "Inquires:zero-matching-lease",
    component: ZeroMatchingLeaseInquiresLayout,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ZeroMatchingLeasePermissions)],
    groupId: 27,
    order: 1,
    icon: "mdi mdi-equal-box c-white",
    iconActive: "mdi mdi-equal-box c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Inquires:zero-matching-lease",
        isDisabled: false,
        route: "/home/zero-matching-lease/view",
        groupName: "inquiries",
      },
    ],
  },
  {
    id: 154,
    path: "/users-login-history",
    name: "UsersLoginHistoryView:users-login",
    component: UsersLoginHistoryView,
    layout: "/home/system-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(UsersLoginSystemLogPermissions)],
    groupId: 24,
    order: 1,
    icon: "mdi mdi-account-arrow-left-outline",
    iconActive: "mdi mdi-account-arrow-left",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "UsersLoginHistoryView:users-login-history",
        isDisabled: false,
        route: "/home/system-log/users-login-history",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 155,
    path: "/rotation-log",
    name: "RotationLogView:rotation-log",
    component: RotationLogView,
    layout: "/home/system-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AgentsRotationLogPermissions)],
    groupId: 24,
    order: 1,
    icon: "mdi mdi-book-arrow-left-outline",
    iconActive: "mdi mdi-book-arrow-left",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "RotationLogView:rotation-log",
        isDisabled: false,
        route: "/home/system-log/rotation-log",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 156,
    path: "/merge-log",
    name: "MergeLogs:mergeLogs",
    component: MergeLogLayout,
    layout: "/home/system-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(MergeContactLogPermissions)],
    groupId: 24,
    order: 3,
    icon: "mdi mdi-account-outline c-white",
    iconActive: "mdi mdi-account-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "MergeLogs:mergeLogs",
        isDisabled: false,
        route: "/home/system-log/merge-log",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 157,
    path: "/lost-leads",
    name: "LostLeadsView:lost-leads",
    component: LostLeadsView,
    layout: "/home/system-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LostLeadsLogPermissions)],
    groupId: 24,
    order: 7,
    icon: "mdi mdi-account-remove c-white",
    iconActive: "mdi mdi-account-remove c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "LostLeadsView:lost-leads",
        isDisabled: false,
        route: "/home/system-log/lost-leads",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 170,
    path: "/AMLView",
    name: "AMLView",
    component: AMLView,
    layout: "/home/AMLView",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AMLViewPermissions)],
    groupId: 29,
    order: 1,
    icon: "mdi mdi-briefcase-download",
    iconActive: "mdi mdi-briefcase-upload",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "AMLView",
        isDisabled: false,
        route: "/home/AMLView/AMLView",
        groupName: "AML",
      },
    ],
  },
  {
    id: 171,
    path: "/report-builder-log",
    name: "ReportBuilderLogView:report-builder-log",
    component: ReportBuilderLogView,
    layout: "/home/system-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ReportBuilderLogPermissions)],
    groupId: 24,
    order: 1,
    icon: "mdi mdi-note-multiple-outline",
    iconActive: "mdi mdi-note-multiple",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "ReportBuilderLogView:report-builder-log",
        isDisabled: false,
        route: "/home/system-log/report-builder-log",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 188,
    path: "/lost-inquiry-log",
    name: "LostInquiryLogView:lost-inquiry-log",
    component: LostInquiryLogView,
    layout: "/home/system-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LostInquiryLogPermissions)],
    groupId: 24,
    order: 1,
    icon: "mdi mdi-note-multiple-outline",
    iconActive: "mdi mdi-note-multiple",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "LostInquiryLogView:lost-inquiry-log",
        isDisabled: false,
        route: "/home/system-log/lost-inquiry-log",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 170,
    path: "/UserAMLView",
    name: "UserAMLView",
    component: UserAMLView,
    layout: "/home/UserAMLView",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(UserAMLViewPermissions)],
    groupId: 29,
    order: 2,
    icon: "mdi mdi-briefcase-download",
    iconActive: "mdi mdi-briefcase-upload",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "UserAMLView",
        isDisabled: false,
        route: "/home/UserAMLView/UserAMLView",
        groupName: "AML",
      },
    ],
  },
  {
    id: 170,
    path: "/BranchAML",
    name: "BranchAML",
    component: BranchAMLView,
    layout: "/home/BranchAML",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(BranchAMLPermissions)],
    groupId: 29,
    order: 3,
    icon: "mdi mdi-briefcase-download",
    iconActive: "mdi mdi-briefcase-upload",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "BranchAML",
        isDisabled: false,
        route: "/home/AML/BranchAML",
        groupName: "AML",
      },
    ],
  },
  {
    id: 221,
    path: "/primary-units",
    name: "UnitsView:primary-units",
    component: PrimaryJourneyView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(PrimaryUnitsPermissions)],
    groupId: 6,
    order: 4,
    icon: "mdi mdi-assistant",
    iconActive: "mdi mdi-assistant",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "UnitsView:primary-units",
        isDisabled: false,
        route: "/home/primary-units",
      },
    ],
  },
  {
    id: 221,
    path: "/sales:leads-pool",
    name: "UnitsView:leads-pool",
    component: LeadsPoolView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(LeadsPoolSalesPermissions),
    groupId: 6,
    order: 5,
    icon: "mdi mdi-assistant",
    iconActive: "mdi mdi-assistant",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "UnitsView:leads-pool",
        isDisabled: false,
        route: "/home/sales:leads-pool",
      },
    ],
  },
  {
    id: 171,
    path: "/admin-external-links",
    name: "ExternalLinksView:admin-external-links",
    component: AdminExternalLinksView,
    layout: "/home/external-links",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminExternalLinksPermissions)],
    groupId: 30,
    order: 2,
    icon: "mdi mdi-link-variant-off",
    iconActive: "mdi mdi-link-variant-off",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "ExternalLinksView:admin-external-links",
        isDisabled: false,
        route: "/home/external-links/admin-external-links",
        groupName: "ExternalLinksView:external-links",
      },
    ],
  },
  {
    id: 172,
    path: "/user-external-links",
    name: "ExternalLinksView:external-links",
    component: ExternalLinksView,
    layout: "/home/external-links",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ExternalLinksPermissions)],
    groupId: 30,
    order: 2,
    icon: "mdi mdi-link-variant",
    iconActive: "mdi mdi-link-variant",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [],
  },
  {
    id: 173,
    path: "/template-share-log",
    name: "TemplateShareLog:template-share-log",
    component: TemplateShareLogView,
    layout: "/home/system-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(TemplateShareLogPermissions)],
    groupId: 24,
    order: 9,
    icon: "mdi mdi-share-variant c-white",
    iconActive: "mdi mdi-share-variant c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "TemplateShareLog:template-share-log",
        isDisabled: false,
        route: "/home/system-log/template-share-log",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 176,
    path: "/campaign-requests",
    name: "Campaign:campaign-requests",
    component: CampaignRequestsLayout,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(CampaignRequestsPermissions)],
    groupId: 26,
    order: 5,
    icon: "mdi mdi-comment-text-multiple-outline c-white",
    iconActive: "mdi mdi-comment-text-multiple-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [],
  },
  {
    id: 175,
    path: "/activities-communication-logs",
    name: "ActivitiesCommunicationLogs:activities-communication",
    component: ActivitiesCommunicationLogsLayout,
    layout: "/home/system-log",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(CommunicationActivitiesLogPermissions)],
    groupId: 24,
    order: 10,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "ActivitiesCommunicationLogs:activities-communication-logs",
        isDisabled: false,
        route: "/home/system-log/activities-communication-logs",
        groupName: "system-log",
      },
    ],
  },

  {
    id: 178,
    path: "/inquiry-log",
    name: "InquiryLogs:inquiry-log",
    component: InquiryLogView,
    layout: "/home/system-log",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(InquiryLogPermissions)],
    groupId: 24,
    order: 11,
    icon: "mdi mdi-help-circle c-white",
    iconActive: "mdi mdi-help-circle c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "InquiryLogs:inquiry-log",
        isDisabled: false,
        route: "/home/system-log/inquiry-log",
        groupName: "system-log",
      },
    ],
  },

  {
    id: 175,
    path: "/lead-opportunities-logs",
    name: "OpportunitiesLog:opportunities-logs",
    component: OpporunitiesLogsView,
    layout: "/home/system-log",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadOpportunitiesLogsPermissions)],
    groupId: 24,
    order: 11,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "OpportunitiesLog:opportunities-logs",
        isDisabled: false,
        route: "/home/system-log/lead-opportunities-logs",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 176,
    path: "/consent-log",
    name: "DNCRAndConsentManagment:consent-log",
    component: ConsentLogView,
    layout: "/home/system-log",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ConsentLogPermissions)],
    groupId: 24,
    order: 12,
    icon: "mdi mdi-format-list-checks c-white",
    iconActive: "mdi mdi-format-list-checks c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "DNCRAndConsentManagment:consent-log",
        isDisabled: false,
        route: "/home/system-log/consent-log",
        groupName: "system-log",
      },
    ],
  },
  {
    id: 177,
    path: "/portals-configuration",
    name: "Portals:portals-configuration",
    component: PortalLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(PortalsConfigurationPermissions)],
    groupId: 31,
    order: 2,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [],
  },
  {
    id: 177,
    path: '/payment-plan',
    name: `${PaymentPlansLocalePath}:payment_plans`,
    component: PaymentPlansView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(PaymentPlansConfigurationsPermissions)],
    groupId: 31,
    order: 9,
    icon: 'mdi mdi-home-outline c-white',
    iconActive: 'mdi mdi-home-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: `${PaymentPlansLocalePath}:payment_plans`,
        isDisabled: true,
        route: '/home/rotation-configration/view',
        groupName: 'system-configuration',
      },
    ],
  },
  {
    id: 201,
    path: "/approvals-configuration",
    name: "ApprovalsConfiguration:approvals-configuration",
    component: ApprovalsConfigurationLayout,
    roles: [...Object.values(ApprovalsConfigurationPermissions)],
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    groupId: 31,
    order: 2,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [],
  },
  {
    id: 170,
    path: "/kenban-board",
    name: "Campaign:kenban-board",
    component: KenbanBoardLayout,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(KanbanBoardPermissions)],
    groupId: 26,
    order: 1,
    icon: "mdi mdi-chart-timeline c-white",
    iconActive: "mdi mdi-chart-timeline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [],
  },
  {
    id: 174,
    path: "/campaign-configuration",
    name: "Campaign:campaign-configuration",
    component: CampaignConfigurationView,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(CampaignConfigurationPermissions)],
    groupId: 26,
    order: 5,
    icon: "mdi mdi-tune-vertical c-white",
    iconActive: "mdi mdi-tune-vertical c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Campaign:campaign-configuration",
        isDisabled: false,
        route: "/home/campaign-configuration",
        groupName: "marketing",
      },
    ],
  },
  {
    id: 175,
    path: "/hubspot-contacts",
    name: "Campaign:hubspot-contacts",
    component: HubSpotContactsView,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(HubspotContactsPermissions)],
    groupId: 26,
    order: 9,
    icon: "mdi mdi-tune-vertical c-white",
    iconActive: "mdi mdi-tune-vertical c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Campaign:hubspot-contacts",
        isDisabled: false,
        route: "/home/hubspot-contacts",
        groupName: "marketing",
      },
    ],
  },
  {
    id: 183,
    path: "/Landmarks",
    name: "LocationView:Landmarks",
    component: LandmarksLocationsLayout,
    layout: "/home",
    default: false,
    icon: "mdi mdi-bank",
    iconActive: "mdi mdi-bank",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LandmarksPermissions)],
    groupId: 19,
    order: 6,
    breadcrumbs: [
      {
        name: "LocationView:Landmarks",
        isDisabled: false,
        route: "/home/Landmarks",
        groupName: "locations",
      },
    ],
  },
  {
    id: 300,
    groupId: 20,
    order: 7,
    path: "/Unqualified-Lead-owner",
    name: "SideMenuView.Unqualified-Lead-owner",
    component: UnqualifiedLeadView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeadOwnerUnqualifiedLeadsPermissions)],
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.Unqualified-Lead-owner",
        isDisabled: false,
        route: "/home/Unqualified-Lead-owner",
        groupName: "lead-owner",
      },
    ],
  },
  {
    id: 200,
    groupId: 2,
    order: 3,
    path: "/Unqualified-Lead",
    name: "SideMenuView.Unqualified-Lead",
    component: UnqualifiedLeadView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(UnqualifiedLeadsCallCenterPermissions)],
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.Unqualified-Lead",
        isDisabled: false,
        route: "/home/Unqualified-Lead",
        groupName: "contact-center",
      },
    ],
  },
  {
    id: 200,
    groupId: 2,
    order: 3,
    path: "/contact:leads-pool",
    name: "UnitsView:leads-pool",
    component: LeadsPoolView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(LeadsPoolCallCenterPermissions),
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "UnitsView:leads-pool",
        isDisabled: true,
        route: "/home/contact:leads-pool",
        groupName: "contact-center",
      },
    ],
  },
  {
    id: 202,
    path: "/TransactionConfigurationView",
    name: "TransactionConfigurationView:TransactionConfigurationView",
    component: TransactionConfigurationLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(TransactionConfigurationPermissions)],
    groupId: 31,
    order: 3,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [],
  },
  {
    id: 220,
    path: "/OwnerRotation",
    name: "OwnerRotationView:OwnerRotation",
    component: OwnerRotationLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(OwnerRotationPermissions)],
    groupId: 18,
    order: 6,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [],
  },
  {
    id: 221,
    path: "/convolo-agents",
    name: "Agents:convolo-agents",
    component: ConvoloAgentsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ConvoloRotationPermissions)],
    groupId: 18,
    order: 9,
    icon: "mdi mdi-google-street-view c-white",
    iconActive: "mdi mdi-google-street-view c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "Agents:convolo-agents",
        isDisabled: false,
        route: "/home/convolo-agents",
      },
    ],
  },
  {
    id: 301,
    path: "/learning-and-development",
    name: "LearningAndDevelopmentView:courses",
    component: LearningAndDevelopmentLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(CoursesPermissions)],
    groupId: 32,
    order: 1,
    icon: "mdi mdi-book-open c-white",
    iconActive: "mdi mdi-book-open c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "LearningAndDevelopmentView:courses",
        isDisabled: false,
        route: "/home/learning-and-development",
        groupName: "l-and-d",
      },
    ],
  },
  {
    id: 301,
    path: "/learning-hub",
    name: "LearningAndDevelopmentView:learning-hub",
    component: LearningHub,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LearningHubPermissions)],
    groupId: 32,
    order: 1,
    icon: "mdi mdi-book-open c-white",
    iconActive: "mdi mdi-book-open c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "LearningAndDevelopmentView:learning-hub",
        isDisabled: false,
        route: "/home/learning-hub",
        groupName: "l-and-d",
      },
    ],
  },
  {
    id: 301,
    path: "/user-training-insights",
    name: "LearningAndDevelopmentView:user-training-insights",
    component: UserTrainingInsights,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(UserTrainingInsightsPermissions)],
    groupId: 32,
    order: 1,
    icon: "mdi mdi-book-open c-white",
    iconActive: "mdi mdi-book-open c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "LearningAndDevelopmentView:user-training-insights",
        isDisabled: false,
        route: "/home/user-training-insights",
        groupName: "l-and-d",
      },
    ],
  },
  {
    id: 301,
    path: "/profile-and-achievements",
    name: "LearningAndDevelopmentView:profile-and-achievements",
    component: LearningUserProfile,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ProfileAndAchievementstPermissions)],
    groupId: 32,
    order: 1,
    icon: "mdi mdi-book-open c-white",
    iconActive: "mdi mdi-book-open c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "LearningAndDevelopmentView:profile-and-achievements",
        isDisabled: false,
        route: "/home/profile-and-achievements",
        groupName: "l-and-d",
      },
    ],
  },
  {
    id: 205,
    path: "/user-groups",
    name: "UsersView:UserGroups.user-groups",
    component: GroupsView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(GroupsPermissions)],
    icon: "",
    iconActive: "",
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "UsersView:UserGroups.user-groups",
        isDisabled: false,
        route: "/home/user-groups",
      },
    ],
  },
  {
    id: 206,
    path: "/users-management",
    name: "UsersView:UsersManagement.users-management",
    component: UsersManagementView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(UsersManagementPermissions)],
    icon: "",
    iconActive: "",
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "UsersView:UsersManagement.users-management",
        isDisabled: false,
        route: "/home/users-management",
      },
    ],
  },
  {
    id: 208,
    path: "/user-approvals",
    name: "UsersView:UserApprovals.user-approvals",
    component: UserApprovalsView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: "",
    iconActive: "",
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "UsersView:UserApprovals.user-approvals",
        isDisabled: false,
        route: "/home/user-approvals",
      },
    ],
  },
  {
    id: 303,
    path: "/activity-type-bulk-update",
    name: "activity-type-bulk-update",
    component: ActivitiesTypesBulkUpdateView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "Activity-type",
        isDisabled: false,
        route: "/home/activity-type",
        groupName: "system-parameters",
      },
      {
        name: "activity-type-bulk-update",
        isDisabled: false,
        route: "/home/activity-type-bulk-update",
      },
    ],
  },
  {
    id: 304,
    path: "/activity-type-bulk-update",
    name: "rr",
    component: ActivitiesTypesBulkUpdateView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "Activity-type",
        isDisabled: false,
        route: "/home/activity-type",
        groupName: "system-parameters",
      },
      {
        name: "activity-type-bulk-update",
        isDisabled: false,
        route: "/home/activity-type-bulk-update",
      },
    ],
  },
  {
    id: 308,
    path: "/activity-builder",
    name: "SideMenuView.activity-builder",
    component: ActivityBuilderView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 16,
    order: 2,
    icon: "mdi mdi-creation c-white",
    iconActive: "mdi mdi-creation c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: "SideMenuView.activity-builder",
        isDisabled: true,
        route: "/home/activity-builder",
      },
    ],
  },
  {
    id: 309,
    path: "/lease-transaction-journey",
    name: "",
    component: LeaseTransactionJourney,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.lease-transaction",
        isDisabled: true,
        route: "/home/lease-transaction-journey",
      },
    ],
  },
  {
    id: 304,
    path: "/billing",
    name: "billing",
    component: BillingLayout,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "Billing",
        isDisabled: true,
        route: "/home/billing",
      },
    ],
  },
  {
    id: 307,
    path: "/billing-users",
    name: "billing-users",
    component: UsersManagementLayout,
    layout: "/home",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "Billing",
        isDisabled: false,
        route: "/home/billing",
      },
      {
        name: "Users",
        isDisabled: true,
        route: "/home/users",
      },
    ],
  },
  {
    id: 305,
    path: "/property-rating-configuration",
    name: "PropertyRating:property-rating",
    component: PropertyRatingLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(PropertyRatingPermissions)],
    groupId: 31,
    order: 5,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [],
  },
  {
    id: 306,
    path: "/auto-correspondence/view",
    name: "AutoCorrespondence:auto-correspondence",
    component: AutoCorrespondenceView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AutoCorrespondencePermissions)],
    groupId: 31,
    order: 6,
    icon: "mdi mdi-badge-account-outline",
    iconActive: "mdi mdi-badge-account",
    isDisabled: true,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "AutoCorrespondence:auto-correspondence",
        isDisabled: true,
        route: "/home/auto-correspondencen/view",
        groupName: "system-configuration",
      },
    ],
  },

  {
    id: 309,
    path: "/salesAdmin",
    name: "Dashboard:sales",
    component: () => (
      <AdminSalesLeaseLead
        id={PowerBiEnum.SalesTeamLeadDate.reportid}
        embedUrl={PowerBiEnum.SalesTeamLeadDate.url}
      />
    ),
    layout: "/home",
    submenu: true, // TODO: XELE-14482 handle this in New Side Nav component
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminSalesDashboards)],
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-sales",
        isDisabled: false,
        route: "/home/dashboard",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 310,
    path: "/leaseAdmin",
    name: "Dashboard:lease",
    component: () => (
      <AdminSalesLeaseLead
        id={PowerBiEnum.LeaseTeamLeadDate.reportid}
        embedUrl={PowerBiEnum.LeaseTeamLeadDate.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminLeaseDashboards)],
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-lease",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 311,
    path: "/callCenterAdmin",
    name: "Dashboard:contact-center",
    component: () => (
      <AdminCallCenter
        id={PowerBiEnum.callCenterDate.reportid}
        embedUrl={PowerBiEnum.callCenterDate.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminCallCenterDashboards)],
    icon: "mdi mdi-cellphone-link c-white",
    iconActive: "mdi mdi-cellphone-link c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-contact-center",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 312,
    path: "/marketingAdmin",
    name: "Dashboard:marketing",
    component: () => (
      <AdminMarketing
        id={PowerBiEnum.newmarketing.reportid}
        embedUrl={PowerBiEnum.newmarketing.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminMarketingDashboards)],
    icon: "mdi mdi-bullhorn-outline c-white",
    iconActive: "mdi mdi-bullhorn-outline c-primary",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-marketing-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 313,
    path: "/psidailyAdmin",
    name: "Dashboard:PSI-Daily",
    component: () => (
      <AdminDashboards
        id={PowerBiEnum.Newpsidaily.reportid}
        embedUrl={PowerBiEnum.Newpsidaily.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminPSIDailyDashboards)],
    icon: "mdi mdi-bullhorn-outline c-white",
    iconActive: "mdi mdi-bullhorn-outline c-primary",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:Admin-PSI-Daily",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },

  {
    id: 314,
    path: "/saleslistingAdmin",
    name: "Dashboard:saleslistingmanager",
    component: () => (
      <AdminSalesLeaseListing
        id={PowerBiEnum.SalesListingUnitsDate.reportid}
        embedUrl={PowerBiEnum.SalesListingUnitsDate.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminSalesListingDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-saleslistingmanager",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 315,
    path: "/leaselistingAdmin",
    name: "Dashboard:leaselistingmanager",
    component: () => (
      <AdminSalesLeaseListing
        id={PowerBiEnum.LeaseListingUnitsDate.reportid}
        embedUrl={PowerBiEnum.LeaseListingUnitsDate.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminLeaseListingDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-leaselistingmanager",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 316,
    path: "/ListingUnitsAdmin",
    name: "Dashboard:ListingUnits",
    component: () => (
      <AdminListingUnits
        id={PowerBiEnum.ListingAllUnits.reportid}
        embedUrl={PowerBiEnum.ListingAllUnits.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminListingUnitsDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-ListingUnits",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 317,
    path: "/intentionalAdmin",
    name: "Dashboard:intentionallead",
    component: () => (
      <AdminDashboards
        id={PowerBiEnum.intentionalsales.reportid}
        embedUrl={PowerBiEnum.intentionalsales.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminIntentionalDashboards)],
    icon: "mdi mdi-bullhorn-outline c-white",
    iconActive: "mdi mdi-bullhorn-outline c-primary",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-intentionallead",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 318,
    path: "/brancheAdmin",
    name: "Dashboard:branches",
    component: () => (
      <AdminDashboards
        id={PowerBiEnum.Branches.reportid}
        embedUrl={PowerBiEnum.Branches.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminBranchesDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-branches",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 319,
    path: "/LeasedetailsAdmin",
    name: "Dashboard:leasecontracts",
    component: () => (
      <AdminDashboards
        id={PowerBiEnum.Leasedetails.reportid}
        embedUrl={PowerBiEnum.Leasedetails.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminLeasedetailsDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-leasecontracts",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 320,
    path: "/customersriskAdmin",
    name: "Dashboard:customersRisk",
    component: () => (
      <AdminDashboards
        id={PowerBiEnum.CustomersRisk.reportid}
        embedUrl={PowerBiEnum.CustomersRisk.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminCustomersRiskDashboards)],
    icon: "mdi mdi-asterisk c-white",
    iconActive: "mdi mdi-asterisk c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-customersRisk",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 321,
    path: "/campaigncostAdmin",
    name: "Dashboard:adminCampaignCost",
    component: () => (
      <AdminDashboards
        id={PowerBiEnum.CampaignCost.reportid}
        embedUrl={PowerBiEnum.CampaignCost.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminCampaignCostDashboards)],
    icon: "mdi mdi-asterisk c-white",
    iconActive: "mdi mdi-asterisk c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:adminCampaignCost",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 322,
    path: "/leadownerAdmin",
    name: "Dashboard:leadower-dashboard",
    component: () => (
      <AdminDashboards
        id={PowerBiEnum.LeadOwner.reportid}
        embedUrl={PowerBiEnum.LeadOwner.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminLeadOwnerDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-leadower-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 323,
    path: "/leadautomationAdmin",
    name: "Dashboard:LeadAutomation",
    component: () => (
      <AdminLeadAutomation
        id={PowerBiEnum.LeadAutomation.reportid}
        embedUrl={PowerBiEnum.LeadAutomation.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminLeadAutomationPermissions)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-LeadAutomation",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 324,
    path: "/unqualifiedleadAdmin",
    name: "Dashboard:UnqualifiedLeads",
    component: () => (
      <AdminUnqualifiedLeads
        id={PowerBiEnum.UnqualifiedLeads.reportid}
        embedUrl={PowerBiEnum.UnqualifiedLeads.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminUnqualifiedLeadsPermissions)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-UnqualifiedLeads",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 325,
    path: "/internationalsalesYtdAdmin",
    name: "Dashboard:InternationalSalesYTDMTD",
    component: () => (
      <AdminInternationalSalesYTDMTD
        id={PowerBiEnum.InternationalSalesYTDMTD.reportid}
        embedUrl={PowerBiEnum.InternationalSalesYTDMTD.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminInterytdPermissions)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-InternationalSalesYTDMTD",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 326,
    path: "/usersdetailsAdmin",
    name: "Dashboard:UsersDetails",
    component: () => (
      <AdminUsersDetails
        id={PowerBiEnum.UsersDetails.reportid}
        embedUrl={PowerBiEnum.UsersDetails.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminUsersDetailsPermissions)],
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-UsersDetails",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 327,
    path: "/accountAdmin",
    name: "Dashboard:accounts",
    component: () => (
      <AdminDashboards
        id={PowerBiEnum.accounts.reportid}
        embedUrl={PowerBiEnum.accounts.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminAccountsPermissions)],
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-accounts",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 328,
    path: "/allcampaignsrequestsAdmin",
    name: "Dashboard:campaign-request-dashboard",
    component: () => (
      <AdminDashboards
        id={PowerBiEnum.CampaignRequest.reportid}
        embedUrl={PowerBiEnum.CampaignRequest.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminCampaignRequestPermissions)],
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-campaign-request-dashboard",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 329,
    path: "/leadpoolsAdmin",
    name: "Dashboard:admin-leadpool",
    component: AdminLeadPoolCmp,
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminLeadPoolPermissions)],
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-leadpool",
        route: "/home",
        groupName: "dashboard",
        isDisabled: true,
      },
    ],
  },
  {
    id: 334,
    path: "/zero-matching-admin",
    name: "Dashboard:zero-matching-dashboards",
    component: ZeromatchingAdmin,
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminZeromatchingDashboards)],
    icon: "mdi mdi-account-details-outline c-white",
    iconActive: "mdi mdi-account-details-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:zero-matching-dashboards",
        route: "/home",
        groupName: "dashboard",
        isDisabled: true,
      },
    ],
  },
  {
    id: 336,
    path: "/Developer-Property-Admin",
    name: "Dashboard:Developer",
    component: () => (
      <AdminDeveloper
        id={PowerBiEnum.developerProperty.reportid}
        embedUrl={PowerBiEnum.developerProperty.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminDeveloperDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-Developer",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 337,
    path: "/activity-Admin",
    name: "Dashboard:Activity",
    component: () => (
      <AdminActivity
        id={PowerBiEnum.activity.reportid}
        embedUrl={PowerBiEnum.activity.url}
      />
    ),
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminActivityDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:admin-Activity",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 339,
    path: "/ai-call-Admin",
    name: "Dashboard:ai-call",
    component: AICallInsights,
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminCallInsightsDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:ai-call",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 344,
    path: "/proposal-dashboard",
    name: "Dashboard:proposal-tracking",
    component: ProposalTrackingAdmin,
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AdminProposalTracking)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:proposal-tracking",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 345,
    path: "/inventory-dashboard",
    name: "Dashboard:inventory",
    component: Inventory,
    layout: "/home",
    submenu: true,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(Admininventory)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:inventory",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 340,
    path: "/ai-call-Insights",
    name: "Dashboard:ai-call-Insights",
    component: AICallInsightsFilter,
    layout: "/home",
    submenu: false,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(CallInsightsTeamsDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:ai-call-Insights",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 346,
    path: "/ai-call-Insights-agent",
    name: "Dashboard:ai-call-Insights-agent",
    component: AICallInsightsAgentFilter,
    layout: "/home",
    submenu: false,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(CallInsightsAgentDashboards)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:ai-call-Insights-agent",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 342,
    path: "/proposal-agent-dashboard",
    name: "Dashboard:proposal-tracking-agent",
    component: ProposalTrackingAgent,
    layout: "/home",
    submenu: false,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ProposalTrackingAgentPermissions)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:proposal-tracking-agent",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 343,
    path: "/proposal-team-dashboard",
    name: "Dashboard:proposal-tracking-team",
    component: ProposalTrackingTeam,
    layout: "/home",
    submenu: false,
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ProposalTrackingTeamPermissions)],
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    groupId: 1,
    breadcrumbs: [
      {
        name: "Dashboard:proposal-tracking-team",
        isDisabled: true,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 338,
    path: "/activity-teams-dashboard",
    name: "Dashboard:activity-team",
    component: ActivityTeamLead,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-web c-white",
    iconActive: "mdi mdi-web c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(ActivityTeamLeadPermissions)],
    groupId: 1,
    order: 7,
    breadcrumbs: [
      {
        name: "Dashboard:activity-team",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 341,
    path: "/activity-agent-dashboard",
    name: "Dashboard:activity-agent",
    component: ActivityAgents,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    icon: "mdi mdi-web c-white",
    iconActive: "mdi mdi-web c-blue-dark",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(ActivityAgentPermissions)],
    groupId: 1,
    order: 7,
    breadcrumbs: [
      {
        name: "Dashboard:activity-agent",
        isDisabled: false,
        route: "/home",
        groupName: "dashboard",
      },
    ],
  },
  {
    id: 330,
    path: "/my-share-unit",
    name: "ShareUnit:my-share-unit",
    component: MyShareUnitView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 33,
    order: 1,
    isDisabled: false,
    icon: "mdi mdi-currency-usd c-blue-lighter",
    iconActive: "mdi mdi-currency-usd c-white",
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ShareUnit:my-share-unit",
        route: "/home/share-unit/view",
        groupName: "shared-data",
        isDisabled: true,
      },
    ],
  },
  {
    id: 331,
    path: "/share-unit",
    name: "ShareUnit:share-unit",
    component: ShareUnitView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 33,
    order: 2,
    isDisabled: false,
    icon: "mdi mdi-currency-usd c-blue-lighter",
    iconActive: "mdi mdi-currency-usd c-white",
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "ShareUnit:share-unit",
        isDisabled: true,
        route: "/home/share-unit/view",
        groupName: "shared-data",
      },
    ],
  },
  {
    id: 332,
    path: "/resale-units",
    name: "UnitsView:resale-units",
    component: ResaleUnitsLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ResaleUnitsPermissions)],
    groupId: 6,
    order: 11,
    isDisabled: false,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 333,
    path: "/risk-rating-configuration",
    name: "RiskRating:Risk-rating",
    component: RiskRatingLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(RiskRatingConfigurationsPermissions)],
    groupId: 31,
    order: 8,
    icon: "mdi mdi-alert c-white",
    iconActive: "mdi mdi-alert-outline c-primary",
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [],
  },
  {
    id: 334,
    path: "/inquiry-rotation",
    name: "InquiryRotation:inquiry-rotation",
    component: InquiryRotationLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(InquiryRotationPermissions)],
    groupId: 27,
    order: 6,
    isDisabled: false,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 335,
    path: "/developers",
    name: "Developers:developers",
    component: DevelopersLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(DevelopersPermissions)],
    groupId: 15,
    order: 3,
    isDisabled: false,
    icon: "mdi mdi-home-outline c-white",
    iconActive: "mdi mdi-home-outline c-primary",
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "Developers:developers",
        isDisabled: false,
        route: "/home/developers",
        groupName: "crm",
      },
    ],
  },
  {
    id: 999,
    path: "/WorkFlow",
    name: "SideMenuView.WorkFlow.WorkFlow",
    component: WorkFlowView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 3,
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.WorkFlow.WorkFlow",
        isDisabled: false,
        route: "/home/WorkFlow",
        groupName: "contact-center",
      },
    ],
  },
  {
    id: 998,
    path: "/ExistingWorkFlowView",
    name: "SideMenuView.WorkFlow.ExistingWorkFlowView",
    component: ExistingWorkFlowView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 3,
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.WorkFlow.ExistingWorkFlowView",
        isDisabled: false,
        route: "/home/ExistingWorkFlowView",
        groupName: "contact-center",
      },
    ],
  },
  {
    id: 978,
    path: "/ExistingWorkFlow",
    name: "SideMenuView.WorkFlow.ExistingWorkFlow",
    component: WorkFlowView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 3,
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.WorkFlow.ExistingWorkFlow",
        isDisabled: false,
        route: "/home/ExistingWorkFlow",
        groupName: "contact-center",
      },
    ],
  },
  {
    id: 998,
    path: "/MainWorkFlowView",
    name: "SideMenuView.WorkFlow.WorkFlow",
    component: MainWorkFlowView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 15,
    order: 3,
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.WorkFlow.MainWorkFlowView",
        isDisabled: false,
        route: "/home/MainWorkFlowView",
        groupName: "crm",
      },
    ],
  },
  {
    id: 999,
    path: "/WorkFlow",
    name: "SideMenuView.WorkFlow.WorkFlow",
    component: WorkFlowView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 3,
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "SideMenuView.WorkFlow.WorkFlow",
        isDisabled: false,
        route: "/home/WorkFlow",
        groupName: "contact-center",
      },
    ],
  },
  {
    id: 347,
    path: "/sales-transactions-with-AML",
    name: "SalesTransactionsView:sales-transactions-with-aml",
    component: SalesTransactionsWithAMLLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(SalesTransactionsWithAMLPermissions)],
    groupId: 12,
    order: 5,
    icon: "mdi mdi-currency-usd c-blue-lighter",
    iconActive: "mdi mdi-currency-usd c-white",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "SalesTransactionsView:sales-transactions-with-aml",
        isDisabled: false,
        route: "/home/sales-transactions-with-AML/view",
        groupName: "accounts",
      },
    ],
  },
  {
    id: 400,
    path: "/matched-unit-overview",
    name: "duplicated-unit-overview",
    component: DuplicatedUnitOverview,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 3,
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: false,
  },
  {
    id: 348,
    path: "/leasing-transactions-with-AML",
    name: "LeasingTransactionsView:leasing-transactions-with-aml",
    component: LeasingTransactionsWithAMLLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LeaseTransactionsWithAMLPermissions)],
    groupId: 12,
    order: 6,
    icon: "mdi mdi-account-key-outline c-blue-lighter",
    iconActive: "mdi mdi-account-key-outline c-white",
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: "LeasingTransactionsView:leasing-transactions",
        isDisabled: false,
        route: "/home/leasing-transactions/view",
        groupName: "accounts",
      },
    ],
  },

];

export const NewHomeRoutes = [
  ...HomeRoutes,
  {
    id: 1,
    path: "/Contacts-CRM",
    name: "ContactsView:contacts",
    component: NewContactsCrmLayout,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(ContactsPermissions),
    groupId: 15,
    order: 1,
    icon: "mdi mdi-account-group-outline c-white",
    iconActive: "mdi mdi-account-group-outline c-primary",
    isDisabled: false,
    showInMenu: !contactExceptionPermission,
    // showInMenu:true ,
    isExact: false,
    breadcrumbs: [
      {
        name: "ContactsView:contacts",
        isDisabled: false,
        route: "/home/contacts-crm",
        groupName: "crm",
      },
    ],
  },
  {
    id: 995,
    path: "/Change-Password",
    name: "ChangePasswordView:ChangePassword",
    component: NewChangePasswordView,
    layout: "/home",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 8,
    order: 3,
    icon: "mdi mdi-language-xaml c-white",
    iconActive: "mdi mdi-language-xaml c-blue-dark",
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: "ChangePasswordView:ChangePassword",
        isDisabled: false,
        route: "/home/change-password",
      },
    ],
  },
];
