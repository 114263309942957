import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  AutocompleteComponent,
  DialogComponent,
} from "../../../../../../Components";
import {
  OrganizationUserSearch,
  lookupItemsGetId,
} from "../../../../../../Services";
import { GlobalTranslate } from "../../../../../../Helper";
import { AgentRoleEnum } from "../../../../../../Enums";

const parentTranslationPath = "";
const translationPath = "LeadsView:utilities.cloneleadsDialog.";
export const CloneLeadsDialog = ({
  isOpen,
  onClose,
  onSave,
  totalCloneLeads,
  checkedCardsIds,
  checkedCards,
  isOwnerLeadClone = false,
}) => {
  const { t } = useTranslation("LeadsView");
  const searchTimer = useRef(null);
  const onSubmit = async () => {
    onSave({
      cloneLeadsData: checkedCardsIds.map((leadId) => ({
        leadId,
        ...Dto,
      })),
    });
  };
  const [data, setData] = useState({
    campaignLanguages: [],
    mediaTypes: [],
    mediaDetails: [],
    users: [],
    referredBy: [],
    referredTo: [],
    methodOfContact: [],
    campaignTypes: [],
  });
  const [Dto, setDto] = useState({
    mediaDetailsId: "",
    methodOfContactId: "",
    referredBy: "",
    referredTo: "",
  });
  const [selected, setSelected] = useState({
    mediaDetails: null,
    methodOfContact: null,
    referredBy: null,
    referredTo: null,
  });
  
  const getAllMediaDetails = useCallback(async (mediaTypeId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: 1240,
      lookupParentId: mediaTypeId,
    });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, mediaDetails: res }));
    else setData((item) => ({ ...item, mediaDetails: [] }));
  }, []);

  const getAllMethodOfContact = async () => {
    const res = await lookupItemsGetId({ lookupTypeId: 35 });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, methodOfContact: res }));
    else setData((item) => ({ ...item, methodOfContact: [] }));
  };

  const getAllReferred = useCallback(async (searchValue) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2,
      userTypeId:
        checkedCards?.[0]?.leadClass.toLowerCase() === "buyer"
          ? AgentRoleEnum.SaleAgent.value
          : (checkedCards[0].leadClass.toLowerCase() === "tenant" &&
              AgentRoleEnum.LeaseAgent.value) ||
            "",
    });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, referredTo: res && res.result }));
    else setData((item) => ({ ...item, referredTo: [] }));
  }, []);

  const getAllReferredBy = useCallback(async (searchValue) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2,
    });

    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, referredBy: res && res.result }));
    else setData((item) => ({ ...item, referredBy: [] }));
  }, []);

  function findObjectInArraySales(array) {
    if (isOwnerLeadClone) {
      if (checkedCardsIds?.length === 1) {
        return (
          array.find(
            (item) =>
              item?.lookupItemName ===
                checkedCards[0].media_detail?.lookupItemName ||
              item?.lookupItemId === checkedCards[0].media_detail?.lookupItemId
          ) || null
        );
      }
    } else {
      return (
        array.find(
          (item) =>
            item.lookupItemName === "Existing Client - Sales" ||
            item.lookupItemId === 57269
        ) || null
      );
    }
  }

  function findObjectInArrayLeasing(array) {
    if (isOwnerLeadClone) {
      if (checkedCardsIds?.length === 1) {
        return (
          array.find(
            (item) =>
              item?.lookupItemName ===
                checkedCards[0].media_detail?.lookupItemName ||
              item?.lookupItemId === checkedCards[0].media_detail?.lookupItemId
          ) || null
        );
      }
    } else {
      return (
        array.find(
          (item) =>
            item.lookupItemName === "Existing Client - Leasing" ||
            item.lookupItemId === 57270
        ) || null
      );
    }
  }

  function findMethodOfContact() {
    if (isOwnerLeadClone) {
      if (checkedCardsIds.length === 1) {
        return (
          data?.methodOfContact?.find(
            (item) =>
              item?.lookupItemName ===
                checkedCards[0].method_of_contact?.lookupItemName ||
              item?.lookupItemId ===
                checkedCards[0].method_of_contact?.lookupItemId
          ) || null
        );
      }
    } else {
      return (
        data?.methodOfContact?.find(
          (item) =>
            item.lookupItemName === "Existing Client - Leasing" ||
            item.lookupItemId === 57270
        ) || null
      );
    }
  }

  useEffect(() => {
    getAllMediaDetails();
    getAllMethodOfContact();
    getAllReferred();
    getAllReferredBy();
  }, []);

  useEffect(() => {
    if (data?.mediaDetails?.length > 0) {
      if (
        checkedCards?.[0]?.leadClass.toLowerCase() === "buyer" ||
        checkedCards?.[0]?.leadClass.toLowerCase() === "seller"
      ) {
        const result = findObjectInArraySales(data.mediaDetails);
        const methodOfContact = findMethodOfContact();
        console.log("🚀 ~ useEffect ~ methodOfContact:", methodOfContact);
        setSelected({
          ...selected,
          mediaDetails: result,
          methodOfContact: methodOfContact,
        });
        setDto((item) => ({
          ...item,
          mediaDetailsId: +result?.lookupItemId || "",
          methodOfContactId: +methodOfContact?.lookupItemId || "",
        }));
      } else if (
        checkedCards?.[0]?.leadClass.toLowerCase() === "tenant" ||
        checkedCards?.[0]?.leadClass.toLowerCase() === "landlord"
      ) {
        const result = findObjectInArrayLeasing(data.mediaDetails);
        const methodOfContact = findMethodOfContact();
        setSelected({
          ...selected,
          mediaDetails: result,
          methodOfContact: methodOfContact,
        });
        setDto((item) => ({
          ...item,
          mediaDetailsId: +result?.lookupItemId || "",
          methodOfContactId: +methodOfContact?.lookupItemId || "",
        }));
      }
    }
  }, [data.mediaDetails]);

  return (
    <DialogComponent
      titleText={GlobalTranslate.t("Shared:clone-leads")}
      saveText={GlobalTranslate.t("Shared:confirm")}
      saveType="button"
      maxWidth="sm"
      dialogContent={
        <div className="d-flex-column-center">
          <span>
            {GlobalTranslate.t(
              "Shared:Are-you-sure-to-clone-this-leads-leads-total"
            )}
            : {totalCloneLeads}
          </span>
          <div className="w-100 mr-1-reversed mt-3">
            <AutocompleteComponent
              idRef="mediaDetailseRef"
              labelValue={GlobalTranslate.t("Shared:mediaDetails")}
              multiple={false}
              selectedValues={selected?.mediaDetails}
              getOptionSelected={(option) =>
                option?.lookupItemId === selected?.mediaDetails?.lookupItemId
              }
              labelClasses={"Requierd-Color"}
              data={data.mediaDetails || []}
              displayLabel={(option) => t(`${option.lookupItemName || ""}`)}
              withoutSearchButton
              inputPlaceholder={GlobalTranslate.t("Shared:SelectmediaDetails")}
              isWithError
              onChange={(e, newValue) => {
                setDto((item) => ({
                  ...item,
                  mediaDetailsId: +newValue?.lookupItemId || "",
                }));
                setSelected({
                  ...selected,
                  mediaDetails: newValue,
                });
              }}
            />
          </div>
          <div className="w-100 mr-1-reversed">
            <AutocompleteComponent
              idRef="methodOfContactRef"
              labelValue={GlobalTranslate.t("Shared:method-Of-Contact")}
              inputPlaceholder={GlobalTranslate.t(
                "Shared:Selectmethod-Of-Contact"
              )}
              multiple={false}
              labelClasses={"Requierd-Color"}
              data={data.methodOfContact}
              displayLabel={(option) => option.lookupItemName}
              selectedValues={selected?.methodOfContact}
              renderOption={(option) => option.lookupItemName || ""}
              withoutSearchButton
              isWithError
              onChange={(e, newValue) => {
                setDto((item) => ({
                  ...item,
                  methodOfContactId: +newValue?.lookupItemId || "",
                }));
                setSelected({ ...selected, methodOfContact: newValue || null });
              }}
            />
          </div>
          <div className="w-100 mr-1-reversed">
            <AutocompleteComponent
              idRef="ReferredByRef"
              labelValue={GlobalTranslate.t("Shared:ReferredBy")}
              inputPlaceholder={GlobalTranslate.t("Shared:SelectReferredBy")}
              labelClasses={"Requierd-Color"}
              selectedValues={selected?.referredBy}
              data={data?.referredBy || []}
              onInputKeyUp={(e) => {
                const newValue = e.target.value;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getAllReferredBy(newValue || "");
                }, 700);
              }}
              multiple={false}
              displayLabel={(option) => (option && option.fullName) || ""}
              chipsLabel={(option) => option?.fullName || ""}
              withoutSearchButton
              onChange={(e, newValue) => {
                setDto((item) => ({ ...item, referredBy: newValue?.id || "" }));
                setSelected({
                  ...selected,
                  referredBy: newValue,
                });
              }}
            />
          </div>
          <div className="w-100 mr-1-reversed">
            <AutocompleteComponent
              idRef="ReferredToRef"
              labelValue={GlobalTranslate.t("Shared:ReferredTo")}
              inputPlaceholder={GlobalTranslate.t("Shared:ReferredTo")}
              data={data?.referredTo || []}
              selectedValues={selected?.referredTo}
              onInputKeyUp={(e) => {
                const newValue = e.target.value;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getAllReferred(newValue || "");
                }, 700);
              }}
              multiple={false}
              displayLabel={(option) => option?.fullName || ""}
              chipsLabel={(option) => option?.fullName || ""}
              withoutSearchButton
              onChange={(e, newValue) => {
                setDto((item) => ({ ...item, referredTo: newValue?.id || "" }));
                setSelected({
                  ...selected,
                  referredTo: newValue,
                });
              }}
            />
          </div>
        </div>
      }
      saveClasses="btns theme-solid w-100 mx-2 mb-2"
      isOpen={isOpen}
      saveIsDisabled={
        Dto?.methodOfContactId === "" ||
        Dto?.referredBy === "" ||
        Dto?.mediaDetailsId === ""
      }
      onSaveClicked={() => onSubmit()}
      onCancelClicked={onClose}
      translationPath={translationPath.confirm}
    />
  );
};

CloneLeadsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
