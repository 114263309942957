import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { ButtonBase } from "@material-ui/core";
import {
  Spinner,
  Tables,
  AutocompleteComponent,
  Inputs,
  SelectComponet,
  PhonesComponent,
} from "../../../../../Components";
import { DateRangePickerComponent } from "../../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import {
  LeadStatusEnum ,
  TableFilterTypesEnum,
  LeadsClassTypesEnum,
} from "../../../../../Enums";
import { GetParams, bottomBoxComponentUpdate } from "../../../../../Helper";
import { GetDeveloperLeadsAPI } from "../../../../../Services";

export const DeveloperProfileLeadsOverviewComponent = ({
  id,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [developerId, setDeveloperId] = useState(+GetParams("id"));
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
    selectedDateType: 1,
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [leadStatusList, setLeadStatusList] = useState([...Object.values(LeadStatusEnum)].filter((s)=> s.key));
  const [leadsOverview, setLeadsOverview] = useState({
    result: [],
    totalCount: 0,
  });
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [filter, setFilter] = useState({
    pageSize: +parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex: 0,
    leadId: null,
    developerContactId: null,
    unitRefNo: null,
    leadStatus: null,
    assignedAgentName: null,
    developerId: +GetParams("id"),
    leadClass: null,
    leadCampaignName: null,
    startDate: null,
    endDate: null,
  });

  const HeaderDataTable = [
    {
      id: 1,
      label: "lead-id",
      input: "leadId",
      isHiddenFilter: false,
      component: (item) =>
        item.leadId ? (
          <a
            className="c-primary newTabActivites"
            onClick={transactionLinksActionClicked(item, "lead")}
          >
            {item.leadId || "N/A"}
          </a>
        ) : (
          <span> {item.leadId || ""} </span>
        ),
    },
    {
      id: 2,
      label: "lead-name",
      input: "leadName",
      isHiddenFilter: true,
      component: (item) =>
        item.leadId ? (
          <a
            className="c-primary newTabActivites"
            onClick={transactionLinksActionClicked(item, "lead")}
          >
            {item.leadName || ""}
          </a>
        ) : (
          <span> {item.leadName || ""} </span>
        ),
    },
    {
      id: 3,
      label: "created-date",
      input: "creationDate",
      isDate: true,
      isHiddenFilter: true,
    },
    {
      id: 4,
      label: "lead-class",
      input: "leadClass",
      isHiddenFilter: false,
      component: (item, index) =>
        item.leadClass === "Seller" ? (
          <div className="pass-lapel" index={index}>
            {t(`${translationPath}${item.leadClass}`)}
          </div>
        ) : item.leadClass === "Buyer" ? (
          <div className="Pending-lapel" index={index}>
            {t(`${translationPath}${item.leadClass}`)}
          </div>
        ) : item.leadClass === "Landlord" ? (
          <div className="Fail-lapel" index={index}>
            {t(`${translationPath}${item.leadClass}`)}
          </div>
        ) : item.leadClass === "Tenant" ? (
          <div className="Fail-lapel" index={index}>
            {t(`${translationPath}${item.leadClass}`)}
          </div>
        ) : null,
      withSelectFilter: true,
      optionFilterList: [...Object.values(LeadsClassTypesEnum)],
    },
    {
      id: 5,
      label: "lead-campaign-name",
      input: "leadCampaignName",
      isHiddenFilter: false,
    },
    {
      id: 6,
      label: "contactId",
      isHiddenFilter: false,
      input: "developerContactId",
      component: (item) =>
        item.contactId ? (
          <a
            className="c-primary newTabActivites"
            onClick={transactionLinksActionClicked(item, "contact")}
          >
            {item.contactId || ""}
          </a>
        ) : (
          <span> {item.contactId || ""} </span>
        ),
    },
    {
      id: 7,
      label: "interested-property",
      input: "interestedProperty",
      isHiddenFilter: true,
      component: (item) =>
        item.interestedPropertyId ? (
          <a
            className="c-primary newTabActivites"
            onClick={transactionLinksActionClicked(item, "property")}
          >
            {item.interestedProperty || ""}
          </a>
        ) : (
          <span> {item.interestedProperty || ""} </span>
        ),
    },
    {
      id: 8,
      label: "lead-stage",
      input: "leadStage",
      isHiddenFilter: true,
    },

    {
      id: 9,
      label: "lead-status",
      input: "leadStatus",
      withSelectFilter: true,
      optionFilterList : leadStatusList , 
      isHiddenFilter: false,
      component: (item, index) =>
        (item.leadStatus === "Open" && (
          <div className="globel-open-style" index={index}>
            {t(`${translationPath}open`)}
          </div>
        )) || (
          <div className="globel-closed-style" index={index}>
            {t(`${translationPath}closed`)}
          </div>
        ),
    },
    {
      id: 10,
      label: "assigned-agent",
      input: "assignedAgentName",
      isHiddenFilter: false,
      component: (item) => <span> {item.assignedAgent || ""} </span>,
    },
    {
      id: 11,
      label: "transaction-value",
      input: "transactionValue",
      isHiddenFilter: true,
    },
    {
      id: 12,
      label: "unit-type",
      input: "unitType",
      isHiddenFilter: true,
    },
    {
      id: 13,
      label: "unitRef",
      input: "unitRefNo",
      isHiddenFilter: false,
      component: (item) =>
        item.unitId ? (
          <a
            className="c-primary newTabActivites"
            onClick={transactionLinksActionClicked(item, "unit")}
          >
            {item.unitRefNo || ""}
          </a>
        ) : (
          <span> {item.unitRefNo || ""} </span>
        ),
    },
  ];

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const transactionLinksActionClicked = useCallback(
    (item, key) => async (event) => {
      event.stopPropagation();
      if (
        key === "lead" &&
        item?.leadType === "Owner" &&
        item.leadTypeId &&
        item.leadId
      )
        window.open(
          `/home/leads/lead-profile-edit?formType=${item.leadTypeId}&id=${item.leadId}`,
          "_blank"
        );
      else if (key === "contact" && item.contactId && item.contactTypeId)
        window.open(
          `/home/Contacts-CRM/contact-profile-edit?formType=${item.contactTypeId}&id=${item.contactId}`,
          "_blank"
        );
      else if (
        key === "lead" &&
        item.leadType === "Seeker" &&
        item.leadTypeId &&
        item.leadId
      )
        window.open(
          `/home/leads/lead-profile-edit?formType=${item.leadTypeId}&id=${item.leadId}`,
          "_blank"
        );
      else if (
        key === "unit" &&
        item?.unitOperationType === "SaleAndRent" &&
        item.unitOperationTypeId &&
        item.unitRefNo
      )
        window.open(
          `/home/units-sales/unit-profile-edit?formType=${1}&id=${
            item.unitId || item.unitRefNo
          }&operationType=${item.unitOperationTypeId}`,
          "_blank"
        );
      else if (key === "unit" && item.unitOperationType === "Sale")
        window.open(
          `/home/units-sales/unit-profile-edit?formType=${1}&id=${
            item.unitId || item.unitRefNo
          }&operationType=${item.unitOperationTypeId}`,
          "_blank"
        );
      else if (key === "unit" && item.unitOperationType === "Lease")
        window.open(
          `/home/units-lease/unit-profile-edit?formType=${1}&id=${
            item.unitId || item.unitRefNo
          }&operationType=${item.unitOperationTypeId}`,
          "_blank"
        );
      else if (
        key === "property" &&
        item.interestedProperty &&
        item.interestedPropertyId
      )
        window.open(
          `/home/Properties-CRM/property-profile-edit?formType=${1}&id=${
            item.interestedPropertyId
          }`,
          "_blank"
        );
    }
  );

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const GetAllDeveloperLeads = useCallback(
    async (searchObj, dateFilterUpdate) => {
      setIsLoading(true);

      const leadStatus =
        (searchObj &&
          (searchObj?.leadStatus && searchObj.leadStatus === 457
            ? 457
            : searchObj?.leadStatus === 458
            ? 458
            : null)) ||
        null;
      const startDate =
        dateFilterUpdate && dateFilterUpdate?.startDate
          ? moment(dateFilterUpdate.startDate).format("YYYY-MM-DD")
          : null;
      const endDate =
        dateFilterUpdate && dateFilterUpdate?.endDate
          ? moment(dateFilterUpdate.endDate).format("YYYY-MM-DD")
          : null;

      const res = await GetDeveloperLeadsAPI(
        !searchObj
          ? {
              ...filter,
              pageIndex: filter.pageIndex + 1,
              startDate: startDate,
              endDate: endDate,
            }
          : {
              ...searchObj,
              pageIndex: searchObj.pageIndex + 1,
              leadStatus: leadStatus,
              startDate: startDate,
              endDate: endDate,
            }
      );
      if (!(res && res.status && res.status !== 200)) {
        setLeadsOverview({
          result: (res && res.result) || [],
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setLeadsOverview({
          result: [],
          totalCount: 0,
        });
      }
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    if (filterValues && Object.keys(filterValues).length > 0) {
      Object.values(filterValues).map((item, index) => {
        setFilter((i) => ({
          ...i,
          [item.displayPath]: item.value,
        }));
      });
    }
  }, [filterValues]);

  useEffect(() => {
    setTableFilterData(
      HeaderDataTable.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
        optionFilterList: column?.optionFilterList,
        multiple: column?.multiple,
      }))
    );
  }, []);

  const onFilterValuesChanged = (newValue) => {
    const localFilterDto = filter || {};
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.displayPath])
            localFilterDto[item.displayPath] =
              item?.value && item.value !== "" ? item.value : null;
          else if (item.value)
            localFilterDto[item.displayPath] =
              item?.value && item.value !== "" ? item.value : null;
        });
    }
    setFilter({
      ...filter,
      ...localFilterDto,
      pageIndex: 0,
      selectedleadStatus:
        (localFilterDto.leadStatus &&
          leadStatusList.find((l) => l.key === localFilterDto.leadStatus)) ||
        null,
    });
  };

  useEffect(() => {
    GetAllDeveloperLeads();
  }, [GetAllDeveloperLeads]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  return (
    <div className="w-100">
      <Spinner isActive={isLoading} isAbsolute />

      <div className="d-flex-column mt-3  mb-2">
        <div className="header-section mb-2 mt-2">
          <div className="filter-section">
            <div className="section"></div>
            <div className="section autocomplete-section">
              <div className="d-flex-column px-2 w-100 p-relative">
                <div className="w-100 d-flex d-flex-inline d-flex-h-center-h-start">
                  <div className="w-25 p-relative px-2">
                    <Inputs
                      idRef="leadIdRef"
                      type="number"
                      min={0}
                      value={filter.leadId || ""}
                      inputPlaceholder={t(`${translationPath}enter-lead-id`)}
                      onInputChanged={(event) => {
                        let   newValue =  (event.target.value && event.target.value !== "" && +event.target.value) || null;
                        setFilter({
                          ...filter,
                          leadId:
                             newValue ||
                            null,
                        });
                        setFilterValues({
                          ...filterValues,
                          leadId: {
                            value: newValue ,
                            operator: 1,
                            displayPath: "leadId",
                            searchableKey: "leadId",
                          },
                        });
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                  <div className="w-25 p-relative px-2">
                    <Inputs
                      idRef="contactIdRef"
                      value={filter.developerContactId || ""}
                      inputPlaceholder={t(`${translationPath}enter-contact-id`)}
                      onInputChanged={(event) => {
                        let   newValue =  (event.target.value && event.target.value !== "" && +event.target.value) || null;
                        setFilter({
                          ...filter,
                          developerContactId:
                            (newValue) || null,
                        });
                        setFilterValues({
                          ...filterValues,
                          developerContactId: {
                            value: newValue ,
                            operator: 1,
                            displayPath: "developerContactId",
                            searchableKey: "developerContactId",
                          },
                        });
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                  <div className="w-25 p-relative px-2">
                    <Inputs
                      idRef="unitRefNoRef"
                      value={filter.unitRefNo || ""}
                      inputPlaceholder={t(
                        `${translationPath}enter-unit-ref-no`
                      )}
                      onInputChanged={(event) => {
                        const newValue = (event.target.value && event.target.value !== "" && event.target.value) || null ; 
                        setFilter({
                          ...filter,
                          unitRefNo: newValue || null,
                        });
                        setFilterValues({
                          ...filterValues,
                          unitRefNo: {
                            value: newValue,
                            operator: 1,
                            displayPath: "unitRefNo",
                            searchableKey: "unitRefNo",
                          },
                        });
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                  <div className="w-25 p-relative px-2">
                    <Inputs
                      idRef="assignedAgentNameRef"
                      value={filter.assignedAgentName || ""}
                      inputPlaceholder={t(
                        `${translationPath}assigned-agent-name`
                      )}
                      onInputChanged={(event) => {
                        const newValue = (event.target.value && event.target.value !== "" && event.target.value) || null ; 
                        setFilter({
                          ...filter,
                          assignedAgentName: newValue || null,
                        });
                        setFilterValues({
                          ...filterValues,
                          assignedAgentName: {
                            value: newValue,
                            operator: 1,
                            displayPath: "assignedAgentName",
                            searchableKey: "assignedAgentName",
                          },
                        });
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                </div>
                <div className="w-100 d-flex d-flex-inline d-flex-h-center-h-start">
                  <div className="w-33 p-relative px-2">
                    <AutocompleteComponent
                      idRef="statusRef"
                      selectedValues={filter?.selectedleadStatus || null}
                      inputPlaceholder={t(`${translationPath}lead-status`)}
                      data={leadStatusList}
                      multiple={false}
                      displayLabel={(option) =>
                        (option && t(`${translationPath}${option?.value}`)) ||
                        ""
                      }
                      withoutSearchButton
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        setFilter((item) => ({
                          ...item,
                          leadStatus: newValue && newValue.status,
                          selectedleadStatus: newValue,
                        }));
                        setFilterValues({
                          ...filterValues,
                          leadStatus: {
                            value: newValue?.key || null,
                            operator: 1,
                            displayPath: "leadStatus",
                            searchableKey: "leadStatus",
                          },
                          selectedleadStatus: {
                            value: newValue || null,
                            operator: 1,
                            displayPath: "selectedleadStatus",
                            searchableKey: "selectedleadStatus",
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="w-33 p-relative px-2">
                    <Inputs
                      idRef="leadCampaignNameRef"
                      value={filter.leadCampaignName || ""}
                      inputPlaceholder={t(
                        `${translationPath}lead-campaign-name`
                      )}
                      onInputChanged={(event) => {
                        const newValue = (event.target.value && event.target.value !== "" && event.target.value) || null ; 
                        setFilter({
                          ...filter,
                          leadCampaignName: newValue || null,
                        });
                        setFilterValues({
                          ...filterValues,
                          leadCampaignName: {
                            value: newValue,
                            operator: 1,
                            displayPath: "leadCampaignName",
                            searchableKey: "leadCampaignName",
                          },
                        });
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                  <div className="w-33 p-relative px-2">
                    <SelectComponet
                      data={Object.values(LeadsClassTypesEnum)}
                      emptyItem={{
                        value: null,
                        text: 'select-lead-class',
                        isDisabled: false,
                      }}
                      value={filter.leadClass || null}
                      isWithCheckAll
                      valueInput="key"
                      keyLoopBy="key"
                      textInput="name"
                      onSelectChanged={(value) => {
                        if (!value || value === 0) {
                          setFilter({
                            ...filter,
                            leadClass: null,
                          });
                          setFilterValues({
                            ...filterValues,
                            leadClass: {
                              value: null,
                              operator: 1,
                              displayPath: "leadClass",
                              searchableKey: "leadClass",
                            },
                          });
                        } else {
                          setFilter({
                            ...filter,
                            leadClass: value,
                          });
                          setFilterValues({
                            ...filterValues,
                            leadClass: {
                              value: value,
                              operator: 1,
                              displayPath: "leadClass",
                              searchableKey: "leadClass",
                            },
                          });
                        }
                      }}
                      themeClass="theme-default"
                      wrapperClasses="over-input-select w-auto"
                      idRef="changeContactClassRef"
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>
                </div>
                <div className="w-100 d-flex-inline d-flex-v-center-h-center">
                  <div className="w-50  d-flex d-flex-h-center-v-center">
                    <div className="date-type-select">
                      <SelectComponet
                        data={[
                          { key: 1, value: `${translationPath}created-date` },
                        ]}
                        defaultValue={{
                          key: 1,
                          value: `${translationPath}created-date`,
                        }}
                        value={dateFilter.selectedDateType || 1}
                        valueInput="key"
                        textInput="value"
                        onSelectChanged={(value) => {
                          setIsDatePickerChanged(true);
                          setDateFilter((f) => ({
                            ...f,
                            selectedDateType: value,
                          }));
                        }}
                        wrapperClasses="w-auto"
                        themeClass="theme-transparent"
                        idRef="Date_Select"
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <DateRangePickerComponent
                      onClearClicked={() => {
                        setDateFilter(dateRangeDefault);
                        setIsDatePickerChanged(true);
                      }}
                      onDialogClose={() => setIsDatePickerChanged(true)}
                      ranges={[dateFilter]}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onDateChanged={(selectedDate) => {
                        setDateFilter((item) => ({
                          ...item,
                          selectedDateType: 1,
                          startDate: selectedDate?.selection?.startDate,
                          endDate: new Date(
                            moment(selectedDate?.selection?.endDate).endOf(
                              "day"
                            )
                          ),
                          key: "selection",
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="w-100 mb-2 mt-2 d-flex-v-center-h-end flex-wrap">
                  <span className="">
                    <ButtonBase
                      onClick={() => {
                        setIsClearFiltersClicked(false);
                        GetAllDeveloperLeads(
                          { ...filter, pageIndex: 0 },
                          dateFilter
                        );
                      }}
                      id="filterBtn"
                      className={`px-2 btns theme-solid`}
                    >
                      <span className={`mdi mdi-arrow-expand-left m-1`} />
                      {t(`${translationPath}apply-filters`)}
                    </ButtonBase>
                    <ButtonBase
                      onClick={() => {
                        const clearFilter = {
                          ...filter,
                          pageIndex: 0,
                          leadId: null,
                          developerContactId: null,
                          unitRefNo: null,
                          leadStatus: null,
                          assignedAgentName: null,
                          selectedleadStatus: null,
                          leadClass: null,
                          leadCampaignName: null,
                          startDate: null,
                          endDate: null,
                        };
                        setFilter(clearFilter);
                        setFilterValues(null);
                        setIsClearFiltersClicked(true);
                        GetAllDeveloperLeads(clearFilter);
                        setDateFilter(dateRangeDefault);
                      }}
                      id="onClearedAllFiltersref"
                      className="btns px-2 theme-solid bg-danger clear-all-btn"
                    >
                      <span className="mdi mdi-filter-remove m-1" />
                      {t(`${translationPath}clear-filters`)}
                    </ButtonBase>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 px-3">
        <Tables
          data={leadsOverview.result}
          headerData={HeaderDataTable}
          filterData={tableFilterData}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          dateFormat="DD, MMM, YYYY"
          defaultActions={[]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={leadsOverview.totalCount}
          isWithFilter
          onFilterValuesChanged={onFilterValuesChanged}
          isClearFiltersClicked={isClearFiltersClicked}
          setIsClearFiltersClicked={setIsClearFiltersClicked}
          filterValues={filterValues}
        />
      </div>
    </div>
  );
};

DeveloperProfileLeadsOverviewComponent.propTypes = {
  id: PropTypes.number.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
