import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PaymentPlansLocalePath } from '../i18n/PaymentPlansLocale';

const StatusRadio = ({ status, dispatch }) => {
  const { t } = useTranslation(PaymentPlansLocalePath);
  return (
    <RadioGroup
      row
      aria-label='position'
      name='status'
      value={status}
      onChange={(e) => {
        dispatch({ field: 'propertyPlanStatus', value: +e.target.value });
      }}
      className='flex fj-between flex-wrap'
    >
      <FormControlLabel
        value={1}
        labelPlacement='start'
        control={<Radio color='secondary' size='small' />}
        label={t('active')}
        className='radio-item'
      />
      <FormControlLabel
        value={2}
        labelPlacement='start'
        control={<Radio color='secondary' size='small' />}
        label={t('inactive')}
        className='radio-item'
      />
    </RadioGroup>
  );
};

export default StatusRadio;
