import React from "react";
import {
  CustomContactHistoryComponent,
  CustomMergingHistoryComponent,
} from "../Sections/HistoryComponent";
import { useTranslate } from "../../../../Hooks";

const CustomHistoryComponentTabs = () => {
  const { translate: contactTranslate } = useTranslate("NewContactsView");

  const contactHistory = [
    {
      label: contactTranslate("history"),
      component: <CustomContactHistoryComponent />,
    },
    {
      label: contactTranslate("merging-history"),
      component: <CustomMergingHistoryComponent />,
    },
  ];
  const saleUnitHistory = [];
  const leaseUnitHistory = [];
  const unitPropertyManagmentHistory = [];
  const saleLeadsHistory = [];
  const leaseLeadHistory = [];
  const callCenterLeadHistory = [];
  const propertyManagmentLeadHistory = [];
  const propertyHistory = [];
  const propertyManagmentHistory = [];
  const LeadsMortgageHistory = [];
  const contactLeadHistory = [];
  const leadOwnerUnitSaleHistory = [];
  const leadOwnerUnitLeaseHistory = [];
  const leadOwnerLeadHistory = [];
  const rotationSchema = [];
  const manageAgents = [];
  return {
    contactHistory,
    saleUnitHistory,
    leaseUnitHistory,
    unitPropertyManagmentHistory,
    saleLeadsHistory,
    leaseLeadHistory,
    callCenterLeadHistory,
    propertyManagmentLeadHistory,
    propertyHistory,
    propertyManagmentHistory,
    LeadsMortgageHistory,
    contactLeadHistory,
    leadOwnerUnitSaleHistory,
    leadOwnerUnitLeaseHistory,
    leadOwnerLeadHistory,
    rotationSchema,
    manageAgents,
    saleUnitHistory,
  };
};

export default CustomHistoryComponentTabs;
