import React, { useEffect,  useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import ImagesGrid from "../ImagesGrid/ImagesGrid";
import { GetAllUnitImagesCategoryByUnitId } from "../../../../../../../../../../Services";

const ImageGridDialog = ({ isOpen, onclose, unitId }) => {
  const [unitImages, setUnitImages] = useState([]);

  const getAllCategoryImages = (response) => {
    let allImages = [];
    if(Array.isArray(response)) response.forEach(item => allImages = [
        ...allImages,
        ...(item.images.result || []),
    ])
    return allImages;
  }
  
  const getAllUnitImages = async () => {
    const response = await GetAllUnitImagesCategoryByUnitId(unitId);
    if (!(response && response.status && response.status !== 200)){
        let images = getAllCategoryImages(response);
        setUnitImages(images);
    }
    else setUnitImages([]);
  };

  useEffect(() => {
    getAllUnitImages();
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      onClose={onclose}
      className="dialog-wrapper"
    >
    <DialogTitle
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
      }}>
        <Button
          className='close-btn-wrapper close-btn-wrapper  btns-icon theme-solid bg-danger'
          onClick={onclose}
        >
         <span className='mdi mdi-close' />
        </Button>
      </DialogTitle>
      <DialogContent>
        <div className="dialog-body propx-view d-flex-column gap-32 p-3P5">
          <DialogContent>
            <div className="p-4">
              <ImagesGrid images={unitImages} />
            </div>
          </DialogContent>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageGridDialog;
