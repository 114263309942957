import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Typography } from "@material-ui/core";
import { CustomCard, CustomMenu, DeleteConfirmationDialog } from "..";
import { useIsAr, useSelectedTheme, useTranslate } from "../../../Hooks";
import { getDownloadableLink } from "../../../Helper";
import { DeleteScopeDocument } from "../../../Services";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import clsx from "clsx";
import { GetFileIconbyDocumentName } from "./GetFileIconbyDocumentName";
import { ContactEditDocumentDialog } from "../../../Views/Home/NewContactsView/ContactOverview/UI";
import { ContactEditAllItemsRelatedCategoryDialog } from "../../../Views/Home/NewContactsView/ContactOverview/UI/ContactEditAllItemsRelatedCategoryDialog";

// Styles
import useStyles from "./styles";

//Icons
import {
  MoreVertIcon,
  ShareIcon,
  Image01Icon,
  DeleteIcon,
  AttatchmentIcon,
  FileIcon,
  ErrorImageIcon,
  EyeIcon,
  EditIcon,
  DownloadIcon,
} from "../../../assets/icons";
import { ContactPreviewDocumentsDialog } from "../../../Views/Home/NewContactsView/ContactOverview/UI";

function formatDocumentName(documentName) {
  if (!documentName) return "Untitled";
  const namePart = documentName.split(".")[0].slice(0, 13);
  const extension = documentName.split(".")[1];
  return `${namePart}${extension ? "." + extension : ""}`;
}

function formatCreatorName(creator, isAr) {
  return creator
    ? isAr
      ? `${creator.slice(-9)}...`
      : `${creator.slice(0, 9)}...`
    : "Unknown";
}

function DocumentsContactCard({
  documentItem,
  onShareClickHandler,
  setUpdatedGetAllScopeDocuments,
  documents,
}) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewContactsView");
  const { isAr } = useIsAr();
  const { setAlertBoxContent } = useVerticalNav();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [isPreviewDailogOpen, setIsPreviewDialogOpen] = useState(false);
  const [isEditDocoumentOpen, setIsEditDocoumentOpen] = useState(false);
  const [isEditDocoumentRelatedCategory, setIsEditDocoumentRelatedCategory] =
    useState(false);
  const [editDataOfCategory, setEditDataOfCategory] = useState([]);

  const formattedDate = documentItem?.createdOn
    ? moment(documentItem?.createdOn).locale("en").format("D MMM YYYY")
    : "Date Unknown";

  const documentName = formatDocumentName(documentItem?.documentName);
  const creatorName = formatCreatorName(documentItem?.createdBy, isAr);
  const editImage =
    documentItem.documentId &&
    ["png", "jpg", "jpeg", "gif", "bmp", "tiff", "webp", "svg"].includes(
      documentName.split(".").pop().toLowerCase()
    );

  const editDocument =
    documentName &&
    !["png", "jpg", "jpeg", "gif", "bmp", "tiff", "webp", "svg"].includes(
      documentName.split(".").pop().toLowerCase()
    ) &&
    !documentItem.documentLink;

  const editUrl = documentItem.documentLink || false;
  useEffect(() => {
    // only for imagetype
    if (
      documentItem.documentId &&
      ["png", "jpg", "jpeg", "gif", "bmp", "tiff", "webp", "svg"].includes(
        documentName.split(".").pop().toLowerCase()
      )
    ) {
      setImageUrl(getDownloadableLink(documentItem.documentId));
    }
  }, [documentItem.documentId]);

  const onDeleteHandler = async (scopeDocumentId) => {
    try {
      const res = await DeleteScopeDocument(scopeDocumentId);
      if (!(res && res.status && res.status !== 200)) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: translate("document-deleted-successfully"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
        setUpdatedGetAllScopeDocuments(true);
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: translate("Something-went-wrong!"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } catch (error) {
      console.error("sorry-something-wrong-please-try-again!", error);
    } finally {
    }
  };

  const onPreviewClickHandler = () => {
    setIsPreviewDialogOpen(true);
  };

  const onDownloadClickHandler = (item) => {
    if (!item) {
      return;
    }
    try {
      const link = document.createElement("a");
      link.setAttribute("download", item.documentName);
      link.href = getDownloadableLink(item.documentId);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const handleEditOpenDialogClick = (selectedDoucment) => {
    const matchingDocuments = documents?.result?.filter(
      (documentItem) => documentItem.categoryId === selectedDoucment.categoryId
    );
    if (
      matchingDocuments &&
      matchingDocuments.length === 1 &&
      matchingDocuments[0].documentLink
    ) {
      setIsEditDocoumentOpen(true);
    } else {
      setEditDataOfCategory(matchingDocuments);
      setIsEditDocoumentRelatedCategory(true);
    }
  };

  return (
    <>
      <CustomCard
        borderRadius="xl"
        borderColor="secondary"
        classes={styles.cardWrapper}
      >
        <Box className={styles.cardContainer}>
          <Box
            className={clsx(styles.headingContainer, {
              [styles.withUrl]: documentItem?.documentLink,
              [styles.withImage]:
                documentItem.documentId &&
                [
                  "png",
                  "jpg",
                  "jpeg",
                  "gif",
                  "bmp",
                  "tiff",
                  "webp",
                  "svg",
                ].includes(documentName.split(".").pop().toLowerCase()),
              [styles.withDocument]:
                documentName &&
                ![
                  "png",
                  "jpg",
                  "jpeg",
                  "gif",
                  "bmp",
                  "tiff",
                  "webp",
                  "svg",
                ].includes(documentName.split(".").pop().toLowerCase()),
            })}
          >
            <Box className={styles.imageWrapper}>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt={documentItem.documentName}
                  className={styles.imagePreview}
                  onError={(e) => {
                    e.target.onerror = null;
                    setImageError(true);
                  }}
                />
              ) : imageError ? (
                <ErrorImageIcon
                  width="28"
                  height="28"
                  fill={palette.border.tertiary}
                  className={styles.errorIcon}
                />
              ) : documentItem?.documentLink ? (
                <Box className={styles.containerAttatchmentIcon}>
                  <AttatchmentIcon
                    width="28"
                    height="28"
                    fill={palette.border.tertiary}
                  />
                </Box>
              ) : documentName ? (
                <>
                  {GetFileIconbyDocumentName({
                    documentName: documentName
                      ?.split(".")
                      ?.pop()
                      ?.toLowerCase(),
                    height: "56",
                    width: "56",
                  })}
                </>
              ) : (
                <span className={styles.noImageText}>
                  There is no preview available
                </span>
              )}
            </Box>
            <CustomMenu
              menuItems={[
                documentItem.documentId && {
                  text: "Preview",
                  icon: (
                    <EyeIcon
                      width="16"
                      height="16"
                      fill={palette.foreground.quarterary}
                    />
                  ),
                  handler: () => {
                    onPreviewClickHandler(documentItem);
                  },
                },
                {
                  text: "Edit",
                  icon: (
                    <EditIcon
                      width="16"
                      height="16"
                      fill={palette.foreground.quarterary}
                    />
                  ),
                  handler: () => {
                    handleEditOpenDialogClick(documentItem);
                  },
                },
                {
                  text: "Delete",
                  icon: (
                    <DeleteIcon
                      width="16"
                      height="16"
                      fill={palette.button.secondaryGray_fg}
                    />
                  ),
                  handler: () => {
                    setIsDeleteDialogOpen(true);
                  },
                },
                {
                  text: "Share",
                  icon: (
                    <ShareIcon
                      width="16"
                      height="16"
                      fill={palette.button.secondaryGray_fg}
                    />
                  ),
                  handler: () => {
                    onShareClickHandler(documentItem);
                  },
                },
                documentItem.documentId && {
                  text: "Download",
                  icon: (
                    <DownloadIcon
                      width="16"
                      height="16"
                      fill={palette.button.secondaryGray_fg}
                    />
                  ),
                  handler: () => {
                    onDownloadClickHandler(documentItem);
                  },
                },
              ].filter(Boolean)}
              activeIconClass={styles.menuActiveIconClass}
              defaultIconClass={styles.menuDefaultIconClass}
            >
              <MoreVertIcon width="20" height="20" />
            </CustomMenu>
          </Box>

          <Box className={styles.contentContainer}>
            <Box className={styles.titleWrapper}>
              <Typography
                className={styles.categoryName}
                variant="h6"
                align="center"
              >
                {documentItem?.categoryName || "No Category"}
              </Typography>
              <Box className={styles.documentNameWrapper}>
                {documentItem.documentName ? (
                  [
                    "png",
                    "jpg",
                    "jpeg",
                    "gif",
                    "bmp",
                    "tiff",
                    "webp",
                    "svg",
                  ].includes(documentName.split(".").pop().toLowerCase()) ? (
                    <>
                      <Image01Icon
                        width="20"
                        height="20"
                        fill={palette.utility.utility_gray_400}
                      />
                      <span className={styles.documentName}>
                        {documentName}
                      </span>
                    </>
                  ) : (
                    <>
                      <FileIcon
                        width="20"
                        height="20"
                        fill={palette.utility.utility_gray_400}
                      />
                      <span className={styles.documentLink}>
                        {documentItem.documentName}
                      </span>
                    </>
                  )
                ) : documentItem?.documentLink ? (
                  <>
                    <AttatchmentIcon
                      width="20"
                      height="20"
                      fill={palette.utility.utility_gray_400}
                    />
                    <span className={styles.documentLink}>
                      {documentItem.documentLink}
                    </span>
                  </>
                ) : null}
              </Box>
            </Box>
            <Box className={styles.footerContainer}>
              <Typography
                className={styles.createdOn}
                variant="body1"
                align="center"
              >
                Created On: <b className={styles.date}>{formattedDate}</b>{" "}
                <span>{creatorName}</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </CustomCard>
      <ContactEditDocumentDialog
        open={isEditDocoumentOpen}
        onClose={() => {
          setIsEditDocoumentOpen(false);
        }}
        scopeCategoryDocumentData={documentItem}
        setUpdatedGetAllScopeDocuments={setUpdatedGetAllScopeDocuments}
        editUrl={editUrl}
      />
      <ContactEditAllItemsRelatedCategoryDialog
        open={isEditDocoumentRelatedCategory}
        onClose={() => {
          setIsEditDocoumentRelatedCategory(false);
        }}
        scopeCategoryDocumentData={documentItem}
        setUpdatedGetAllScopeDocuments={setUpdatedGetAllScopeDocuments}
        editAllDataOfCategory={editDataOfCategory}
        editDocument={editDocument}
        editImage={editImage}
      />
      {isDeleteDialogOpen && (
        <DeleteConfirmationDialog
          onClose={() => {
            setIsDeleteDialogOpen(false);
          }}
          title={translate("Your-document-will-be-deleted")}
          subTitle={translate(
            "Are-you-sure-you-want-to-delete-this-document-This-action-cannot-be-undone"
          )}
          onConfirm={() => {
            onDeleteHandler(documentItem.scopeDocumentId);
            setIsDeleteDialogOpen(false);
          }}
          confirmText={translate("Delete")}
        />
      )}
      <ContactPreviewDocumentsDialog
        OnOpen={isPreviewDailogOpen}
        onClose={() => {
          setIsPreviewDialogOpen(false);
        }}
        documentItem={documentItem}
      />
    </>
  );
}

export default DocumentsContactCard;
