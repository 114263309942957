import React from 'react'

export default function CheckCircleGreen({fill , ...restProps}) {
    return (
        <svg
          viewBox="0 0 24 24"
          fill={fill ?? ""}
          xmlns="http://www.w3.org/2000/svg"
          {...restProps}
        >
          <path
            d="M11.54 1.026c-2.33.1-4.571.943-6.42 2.414-1.717 1.366-3.052 3.37-3.674 5.52-.289.994-.415 1.92-.415 3.04 0 1.789.339 3.248 1.13 4.86a10.85 10.85 0 0 0 4.979 4.979c.739.362 1.226.55 1.929.743 1.023.28 1.829.386 2.931.386 1.783 0 3.254-.341 4.86-1.129a10.963 10.963 0 0 0 5.758-7.049c.263-1.016.35-1.71.35-2.79 0-1.783-.341-3.254-1.129-4.86a10.981 10.981 0 0 0-6.799-5.694 11.647 11.647 0 0 0-3.5-.42m1.605 2.036a9.042 9.042 0 0 1 6.366 3.956 9.707 9.707 0 0 1 1.286 3.005 9.682 9.682 0 0 1 0 3.954 10.434 10.434 0 0 1-.648 1.872 9.031 9.031 0 0 1-7.004 5.089c-.604.08-1.686.08-2.29 0-3.433-.45-6.309-2.81-7.395-6.069A8.409 8.409 0 0 1 3 12c0-1.45.305-2.758.936-4.015a9.018 9.018 0 0 1 6.884-4.922c.56-.076 1.749-.077 2.325-.001m3.144 4.98a.99.99 0 0 0-.3.122c-.071.048-1.335 1.291-2.809 2.762L10.5 13.6l-1.14-1.143c-.627-.629-1.216-1.196-1.308-1.261a.988.988 0 0 0-1.401.315c-.095.161-.111.233-.111.489 0 .254.017.328.107.48.153.257 3.252 3.328 3.45 3.418.206.095.599.095.806.001.199-.091 6.292-6.155 6.45-6.419.091-.152.107-.226.107-.48 0-.256-.016-.328-.111-.489a1.038 1.038 0 0 0-.673-.472 2.936 2.936 0 0 0-.211-.035 2.03 2.03 0 0 1-.176.038"
            fill-rule="evenodd"
            fill={fill ?? ""}
          />
        </svg>
      );
}
