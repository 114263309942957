import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import ContactMergingHistoryTimeLine from "../UI/ContactMergingHistoryTimeLine/ContactMergingHistoryTimeLine";
import { GetMergedContactDetails } from "../../../../../../Services";
import { useQuery, useTranslate } from "../../../../../../Hooks";
import CustomPaginationHistorySideMenu from "../UI/CustomPaginationHistorySideMenu/CustomPaginationHistorySideMenu";
import { NoDataFound } from "../../../../NoDataFound";

//styles
import useStyles from "./styles";

const CustomMergingHistoryComponent = ({
  typeId,
  formType,
  handleUpdatedCountForTabs,
  handleComponentSwitch,
  componentSwitch,
}) => {
  const styles = useStyles();
  const query = useQuery();
  const contactId = Number(query.get("id"));
  const { translate } = useTranslate("NewContactsView");
  const pathName = window.location.pathname
    .split("/home/")[1]
    .split("/view")[0]
    .split("/")[0];

  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const [pagination, setPagination] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 10,
    pageIndex: 0,
  });
  const getAllDFMTransaction = useCallback(async () => {
    setIsLoading(true);
    let result = await GetMergedContactDetails(pagination, contactId);
    if (!(result && result.status && result.status !== 200)) {
      setTransactions({
        result: result && result.result,
        totalCount: result && result.totalCount,
      });
      handleUpdatedCountForTabs({ totalCount: result && result.totalCount });
    } else setTransactions({ result: [], totalCount: 0 });
    setIsLoading(false);
  }, [pagination, pathName]);

  useEffect(() => {
    getAllDFMTransaction();
  }, [getAllDFMTransaction]);

  return (
    <Box className={styles.containerMergingHistoryPlsPaggination}>
      <Box className={styles.containerMergingHistoryComponent}>
        {isLoading ? (
          <Box className={styles.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : transactions?.result?.length > 0 ? (
          <ContactMergingHistoryTimeLine
            timelineItems={transactions?.result}
            typeId={typeId}
            formType={formType}
            pathName={pathName}
            handleComponentSwitch={handleComponentSwitch}
            componentSwitch={componentSwitch}
          />
        ) : (
          <NoDataFound title={translate("Merging-History")} />
        )}
      </Box>
      {!componentSwitch && (
        <CustomPaginationHistorySideMenu
          totalItems={transactions?.totalCount}
          itemsPerPage={pagination?.pageSize}
          currentPage={pagination?.pageIndex}
          onPageChange={(page) =>
            setPagination((prev) => ({ ...prev, pageIndex: page }))
          }
        />
      )}
    </Box>
  );
};
export default CustomMergingHistoryComponent;

CustomMergingHistoryComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
