import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Inputs } from "../../../../../Components";

export const EmailItemComponent = ({
  props,
  item,
  index,
  loopValue,
  state,
  setState,
  listItem,
  setListItem,
  setSaveIsDisabled,
}) => {
  const [helperText, setHelperText] = useState("");
  const { t } = useTranslation("Shared");

  const [currentvalue, setcurrentvalue] = useState("");
  const searchTimer = useRef(null);

  const isEmailItemDuplicating = () => {
    if (!props?.initialState || !props?.initialState?.email) return false;
    let isDublocate = false;
    let list = [];
    if (state && state.others)
      list = [...state.others, props?.initialState?.email];
    else list = [props?.initialState?.email];
    list.map((item) => {
      if (list.filter((w) => w === currentvalue).length > 1) isDublocate = true;
    });
    return isDublocate;
  };

  useEffect(() => {
    const itemRegex = new RegExp(
      item?.data?.regExp ||
        /^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|\*{8})$/
    );
    if (
      typeof currentvalue === "string" &&
      currentvalue !== "" &&
      !itemRegex.test(currentvalue)
    ){
      setHelperText(item?.data?.errorMsg || t("please-fill-email-in-correct-way"));
      setSaveIsDisabled(true);
  }

    else if (isEmailItemDuplicating()) {
      setHelperText(t("duplicate-email-value"));
      setSaveIsDisabled(true);
    } else {
      setHelperText("");
      setSaveIsDisabled(false);
    }
    if (currentvalue === "" && state?.others)
      setState({
        id: "others",
        value: [...state.others.filter((d) => d !== "")],
      });
  }, [currentvalue]);

  return (
    <Inputs
      idRef={item?.data?.id || `emailRef${index + 1}`}
      isDisabled={item?.data?.isReadonly}
      labelValue={`${index + 2}- ${props?.label}`}
      value={
        state && state?.others && state?.others[index]
          ? state?.others[index]
          : ""
      }
      parentTranslationPath={"Shared"}
      translationPath={"Shared"}
      isWithError
      helperText={helperText}
      error={helperText !== ""}
      onInputChanged={(e) => {
        let { value } = e?.target 
        setcurrentvalue(value);
        if (state && state?.others && state.others[index]) {
          state.others[index] = value;
          setState({ id: "others", value: [...state?.others] });
        } else if (state && state?.others && state.others?.length !== 0 &&  value !== '')
          setState({
            id: "others",
            value: [...state.others, e?.target?.value],
          });
          else if (state && state?.others && state.others?.length !== 0 &&  value === '')
          {
            let newList = [...state.others] ; 
            newList[index] = '' ; 
            setState({
              id: "others",
              value: [...newList],
            });
          }   
        else setState({ id: "others", value: [e?.target?.value] });
      }}
      buttonOptions={
        (!item?.data?.isReadonly && {
          className: `btns-icon theme-solid ${
            index === listItem?.length - 1 ? "bg-blue-lighter" : "bg-danger"
          }`,
          iconClasses:
            index === listItem?.length - 1 ? "mdi mdi-plus" : "mdi mdi-minus",
          isDisabled: !(
            state &&
            state.others &&
            state.others[index] &&
            state.others[index].length >= 9 &&
            !isEmailItemDuplicating()) || 
            (helperText !== '' && state.email !=='' ) || 
            !(new RegExp(/^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|\*{8})$/).test(state?.others[index])
        ) ,
          isRequired: false,
          onActionClicked:
            index === listItem.length - 1
              ? () => {
                  const itemRegex = new RegExp(
                    props?.item.data?.regExp ||
                      /^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|\*{8})$/
                  );
                  if (
                    state &&
                    state?.others &&
                    state?.others[index] &&
                    itemRegex.test(state?.others[index])
                  )
                    setListItem([
                      ...listItem,
                      listItem[listItem.length - 1] + 1,
                    ]);
                }
              : () => {
                  const listItemIndex = listItem.findIndex(
                    (f) => f === loopValue
                  );
                  listItem.splice(listItemIndex, 1);
                  state.others.splice(index, 1);
                  setState({ id: "others", value: [...state?.others] });
                  const newlist = [];
                  listItem.map((v, loopIndex) => newlist.push(loopIndex + 2));
                  setListItem([...newlist]);
                },
        }) ||
        undefined
      }
    />
  );
};

EmailItemComponent.propTypes = {
  props: PropTypes.instanceOf(Object).isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  setState: PropTypes.func.isRequired,
  listItem: PropTypes.array.isRequired,
  setListItem: PropTypes.func.isRequired,
  setSaveIsDisabled: PropTypes.func.isRequired,
};
