import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tables } from '../../../../../../../../../Components';

export const TenantsTable = ({
 tenants, filter, parentTranslationPath, translationPath
}) => {
  const { t } = useTranslation(parentTranslationPath);

    
  
  const repeated = (list, filed) =>
    list &&
    list.map((item, index) => (

      
        !item[filed]  ? (<span key={index}>
         <span> </span>
        </span>)
       :<span key={index}> 
        {" "}
        {item[filed]} {list.length - 1 != index && <span> , </span>}
      </span>
    )); 

    
  return (
    <div className='tenant-table-wrapper presentational-wrapper mb-3'>
      <div className='title-section mb-2'>
        <span>{t(`${translationPath}contact-list-tenant`)}</span>
      </div>
      <Tables
        data={tenants}
        headerData={[
          {
            id: 1,
            isSortable: true,
            label: "contact-id",
            input: "contactId",
          },
          {
            id: 2,
            isSortable: true,
            label: "lead-id",
            input: "leadId",
          },
          {
            id: 3,
            isSortable: true,
            label: "contact-name",
            input: "contactName",
          },
          {
            id: 4,
            isSortable: false,
            label: "lead-type",
            input: "leadType",
            component: () => <span>{t(`${translationPath}tenant`)}</span>,
          },
          {
            id: 5,
            isSortable: false,
            label: "birth-date",
            input: "birthDate",
            component: (item) => (
              <span>
                {" "}
                {item &&
                  item.transactionTenantDetails &&
                  item.transactionTenantDetails.birthDate}
              </span>
            ),
          },
          {
            id: 6,
            isSortable: false,
            label: "nationality",
            input: "nationality",
            component: (item) => (
              <span>
                {" "}
                {item &&
                  item.transactionTenantDetails &&
                  item.transactionTenantDetails.nationality}
              </span>
            ),
          },
          {
            id: 7,
            isSortable: false,
            label: "referred-by-name",
            input: "referredByName",
            component: (item) => (
              <span>
                {" "}
                {item &&
                  item.transactionTenantDetails &&
                  item.transactionTenantDetails.referredByName}
              </span>
            ),
          },
          {
            id: 8,
            isSortable: false,
            label: "referred-to-name",
            input: "referredToName",
            component: (item) => (
              <span>
                {" "}
                {item &&
                  item.transactionTenantDetails &&
                  item.transactionTenantDetails.referredToName}
              </span>
            ),
          },
          {
            id: 9,
            isSortable: false,
            label: "agent-team-lead",
            input: "agentTeamLead" , 
            component: (item) =>
              repeated(item.transactionTenantDetails.transactionReferralDetails
                , "teamLeadName"),
          },
          {
            id: 10,
            isSortable: false,
            label: "agent-team-manager",
            input: "agentTeamManager",
            component: (item) =>
              repeated(item.transactionTenantDetails.transactionReferralDetails
                , "teamName"),
          },
          {
            id: 11,
            isSortable: false,
            label: "agent-branch",
            input: "agentBranch",
            component: (item) =>
              repeated(item.transactionTenantDetails.transactionReferralDetails
                , "agentBranch"),
          },
          {
            id: 12,
            isSortable: false,
            label: "campaign-name",
            input: "campaignName",
            component: (item) => (
              <span>
                {" "}
                {item &&
                  item.transactionTenantDetails &&
                  item.transactionTenantDetails.campaignName}
              </span>
            ),
          },
          {
            id: 13,
            isSortable: true,
            label: "media-name",
            input: "contactMedia",
          },

          {
            id: 14,
            isSortable: false,
            label: "media-detail",
            input: "media-detail",
            component: (item) => (
              <span>
                {" "}
                {item &&
                  item.mediaDetail}
              </span>
            ),
          },
        ]}
        defaultActions={[]}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        totalItems={tenants.length}
      />
    </div>
  );
};

TenantsTable.propTypes = {
  tenants: PropTypes.instanceOf(Array).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
