import React from "react";

function TrendUpIcon01({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M12.268 5.039a.923.923 0 0 0-.442.326c-.293.407-.145.962.324 1.213.131.071.167.072 2.142.089l2.008.016-2.732 2.733-2.733 2.732-1.526-1.518C7.841 9.17 7.644 8.989 7.35 8.842c-.415-.207-.973-.196-1.421.027-.274.138-.663.507-2.915 2.769C1.651 13.006.957 13.727.915 13.817c-.138.292-.063.692.173.928a.83.83 0 0 0 .945.159c.104-.054.845-.771 2.391-2.314l2.241-2.237 1.559 1.549c1.898 1.884 1.892 1.88 2.609 1.88.754 0 .559.158 3.858-3.129l2.807-2.798.009 2.014c.01 1.988.011 2.016.081 2.148.164.306.417.464.745.464s.581-.158.745-.464l.072-.134V5.617l-.072-.134a.95.95 0 0 0-.394-.395l-.134-.071-3.083-.006c-1.977-.004-3.125.006-3.199.028"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default TrendUpIcon01;
