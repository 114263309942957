import React from "react";
import PropTypes from "prop-types";
import { ButtonGroup, Button } from "@material-ui/core";

import { useSelectedTheme, useTranslate } from "../../../Hooks";

// Icons
import { ReassignOwnerIcon, ClockIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

// Constants for view modes
export const KYC_TAB_MODES = {
  KYC: "KYCView",
  History: "HistoryView",
};

function KYCSwitcher({ kycMode, onChangeViewMode }) {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewContactsView");

  return (
    <ButtonGroup
      className={styles.viewModeBtnGroup}
      size="medium"
      disableRipple
    >
      <Button
        className={kycMode === KYC_TAB_MODES.KYC ? styles.isActiveBg : ""}
        onClick={() => onChangeViewMode(KYC_TAB_MODES.KYC)}
      >
        <ReassignOwnerIcon
          width="20"
          height="20"
          fill={palette.button.secondary_fg}
        />
        <span>{translate("KYC")}</span>
      </Button>
      <Button
        className={kycMode === KYC_TAB_MODES.History ? styles.isActiveBg : ""}
        onClick={() => onChangeViewMode(KYC_TAB_MODES.History)}
      >
        <ClockIcon width="20" height="20" fill={palette.button.secondary_fg} />
        <span>{translate("History")}</span>
      </Button>
    </ButtonGroup>
  );
}

KYCSwitcher.propTypes = {
  kycMode: PropTypes.oneOf([KYC_TAB_MODES.KYC, KYC_TAB_MODES.History]),
  onChangeViewMode: PropTypes.func.isRequired,
};

KYCSwitcher.defaultProps = {
  kycMode: KYC_TAB_MODES.History,
};

export default KYCSwitcher;
