import React, {
  Children,
  useCallback,
  useReducer,
  useRef,
  useState,
} from "react";
import clsx from "clsx";

import {
  AutocompleteComponent,
  CustomAutocomplete,
  CustomInput,
  Inputs,
} from "../../../../../../../../../../../Components";
import {
  useLayoutDetection,
  useIsDesktop
} from "../../../../../../../../../../../Hooks";

export const DynamicTableComponent = ({
  headerData,
  data,
  fieldProps,
  onCurrentNewRowChanged,
  currentNewRow,
  tableKey,
  isClientForm
}) => {
  const searchTimer = useRef(null);
  const {isNewLayout, hasNewLayout} = useLayoutDetection();
  const { isDesktop } = useIsDesktop();

  const mapToIdAndName = (obj) => ({
    id: obj.lookupsId,
    name: obj.lookupItemName,
  });

  const getContactName = (rowData) => (rowData ?
      (rowData.contact?.company_name ||
        ((rowData.contact?.first_name || rowData.contact?.last_name) &&
          `${rowData.contact?.first_name || ""} ${rowData.contact?.last_name || ""}`)) : "");
  

  const returnFieldInput = (field) => {
    switch (field.fieldType) {
      case "textInput":
        return isNewLayout && hasNewLayout ? (
          <CustomInput
            hasSearchIcon={false}
            placeholder="Enter value"
            type={field.isNumber ? "number" : "text"}
            value={currentNewRow[tableKey][field.input] || ""}
            onChange={(event) =>
              onCurrentNewRowChanged({
                key: field.input,
                selectValue: event.target.value,
              })
            }
          />
        ) : (
          <Inputs
            idRef={`${field.input}Ref`}
            inputPlaceholder="enter"
            value={currentNewRow[tableKey][field.input] || ""}
            type={field.isNumber ? "number" : "text"}
            onInputChanged={(event) =>
              onCurrentNewRowChanged({
                key: field.input,
                selectValue: event.target.value,
              })
            }
          />
        );
      case "selectInput":
        return isNewLayout && hasNewLayout ? (
          <CustomAutocomplete
            options={
              fieldProps?.optionsData?.[field?.optionsKey] ||
              []?.map((field) => ({
                id: field?.lookupItemId,
                name:
                  field?.lookupItemName ||
                  field?.contact?.company_name ||
                  `${field?.contact?.first_name} ${field?.contact?.last_name}`,
              }))
            }
            defaultValue={
              currentNewRow[tableKey][field.input]
                ? mapToIdAndName(currentNewRow[tableKey][field.input])
                : null
            }
            getOptionLabel={(option) => option?.name || ""}
            getOptionValue={(option) => option?.id || ""}
            placeholder={`Select value`}
            onOpen={() => {
              if (
                fieldProps.optionsData[field.optionsKey] &&
                fieldProps.optionsData[field.optionsKey].length == 0
              ) {
                const isContactOptions = field.optionsKey === "Contacts";
                if (isContactOptions) fieldProps.getContactOptions();
                else
                  fieldProps.getLookupsByName(
                    field.lookupName,
                    field.optionsKey
                  );
              }
            }}
            onChange={(_, newValue) => {
              const isContactOptions = field.optionsKey === "Contacts";
              onCurrentNewRowChanged({
                key: field.input,
                selectValue: newValue,
                isContactOptions,
              });
            }}
            onInputKeyUp={(event) => {
              const searchValue = event?.target?.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const isContactOptions = field.optionsKey === "Contacts";
                if (isContactOptions) fieldProps.getContactOptions(searchValue);
              }, 500);
            }}
          />
        ) : (
          <AutocompleteComponent
            idRef={`${field.input}Ref`}
            inputPlaceholder={`select`}
            wrapperClasses="w-min-unset"
            selectedValues={currentNewRow[tableKey][field.input] || null}
            data={fieldProps.optionsData[field.optionsKey] || []}
            displayLabel={(option) =>
              option.lookupItemName ||
              option.contact.company_name ||
              (option.contact &&
                (option.contact.first_name || option.contact.last_name) &&
                `${option.contact.first_name} ${option.contact.last_name}`) ||
              ""
            }
            multiple={false}
            withoutSearchButton
            onOpen={() => {
              if (
                fieldProps.optionsData[field.optionsKey] &&
                fieldProps.optionsData[field.optionsKey].length == 0
              ) {
                const isContactOptions = field.optionsKey === "Contacts";
                if (isContactOptions) fieldProps.getContactOptions();
                else
                  fieldProps.getLookupsByName(
                    field.lookupName,
                    field.optionsKey
                  );
              }
            }}
            onChange={(_, newValue) => {
              const isContactOptions = field.optionsKey === "Contacts";
              onCurrentNewRowChanged({
                key: field.input,
                selectValue: newValue,
                isContactOptions,
              });
            }}
            onInputKeyUp={(event) => {
              const searchValue = event?.target?.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                const isContactOptions = field.optionsKey === "Contacts";
                if (isContactOptions) fieldProps.getContactOptions(searchValue);
              }, 500);
            }}
          />
        );
      case "readOnly":
        return (<span className="readOnly_input">{
          currentNewRow[tableKey][field.input] &&
          typeof currentNewRow[tableKey][field.input] === "object"
          ? (currentNewRow[tableKey][field.input]?.lookupItemName
            || getContactName(currentNewRow[tableKey][field.input]))
          : (currentNewRow[tableKey][field.input] ||
            "")}</span>);
      case "signature":
        return (<span className="signature_input"></span>);
      default:
        return null;
    }
  };
  const returnClientFormFields = (field) => {
    switch (field.fieldType) {
      case "readOnly":
        return (<span className="readOnly_input">{
          currentNewRow[tableKey][field.input] &&
          typeof currentNewRow[tableKey][field.input] === "object"
          ? (currentNewRow[tableKey][field.input]?.lookupItemName
            || getContactName(currentNewRow[tableKey][field.input]))
          : (currentNewRow[tableKey][field.input] ||
            "")}</span>);
      case "signature":
        return (<span className="signature_input">
          <canvas className="signature-pad" width="500" height="120"></canvas>
        </span>);
      default:
        return null;
    }
  };
  
  return !isDesktop && isNewLayout && hasNewLayout ? (
    <table className="Dynamic_Table">
      <tfoot>
        {headerData.map((headerCell) => (
          <tr>
            <>
              <th key={headerCell.input} className="padding_12">{headerCell.label}</th>

              <td key={headerCell.input}>
                <div
                  className={clsx("Cell_Value", {
                    ["Gray_Cell_Value"]:
                      isNewLayout &&
                      hasNewLayout &&
                      headerCell?.fieldType === "readOnly",
                  })}

                  style={{margin: '8px 0'}}
                >
                  {isClientForm
                    ? returnClientFormFields(headerCell)
                    : returnFieldInput(headerCell)}
                </div>
              </td>
            </>
          </tr>
        ))}
      </tfoot>
    </table>
  ) : (
    <table className="Dynamic_Table">
      <thead>
        <tr>
          {headerData.map((headerCell) => (
            <th key={headerCell.input}>{headerCell.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {headerData.map((headerCell) => (
              <td key={`${headerCell?.input}-${rowIndex}`}>
                <div
                className={clsx("Cell_Value", {
                  ["Gray_Cell_Value"]:
                    isNewLayout &&
                    hasNewLayout &&
                    headerCell?.fieldType === "readOnly",
                })}>
                  <span className="readOnly_input">
                    {isClientForm ? (
                      <Inputs
                        idRef={`${headerCell?.input}Ref`}
                        inputPlaceholder="enter"
                        value={
                          row[headerCell?.input]?.lookupItemName ||
                          getContactName(row[headerCell?.input]) ||
                          row[headerCell?.input] ||
                          ""
                        }
                      />
                    ) : (
                      row[headerCell?.input]?.lookupItemName ||
                      getContactName(row[headerCell?.input]) ||
                      row[headerCell?.input] ||
                      ""
                    )}
                  </span>
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          {headerData.map((headerCell) => (
            <td key={headerCell.input}>
              <div
                className={clsx("Cell_Value", {
                  ["Gray_Cell_Value"]:
                    isNewLayout &&
                    hasNewLayout &&
                    headerCell?.fieldType === "readOnly",
                })}
              >
                {isClientForm
                  ? returnClientFormFields(headerCell)
                  : returnFieldInput(headerCell)}
              </div>
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
};
