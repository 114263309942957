import { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getIsAllowedPermission } from "../../../Helper";

function CustomPermission({
  permissionsList,
  permissionsId,
  allowEmptyRoles,
  children,
}) {
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const permissionAllowed = useMemo(
    () =>
      getIsAllowedPermission(
        permissionsList,
        loginResponse,
        permissionsId,
        allowEmptyRoles
      ),
    [permissionsList, loginResponse, permissionsId, allowEmptyRoles]
  );

  return permissionAllowed ? children : null;
}

export default CustomPermission;

CustomPermission.propTypes = {
  permissionsList: PropTypes.arrayOf(PropTypes.string),
  permissionsId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  allowEmptyRoles: PropTypes.bool,
};

CustomPermission.defaultProps = {
  permissionsList: [],
  permissionsId: undefined,
  allowEmptyRoles: false,
};
