import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Tables, Spinner, re } from "../../../../../../Components";
import {
  showError,
  showSuccess,
  returnPropsByPermissions,
} from "../../../../../../Helper";
import {
  GetDeveloperEmployeeByIdAPI,
  DeleteDeveloperEmployeeAPI,
} from "../../../../../../Services";
import { TableActions } from "../../../../../../Enums";
import { MessageConfirmDialog } from "../../../../../../SharedComponents";
import { DeveloperEmployeesManagementDialog } from "./DeveloperEmployeesManagementDialog";
import { DevelopersPermissions } from "../../../../../../Permissions";

export const DeveloperEmployeesTable = ({
  translationPath,
  parentTranslationPath,
  data,
  filter,
  setFilter,
  reloadData,
  communicationsIdsValues,
  setIsReloadData,
  isReloadData,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [isActiveEmployeeEmail, setIsActiveEmployeeEmail] = useState(false);
  const [isLoading, setIsLoading] = useState({ info: false });
  const [activeItem, setActiveItem] = useState(null);
  const [developerEmployeeEditObj, setDeveloperEmployeeEditObj] =
    useState(null);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isMissingInfoMSGOpen, setIsMissingInfoMSGOpen] = useState(false);

  const GetDeveloperEmployeeHandler = useCallback(async () => {
    setIsLoading((item) => ({ ...item, info: true }));
    const res = await GetDeveloperEmployeeByIdAPI(
      activeItem && activeItem.employeeId
    );
    if (res && res.ErrorId) {
      showError(t(`${translationPath}failed-to-get-developer-employee`));
      setDeveloperEmployeeEditObj(null);
      setIsActiveEmployeeEmail(null) ; 
    } else if (!(res && res.status && res.status !== 200)) {
      setDeveloperEmployeeEditObj(res);
      setIsActiveEmployeeEmail((res?.developerEmployeeCommunications && Array.isArray(res.developerEmployeeCommunications) && res.developerEmployeeCommunications.filter((s)=> s.communicationTypeId === 18037)) || null);
    }
    setIsLoading((item) => ({ ...item, info: false }));
  }, [activeItem]);

  const DeleteHandler = useCallback(async () => {
    if (activeItem && activeItem.employeeId) {
      setIsLoading((item) => ({ ...item, delete: true }));

      const res = await DeleteDeveloperEmployeeAPI(
        activeItem && activeItem.employeeId
      );
      if (res && res.ErrorId) {
        showError(t(`${translationPath}failed-to-delete-developer-employee`));
      } else if (!(res && res.status && res.status !== 200)) {
        showSuccess(
          t(`${translationPath}delete-developer-employee-successfully`)
        );
        setIsMissingInfoMSGOpen(false);
        setActiveItem(null);
        reloadData();
      }
      setIsLoading((item) => ({ ...item, delete: false }));
    }
  }, [activeItem]);

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveItem(item);

      if (actionEnum === TableActions.delete.key) {
        setIsMissingInfoMSGOpen(true);
      } else if (actionEnum === TableActions.editText.key) {
        setIsOpenEditDialog(true);
      }
    },
    []
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const getActionTableWithPermissions = () => {
    const list = [];
    if (
      returnPropsByPermissions(
        DevelopersPermissions.EditDeveloperEmployees.permissionsId
      )
    )
      list.push({
        enum: TableActions.editText.key,
        title: t(`${translationPath}Shared:edit`),
      });
    list.push({
      enum: TableActions.delete.key,
      title: t(`${translationPath}Shared:delete`),
    });
    return list;
  };

  const repeated = (list, filed) =>
    list &&
    list.map((item, index) => (
      <span key={index}>
        {" "}
        {item[filed]} {list.length - 1 != index && <span> , </span>}
      </span>
    ));

  useEffect(() => {
    if (activeItem) {
      GetDeveloperEmployeeHandler(activeItem.employeeId);
    }
  }, [activeItem]);

  return (
    <div className="d-flex-column mt-2">
      <Spinner isActive={isLoading.info || isLoading.delete} isAbsolute />
      <Tables
        data={data?.result || []}
        headerData={[
          {
            id: 1,
            label: t(`${translationPath}employee-name`),
            input: "employeeName",
          },
          {
            id: 2,
            label: t(`${translationPath}contact-designation`),
            input: "jobTitle",
          },
          {
            id: 3,
            label: t(`${translationPath}mobile-numbers`),
            component: (item) =>
              repeated(
                item.developerEmployeeCommunications &&
                  item.developerEmployeeCommunications.filter(
                    (m) =>
                      m.communicationTypeId === communicationsIdsValues.mobile
                  ),
                "communicationValue"
              ),
          },
          {
            id: 3,
            label: t(`${translationPath}email-addresses`),
            component: (item) =>
              repeated(
                item.developerEmployeeCommunications &&
                  item.developerEmployeeCommunications.filter(
                    (e) =>
                      e.communicationTypeId === communicationsIdsValues.email
                  ),
                "communicationValue"
              ),
          },
          {
            id: 5,
            label: t(`${translationPath}linkedIn-profile`),
            input: "linkedInProfile",
          },
        ]}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        defaultActions={getActionTableWithPermissions()}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        itemsPerPage={filter.pageSize}
        totalItems={(data && data?.totalCount) || 0}
      />
      {isMissingInfoMSGOpen && activeItem && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "delete-dveloper-employee",
            body:
              t(
                `${translationPath}are-you-sure-to-delete-developer-employee-id`
              ) +
              " " +
              ((activeItem && activeItem.employeeId) || ""),
          }}
          confirmButton={{
            label: t(`${translationPath}confirm`),
            handler: () => DeleteHandler(),
          }}
          cancelButton={{
            label: t(`${translationPath}discard`),
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenEditDialog && activeItem && (
        <DeveloperEmployeesManagementDialog
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isOpen={isOpenEditDialog}
          onClose={() => {
            setIsOpenEditDialog(false);
            setActiveItem(null);
            setIsReloadData(
              isReloadData === null || isReloadData === false ? true : false
            );
            setIsActiveEmployeeEmail(null) ; 

          }}
          onSave={(editItem) => {
            if (editItem) {
              setIsOpenEditDialog(false);
              setActiveItem(null);
              reloadData();
              setIsReloadData(
                isReloadData === null || isReloadData === false ? true : false
              );
            }
            setIsActiveEmployeeEmail(null) ; 
          }}
          developerEmployeesValues={developerEmployeeEditObj}
          isReloadData={null}
          communicationsIdsValues={communicationsIdsValues}
          setIsActiveEmployeeEmail={setIsActiveEmployeeEmail}
          isActiveEmployeeEmail={isActiveEmployeeEmail}
        />
      )}
    </div>
  );
};
