import React, { useMemo } from "react";
import "./ImagesGrid.scss";
import { getDownloadableLink } from "../../../../../../../../../../Helper";

const ImagesGrid = ({ images }) => {


  const mappedImages = useMemo(() => convertImageIdsToURLs(), [images]);

  function convertImageIdsToURLs() {
    return images ? images.map(item => ({
      ...item,
      url: getDownloadableLink(item?.fileId) || "",
    })) : [];
  }


  return (
    <div className="Images_Grid">
      <ul class="gallery">
        {mappedImages ?
          mappedImages.map(item => (
            <li id={item.fileId} key={item.fileId}>
              <a href={`#${item.fileId}`} class="zoomin" title="Zoom In">
                <img
                  src={item.url}
                  alt={item.fileName}
                  title=""
                  loading="lazy"
                />
              </a>
              <a href="#close" class="zoomout" title="Zoom Out" autofocus="true">
                <img
                  src={item.url}
                  alt={item.fileName}
                  title=""
                  loading="lazy"
                />
              </a>
            </li>))
          : null}
      </ul>
    </div>
  );
};

export default ImagesGrid;
