import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { CustomCopyToClipboard } from "../../../../../CustomCopyToClipboard";
import { useSelectedTheme, useTranslate } from "../../../../../../../Hooks";
import { getDownloadableLink } from "../../../../../../../Helper";
import { CheckIcon, CloseXIcon } from "../../../../../../../assets/icons";
import { SocialLinks } from "../../../../../..";
//styles
import useStyle from "./styles";

const ContactOriginalHistoryOfMargedComponent = ({ originalContactData }) => {
  const styles = useStyle();
  const { translate } = useTranslate("NewContactsView");
  const {
    theme: { palette },
  } = useSelectedTheme();
  const getAvatarSrc = (item) => {
    const uuid = item?.contact_image?.["Image Upload"][0]?.uuid || null;
    if (uuid) {
      return getDownloadableLink(uuid);
    } else {
      const gender = item?.gender?.lookupItemName;
      return gender === "Female"
        ? "/images/contacts/FemaleAvatar.svg"
        : "/images/contacts/MaleAvatar.svg";
    }
  };

  return (
    <Box className={styles.wrapperOriginalHistory}>
      <Box className={styles.containerHeaderofOriginal}>
        <Box className={styles.containerImage}>
          <img
            className={styles.imageWrapper}
            src={getAvatarSrc(originalContactData?.contact)}
          />
        </Box>
        <Box className={styles.containerTitle}>
          <Typography>{translate("Original-contact")}</Typography>
          <CustomCopyToClipboard
            data={originalContactData?.finalId} // copy
            childrenData={"#" + originalContactData?.finalId} // render
            copyDoneShowTime={1000}
            fontSize="14px"
          />
        </Box>
      </Box>
      <Box className={styles.informationWrapper}>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Title")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.title?.lookupItemName}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("First-name")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.first_name}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Last-name")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.last_name}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Gender")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.gender?.lookupItemName}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Mobile")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.mobile?.phone}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Whatsapp-mobile")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.whatsapp_mobile?.phone}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Other-contact-mobile")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.other_contact_mobile_no?.phone}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Email-Address")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.email_address?.email}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Language")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.language?.lookupItemName}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Contact-class")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.contact_class?.lookupItemName}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Multi-Property-Owner")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {originalContactData?.contact?.multi_property_owner ? (
              "Yes" && (
                <CheckIcon
                  width="20"
                  height="20"
                  fill={palette.text.success_primary}
                />
              )
            ) : (
              <CloseXIcon
                width="20"
                height="20"
                fill={palette.text.error_primary}
              />
            )}
          </Typography>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Contact-Preference")}
          </Typography>

          <Box style={{ direction: "ltr", height: "30px" }}>
            <SocialLinks activeItem={originalContactData?.contact} gap={8} />
          </Box>
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("Country")}
          </Typography>
          {originalContactData?.contact?.country?.lookupItemName.toLowerCase() ===
          "unknown" ? (
            <Typography className={styles.valueInfo}>{""}</Typography>
          ) : (
            <Typography className={styles.valueInfo}>
              {originalContactData?.contact?.country?.lookupItemName}
            </Typography>
          )}
        </Box>
        <Box className={styles.containerInformationSction}>
          <Typography className={styles.titleInfo}>
            {translate("City")}
          </Typography>

          {originalContactData?.contact?.city?.lookupItemName.toLowerCase() ===
          "unknown" ? (
            <Typography className={styles.valueInfo}>{""}</Typography>
          ) : (
            <Typography className={styles.valueInfo}>
              {originalContactData?.contact?.city?.lookupItemName}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default ContactOriginalHistoryOfMargedComponent;
