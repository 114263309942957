import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ContactFormTypeEnum } from '../../Enums/ContactFormTypeEnum';
import {
  DialogComponent,
  Inputs,
  PhonesComponent,
  Spinner,
} from '../../Components';
import { contactFields } from './fields';
import { ContactValidationContextLocalePath } from './i18n/ContactValidationContextLocale';
import { getErrorByName, GlobalHistory, showError } from '../../Helper';
import { CheckContactForDuplicates } from '../../Services';
import { Children } from 'react';
import ContactSummaryCard from './components/ContactSummaryCard';
import { useTranslation } from 'react-i18next';
import { createCorporateSchema, createIndividualSchema } from './Schemas';
import { checkDuplication } from './helpers';
import { useDebouncedAction } from '../../Hooks/DebouncedAction';

const ContactValidationContext = createContext({
  isDuplicatedContact: false,
  isValidationDialogOpen: false,
  contactType: ContactFormTypeEnum.INDIVIDUAL.value,
  toggleContactValidationDialog: (
    session,
    callback = null,
    closeCallback = null
  ) => {},
  setContactType: () => {},
  activeSession: '',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    landline: '',
  },
  isValidContact: false,
  onCloseHandler: (session) => {},
  resetStateHandler: () => {},
  dispatchSession: () => {},
  activeSession: '',
  setIsValidContact: (isValid) => {},
});

export const useContactValidationContext = () =>
  useContext(ContactValidationContext);

export const ContactValidationProvider = ({ children }) => {
  const { t } = useTranslation(ContactValidationContextLocalePath);
  const [isDuplicatedContact, setIsDuplicatedContact] = useState(false);
  const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
  const [isValidContact, setIsValidContact] = useState(false);
  const [loading, setLoading] = useState(false);
  const [matchingContacts, setMatchingContacts] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeSession, setActiveSession] = useState(null);
  const [errors, setErrors] = useState({});
  const callbackRef = useRef({});
  const [contactType, setContactType] = useState(
    ContactFormTypeEnum.INDIVIDUAL.value
  );
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    landline: '',
  });
  const validationSchema = useMemo(
    () =>
      contactType === ContactFormTypeEnum.INDIVIDUAL.value
        ? createIndividualSchema(t, 'InquiryRotation')
        : createCorporateSchema(t, 'InquiryRotation'),
    [contactType]
  );

  const schema = validationSchema.validate(data, {
    allowUnknown: true,
    abortEarly: false,
  });

  const changeDataHandler = (field, value) => {
    setData((prevData) => ({ ...prevData, [field]: value }));
  };
  const toggleContactValidationDialog = (session, callback, closeCallback) => {
    if (session) {
      if (!activeSession) {
        setActiveSession(session);
      }
      callbackRef.current[session] = {};
      if (callback) {
        callbackRef.current[session].callback = callback;
      }
      if (closeCallback) {
        callbackRef.current[session].closeCallback = closeCallback;
      }
    }
    setIsValidationDialogOpen((prev) => !prev);
  };

  const resetStateHandler = () => {
    setData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      landline: '',
    });
    setIsDuplicatedContact(false);
    setIsValidContact(false);
    setContactType(ContactFormTypeEnum.INDIVIDUAL.value);
    setMatchingContacts([]);
    setIsSubmitted(false);
  };

  const onCloseHandler = (session) => {
    setIsValidationDialogOpen(false);
    if (
      !callbackRef.current[session]?.callback &&
      !callbackRef.current[session]?.closeCallback
    ) {
      GlobalHistory.goBack();
    } else {
      if (callbackRef.current[session]?.closeCallback) {
        callbackRef.current[session].closeCallback();
      }
    }
    delete callbackRef.current[session];
    resetStateHandler();
    setErrors({});
  };

  const checkDuplicateFields = useDebouncedAction(async ({ field, value }) => {
    setErrors((prev) => ({
      ...prev,
      [field.name]: true,
    }));
    const exists = await checkDuplication(value);
    if (exists) {
      setErrors((prev) => ({
        ...prev,
        [field.name]:
          field.type === 'email' ? t('duplicate_email') : t('duplicate_phone'),
      }));
    } else {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[field.name];
        return newErrors;
      });
    }
  }, 1000);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsSubmitted(true);
    try {
      if (schema.error) {
        return showError(
          t('fill_all_required_fields', { ns: 'InquiryRotation' })
        );
      }
      const { companyName } = data;
      const body = { contactsType: contactType };
      if (contactType === ContactFormTypeEnum.INDIVIDUAL.value) {
        body.individualCheckDublicate = { ...data, mobileNumber: data.phone };
      } else {
        body.corporateCheckDublicate = {
          companyName,
          landlineNumber: data.landline,
          companyEmailAddress: data.email,
        };
      }
      const res = await CheckContactForDuplicates(body);
      if (res.isDuplicate) {
        setIsDuplicatedContact(true);
        setMatchingContacts(res.matchingContacts);
        return;
      }
      toggleContactValidationDialog();
      setIsValidContact(true);
      if (callbackRef.current[activeSession]?.callback) {
        callbackRef.current[activeSession].callback();
      }
    } catch (error) {
      showError('error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <ContactValidationContext.Provider
      value={{
        isDuplicatedContact,
        isValidationDialogOpen,
        contactType,
        data,
        toggleContactValidationDialog,
        setContactType,
        isValidContact,
        onCloseHandler,
        resetStateHandler,
        dispatchSession: setActiveSession,
        activeSession,
        setIsValidContact,
      }}
    >
      {children}
      {isValidationDialogOpen && (
        <DialogComponent
          key={activeSession}
          parentTranslationPath={ContactValidationContextLocalePath}
          onCancelClicked={() => onCloseHandler(activeSession)}
          onCloseClicked={() => onCloseHandler(activeSession)}
          isOpen
          titleText={'add_new_contact'}
          maxWidth={matchingContacts.length > 0 ? 'lg' : 'sm'}
          dialogContent={
            <div>
              <Spinner isActive={loading} isAbsolute />
              {matchingContacts.length > 0 ? (
                <div className='flex flex-wrap gap-1 fj-center'>
                  <div className='w-100'>
                    <h3 className='text-lg fw-bold'>
                      {matchingContacts.length > 1
                        ? t('multiple_matching_contacts', {
                            count: matchingContacts.length,
                          })
                        : t('single_matching_contact')}
                    </h3>
                  </div>
                  {Children.toArray(
                    matchingContacts.map((contact) => (
                      <ContactSummaryCard
                        key={contact.contactId}
                        {...contact}
                      />
                    ))
                  )}
                </div>
              ) : (
                contactFields
                  .filter((item) =>
                    item.for ? item.for === contactType : true
                  )
                  .map((field) => {
                    if (['text', 'email'].includes(field.type)) {
                      return (
                        <Inputs
                          type={field.type}
                          labelValue={field.label}
                          onInputChanged={async (e) => {
                            e.persist();
                            changeDataHandler(field.name, e.target.value?.trim());
                            if (field.type === 'email') {
                              checkDuplicateFields({
                                field,
                                value: e.target.value,
                              });
                            }
                          }}
                          key={field.name}
                          value={data[field.name]}
                          parentTranslationPath={
                            ContactValidationContextLocalePath
                          }
                          isSubmitted={isSubmitted}
                          isWithError
                          withLoader
                          isLoading={typeof errors[field.name] === 'boolean'}
                          helperText={
                            errors[field.name] ??
                            getErrorByName(schema, field.name).message
                          }
                          error={
                            !!errors[field.name] ||
                            getErrorByName(schema, field.name).error
                          }
                        />
                      );
                    } else if (field.type === 'tel') {
                      return (
                        <PhonesComponent
                          value={data[field.name]}
                          country='ae'
                          labelValue={field.label}
                          onInputChanged={(value) => {
                            changeDataHandler(field.name, value);
                          }}
                          parentTranslationPath={
                            ContactValidationContextLocalePath
                          }
                          isLoading={typeof errors[field.name] === 'boolean'}
                          isSubmitted={isSubmitted}
                          helperText={
                            errors[field.name] ??
                            getErrorByName(schema, field.name).message
                          }
                          error={
                            !!errors[field.name] ||
                            getErrorByName(schema, field.name).error
                          }
                        />
                      );
                    }
                  })
              )}
            </div>
          }
          saveText={'submit'}
          saveIsDisabled={
            isDuplicatedContact ||
            Object.values(errors).some((value) => typeof value === 'boolean')
          }
          onSaveClicked={submitHandler}
        />
      )}
    </ContactValidationContext.Provider>
  );
};
