import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MUIIconButton from '@material-ui/core/IconButton';

// styles
import useStyles from './styles';

function CustomIconButton({ variant, classes ,size, color, boxShadow, children, hideHoverBg, borderRadius, isDropDown, ...restProps }) {

  let elevation = 0;
  switch (boxShadow) {
    case 'none': elevation = 0; break;
    case 'xs': elevation = 1; break;
    case 'sm': elevation = 2; break;
    case 'md': elevation = 3; break;
    case 'lg': elevation = 4; break;
    case 'xl': elevation = 5; break;
    case '2xl': elevation = 6; break;
    case '3xl': elevation = 7; break;
    default: elevation = 0; break;
  }

  let br = 4;
  switch (borderRadius) {
    case 'none': br = 0; break;
    case 'xxs': br = 1; break;
    case 'xs': br = 2; break;
    case 'sm': br = 3; break;
    case 'md': br = 4; break;
    case 'lg': br = 5; break;
    case 'xl': br = 6; break;
    case '2xl': br = 7; break;
    case '3xl': br = 8; break;
    case '4xl': br = 9; break;
    case 'full': br = 10; break;
    default: br = 4; break;
  }

  const sizeStyles = {
    none: { padding: '0px' },
    xs: { padding: '4px' },
    sm: { padding: '8px' },
    md: { padding: '10px' },
    lg: { padding: '12px' },
    xl: { padding: '14px' },
    '2xl': { padding: '16px' },
  };

  const styles = useStyles({
    elevation,
    sizeStyles: sizeStyles[size],
    color,
    variant,
    hideHoverBg,
    borderRadius: br,
    isDropDown
  });

  return (
    <MUIIconButton
      className={clsx(styles.root , classes)}
      disableRipple
      variant={variant}
      {...restProps}
    >
      {children}
    </MUIIconButton>
  );
}

CustomIconButton.propTypes = {
  boxShadow: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl']),
  variant: PropTypes.oneOf(['contained', 'text', 'outlined']),
  color: PropTypes.oneOf(['primary', 'secondary', 'lightGray', 'tertiaryColor']),
  isDropDown: PropTypes.bool,
  ...MUIIconButton.propTypes,
};

CustomIconButton.defaultProps = {
  variant: 'contained',
  size: 'sm',
  color: 'primary',
  boxShadow: 'none',
  borderRadius: 'md',
  isDropDown: false,
  classes : ""
};

export default CustomIconButton;
