import React, { useRef, useState } from 'react';
import { Box, Typography, Divider, Avatar } from '@material-ui/core';
import { CustomAutocomplete } from '../../../CustomAutocomplete';
import { CustomTextArea } from '../../../CustomTextArea';

// Styles
import useStyles from './styles';

function ContactSMSDialog({
  setState,
  getContacts,
  contactList,
  state,
  setSelected,
  selected,
}) {
  const styles = useStyles();
  const searchTimer = useRef(null);

  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getContacts(value);
    }, 1000);
  };

  const handleSelectionChange = (event, newValue) => {
    setSelected(
      newValue.map((contact) => ({
        contactsId: contact.contactsId,
        contact: {
          first_name: contact.contact?.first_name,
          last_name: contact.contact?.last_name,
          company_name: contact.contact?.company_name,
        },
      }))
    );
    setState((prev) => ({
      ...prev,
      contactIds: newValue.map((contact) => contact.contactsId),
    }));
  };

  return (
    <Box className={styles.preferenceDialogWrapper}>
      <Box className={styles.rowItem}>
        <Typography className={styles.rowLabel}>To</Typography>
        <CustomAutocomplete
          variant='default'
          multiple={true}
          placeholder='Select contacts'
          options={contactList}
          renderOption={(option) =>
            option.contact?.company_name ||
            `${option.contact?.first_name ?? ''} ${
              option.contact?.last_name ?? ''
            }`.trim()
          }
          getOptionLabel={(option) =>
            option.contact?.company_name ||
            `${option.contact?.first_name ?? ''} ${
              option.contact?.last_name ?? ''
            }`.trim()
          }
          chipsLabel={(option) =>
            option.contact?.company_name ||
            `${option.contact?.first_name ?? ''} ${
              option.contact?.last_name ?? ''
            }`.trim()
          }
          selectedValues={selected || []}
          onInputKeyUp={(event) => searchHandler(event)}
          onChange={handleSelectionChange}
          getChipIcon={(item) => (
            <Avatar
              className={styles.defaultAvatar}
              src={
                item?.contact_type_id === 2 //"CORPORATE"
                  ? '/images/contacts/CorporateAvatar.svg'
                  : item?.gender?.lookupItemName === 'Male'
                  ? '/images/contacts/MaleAvatar.svg'
                  : '/images/contacts/FemaleAvatar.svg'
              }
            />
          )}
        />
      </Box>
      <Divider />
      <Box className={styles.rowItem}>
        <Typography className={styles.rowLabel}>{'Message'}</Typography>
        <CustomTextArea
          maxRows={5}
          minRows={5}
          placeholder={'Enter a message '}
          onInputChange={(value) => {
            setState((prev) => ({ ...prev, message: value }));
          }}
          value={state.message}
        />
      </Box>
    </Box>
  );
}

export default ContactSMSDialog;
