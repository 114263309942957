import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  PaginationComponent,
  Spinner,
  Tables,
  ViewTypes,
} from '../../../../../../Components';
import { TableActions, ViewTypesEnum } from '../../../../../../Enums';
import { AssignUnitCards } from '../../../AssignUnitCards/AssignUnitCards';
import {
  AssignInquiryToUnitServices,
  GetMatchingUnitsByInquiryId,
  SetInquiryStatusAsCompleteServices,
  UnAssignInquiryFromUnitServices,
} from '../../../../../../Services';
import {
  bottomBoxComponentUpdate,
  GetParams,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import { ActiveItemActions } from '../../../../../../store/ActiveItem/ActiveItemActions';
import { AssignToUnitDialog } from '../../ListingShortageUtilities';
import { CompleteInquiryDialog } from '../../../CompleteInquires/CompleteInquiryDialog';

export const AssignInquiryToUnitComponent = ({
  parentTranslationPath,
  translationPath,
  activeInquiryData,
  setCompletInquiry,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const inquiryId = +GetParams('id');
  const [activeActionType, setActiveActionType] = useState(
    ViewTypesEnum.cards.key
  );
  const [isOpenCompleteDialog, setIsOpenCompleteDialog] = useState(false);
  const isLease = useLocation().pathname.includes('lease');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
  });
  const [isOpenAssignToUnitDialog, setIsOpenAssignToUnitDialog] =
    useState(false);
  const [matchedUnits, setMatchedUnits] = useState({
    result: [],
    totalCount: 0,
  });

  const onTypeChanged = useCallback((activeType) => {
    setActiveActionType(activeType);
  }, []);

  const saveHandler = async (unit) => {
    if (!unit) {
      showError(t(`${translationPath}emptyUnitToAssignToInquiry`));
      return;
    }
    setIsLoading(true);
    const res = await AssignInquiryToUnitServices(
      unit.id,
      activeInquiryData && activeInquiryData.inquiryId
    );
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}assign-inquiry-to-unit-successfully`));
    } else showError(t(`${translationPath}assign-inquiry-to-unit-failure`));

    setIsLoading(false);
    setIsOpenAssignToUnitDialog(false);
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };

  const completedHandler = async () => {
    setIsLoading(true);
    const res = await SetInquiryStatusAsCompleteServices(
      activeInquiryData && activeInquiryData.inquiryId
    );
    if (!(res && res.status && res.status !== 200)) {
      setCompletInquiry(true);
      showSuccess(
        t(`${translationPath}SetInquiryStatusAsComplete-successfully`)
      );
    } else
      showError(
        t(
          `${translationPath}THERE_IS_NO_UNITS_ASSIGNED_TO_THIS_INQUIRY_Please-Assign_units`
        )
      );

    setIsLoading(false);
  };

  const getMatchingUnitsByInquiryId = useCallback(async () => {
    setIsLoading(true);
    const res = await GetMatchingUnitsByInquiryId({
      inquiryId,
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
    });
    if (!(res && res.status && res.status !== 200)) {
      setMatchedUnits(res);
    } else {
      setMatchedUnits({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter]);

  const unAssignUnitHandler = useCallback(
    async (unit) => {
      if (!unit) {
        showError(t(`${translationPath}emptyUnitToAssignToInquiry`));
        return;
      }
      setIsLoading(true);
      const res = await UnAssignInquiryFromUnitServices(
        unit.id,
        activeInquiryData && activeInquiryData.inquiryId
      );
      if (!(res && res.status && res.status !== 200))
        showSuccess(
          t(`${translationPath}Unassign-inquiry-to-unit-successfully`)
        );
      else showError(t(`${translationPath}unassign-inquiry-to-unit-failure`));
      setIsLoading(false);
      setFilter((item) => ({ ...item, pageIndex: 0 }));
    },
    [activeInquiryData]
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.delete.key) {
        unAssignUnitHandler(item);
        setFilter((item) => ({ ...item, pageIndex: 0 }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={matchedUnits && matchedUnits.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(() => {
    getMatchingUnitsByInquiryId();
  }, [getMatchingUnitsByInquiryId]);

  const focusedRowChanged = useCallback(() => {}, []);
  return (
    <div className='associated-contacts-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section d-flex-v-center-h-between flex-wrap'>
        <span>{t(`${translationPath}units`)}</span>
        <ViewTypes
          onTypeChanged={onTypeChanged}
          activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
        />
      </div>
      <div className='filter-sections-wrapper mb-2'>
        <div className='filter-section-item'>
          {!(
            activeInquiryData && activeInquiryData.inquiryStatus === 'Canceled'
          ) && (
            <ButtonBase
              onClick={() => setIsOpenAssignToUnitDialog(true)}
              className='btns theme-transparent c-gray-primary'
            >
              <span className='mdi mdi-send-outline mdi-rotate-315' />
              <span className='px-2'>
                {t(`${translationPath}assigntoUnit`)}
              </span>
            </ButtonBase>
          )}
        </div>
        <div className='filter-section-item'>
          {!(
            (activeInquiryData &&
              activeInquiryData.inquiryStatus === 'Canceled') ||
            (activeInquiryData &&
              activeInquiryData.inquiryStatus === 'Completed')
          ) && (
            <ButtonBase
              onClick={() => {
                setIsOpenCompleteDialog(true);
              }}
              className='btns theme-solid mb-2 mx-2'
            >
              <span className='px-2'>{t(`${translationPath}completed`)}</span>
            </ButtonBase>
          )}
        </div>
      </div>

      {activeActionType === ViewTypesEnum.tableView.key && (
        <div className='w-100 px-2'>
          <Tables
            data={matchedUnits && matchedUnits.result}
            headerData={[
              { id: 1, label: 'ref-no', input: 'unitRefNo' },
              { id: 2, label: 'property', input: 'propertyName' },
              {
                id: 5,
                label: 'unit-type',
                input: 'unitType',
              },
              {
                id: 6,
                label: 'bedrooms',
                input: 'bedroom',
              },
              {
                id: 7,
                label: 'bathrooms',
                input: 'bathroom',
              },
            ]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            defaultActions={[
              {
                enum: TableActions.delete.key,
                isDisabled:
                  activeInquiryData?.inquiryStatus === 'Completed' ||
                  activeInquiryData?.inquiryStatus === 'Canceled',
              },
            ]}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            focusedRowChanged={focusedRowChanged}
            totalItems={
              matchedUnits && matchedUnits.totalCount
                ? matchedUnits.totalCount
                : 0
            }
          />
        </div>
      )}
      {activeActionType === ViewTypesEnum.cards.key &&
        matchedUnits &&
        matchedUnits.result && (
          <AssignUnitCards
            data={matchedUnits}
            onFooterActionsClicked={() => {}}
            onCardClicked={() => {}}
            onCardCheckboxClick={() => {}}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isExpanded={false}
            unAssignUnitHandler={(unit) => {
              unAssignUnitHandler(unit);
            }}
            inquiryStatus={
              activeInquiryData?.inquiryStatus === 'Completed' ||
              activeInquiryData?.inquiryStatus === 'Canceled'
            }
            isSale={
              activeInquiryData?.inquiryTypeId === 3 ||
              activeInquiryData?.inquiryTypeId === 1
            }
          />
        )}
      {isOpenAssignToUnitDialog && (
        <AssignToUnitDialog
          isOpen={isOpenAssignToUnitDialog}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={(unit) => {
            saveHandler(unit);
          }}
          onClose={() => setIsOpenAssignToUnitDialog(false)}
          inquiryTypeId={activeInquiryData && activeInquiryData.inquiryTypeId}
        />
      )}

      {isOpenAssignToUnitDialog && (
        <AssignToUnitDialog
          isOpen={isOpenAssignToUnitDialog}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={(unit) => {
            saveHandler(unit);
          }}
          onClose={() => setIsOpenAssignToUnitDialog(false)}
          inquiryTypeId={activeInquiryData && activeInquiryData.inquiryTypeId}
          allUnitsRelatedInquiry={(matchedUnits && matchedUnits.result) || []}
        />
      )}

      {isOpenCompleteDialog && (
        <CompleteInquiryDialog
          isOpen={isOpenCompleteDialog}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={() => {
            completedHandler();
            setIsOpenCompleteDialog(false);
          }}
          isLoading={isLoading}
          close={() => setIsOpenCompleteDialog(false)}
          inquiry={activeInquiryData}
        />
      )}
    </div>
  );
};

AssignInquiryToUnitComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
