import React, { useState } from 'react';
import { Tables } from '../../../../../Components';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents';
import Recommendation from './Recommendation';
import { TableActions } from '../../../../../Enums';
import MoreDetails from './MoreDetails';
import EvaluationChip from './EvaluationChip';
import AudioPlayer from './AudioPlayer';

function AgentCallsTableView({
  data,
  loading,
  parentTranslationPath,
  translationPath,
  filter,
  setFilter,
}) {
  const [activeItem, setActiveItem] = useState(null);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isAudioDialogOpen, setIsAudioDialogOpen] = useState(false);

  const onActionClicked = (actionId, item) => {
    if (actionId === TableActions.view.key) {
      setIsDetailsDialogOpen(true);
      setActiveItem(item);
    } else if (actionId === TableActions.playAudio.key) {
      setIsAudioDialogOpen(true);
      setActiveItem(item);
    }
  };
  return (
    <>
      <Tables
        data={data.result}
        totalItems={data.totalCount}
        isLoading={loading}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerData={[
          {
            label: 'client-name',
            input: 'lead',
          },
          {
            label: 'lead-id',
            input: 'lead-id',
            component: (row) => (
              <CopyToClipboardComponents
                data={row.leadId}
                childrenData={row.leadId}
              />
            ),
          },
          {
            label: 'agent',
            input: 'agent',
          },
          {
            label: 'Call Evaluation',
            input: 'sentimentEvaluation',
            component: (row) => (
              <EvaluationChip
                sentimentEvaluation={row.sentimentEvaluation}
                size='small '
              />
            ),
          },
          {
            label: 'Agent Performance',
            input: 'agentPerformance',
            component: (row) => (
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                {row.agentPerformance}
              </span>
            ),
          },
          {
            label: 'is Risk',
            component: (row) => (
              <span
                style={{
                  color: row.agentEvaluations?.[0]?.isRisk
                    ? '#b42318'
                    : '#2ECC71',
                }}
              >
                {row.agentEvaluations?.[0]?.isRisk ? 'Yes' : 'No'}
              </span>
            ),
          },
          {
            label: 'Activity Name',
            input: 'activity',
          },
          {
            label: 'call-duration',
            input: 'callDuration',
          },
          {
            label: 'recommendation',
            input: 'adviceForAgent',
            component: (row) => (
              <Recommendation recommendation={row.adviceForAgent} />
            ),
          },
          {
            label: 'date',
            input: 'activityDate',
            isDate: true,
            dateFormat: 'MM/DD/YYYY, h:mm A',
          },
        ]}
        isOriginalPagination
        activePage={filter.pageIndex - 1}
        itemsPerPage={filter.pageSize}
        activePageChanged={(_e, newValue) =>
          setFilter((prevFilter) => ({
            ...prevFilter,
            pageIndex: newValue + 1,
          }))
        }
        actionsOptions={{ onActionClicked }}
        bodyRowId={'agent-calls'}
        itemsPerPageChanged={(e) =>
          setFilter((prevFilter) => ({
            ...prevFilter,
            pageSize: +e.target.value,
          }))
        }
        defaultActions={[
          { enum: TableActions.view.key },
          { enum: TableActions.playAudio.key, title: 'play call' },
        ]}
      />
      {isDetailsDialogOpen && (
        <MoreDetails
          call={activeItem}
          open
          parentTranslationPath={parentTranslationPath}
          onClose={() => {
            setActiveItem(null);
            setIsDetailsDialogOpen(false);
          }}
        />
      )}
      {isAudioDialogOpen && (
        <AudioPlayer
          audioLink={activeItem?.callAudioFile}
          onClose={() => {
            setActiveItem(null);
            setIsAudioDialogOpen(false);
          }}
        />
      )}
    </>
  );
}

export default AgentCallsTableView;
