import React from "react";

function ArrowNarrowDown({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.695 3.057a1.04 1.04 0 0 0-.567.459l-.108.184-.01 6.938-.011 6.939-2.229-2.223c-2.015-2.008-2.249-2.23-2.427-2.289a1.003 1.003 0 0 0-1.215.451c-.091.156-.108.23-.108.484s.016.328.107.48c.135.226 6.241 6.325 6.433 6.425.099.052.227.073.44.073s.341-.021.44-.073c.192-.1 6.298-6.199 6.433-6.425.091-.152.107-.226.107-.48s-.017-.328-.108-.484a1.003 1.003 0 0 0-1.215-.451c-.178.059-.412.281-2.427 2.289l-2.229 2.223-.011-6.939-.01-6.938-.108-.184a1.005 1.005 0 0 0-1.177-.459"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ArrowNarrowDown;
