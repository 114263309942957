import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    customSelect: {
      marginBottom: "16px",
    },
    selectionHeading: {
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.text.secondary,
      lineHeight: "20px",
      marginBottom: "16px",
    },
    emptyState: {
      fontSize: "14px",
      fontWeight: 400,
      color: theme.palette.text.tertiary,
      lineHeight: "20px",
      marginBottom: "16px",
    },
    favouriteCollections: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    radioControlLabel: {
      gap: "12px",
    },
    radioWrapper: {
      marginInlineStart: "11px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    favouriteName: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
    },
    favouriteTag: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
    trashIcon: {
      cursor: "pointer",
    },
    divider: {
      marginTop: '16px',
      marginBottom: '16px',
      backgroundColor: theme.palette.border.secondary,
    },
    customInput: {
      maxWidth: "100% !important",
    },
    customPaper: {
      position: "relative",
      zIndex: 1,

      "& > *": {
        position: "relative",
        zIndex: 1,
      },

      "&::after": {
        content: '""',
        position: "absolute",
        top: "-128px",
        left: "-128px",
        height: "336px",
        width: "336px",
        backgroundImage: 'url("/images/backgroundPatterns/background-grid-sm.svg")',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        zIndex: 0,
      },
    }
  };
});
