import React, { useEffect, useState } from 'react';
import { CustomDialog } from '../../CustomDialog';
import { useTranslate } from '../../../../Hooks';
import {
  ContactEmailDialog,
  ContactSMSDialog,
} from './ContactsPreferenceDialogs';
import {
  GetContacts,
  SendBulkSmsPost,
  SendCorrespondingEmailPost,
} from '../../../../Services';

function ContactPreferenceComponents({
  isOpenChanged,
  actionEnum,
  isOpen,
  activeItem,
}) {
  const { translate } = useTranslate('NewContactsView');
  const [state, setState] = useState({
    contactIds: [],
    message: '',
    subject: '',
    messageKeyValue: {},
    files: [],
    bcc: [],
    ccc: [],
  });

  const [contactList, setContactList] = useState([]);
  const [selected, setSelected] = useState([]);

  const onSubmitHandler = async (preferenceAPI, submissionData) => {
    try {
      await preferenceAPI(submissionData);
    } catch (error) {
      console.error('Error submitting data: ', error);
    }
  };

  const getContacts = async (search) => {
    try {
      const response = await GetContacts({
        pageIndex: 0,
        pageSize: 100,
        isAdvance: false,
        search,
      });

      if (Array.isArray(response?.result)) {
        setContactList(response.result);
      } else {
        setContactList([]);
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const resetState = () => {
    setState({
      contactIds: '',
      message: '',
      subject: '',
      messageKeyValue: {},
      files: [],
      bcc: [],
      ccc: [],
    });
    setContactList([]);
  };

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen]);
  useEffect(() => {
    if (!activeItem) return;
    setSelected([
      {
        contactsId: activeItem.id,
        contact: {
          first_name: activeItem?.allDetails['Main Information'][1].value || '',
          last_name: activeItem?.allDetails['Main Information'][2].value || '',
          company_name: activeItem.name,
        },
      },
    ]);
    setState((items) => ({
      ...items,
      contactIds: [activeItem.id],
    }));
  }, [activeItem]);
  return (
    <>
      <CustomDialog
        open={isOpen}
        onClose={() => {
          isOpenChanged();
          resetState();
        }}
        title={
          actionEnum === 'smsSolid'
            ? translate('send-sms')
            : translate('send-email')
        }
        subtitle={
          actionEnum === 'smsSolid'
            ? translate('reach-out-sms')
            : translate('reach-out-email')
        }
        confirmText={translate('send')}
        width={'688px'}
        isDisabled={state.contactIds.length === 0 || !state.message}
        onConfirm={() => {
          const api =
            actionEnum === 'smsSolid'
              ? SendBulkSmsPost
              : SendCorrespondingEmailPost;
          onSubmitHandler(api, state);
        }}
        content={
          (actionEnum === 'smsSolid' && (
            <ContactSMSDialog
              setState={setState}
              getContacts={getContacts}
              contactList={contactList}
              state={state}
              setSelected={setSelected}
              selected={selected}
            />
          )) ||
          (actionEnum === 'emailSolid' && (
            <ContactEmailDialog
              setState={setState}
              getContacts={getContacts}
              contactList={contactList}
              state={state}
              setSelected={setSelected}
              selected={selected}
            />
          ))
        }
      />
    </>
  );
}

export default ContactPreferenceComponents;
