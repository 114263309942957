import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wrapperHistoryOfMarged: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      overflowY: "auto",
      height: "100vh",
      boxSizing: "border-box",
    },
  };
});
