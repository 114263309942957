import React from 'react';
import { AMLStatusEnum } from '../../../Enums';

export const headerData = (t, AMLTransactionStatusList) => [
  {
    id: 1,
    label: 'ref-no',
    input: 'unitRefNumber',
    component: (item) => <span>{item && item.unitReferenceNo}</span> || '',
  },
  {
    id: 2,
    label: 'property',
    input: 'propertyName',
  },
  {
    id: 3,
    label: 'transaction-status',
    input: 'transactionStatusId',
    withSelectFilter: true,
    optionFilterList: [...AMLTransactionStatusList],
    component: (item) =>
      (
        <span>
          {item.transactionStatus === 'Pass' ||
          item.transactionStatus === 'Approved' ? (
            <span className='pass-lapel'>{t(`${item.transactionStatus}`)}</span>
          ) : item.transactionStatus === 'Fail' ||
            item.transactionStatus === 'Rejected' ? (
            <div className='Fail-lapel'>{t(`${item.transactionStatus}`)}</div>
          ) : item.transactionStatus === 'Pending' ||
            item.transactionStatus === 'WithdrawTransaction' ||
            item.transactionStatus === 'ReassignedToRequester' ||
            item.transactionStatus === 'NeedRevision' ||
            item.transactionStatus === 'Resubmitted' ||
            item.transactionStatus === 'AutoWithdraw' ? (
            <div className='Pending-lapel'>
              {t(`${item.transactionStatus}`)}
            </div>
          ) : (
            ''
          )}
        </span>
      ) || '',
  },
  {
    id: 4,
    label: 'status',
    input: 'statusId',
    withSelectFilter: true,
    component: (item) => <span>{item && item.status}</span> || '',

    optionFilterList: [
      {
        key: AMLStatusEnum[6].key,
        value: AMLStatusEnum[6].name,
      },
      {
        key: AMLStatusEnum[5].key,
        value: AMLStatusEnum[5].name,
      },
      {
        key: AMLStatusEnum[10].key,
        value: AMLStatusEnum[10].name,
      },
    ],
  },
  {
    id: 5,
    label: 'community',
    input: 'communityName',
  },
  {
    id: 6,
    label: 'unit-no',
    input: 'unitNumber',
  },
  {
    id: 7,
    label: 'buyer',
    input: 'buyerName',
    component: (item) => (
      <span>
        {item.buyersNames &&
          item.buyersNames.map((element, index) => (
            <span key={`buyersNamesRef${index + 1}`}>
              {`${element}${
                (index < item.buyersNames.length - 1 && ', ') || ''
              }`}
            </span>
          ))}
      </span>
    ),
  },
  {
    id: 8,
    label: 'buyer-agent',
    input: 'buyerAgent',
    component: (item) =>
      (
        <span>
          {item &&
            item.buyerAgents &&
            item.buyerAgents.map((item) => `${item.agentName} , `)}
        </span>
      ) || '',
  },
  {
    id: 9,
    label: 'buyer-agent-staff-id',
    input: 'buyerStaffId',
    component: (item) =>
      (
        <span>
          {item &&
            item.buyerAgents &&
            item.buyerAgents.map((item) =>
              item.staffId ? `${item.staffId} ,  ` : 'N/A ,  '
            )}
        </span>
      ) || '',
  },
  {
    id: 10,
    label: 'seller',
    input: 'sellerName',
  },
  {
    id: 11,
    label: 'seller-agent',
    input: 'sellerAgent',
    component: (item) =>
      (
        <span>
          {item &&
            item.sellerAgents &&
            item.sellerAgents.map((item) => `${item.listingAgentName}, `)}
        </span>
      ) || '',
  },
  {
    id: 12,
    label: 'seller-agent-staff-id',
    input: 'sellerStaffId',
    component: (item) =>
      (
        <span>
          {item &&
            item.sellerAgents &&
            item.sellerAgents.map((item) =>
              item.staffId ? `${item.staffId} ,  ` : 'N/A ,  '
            )}
        </span>
      ) || '',
  },
  {
    id: 13,
    isSortable: true,
    label: 'selling-price',
    input: 'sellingPrice',
  },
  {
    id: 14,
    isSortable: true,
    label: 'transaction-date',
    input: 'transactionEntryDate',
    isDate: true,
  },
  {
    id: 15,
    isSortable: true,
    label: 'transaction-id',
    input: 'unitTransactionId',
  },
  {
    id: 16,
    label: 'buyer-branch',
    input: 'buyerBranch',
  },
  {
    id: 17,
    label: 'seller-branch',
    input: 'sellerBranch',
  },
  {
    id: 18,
    label: 'notes',
    input: 'invoiceNotes',
    isHiddenFilter: true,
  },
];
