import React from "react";

function Download01({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.695 2.057a1.04 1.04 0 0 0-.567.459l-.108.184-.011 4.938-.01 4.938-1.73-1.722c-1.548-1.542-1.75-1.73-1.926-1.788-.48-.16-.961.018-1.215.45-.092.156-.108.229-.107.484 0 .212.022.341.074.44.1.192 5.199 5.298 5.425 5.433.152.091.226.107.48.107s.328-.016.48-.107c.226-.135 5.325-5.241 5.425-5.433.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.002 1.002 0 0 0-1.215-.45c-.176.058-.378.246-1.926 1.788l-1.73 1.722-.01-4.938L12.98 2.7l-.108-.184a1.005 1.005 0 0 0-1.177-.459m-9 12a1.04 1.04 0 0 0-.567.459l-.108.184-.013 1.46c-.019 2.224.068 2.931.458 3.699.45.89 1.307 1.623 2.215 1.896.794.239.962.245 7.32.245 6.358 0 6.526-.006 7.32-.245.908-.273 1.765-1.006 2.215-1.896.39-.768.477-1.475.458-3.699l-.013-1.46-.109-.186a1.01 1.01 0 0 0-1.742 0l-.109.186-.027 1.76c-.035 2.267-.065 2.412-.593 2.941-.299.3-.58.45-.982.527-.408.077-12.428.077-12.836-.001a1.69 1.69 0 0 1-.982-.524c-.529-.534-.558-.677-.593-2.943L3.98 14.7l-.108-.184a1.005 1.005 0 0 0-1.177-.459"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default Download01;
