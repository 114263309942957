import React from "react";
import { Box } from "@material-ui/core";

import { useSelectedTheme } from "../../../Hooks";
import { CustomBadge } from "../CustomBadge";

// Styles
import useStyles from "./styles";

const StatusBadges = ({ Status }) => {
  const {
    theme: { palette },
  } = useSelectedTheme();
  const styles = useStyles();

  const statusEnum = {
    Active: {
      key: "Active",
      value: "Active",
      backgroundColor: palette.utility.success_50,
      borderColor: palette.utility.success_200,
      color: palette.utility.success_700,
    },
    Approved: {
      key: "Approved",
      value: "Approved",
      backgroundColor: palette.utility.blue_50,
      borderColor: palette.utility.blue_200,
      color: palette.utility.blue_700,
    },
    Pending: {
      key: "Pending",
      value: "Pending",
      backgroundColor: palette.utility.orange_50,
      borderColor: palette.utility.orange_200,
      color: palette.utility.orange_700,
    },
    Rejected: {
      key: "Rejected",
      value: "Rejected",
      backgroundColor: palette.utility.error_50,
      borderColor: palette.utility.error_200,
      color: palette.utility.error_700,
    },
    InActive: {
      key: "InActive",
      value: "InActive",
      backgroundColor: palette.utility.utility_gray_50,
      borderColor: palette.utility.utility_gray_200,
      color: palette.utility.utility_gray_700,
    },
  };

  const leadType = statusEnum[Status];

  return (
    <Box className={styles.badgeSection}>
      <CustomBadge
        key={leadType.key}
        Style={{
          padding: "4px 12px",
        }}
        SizeVariant="medium"
        label={leadType.key}
        BackgroundColor={leadType.backgroundColor}
        BorderColor={leadType.borderColor}
        Color={leadType.color}
      />
    </Box>
  );
};

export default StatusBadges;
