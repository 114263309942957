import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@material-ui/core";

// styles
import useStyles from "./styles";

const a11yProps = (index) => ({
  "aria-controls": `custom-tabpanel-${index}`,
  id: `custom-tab-${index}`,
});

const TabPanel = (props) => {
  const { children, index, value, ...other } = props;
  return (
    <div
      aria-labelledby={`custom-tab-${index}`}
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function CustomTabs({ tabs, tabValue, onTabChange, variant, dynamicProps ,classesname}) {
  const styles = useStyles({ variant });
  return (
    <Box>
      <Tabs
        TabIndicatorProps={{ style: { display: "none" } }}
        aria-label="custom tabs"
        className={styles.tabs}
        onChange={onTabChange}
        scrollButtons={false}
        value={tabValue}
        variant="standard"
        classes={{
          flexContainer: styles.flexContainer,
          scroller: clsx(classesname || styles.tabsScroller),
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={tab.label}
              className={styles.tabButton}
              disableRipple
              classes={{ selected: styles.tab }}
              label={
                <div className={styles.tabButtonContainer}>
                  <span
                    className={clsx(
                      styles.tabLabel,
                      variant === "default" ? styles.font14 : styles.font16
                    )}
                  >
                    {tab.label}
                  </span>
                  {tabValue === index && dynamicProps?.updatedCount && (
                    <Box className={styles.containerTotalCount}>
                      <span
                        className={clsx(
                          styles.tabLabel,
                          variant === "default" ? styles.font14 : styles.font16
                        )}
                      >
                        {dynamicProps.updatedCount}
                      </span>
                    </Box>
                  )}
                </div>
              }
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel key={index} index={index} value={tabValue}>
          {React.cloneElement(tab.component, { ...(dynamicProps || {}) })}{" "}
        </TabPanel>
      ))}
    </Box>
  );
}

CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    })
  ).isRequired,
  onTabChange: PropTypes.func,
  tabValue: PropTypes.number,
  variant: PropTypes.oneOf(["default", "overview"]),
  dynamicProps: PropTypes.object,
};

CustomTabs.defaultProps = {
  onTabChange: () => {},
  tabValue: 0,
  variant: "default",
  dynamicProps: {},
};

export default CustomTabs;
