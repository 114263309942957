import React from "react";

function ArrowNarrowLeft({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.757 4.213c-.07.026-.183.086-.25.133-.068.047-1.708 1.672-3.645 3.611-2.477 2.48-3.545 3.571-3.599 3.676a.88.88 0 0 0 0 .734c.108.211 7.159 7.262 7.37 7.37.302.155.7.088.946-.158a.834.834 0 0 0 .158-.946c-.054-.104-.93-1.004-2.898-2.974l-2.821-2.825 6.099-.009 6.1-.008.133-.072c.307-.164.464-.417.464-.745s-.157-.581-.464-.745l-.133-.072-6.1-.008-6.099-.009 2.821-2.825c1.968-1.97 2.844-2.87 2.898-2.974a.834.834 0 0 0-.158-.946c-.212-.212-.57-.302-.822-.208"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ArrowNarrowLeft;
