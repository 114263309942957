import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "512px",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      background: theme.palette.background.primary,
      borderRadius: theme.borderRadius.md,
      height: "44px",
      padding: "10px 14px",
      paddingInlineEnd: "34px !important",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.primary, // Default border color
        borderWidth: "1px !important",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.primary, // Border color on hover
      },
      "&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.brand, // Border color when focused
        boxShadow: theme.shadows[12], // Box shadow when focused
      },
      "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.error, // Ensure the error color is also applied
        boxShadow: theme.shadows[16], // Box shadow when focused and error
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.error,
      },
    },
    // Targeting the padding for the inner input element
    "& .MuiInputBase-input": {
      padding: "0 !important",
    },
    "& .MuiFormHelperText-root": {
      color: `${theme.palette.text.tertiary} !important`,
      marginRight: 0,
      marginLeft: 0,
      padding: 0,
      marginTop: "6px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color : `${theme.palette.text.error_primary} !important`,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  wrapperSide: {
    gap: "32px",
  },
  popover: {
    backgroundColor: theme.palette.background.primary,
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: '8px',
    padding: "4px 6px",
  },
  option: {
    color: theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    marginTop: "1px",
    marginBottom: "1px",
    borderRadius: "6px",
    padding: "10px 8px",

    "&:hover": {
      backgroundColor: `${theme.palette.background.hover} !important`, // Background color when hovered
    },
    "&[aria-selected='true']": {
      backgroundColor: `${theme.palette.background.hover} !important`, // Background color for selected option
    },
    "&[data-focus='true']": {
      backgroundColor: `${theme.palette.background.hover} !important`, // Background color for focused option
    },
  },
  listbox: {
    padding: 0,
  },
  popupIndicator: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent !important",
    },
  },
  clearIndicator: {
    display: "none",
  },
  closeIconWrapper: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}));
