import { IconButton } from '@material-ui/core';
import { Clear} from '@material-ui/icons';
import React from 'react';

const SelectedChip = ({ children, ...props }) => {
  return (
    <div {...props} className='selected-chip-container'>
      <span className='selected-chip'>{children}</span>
      {props.onDelete && (
        <IconButton onClick={props.onDelete} size='small'>
          <Clear fontSize='small'/>
        </IconButton>
      )}
    </div>
  );
};

export default SelectedChip;
