import React from "react";
import "./styles.scss";
import { CopyToClipboardComponents } from "../../ReusableComponents/UtilityComponents";
import UnitSizeIcon from "./Icons/UnitSizeIcon";
import BathroomsIcon from "./Icons/BathroomsIcon";
import BedroomsIcon from "./Icons/BedroomIcon";
import PhoneIcon from "./Icons/PhoneIcon";
import MessageIcon from "./Icons/MessageIcon";
import { Avatar ,Button } from "@material-ui/core";
import { getDownloadableLink, getFirstLastNameLetters } from "../../Helper";

function DuplicateUnitCard({ unitData, onCardClicked, isSelected  , onOpenFilClicked , setSelectedDuplicatedUnit , onCloseClicked }) {
 
  return (
    <div
      className={`duplicate-card-wrapper ${
        isSelected  ? "duplicate-card-wrapper-selected" : ""
      }`}
      onClick={() => onCardClicked(unitData)}

    >
      <div className="duplicate-card-content">
        <div className="section-one">
          <span className="property-name">{unitData?.propertyName || ""}</span>
          <span className="city-name">{unitData?.city || ""}</span>
          <div className="idRef-wrapper">
            <span className="unit-number">{unitData?.unitNumber || ""}</span>
            <span className="copy-Ref">
              {unitData.unitRefNumber && (
                <CopyToClipboardComponents
                  data={`#${unitData.unitRefNumber}`}
                  childrenData={`#${unitData.unitRefNumber}`}
                  CustomizationClassName={`copyToClipBoard blueText`}
                />
              )}
            </span>
          </div>
          <div className="unit-type-wrapper">
            {unitData.unitOperationType ? (
              <span className="unit-Operation">
                {unitData.unitOperationType}
              </span>
            ) : (
              ""
            )}
            {unitData.unitType ? (
              <span className="unit-type">{unitData.unitType}</span>
            ) : (
              ""
            )}
            {unitData.unitStatus ? (
              <span className="unit-status">{unitData.unitStatus}</span>
            ) : (
              ""
            )}
          </div>
          <div className="unit-facility">
            <span className="text-icon">
              {" "}
              <UnitSizeIcon width="20" height="20" />
              <span>{unitData?.size || 0} ft</span>
            </span>
            <span className="text-icon">
              {" "}
              <BedroomsIcon width="20" height="20" />
              {unitData?.bedrooms || 0} bed
            </span>
            <span className="text-icon">
              {" "}
              <BathroomsIcon width="20" height="20" />
              {unitData?.bathrooms || 0} bat
            </span>
          </div>
        </div>
        <div className="section-two">
          {unitData?.hasListingAgent ? (
            <>
              <div className="image-name-wrapper">
              <Avatar
                className='Avatar'
                src={
                  unitData?.listingAgentImage
                    ? getDownloadableLink(unitData.listingAgentImage)
                    : ''
                }
              >
                {getFirstLastNameLetters(unitData.listingAgentName|| '')}
              </Avatar>
                <span className="name-agent-wrapper">
                  <span className="name-agent">
                    {unitData.listingAgentName}
                  </span>
                  <span className="label-agent">Listing agent</span>
                </span>
              </div>
              <div className="social-wrapper">
                <div className="listingAgentIcon">
                  <PhoneIcon width="20" height="20" fill={"#2153B1"} />
                </div>
                <div className="listingAgentIcon">
                  <MessageIcon width="20" height="20" fill={"#2153B1"} />
                </div>
              </div>
            </>
          ) : (
            <>
              <span className="no-agent">No listing agent</span>
            </>
          )}
        </div>
      </div>
          <Button className='Side_popup_btn'
            onClick={(e)=>{
              e.stopPropagation();
              setSelectedDuplicatedUnit(unitData)
              onOpenFilClicked()
            }}
            >
            <span className="mdi mdi-folder-open"></span>
          </Button>
    </div>
  );
}

export default DuplicateUnitCard;
