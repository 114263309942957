import React, { useCallback, useEffect, useState } from "react";
import {
  CustomAutocomplete,
  CustomDialog,
  CustomSwitch,
} from "../../../../../../../../Components";
import { Box, Typography } from "@material-ui/core";
import { useTranslate } from "../../../../../../../../Hooks";
import { OrganizationUserSearch } from "../../../../../../../../Services";
import { useDebouncedAction } from "../../../../../../../../Hooks/DebouncedAction";

//styles
import useStyles from "./styles";

function ContactReassignLeadDialog({ isOpen, onClose, userType, onSave }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");

  const [isLoading, setIsLoading] = useState(false);
  const [agentList, setAgentList] = useState({
    result: [],
    totalCount: 0,
  });
  const [Dto, setDto] = useState({
    referredToId: null,
    isCopyTo: false,
  });

  const [selected, setSelected] = useState({
    referredTo: null,
    isCopyTo: false,
  });

  const getSaleOrLeaseAgents = useCallback(async (value) => {
    setIsLoading(true);
    const res = await OrganizationUserSearch({
      pageSize: 10,
      pageIndex: 0,
      name: value,
      userTypeId: userType || null,
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAgentList({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setAgentList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, []);
  const onSubmitHandler = () => {
    onSave(Dto);
  };
  const debouncedGetSaleOrLeaseAgents = useDebouncedAction(
    getSaleOrLeaseAgents,
    700
  );

  useEffect(() => {
    getSaleOrLeaseAgents();
  }, []);

  return (
    <CustomDialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      title={translate("Reassign-lead")}
      subtitle={translate(
        "Reassigning-a-lead-transfers-responsibility-for-managing-the-lead-to-a-different-agent"
      )}
      onConfirm={() => {
        onSubmitHandler();
      }}
      width={"688px"}
      confirmText={translate("Submit")}
      cancelText={translate("Cancle")}
      isDisabled={!Dto.referredToId}
      content={
        <Box className={styles.containerReassignLead}>
          <Typography className={styles.titleItem}>
            {translate("Reassign-lead-to")}
          </Typography>
          <Box className={styles.containerFiledItem}>
            <Box className={styles.containerAutoCompletSwitch}>
              <CustomAutocomplete
                variant="default"
                placeholder={translate("Select")}
                onChange={(e, value) => {
                  setDto((item) => ({
                    ...item,
                    referredToId: value?.id || "",
                  }));
                  setSelected((item) => ({
                    ...item,
                    referredTo: value || null,
                  }));
                }}
                onInputKeyUp={(e) => {
                  const value = e?.target?.value || null;
                  debouncedGetSaleOrLeaseAgents(value);
                }}
                options={agentList?.result || []}
                value={selected?.referredTo || null}
                getOptionLabel={(option) =>
                  (option && option.fullName) || option.name || ""
                }
                renderOption={(option) =>
                  (option && option.fullName) || option.name || ""
                }
              />
              <CustomSwitch
                size={"md"}
                label={translate(
                  "Move-all-assigned-activities-to-the-new-agent"
                )}
                checked={selected.isCopyTo || false}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setDto((item) => ({ ...item, isCopyTo: isChecked }));
                  setSelected((item) => ({ ...item, isCopyTo: isChecked }));
                }}
                isDisabled={false}
              />
            </Box>
          </Box>
        </Box>
      }
    />
  );
}
export default ContactReassignLeadDialog;
