import React, { useEffect, useMemo, useState } from 'react';
import LabeledField from '../../InquiryRotationView/InquirySchemeManagement/components/LabeledField';
import { Button, FormControlLabel } from '@material-ui/core';
import IOSSwitch from '../../InquiryRotationView/InquirySchemeManagement/components/IOSSwitch';
import {
  CheckboxesComponent,
  DatePickerComponent,
  Inputs,
} from '../../../../Components';
import { PaymentPlansLocalePath } from '../i18n/PaymentPlansLocale';
import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import { GetLookupItemsByLookupTypeName } from '../../../../Services';
import { PropertyPaymentPlan } from '../../../../Enums';
import Joi from 'joi';
import {
  generateUniqueKey,
  getErrorByName,
  showError,
} from '../../../../Helper';
import moment from 'moment';
import { config } from '../../../../config';

const initialInstallment = (frequencyId) => ({
  installmentTypeId: config.InstallmentTypes.Installment,
  amountPercentage: '',
  frequencyId,
  isBasedOnBookingDate: false,
  frequencyValue: '',
  numberOfInstallment: null,
  id: crypto.randomUUID(),
});
const InstallmentItem = ({ dispatch }) => {
  const { t } = useTranslation(PaymentPlansLocalePath);
  const [installment, setInstallment] = useState(initialInstallment);
  const [frequencies, setFrequencies] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [key, setKey] = useState(generateUniqueKey);

  const onFrequencyToggleHandler = (e) => {
    e.persist();
    setInstallment((prevInstallment) => ({
      ...prevInstallment,
      frequencyId: +e.target.value,
    }));
  };
  const updateInstallmentHandler = (field, value) => {
    setInstallment((prevInstallment) => ({
      ...prevInstallment,
      [field]: value,
    }));
  };
  const dateFrequencyId = useMemo(
    () =>
      frequencies.find(
        (frequency) => frequency.lookupItemName.toLowerCase() === 'date'
      )?.lookupItemId,
    [frequencies]
  );
  const monthFrequencyId = useMemo(
    () =>
      frequencies.find(
        (frequency) => frequency.lookupItemName.toLowerCase() === 'month'
      )?.lookupItemId,
    [frequencies]
  );
  const installmentSchema = Joi.object({
    installmentTypeId: Joi.number().required(),
    amountPercentage: Joi.number()
      .required()
      .min(1)
      .max(99)
      .messages({
        'number.min': t('installmentAmountPercentageMin'),
        'number.max': t('installmentAmountPercentageMax'),
        'number.base': t('is-required', {
          field: t('amount_percentage'),
          ns: 'InquiryRotation',
        }),
      }),
    frequencyId: Joi.number().required(),
    frequencyValue: Joi.string()
      .required()
      .custom((value, helper) => {
        if (+value) {
          if (!Number.isInteger(+value)) {
            return helper.error('string.decimal');
          } else if (+value < 1) {
            return helper.error('string.negative');
          }
        }
      })
      .messages({
        'string.required': t('is-required', {
          field: t('frequency'),
          ns: 'InquiryRotation',
        }),
        'string.base': t('is-required', {
          field: t('frequency'),
          ns: 'InquiryRotation',
        }),
        'string.empty': t('is-required', {
          field: t('frequency'),
          ns: 'InquiryRotation',
        }),
        'string.negative': t('valueMustBePositive'),
        'string.decimal': t('string_decimal'),
      }),

    numberOfInstallment: Joi.when('frequencyId', {
      is: monthFrequencyId,
      then: Joi.alternatives().conditional('isBasedOnBookingDate', {
        is: true,
        then: Joi.optional(),
        otherwise: Joi.number()
          .min(1)
          .required()
          .messages({
            'number.min': t('number_of_installment_min'),
            'any.required': t('is-required', {
              field: t('number_of_installment'),
              ns: 'InquiryRotation',
            }),
          }),
      }),
      otherwise: Joi.optional(),
    }),
  }).validate(installment, { allowUnknown: true, abortEarly: false });

  const onAddHandler = () => {
    setIsSubmitted(true);
    if (installmentSchema.error) {
      return showError('fill_all_required_fields');
    }
    installment.amountPercentage = +installment.amountPercentage / 100;
    dispatch({ field: 'installment', value: installment });
    setInstallment(initialInstallment(monthFrequencyId));
    setIsSubmitted(false);
    setKey(generateUniqueKey());
  };

  useEffect(() => {
    const getFrequencies = async () => {
      try {
        const res = await GetLookupItemsByLookupTypeName({
          lookUpName: PropertyPaymentPlan.Frequency.lookupTypeName,
          pageIndex: 1,
          pageSize: 100,
        });
        updateInstallmentHandler(
          'frequencyId',
          res.result.find(
            (item) => item.lookupItemName.toLowerCase() === 'month'
          )?.lookupItemId
        );
        setFrequencies(res.result);
      } catch (error) {
        showError('error');
      }
    };
    getFrequencies();
  }, []);
  return (
    <div key={key}>
      <LabeledField
        label={t('installment')}
        className='gap-32'
        component={
          <div className='flex gap-1'>
            <CheckboxesComponent
              label={t('installment_based_on_booking_date')}
              onSelectedCheckboxChanged={(e) => {
                updateInstallmentHandler(
                  'isBasedOnBookingDate',
                  e.target.checked
                );
                updateInstallmentHandler('frequencyId', monthFrequencyId);
              }}
              singleChecked={installment.isBasedOnBookingDate}
            />
            <Inputs
              type={'number'}
              startAdornment={<span className='start-adornment'>%</span>}
              inputPlaceholder={'Enter installment percentage (e.g., 25%)'}
              wrapperClasses={'px-2'}
              onInputChanged={(e) =>
                updateInstallmentHandler('amountPercentage', e.target.value)
              }
              value={installment.amountPercentage}
              isWithError
              helperText={
                getErrorByName(installmentSchema, 'amountPercentage').message
              }
              error={
                getErrorByName(installmentSchema, 'amountPercentage').error
              }
              isSubmitted={isSubmitted}
            />
          </div>
        }
      />
      {installment.isBasedOnBookingDate ? (
        <LabeledField
          hideDivider
          className='gap-32'
          component={
            <div className='flex- flex-col gap-6'>
              <Inputs
                type='number'
                inputPlaceholder={'Enter months (e.g., 12)'}
                startAdornment={
                  <span className='start-adornment'>{t('months')}</span>
                }
                value={installment.frequencyValue}
                onInputChanged={(e) =>
                  updateInstallmentHandler(
                    'frequencyValue',
                    e.currentTarget.value
                  )
                }
                isWithError
                helperText={
                  getErrorByName(installmentSchema, 'frequencyValue').message
                }
                error={
                  getErrorByName(installmentSchema, 'frequencyValue').error
                }
                isSubmitted={isSubmitted}
              />
              <span className='helper-text'>{t('by_booking_date_text')}</span>
            </div>
          }
        />
      ) : (
        <>
          <LabeledField
            className='gap-32'
            hideDivider
            label={t('frequency')}
            component={
              <div className='flex gap-12 flex-col fa-start px-3'>
                <FormControlLabel
                  control={<IOSSwitch />}
                  label={t('by_month')}
                  labelPlacement='end'
                  checked={installment.frequencyId === monthFrequencyId}
                  onChange={onFrequencyToggleHandler}
                  value={monthFrequencyId}
                />
                <FormControlLabel
                  control={<IOSSwitch />}
                  label={t('by_fixed_date')}
                  labelPlacement='end'
                  checked={installment.frequencyId === dateFrequencyId}
                  onChange={onFrequencyToggleHandler}
                  value={dateFrequencyId}
                />
              </div>
            }
          />
          {installment.frequencyId === monthFrequencyId && (
            <>
              <LabeledField
                hideDivider
                className='gap-32'
                label={t('number_of_installment')}
                component={
                  <Inputs
                    type='number'
                    inputPlaceholder={'Enter (e.g., 10)'}
                    value={installment.numberOfInstallment}
                    onInputChanged={(e) =>
                      updateInstallmentHandler(
                        'numberOfInstallment',
                        e.currentTarget.value
                      )
                    }
                  />
                }
              />
              <LabeledField
                hideDivider
                className='gap-32'
                label={t('frequency')}
                component={
                  <div className='flex- flex-col gap-6'>
                    <Inputs
                      type='number'
                      inputPlaceholder={'(e.g., every 1, 2 months)'}
                      startAdornment={
                        <span className='start-adornment'>{t('months')}</span>
                      }
                      value={installment.frequencyValue}
                      onInputChanged={(e) =>
                        updateInstallmentHandler(
                          'frequencyValue',
                          e.currentTarget.value
                        )
                      }
                      isWithError
                      helperText={
                        getErrorByName(installmentSchema, 'frequencyValue')
                          .message
                      }
                      error={
                        getErrorByName(installmentSchema, 'frequencyValue')
                          .error
                      }
                      isSubmitted={isSubmitted}
                    />
                    <span className='helper-text'>{t('by_month_text')}</span>
                  </div>
                }
              />
            </>
          )}
          {installment.frequencyId === dateFrequencyId && (
            <LabeledField
              className='gap-32'
              label={t('by_fixed_date')}
              component={
                <div className='flex- flex-col gap-6'>
                  <DatePickerComponent
                    onDateChanged={(e) =>
                      updateInstallmentHandler(
                        'frequencyValue',
                        new Date(e._d).toLocaleDateString()
                      )
                    }
                    value={moment(installment.frequencyValue)}
                    helperText={
                      getErrorByName(installmentSchema, 'frequencyValue')
                        .message
                    }
                    error={
                      getErrorByName(installmentSchema, 'frequencyValue').error
                    }
                    isSubmitted={isSubmitted}
                  />
                  <span className='helper-text'>
                    {t('select_installment_date')}
                  </span>
                </div>
              }
            />
          )}
        </>
      )}
      <LabeledField
        hideDivider
        className='gap-32'
        component={
          <Button className=' capitalize flex gap-2' onClick={onAddHandler}>
            {' '}
            <Add />
            <span>{t('add_another')}</span>
          </Button>
        }
      />
    </div>
  );
};

export default InstallmentItem;
