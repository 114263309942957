import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

//styles
import useStyles from "./styles";

function CustomDoucomentViewer({ documentUrl, width, height, document }) {
  const styles = useStyles();
  const [isImage, setIsImage] = useState(false);
  const [isVedio, setIsVedio] = useState(false);

  const getGoogleDocURL = (documentUrl) =>
    `https://docs.google.com/gview?url=${documentUrl}&embedded=true`;

  const getFileExtintion = () => {
    const fileExtension = document?.documentName
      ?.split(".")
      .pop()
      .toLowerCase();
    const imageExtensions = ["png", "jpg", "jpeg", "gif", "bmp", "webp", "svg"];
    const videoExtensions = ["mp4", "webm", "ogg", "mov", "avi", "mkv", "flv"];
    setIsImage(imageExtensions.includes(fileExtension));
    setIsVedio(videoExtensions.includes(fileExtension));
  };

  useEffect(() => {
    getFileExtintion();
  }, [documentUrl]);

  return (
    <>
      {!isImage && !isVedio ? (
        <Box className={styles.iframeWrapper}>
          <iframe
            src={getGoogleDocURL(documentUrl)}
            width={width}
            height={height}
            className={styles.iframeStyle}
            title="Document Viewer"
            allowFullScreen
          />
        </Box>
      ) : isImage ? (
        <Box className={styles.imageWrapper}>
          <img
            src={documentUrl}
            alt="Document Preview"
            style={{ width, height, objectFit: "contain" }}
            className={styles.imageStyle}
          />
        </Box>
      ) : (
        <Box className={styles.vedioWapper}>
          <video
            src={documentUrl}
            controls
            style={{ width, height, objectFit: "contain" }}
            className={styles.videoStyle}
            autoPlay
          />
        </Box>
      )}
    </>
  );
}
export default CustomDoucomentViewer;
