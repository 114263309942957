import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    container_content: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
      },
    },
    container_Fileds_Doucoment: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    divider: {
      backgroundColor: theme.palette.background.quarterary,
    },
    container_Title: {
      display: "flex",
      flexDirection: "column",
    },
    titleItem: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
      width: "160px",
    },
    Sub_Description: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
    container_Link_Filed: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    urlFiledSection: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    container_Attatchment_Filed: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    inputFullWidth: {
      width: "100% !important",
      maxWidth: "100% !important",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    containerAttatchmentAndImages: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "32px",
    },
    containerAttachmentUploadAndData: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    containerInput: {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      gap: "10px",
    },
    containerGallarySection: {
      display: "flex",
      flexDirection: "row",
      width: "448px",
      paddingTop: "20px",
      gap: "12px",
    },
    containerOneItemSection: {
      display: "flex",
      flexDirection: "row",
      width: "448px",
      gap: "12px",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    fullcoloum: {
      width: "100%",
      height: "220px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    containerCardOfDoucoment: {
      width: "218px",
      height: "200px",
      cursor: "pointer",
    },
    imagePreview: {
      width: "217px",
      height: "200px",
      borderRadius: "8px",
      objectFit: "fill",
      position: "relative",
      zIndex: 0,
    },
    imagePreviewOneItem: {
      width: "100%",
      height: "200px",
      borderRadius: "8px",
      objectFit: "fill",
      position: "relative",
      zIndex: 0,
      padding: "16px 0px 16px 0px",
    },
    ImageWrapper: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ImageWrapperOneImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    containerImage01Icon: {
      position: "absolute",
      zIndex: 2,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "14px",
      backgroundColor: `rgba(16, 24, 40, 0.08)`,
      width: "56px",
      height: "56px",
      borderRadius: "12px",
      backdropFilter: "blur(4px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    Image01Icon: {
      width: "28px",
      height: "28px",
      zIndex: 3,
    },
    containerDeleteIcon: {
      backgroundColor: `rgba(0, 0, 0, 0.20)`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px",
      position: "absolute",
      zIndex: 2,
      top: "16px",
      right: "16px",
      borderRadius: "50%",
      backdropFilter: `blur(20px)`,
      borderRadius: "4px",
      padding: "4px",
    },
    deleteIcon: {
      cursor: "pointer",
      width: "20px",
      height: "20px",
    },
    noBackgroundWithImage: {
      width: "218px",
      height: "200px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      position: "relative",
    },
    noBackgroundWithImageSelctedUser: {
      width: "220px",
      height: "203px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      position: "relative",
      border: `2px solid ${theme.palette.border.brandSolid}`,
    },
    backgroundWithoutImage: {
      width: "218px",
      height: "151px",
      backgroundColor: theme.palette.background.secondary,
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
    backgroundWithoutImageSelectedUser: {
      width: "218px",
      height: "151px",
      backgroundColor: theme.palette.background.secondary,
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      border: `2px solid ${theme.palette.border.brandSolid}`,
    },
    urlsStyle: {
      width: "218px",
      height: "151px",
      backgroundColor: theme.palette.background.secondary,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      borderRadius: "8px",
    },
    urlsStyleSelectedUser: {
      width: "218px",
      height: "151px",
      backgroundColor: theme.palette.background.secondary,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      borderRadius: "8px",
      border: `2px solid ${theme.palette.border.brandSolid}`,
    },
    styleVedio: {
      width: "218px",
      height: "200px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      position: "relative",
    },
    vedioStyleSelectedUser: {
      width: "218px",
      height: "200px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      position: "relative",
      border: `2px solid ${theme.palette.border.brandSolid}`,
    },
    containerAttatchmentIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: `rgba(16, 24, 40, 0.20)`,
      borderRadius: "28px",
      width: "56px",
      height: "56px",
      backdropFilter: "blur(4px)",
      marginTop: "0px",
    },
    containerAttatchAndUrl: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      alignItems: "center",
      justifyContent: "center",
    },
    documentLinkTitle: {
      width: "128px",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    divider: {
      backgroundColor: theme.palette.border.secondary,
      marginTop: "8px",
      marginBottom: "8px",
    },
    containerdocumentLink: {
      cursor: "pointer",
    },
    Vediowrapper: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    VediowrapperOneItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    videoPreview: {
      width: "218px",
      height: "200px",
      borderRadius: "8px",
      objectFit: "fill",
      position: "relative",
      zIndex: 0,
    },
    videoPreviewOneItem: {
      width: "100%",
      height: "200px",
      borderRadius: "8px",
      objectFit: "fill",
      position: "relative",
      zIndex: 0,
      padding: "16px 0px 16px 0px",
    },
    VideoCreatorIcon: {
      width: "28px",
      height: "28px",
      zIndex: 3,
    },
    containerVideoCreatorIcon: {
      position: "absolute",
      zIndex: 2,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "14px",
      backgroundColor: `rgba(16, 24, 40, 0.08)`,
      width: "56px",
      height: "56px",
      borderRadius: "12px",
      backdropFilter: "blur(4px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    continerIconFileAndName: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
    styleDocName: {
      width: "128px",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    oneItemEditImageVedio: {
      width: "100%",
      backgroundColor: theme.palette.background.secondary,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      height: "200px",
    },
    BodyTableSection: {
      display: "flex",
      flexDirection: "column",
    },
    rowMoreLinkes: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "16px 24px",
      borderBottom: `1px solid ${theme.palette.utility.utility_gray_200}`,
    },
    HyperLinkItem: {
      color: theme.palette.text.tertiary,
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.text.tertiary,
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
      },
    },
    MoreLinkesContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "0px 0px 16px 0px",
    },
    HeaderTableSection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.hover,
      padding: "12px 24px",
    },
    TitleItemTable: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
  };
});
