import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { CustomCopyToClipboard } from "../../../../../CustomCopyToClipboard";
import { useSelectedTheme, useTranslate } from "../../../../../../../Hooks";
import { getDownloadableLink } from "../../../../../../../Helper";
import {
  CheckIcon,
  CloseXIcon,
  DropdownIcon,
} from "../../../../../../../assets/icons";
import { CustomIconButton } from "../../../../../CustomIconButton";
import { SocialLinks } from "../../../../../SocialLinks";

// styles
import useStyle from "./styles";

const ContactAbsolouteHistoryOfMargedComponent = ({ absoluteContactData }) => {
  const { translate } = useTranslate("NewContactsView");
  const {
    theme: { palette },
  } = useSelectedTheme();

  const [expandedObsoleteContactId, setExpandedObsoleteContactId] =
    useState(null);

  const getAvatarSrc = (item) => {
    const parsedItem = JSON.parse(item);
    const uuid =
      parsedItem?.contact?.contact_image?.["Image Upload"][0]?.uuid || null;
    if (uuid) {
      return getDownloadableLink(uuid);
    } else {
      const gender = parsedItem?.contact?.gender?.lookupItemName;
      return gender === "Female"
        ? "/images/contacts/FemaleAvatar.svg"
        : "/images/contacts/MaleAvatar.svg";
    }
  };

  const displayAbsoulDetails = (obsoleteContactId) => {
    setExpandedObsoleteContactId((prevId) =>
      prevId === obsoleteContactId ? null : obsoleteContactId
    );
  };

  const styles = useStyle();
  return (
    <Box className={styles.wrapperAbsolouteHistories}>
      {absoluteContactData &&
        Array.isArray(absoluteContactData) &&
        absoluteContactData.length > 0 &&
        absoluteContactData?.map((abasolouteItem) => (
          <Box
            className={styles.containerBoardItemAbsoloute}
            key={abasolouteItem?.finalId}
            style={{
              ...(expandedObsoleteContactId ===
                abasolouteItem?.ObsoleteContactId && {
                borderRadius: "12px",
              }),
            }}
          >
            <Box className={styles.containerHeaderofAbsoloute}>
              <Box className={styles.containerLeftHeader}>
                <Box className={styles.containerImgTitle}>
                  <Box className={styles.containerImage}>
                    <img
                      className={styles.imageWrapper}
                      src={getAvatarSrc(abasolouteItem?.imageAvatar)}
                      alt="Avatar"
                    />
                  </Box>
                  <Box className={styles.containerTitle}>
                    <Typography>{translate("Final-contact")}</Typography>
                    <CustomCopyToClipboard
                      data={abasolouteItem?.ObsoleteContactId}
                      childrenData={"#" + abasolouteItem?.ObsoleteContactId}
                      copyDoneShowTime={1000}
                      fontSize="14px"
                    />
                  </Box>
                </Box>
              </Box>
              <CustomIconButton
                variant="text"
                size="none"
                boxShadow="none"
                color="tertiaryColor"
                hideHoverBg
                onClick={() =>
                  displayAbsoulDetails(abasolouteItem?.ObsoleteContactId)
                }
              >
                <DropdownIcon
                  width="20"
                  height="20"
                  fill={
                    palette.foreground.navigation["nav-item-button-icon-fg"]
                  }
                  style={{
                    transform:
                      expandedObsoleteContactId ===
                      abasolouteItem?.ObsoleteContactId
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                />
              </CustomIconButton>
            </Box>
            {expandedObsoleteContactId === abasolouteItem?.ObsoleteContactId && (
              <Box className={styles.containerInfoAbsoloute}>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Title")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact?.title
                      ?.lookupItemName || null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("First-name")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact?.first_name ||
                      null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Last-name")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact?.last_name ||
                      null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Gender")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact?.gender
                      ?.lookupItemName || null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Mobile")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact?.mobile?.phone ||
                      null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Whatsapp-mobile")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact?.whatsapp_mobile
                      ?.phone || null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Other-contact-mobile")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact
                      ?.other_contact_mobile_no?.phone || null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Email-Address")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact?.email_address
                      ?.email || null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Language")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact?.language
                      ?.lookupItemName || null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Contact-class")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact?.contact_class
                      ?.lookupItemName || null}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Multi-Property-Owner")}
                  </Typography>
                  <Typography className={styles.valueInfo}>
                    {abasolouteItem?.ObsoleteContact?.contact
                      ?.multi_property_owner ? (
                      "Yes" && (
                        <CheckIcon
                          width="20"
                          height="20"
                          fill={palette.text.success_primary}
                        />
                      )
                    ) : (
                      <CloseXIcon
                        width="20"
                        height="20"
                        fill={palette.text.error_primary}
                      />
                    )}
                  </Typography>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Contact-Preference")}
                  </Typography>
                  <Box style={{ direction: "ltr", height: "30px" }}>
                    <SocialLinks
                      activeItem={abasolouteItem?.ObsoleteContact?.contact}
                      gap={8}
                    />
                  </Box>
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("Country")}
                  </Typography>
                  {abasolouteItem?.ObsoleteContact?.contact?.country?.lookupItemName.toLowerCase() ===
                  "unknown" ? (
                    <Typography className={styles.valueInfo}>{""}</Typography>
                  ) : (
                    <Typography className={styles.valueInfo}>
                      {abasolouteItem?.ObsoleteContact?.contact?.country
                        ?.lookupItemName || null}
                    </Typography>
                  )}
                </Box>
                <Box className={styles.containerInformationSction}>
                  <Typography className={styles.titleInfo}>
                    {translate("City")}
                  </Typography>

                  {abasolouteItem?.ObsoleteContact?.contact?.city?.lookupItemName.toLowerCase() ===
                  "unknown" ? (
                    <Typography className={styles.valueInfo}>{""}</Typography>
                  ) : (
                    <Typography className={styles.valueInfo}>
                      {abasolouteItem?.ObsoleteContact?.contact?.city
                        ?.lookupItemName || null}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
};
export default ContactAbsolouteHistoryOfMargedComponent;
