import React from "react";

function Tick({ fill, ...restProps }) {
  return (
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0964 0.390037L3.93638 7.30004L2.03638 5.27004C1.68638 4.94004 1.13638 4.92004 0.736381 5.20004C0.346381 5.49004 0.236381 6.00004 0.476381 6.41004L2.72638 10.07C2.94638 10.41 3.32638 10.62 3.75638 10.62C4.16638 10.62 4.55638 10.41 4.77638 10.07C5.13638 9.60004 12.0064 1.41004 12.0064 1.41004C12.9064 0.490037 11.8164 -0.319963 11.0964 0.380037V0.390037Z"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default Tick;
