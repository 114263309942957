import React, { useState } from "react";
import { Box, Menu, MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CustomIconButton, CustomButton } from "..";
import { useIsAr, useSelectedTheme } from "../../../Hooks";

// Icons
import {
  ArrowNarrowDown,
  ArrowNarrowUp,
  CheckIcon,
  DropdownIcon,
} from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function SortingDropdown({ setAdvancedSearchBody, translate, isLoading }) {
  const {
    theme: { palette },
  } = useSelectedTheme();
  const styles = useStyles();
  const theme = useSelector((store) => store.theme);
  const { isAr } = useIsAr(theme.themeDirection);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedField, setSelectedField] = useState("created-On");
  const [selectedOrder, setSelectedOrder] = useState("up");
  const [labelValue, setLabelValue] = useState("created-On");
  const [labelIcon, setLabelIcon] = useState("up");

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setSelectedField(labelValue);
    setSelectedOrder(labelIcon);
    setAnchorEl(null);
  };
  const handleFieldSelect = (field) => {
    setSelectedField(field);
  };

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
  };

  const handleApplyFilter = () => {
    getLabelDetails();
    setAdvancedSearchBody((prev) => ({
      ...prev,
      filterBy: selectedField === "created-On " ? "createdOn" : "updateOn",
      orderBy: selectedOrder === "up" ? 2 : 1,
    }));
    setAnchorEl(null);
  };

  const getLabelDetails = () => {
    if (selectedField === "created-On") {
      setLabelValue("created-On");
    } else {
      setLabelValue("last-updated");
    }

    if (selectedOrder === "down") {
      setLabelIcon("down");
    } else {
      setLabelIcon("up");
    }
  };

  const anchorOrigin = isAr
    ? { vertical: "bottom", horizontal: "left" }
    : { vertical: "bottom", horizontal: "right" };

  const transformOrigin = isAr
    ? { vertical: "top", horizontal: "left" }
    : { vertical: "top", horizontal: "right" };

  return (
    <>
      <CustomIconButton
        classes={styles.buttonSortWrapper}
        variant="outlined"
        size="md"
        boxShadow="none"
        hideHoverBg={true}
        color="secondary"
        isDropDown={true}
        id="sorting-dropdown-btn"
        aria-controls={open ? "sorting-dropdown-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleMenuOpen}
        disabled={isLoading}
      >
        <Box className={styles.buttonContainer}>
          <span className={styles.buttonLabel}>{translate("sort-by")}</span>
          <span className={styles.selectedValueWrapper}>
            <span className={styles.selectedValue}>
              {translate(labelValue)}
            </span>
            {labelIcon === "up" ? (
              <ArrowNarrowUp
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            ) : (
              <ArrowNarrowDown
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            )}
          </span>
        </Box>
        <DropdownIcon
          width="20"
          height="20"
          fill={palette.button.secondary_fg}
        />
      </CustomIconButton>

      <Menu
        id="custom-sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        elevation={0}
        PaperProps={{
          className: styles.customMenu,
        }}
        MenuListProps={{
          className: styles.customMenuList,
        }}
      >
        <Box className={styles.menuSection}>
          <MenuItem
            disableRipple
            selected={selectedField === "updateOn"}
            onClick={() => handleFieldSelect("updateOn")}
            className={styles.customMenuItem}
          >
            <Box className={styles.labelWrapper}>
              <span className={styles.label}>{translate("last-updated")}</span>
              {selectedField === "updateOn" && (
                <CheckIcon
                  width="16"
                  height="16"
                  fill={palette.foreground.brandPrimary}
                />
              )}
            </Box>
          </MenuItem>
          <MenuItem
            disableRipple
            selected={selectedField === "created-On"}
            onClick={() => handleFieldSelect("created-On")}
            className={styles.customMenuItem}
          >
            <Box className={styles.labelWrapper}>
              <span className={styles.label}>{translate("created-On")}</span>
              {selectedField === "created-On" && (
                <CheckIcon
                  width="16"
                  height="16"
                  fill={palette.foreground.brandPrimary}
                />
              )}
            </Box>
          </MenuItem>
        </Box>
        <Box className={styles.menuDivider} />
        <Box className={styles.menuSection}>
          <MenuItem
            disableRipple
            selected={selectedOrder === "down"}
            onClick={() => handleOrderSelect("down")}
            className={styles.customMenuItem}
          >
            <Box className={styles.labelWrapper}>
              <Box className={styles.orderWrapper}>
                <ArrowNarrowDown
                  width="16"
                  height="16"
                  fill={palette.foreground.quarterary}
                />
                <span>{translate("asc")}</span>
              </Box>
              {selectedOrder === "down" && (
                <CheckIcon
                  width="16"
                  height="16"
                  fill={palette.foreground.brandPrimary}
                />
              )}
            </Box>
          </MenuItem>
          <MenuItem
            disableRipple
            selected={selectedOrder === "up"}
            onClick={() => handleOrderSelect("up")}
            className={styles.customMenuItem}
          >
            <Box className={styles.labelWrapper}>
              <Box className={styles.orderWrapper}>
                <ArrowNarrowUp
                  width="16"
                  height="16"
                  fill={palette.foreground.quarterary}
                />
                <span>{translate("desc")}</span>
              </Box>
              {selectedOrder === "up" && (
                <CheckIcon
                  width="16"
                  height="16"
                  fill={palette.foreground.brandPrimary}
                />
              )}
            </Box>
          </MenuItem>
        </Box>
        <Box className={styles.menuDivider} />
        <Box className={styles.actionsWrapper}>
          <CustomButton
            boxShadow="xs"
            size="md"
            variant="outlined"
            color="secondary"
            style={{ width: "105px" }}
            onClick={() => {
              setSelectedField(labelValue);
              setSelectedOrder(labelIcon);
              setAnchorEl(null);
            }}
          >
            {translate("cancel")}
          </CustomButton>
          <CustomButton
            boxShadow="xs"
            size="md"
            variant="contained"
            color="primary"
            style={{ width: "105px" }}
            onClick={handleApplyFilter}
          >
            {translate("apply")}
          </CustomButton>
        </Box>
      </Menu>
    </>
  );
}

export default SortingDropdown;
