import React from "react";

function VedioIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.75 4C4.75 2.20508 6.20508 0.75 8 0.75H24C24.1212 0.75 24.2375 0.798159 24.3232 0.883885L35.1161 11.6768C35.2018 11.7625 35.25 11.8788 35.25 12V36C35.25 37.7949 33.7949 39.25 32 39.25H8C6.20507 39.25 4.75 37.7949 4.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M24 0.5V8C24 10.2091 25.7909 12 28 12H35.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <g clip-path="url(#clip0_4916_411641)">
        <path
          d="M20 31.5C24.1421 31.5 27.5 28.1421 27.5 24C27.5 19.8579 24.1421 16.5 20 16.5C15.8579 16.5 12.5 19.8579 12.5 24C12.5 28.1421 15.8579 31.5 20 31.5Z"
          stroke="#155EEF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.125 21.724C18.125 21.366 18.125 21.1871 18.1998 21.0871C18.265 21.0001 18.3648 20.9456 18.4733 20.9378C18.5978 20.9289 18.7483 21.0257 19.0495 21.2193L22.5899 23.4953C22.8512 23.6633 22.9818 23.7472 23.027 23.854C23.0664 23.9474 23.0664 24.0526 23.027 24.146C22.9818 24.2527 22.8512 24.3367 22.5899 24.5047L19.0495 26.7807C18.7483 26.9743 18.5978 27.0711 18.4733 27.0622C18.3648 27.0544 18.265 26.9999 18.1998 26.9129C18.125 26.8129 18.125 26.634 18.125 26.276V21.724Z"
          stroke="#155EEF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4916_411641">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(11 15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VedioIcon;
