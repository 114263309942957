import React, { useRef, useContext } from "react";
import { Paper, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  useSelectedTheme,
  useOutsideClick,
  useTranslate,
} from "../../../Hooks";
import { CloseXIcon } from "../../../assets/icons";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";

// Styles
import useStyles from "./styles";

const AddContactModal = ({ onClose, isBulkUpdateModal }) => {
  const styles = useStyles();
  const { setAdvancedSearchBody } = useContext(ContactLayoutContext);
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { isDarkMode } = useSelector((state) => state.theme);
  const history = useHistory();
  const { translate } = useTranslate("NewContactsView");

  const modalRef = useRef(null);
  const iconRef = useRef(null);

  useOutsideClick({
    ref: modalRef,
    handler: () => onClose,
    iconRef,
  });

  const handleSelectTypeChange = (newValue) => {
    setAdvancedSearchBody((prev) => {
      const { contactTypeStr, ...restCriteria } = prev.criteria;

      if (!newValue && !contactTypeStr) return prev;

      return {
        ...prev,
        criteria: !newValue
          ? restCriteria
          : {
              ...restCriteria,
              contactTypeStr: [
                {
                  searchType: newValue === "Individual" ? 1 : 2,
                  value: newValue,
                },
              ],
            },
      };
    });
    onClose();
  };

  return (
    <Box className={styles.modalContainer}>
      <Paper ref={modalRef} className={styles.modalPaper}>
        <Box className={styles.content}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span className={styles.text}>
              {isBulkUpdateModal
                ? translate("selectContactType")
                : translate("addNewContacts")}
            </span>

            <CloseXIcon
              onClick={onClose}
              style={{ cursor: "pointer" }}
              width="24"
              height="24"
              fill={palette.foreground.quinary}
            />
          </Box>

          {!isBulkUpdateModal && <p>{translate("addContactsTitle")}</p>}
        </Box>

        <Box className={styles.container}>
          <Box
            className={styles.card}
            onClick={() => {
              if (isBulkUpdateModal) {
                handleSelectTypeChange("Individual");
              } else {
                history.push("/home/Contacts-CRM/add?formType=1");
              }
            }}
          >
            <img
              width="64px"
              height="64px"
              src={`/images/contacts/individualType${
                isDarkMode ? "Dark" : "Light"
              }Icon.svg`}
              alt="IndividualTypeIcon"
            />

            <span>{translate("individualText")}</span>
            <p>{translate("individualContactsText")}</p>
          </Box>

          <Box
            className={styles.card}
            onClick={() => {
              if (isBulkUpdateModal) {
                handleSelectTypeChange("Corporate");
              } else {
                history.push("/home/Contacts-CRM/add?formType=2");
              }
            }}
          >
            <img
              src={`/images/contacts/corporateType${
                isDarkMode ? "Dark" : "Light"
              }Icon.svg`}
              width="64px"
              height="64px"
              alt="CorporateTypeIcon"
            />

            <span>{translate("corporateText")}</span>
            <p>{translate("corporateContactsText")}</p>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddContactModal;
