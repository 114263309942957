import React from 'react';
import { AMLStatusEnum } from '../../../Enums';

export const headerData = (t, AMLTransactionStatusList) => [
  {
    id: 1,
    label: 'ref-no',
    input: 'unitRefNumber',
    component: (item) => <span>{item && item.unitReferenceNo}</span> || '',
  },
  {
    id: 2,
    label: 'property',
    input: 'propertyName',
  },

  {
    id: 3,
    label: 'transaction-status',
    input: 'transactionStatusId',
    withSelectFilter: true,
    optionFilterList: AMLTransactionStatusList,
    component: (item) =>
      (
        <span>
          {item.transactionStatus === 'Pass' ||
          item.transactionStatus === 'Approved' ? (
            <span className='pass-lapel'>{t(`${item.transactionStatus}`)}</span>
          ) : item.transactionStatus === 'Fail' ||
            item.transactionStatus === 'Rejected' ? (
            <div className='Fail-lapel'>{t(`${item.transactionStatus}`)}</div>
          ) : item.transactionStatus === 'Pending' ||
            item.transactionStatus === 'WithdrawTransaction' ||
            item.transactionStatus === 'ReassignedToRequester' ||
            item.transactionStatus === 'NeedRevision' ||
            item.transactionStatus === 'Resubmitted' ||
            item.transactionStatus === 'AutoWithdraw' ? (
            <div className='Pending-lapel'>
              {t(`${item.transactionStatus}`)}
            </div>
          ) : (
            ''
          )}
        </span>
      ) || '',
  },
  {
    id: 4,
    label: 'status',
    input: 'statusId',
    withSelectFilter: true,
    optionFilterList: [
      {
        key: AMLStatusEnum[2].key,
        value: AMLStatusEnum[2].name,
      },
      {
        key: AMLStatusEnum[3].key,
        value: AMLStatusEnum[3].name,
      },
      {
        key: AMLStatusEnum[10].key,
        value: AMLStatusEnum[10].name,
      },
    ],
    component: (item) => <span>{item && item.status}</span> || '',
  },
  {
    id: 5,
    label: 'community',
    input: 'communityName',
  },
  {
    id: 6,
    label: 'unit-no',
    input: 'unitNumber',
  },
  {
    id: 7,
    label: 'tenant',
    input: 'tenantName',
    component: (item) => (
      <span>
        {item.tenantNames &&
          item.tenantNames.map((element, index) => (
            <span key={`tenantNamesRef${index + 1}`}>
              {`${element}${
                (index < item.tenantNames.length - 1 && ', ') || ''
              }`}
            </span>
          ))}
      </span>
    ),
  },
  {
    id: 8,
    label: 'tenant-agent',
    input: 'tenantAgent',
    component: (item) =>
      (
        <span>
          {item &&
            item.tenantAgents &&
            item.tenantAgents.map((item) => `${item.agentName} , `)}
        </span>
      ) || '',
  },
  {
    id: 98,
    label: 'tenant-agent-staff-id',
    input: 'tenantStaffId',
    component: (item) =>
      (
        <span>
          {item &&
            item.tenantAgents &&
            item.tenantAgents.map((item) =>
              item.staffId ? `${item.staffId} ,  ` : 'N/A ,  '
            )}
        </span>
      ) || '',
  },
  {
    id: 10,
    label: 'landlord',
    input: 'landlordName',
  },
  {
    id: 11,
    label: 'landlord-agent',
    input: 'landlordAgent',
    component: (item) =>
      (
        <span>
          {item &&
            item.landlordAgents &&
            item.landlordAgents.map((item) => `${item.listingAgentName} , `)}
        </span>
      ) || '',
  },
  {
    id: 12,
    label: 'landlord-agent-staff-id',
    input: 'landlordStaffId',
    component: (item) =>
      (
        <span>
          {item &&
            item.landlordAgents &&
            item.landlordAgents.map((item) =>
              item.staffId ? `${item.staffId} ,  ` : 'N/A ,  '
            )}
        </span>
      ) || '',
  },
  {
    id: 13,
    isSortable: true,
    label: 'rent/year',
    input: 'rentPerYear',
  },
  {
    id: 14,
    input: 'startDate',
    label: 'contract-start-date',
    dateFormat: 'DD/MM/YYYY',
    isDate: true,
  },
  {
    id: 15,
    input: 'endDate',
    label: 'contract-end-date',
    dateFormat: 'DD/MM/YYYY',
    isDate: true,
  },
  {
    id: 16,
    isSortable: true,
    label: 'contract-rent',
    input: 'contractRent',
  },
  {
    id: 17,
    isSortable: true,
    label: 'transaction-date',
    input: 'transactionEntryDate',
    isDate: true,
  },
  {
    id: 18,
    isSortable: true,
    label: 'Transaction ID',
    input: 'unitTransactionId',
  },
  {
    id: 19,
    label: 'tenant-branch',
    input: 'tenantBranch',
  },
  {
    id: 20,
    label: 'landlord-branch',
    input: 'landlordBranch',
  },
  {
    id: 21,
    label: 'notes',
    input: 'invoiceNotes',
  },
];
