import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Avatar, Divider } from '@material-ui/core';
import ReactQuill from 'react-quill';
import clsx from 'clsx';

import { CustomAutocomplete } from '../../../CustomAutocomplete';
import { CustomSwitch } from '../../../CustomSwitch';
import { CustomInput } from '../../../CustomInput';
import { DropzoneComponent } from '../../../DropzoneComponent';

import { CustomCard } from '../../../CustomCard';
import { GetFileTypeIcon } from '../../../../../Views/Home/NewContactsView/ContactOverview/UI';
import { DeleteIcon } from '../../../../../assets/icons';
import { useSelectedTheme } from '../../../../../Hooks';
import ChipInput from 'material-ui-chip-input';
import { emailExpression } from '../../../../../Utils';
import CustomChipInput from './CustomChipInput';

// Styles
import useStyles from './styles';

function ContactEmailDialog({
  setState,
  getContacts,
  contactList,
  state,
  setSelected,
  selected,
}) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const searchTimer = useRef(null);

  const [showCC, setShowCC] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesAccepted, setFilesAccepted] = useState([]);
  const [showHelperText, setShowHelperText] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getContacts(value);
    }, 1000);
  };

  const handleSelectionChange = (event, newValue) => {
    setSelected(
      newValue.map((contact) => ({
        contactsId: contact.contactsId,
        contact: {
          first_name: contact.contact?.first_name,
          last_name: contact.contact?.last_name,
          company_name: contact.contact?.company_name,
        },
      }))
    );
    setState((prev) => ({
      ...prev,
      contactIds: newValue.map((contact) => contact.contactsId),
    }));
  };

  const customToolbar = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ];

  const modules = {
    toolbar: customToolbar,
  };

  const handleFilesAccepted = (files) => {
    setFilesAccepted(files);
  };

  const handleFileUploadMapping = () => {
    if (uploadedFileId) {
      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        filesAccepted[0],
      ]);
      setUploadedFiles((prevUploadedFiles) => {
        const updatedFiles = [...prevUploadedFiles];
        if (updatedFiles?.length > 0) {
          updatedFiles[updatedFiles.length - 1] = {
            ...updatedFiles[updatedFiles.length - 1],
            fileId: uploadedFileId,
            fileSize: updatedFiles[updatedFiles?.length - 1]?.size,
            fileName: updatedFiles[updatedFiles?.length - 1]?.path,
            fileType: updatedFiles[updatedFiles?.length - 1]?.type,
          };
        }
        return updatedFiles;
      });
    }
  };

  const handleDeleteUploadedFile = (index) => {
    setUploadedFiles((prevUploadedFiles) =>
      prevUploadedFiles.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    if (uploadedFileId) {
      setState((prev) => ({
        ...prev,
        files: uploadedFiles?.map((file) => ({
          uuid: uploadedFileId || 'unknown-uuid',
          fileName: file.fileName || 'unknown-file',
        })),
      }));
    }
  }, [uploadedFileId, uploadedFiles]);
  useEffect(() => {
    handleFileUploadMapping();
  }, [uploadedFileId]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    setIsFocused(false);
  };

  return (
    <Box className={styles.preferenceDialogWrapper}>
      <Box className={styles.rowItem}>
        <Typography className={styles.rowLabel}>To</Typography>
        <Box className={styles.rowField}>
          <CustomAutocomplete
            variant='default'
            multiple={true}
            placeholder='Select contacts'
            options={contactList}
            renderOption={(option) =>
              option.contact?.company_name ||
              `${option.contact?.first_name ?? ''} ${
                option.contact?.last_name ?? ''
              }`.trim()
            }
            getOptionLabel={(option) =>
              option.contact?.company_name ||
              `${option.contact?.first_name ?? ''} ${
                option.contact?.last_name ?? ''
              }`.trim()
            }
            chipsLabel={(option) =>
              option.contact?.company_name ||
              `${option.contact?.first_name ?? ''} ${
                option.contact?.last_name ?? ''
              }`.trim()
            }
            selectedValues={selected || []}
            onInputKeyUp={(event) => searchHandler(event)}
            onChange={handleSelectionChange}
            getChipIcon={(item) => (
              <Avatar
                className={styles.defaultAvatar}
                src={
                  item?.contact_type_id === 2
                    ? '/images/contacts/CorporateAvatar.svg'
                    : item?.gender?.lookupItemName === 'Male'
                    ? '/images/contacts/MaleAvatar.svg'
                    : '/images/contacts/FemaleAvatar.svg'
                }
              />
            )}
          />
        </Box>
      </Box>
      <Divider />
      <Box className={styles.rowItem}>
        <Typography className={styles.rowLabel}>CC & BCC</Typography>
        <Box className={styles.CCContainer}>
          <CustomSwitch
            size='md'
            label='Show'
            onChange={(e) => {
              const isChecked = e.target.checked;
              setShowCC(isChecked);
            }}
          />
          {showCC && (
            <>
              <Box className={styles.inputWrapper}>
                <Box
                  className={clsx(styles.customChipInputWrapper, {
                    [styles.inputContainerError]: showHelperText,
                    [styles.inputContainerFocused]: isFocused,
                  })}
                >
                  <ChipInput
                    variant='standard'
                    chipRenderer={(item) => <CustomChipInput item={item} />}
                    className={styles.customChipInput}
                    placeholder={'Enter CC'}
                    value={state.ccc}
                    onAdd={(chip) => {
                      if (!chip) return;
                      if (emailExpression.test(chip)) {
                        setState((items) => {
                          items.ccc.push(chip);
                          return { ...items };
                        });
                      } else setShowHelperText(true);
                    }}
                    onDelete={(chip, itemIndex) => {
                      setState((items) => {
                        items.ccc.splice(itemIndex, 1);
                        return { ...items };
                      });
                    }}
                    classes={{
                      root: styles.removeBeforeAfter,
                      chipContainer: styles.chipWrapper,
                      input: styles.inputWrapper,
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </Box>
                {showHelperText && (
                  <span className={styles.errorMessage}>
                    invalid-email-format!
                  </span>
                )}
              </Box>
              <Box className={styles.inputWrapper}>
                <Box
                  className={clsx(styles.customChipInputWrapper, {
                    [styles.inputContainerError]: showHelperText,
                    [styles.inputContainerFocused]: isFocused,
                  })}
                >
                  <ChipInput
                    variant='standard'
                    chipRenderer={(item) => <CustomChipInput item={item} />}
                    className={styles.customChipInput}
                    placeholder={'Enter BCC'}
                    value={state.bcc}
                    onAdd={(chip) => {
                      if (!chip) return;
                      if (emailExpression.test(chip)) {
                        setState((items) => {
                          items.bcc.push(chip);
                          return { ...items };
                        });
                      } else setShowHelperText(true);
                    }}
                    onDelete={(chip, itemIndex) => {
                      setState((items) => {
                        items.bcc.splice(itemIndex, 1);
                        return { ...items };
                      });
                    }}
                    classes={{
                      root: styles.removeBeforeAfter,
                      chipContainer: styles.chipWrapper,
                      input: styles.inputWrapper,
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </Box>
                {showHelperText && (
                  <span className={styles.errorMessage}>
                    invalid-email-format!
                  </span>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Divider />
      <Box className={styles.rowItem}>
        <Typography className={styles.rowLabel}>Subject</Typography>
        <Box className={styles.rowField}>
          <CustomInput
            placeholder={'Enter'}
            type='text'
            hasSearchIcon={false}
            value={state.subject}
            inputContainerOverrideStyles={styles.inputFullWidth}
            onChange={(event) => {
              const { value } = event.target;
              setState((prev) => ({ ...prev, subject: value }));
            }}
          />
        </Box>
      </Box>
      <Box className={styles.rowItem}>
        <Typography className={styles.rowLabel}>Email</Typography>
        <Box className={styles.rowField}>
          <ReactQuill
            idRef='emailRef'
            placeholder={'Enter a message '}
            modules={modules}
            className={styles.toolbar}
            onChange={(event) =>
              setState((prev) => ({ ...prev, message: event }))
            }
            value={state.message}
            
          />
        </Box>
      </Box>
      <Divider />
      <Box className={styles.rowItem}>
        <Typography className={styles.rowLabel}>Attachment</Typography>
        <Box className={styles.rowField}>
          <DropzoneComponent
            setUploadedFileId={setUploadedFileId}
            onFilesAccepted={handleFilesAccepted}
            accept='image/*,
      video/*,
      application/pdf,
      application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
      application/vnd.ms-excel,
      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
      text/plain,
      application/vnd.adobe.figma,
      application/illustrator,
      application/photoshop,
      application/vnd.adobe.indesign,
      application/aftereffects,
      audio/mp3,
      audio/wav,
      video/mp4,
      video/mpeg,
      video/avi,
      video/mkv,
      text/html,
      text/css,
      application/rss+xml,
      application/sql,
      application/javascript,
      application/json,
      text/x-java-source,
      application/xml,
      application/x-msdownload,
      application/x-dmg,
      application/zip,
      application/x-rar-compressed'
            title={'SVG-PNG-JPG-or-GIF-max-800x400px'}
          />
          {uploadedFiles?.length > 0 && (
            <Box className={styles.uploadedFilesContainer}>
              {uploadedFiles
                .filter((file) => file?.fileType)
                .map((uploadFile, index) => (
                  <CustomCard
                    boxShadow='xs'
                    borderRadius='xl'
                    borderColor='secondary'
                    classes={styles.uploadedFileCard}
                    key={index}
                  >
                    {GetFileTypeIcon(uploadFile?.fileType)}
                    <Box className={styles.uploadedFileDetailsContainer}>
                      <Box className={styles.fileHeaderContainer}>
                        <Typography className={styles.fileNameText}>
                          {uploadFile?.fileName}
                        </Typography>
                        <DeleteIcon
                          width='20'
                          height='20'
                          className={styles.DeleteIcon}
                          style={{ cursor: 'pointer' }}
                          fill={palette.button.secondary_fg}
                          onClick={() => handleDeleteUploadedFile(index)}
                        />
                      </Box>
                      <Typography className={styles.fileSizeText}>
                        {uploadFile.fileSize
                          ? `${uploadFile?.fileSize} bytes`
                          : 'Unknown Size'}
                      </Typography>
                    </Box>
                  </CustomCard>
                ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ContactEmailDialog;
