import { UnitInvoicePaymentDue } from '../../../UnitsView/UnitsReservationView/Sections/UnitInvoicePaymentDue';
import {
  Details,
  ReferenceDetails,
  Earnings,
  ChequeRequestsView,
} from '../Sections';
import { Documents } from '../../../SalesTransactionsView/SalesTransactionsProfile/Sections';

export const LeasingTransactionsProfileTabs = [
  {
    label: 'details',
    component: Details,
  },
  {
    label: 'reference-details',
    component: ReferenceDetails,
  },
  {
    label: 'earnings',
    component: Earnings,
  },
  {
    label: 'invoices-payments-due',
    component: UnitInvoicePaymentDue,
  },
  {
    label: 'cheque-requests',
    component: ChequeRequestsView,
  },
  {
    label: 'documents',
    component: Documents,
    permissionsList: [],
    permissionsId:null
  },
];
