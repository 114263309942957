
import { ClassificationsContactEnum } from '../../../../../../../Enums';
import { GetContacts, GetCorporateContacts } from '../../../../../../../Services';

let timer = null;

export const ContactRule = async (item, value , setItemValue , setIsLoading) => {
  if (item.data.searchKey !== 'contact') return;
  setIsLoading(true) ;
  
  timer = setTimeout(async () => {
    let rs = null;
    let classIndex = -1;
    item.data.enum = [];
    if (item.data.dependOn) classIndex = ClassificationsContactEnum.findIndex((f) => item.data.dependOn.toLowerCase() === f.name.toLowerCase());
    if (item.data.dependOn === 'company') rs = await GetCorporateContacts({ pageIndex: 0, pageSize: 10, search: value });
    else if (classIndex !== -1) {

      rs = await GetContacts({
        pageIndex: 0, pageSize: 10, search: value, classificationId: ClassificationsContactEnum[classIndex].Id
      });
    } else rs = await GetContacts({ pageIndex: 0, pageSize: 10, search: value, isAdvance: true });
    setIsLoading(false) ;

 let list = [];

    if (!rs || !rs.result) return;
    rs.result.map((element) => {
      if (!element.contact) return;
      if (element.contact.contact_type_id !== 2) {
        list.push({
          id: element.contactsId,
          name: `${element.contact.first_name} ${element.contact.last_name}`,
          phone: (element.contact.mobile && element.contact.mobile.phone) || '',
          type: element.contact.contact_type_id,
        });
      } else {
        list.push({
          id: element.contactsId,
          name: element.contact.company_name,
          phone: (element.contact.landline_number && element.contact.landline_number.phone) || '',
          type: element.contact.contact_type_id,
        });
      }
   setItemValue(list|| []);
   item.data.enum = list ;
    });

  }, 500);
};

