import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerReassignLead: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
    },
    containerFiledItem: {
      width: "100%",
    },
    titleItem: {
      maxWidth: "280px",
      minWidth: "160px",
    },
    switchItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    containerAutoCompletSwitch: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
  };
});
