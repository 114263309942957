import React, { memo, useMemo } from 'react';
import Styles from './SideSectionDuplicated.module.scss';
import {
  getDownloadableLink,
  getFirstLastNameLetters,
} from '../../../../../Helper';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents';
import { Avatar, ButtonBase } from '@material-ui/core';

const SideSectionDuplicated = memo(({ data, claimHandler }) => {
 

  const listingAgentMapper = () => {
    if (!data.hasListingAgent) return null;
    return {
      name: data?.listingAgentName,
      branch: data?.listingAgentBranchName,
      mobile: data?.listingAgentMobile,
      email: data?.listingAgentEmail,
    };
  };

  const developerDetailsMapper = () => {
    return {
      developerName: data?.developerName || '',
      developerImage: data?.developerImage || '',
      developerWebsite: data?.developerWebsite || '',
      developerEmail: data?.developerEmail || '',
      developerLandline: data?.developerLandline || '',
    };
  };

  const listingAgent = useMemo(() => listingAgentMapper(), [data]);
  const developerDetails = useMemo(() => developerDetailsMapper(), [data]);
  return (
    <>
      <div className={Styles.Content_Box}>
        <div>
          <p>Listing agent details</p>
        </div>
        {listingAgent ? (
          <>
            <div className='d-flex-v-center'>
              <Avatar
                className={Styles.Avatar}
                src={
                  listingAgent?.profileImage
                    ? getDownloadableLink(listingAgent.profileImage)
                    : ''
                }
              >
                {getFirstLastNameLetters(listingAgent.name || '')}
              </Avatar>
              <div className={Styles.Avatar_Text}>
                <span>{listingAgent.name || '-'}</span>
                <span>{`Branch name - ${listingAgent.branch}`}</span>
              </div>
            </div>
            <div className={Styles.Info_Pair}>
              <span>Mobile</span>
              <span>
                {listingAgent.mobile ? (
                  <CopyToClipboardComponents
                    data={listingAgent.mobile}
                    childrenData={listingAgent.mobile}
                  />
                ) : (
                  '-'
                )}
              </span>
            </div>
            <div className={Styles.Info_Pair}>
              <span>Email</span>
              {listingAgent.email ? (
                <CopyToClipboardComponents
                  data={listingAgent.email}
                  childrenData={listingAgent.email}
                />
              ) : (
                '-'
              )}
            </div>
          </>
        ) : (

            <ButtonBase
            className={`MuiButtonBase-root btns theme-solid ${Styles.claim_btns}`}
            onClick={() => {
              claimHandler();
            }}
          >
            {'Claim Unit'}
          </ButtonBase>
      
        )}
      </div>

      <div className={Styles.Content_Box}>
        <div>
          <p>Developer details</p>
        </div>
        <div className='d-flex-v-center'>
          <Avatar
            className={Styles.Avatar}
            src={
              developerDetails?.developerImage
                ? getDownloadableLink(developerDetails.developerImage)
                : ''
            }
          >
            {getFirstLastNameLetters(developerDetails.developerName || '')}
          </Avatar>
          <div className={Styles.Avatar_Text}>
            <span>{developerDetails.developerName || '-'}</span>
            <span>{developerDetails.developerWebsite || '-'}</span>
          </div>
        </div>
        <div className={Styles.Info_Pair}>
          <span>Landline</span>
          <span>
            {developerDetails.developerLandline ? (
              <CopyToClipboardComponents
                data={developerDetails.developerLandline}
                childrenData={developerDetails.developerLandline}
              />
            ) : (
              '-'
            )}
          </span>
        </div>
        <div className={Styles.Info_Pair}>
          <span>Email</span>
          <span>
            {developerDetails.developerEmail ? (
              <CopyToClipboardComponents
                data={developerDetails.developerEmail}
                childrenData={developerDetails.developerEmail}
              />
            ) : (
              '-'
            )}
          </span>
        </div>
      </div>
    </>
  );
});

export default SideSectionDuplicated;
