import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { Box } from "@material-ui/core";
import ContactOriginalHistoryOfMargedComponent from "../ContactOriginalHistoryOfMargedComponent/ContactOriginalHistoryOfMargedComponent";
import ContactAbsolouteHistoryOfMargedComponent from "../ContactAbsolouteHistoryOfMargedComponent/ContactAbsolouteHistoryOfMargedComponent";

const ContactHistoryOfMergedComponent = ({ activeItem }) => {
  const [originalContactData, setOriginalContactData] = useState([]);
  const [absoluteContactData, setAbsoluteContactData] = useState([]);

  const mappingAbsoluteOriginalData = () => {
    const orginalContactData = activeItem?.orginalContactData
      ? {
          ...JSON.parse(activeItem.orginalContactData),
          finalId: activeItem?.finalId || null,
        }
      : null;

    const obsoleteContactDetails =
      activeItem?.obsoleteContactData?.map((obsoleteContact) => ({
        ObsoleteContactId: obsoleteContact.ObsoleteContactId,
        ObsoleteContact: JSON.parse(obsoleteContact.ObsoleteContact),
        imageAvatar: activeItem?.orginalContactData,
      })) || [];

    setOriginalContactData(orginalContactData);
    setAbsoluteContactData(obsoleteContactDetails);
  };

  useEffect(() => {
    mappingAbsoluteOriginalData();
  }, []);
  const styles = useStyles();
  return (
    <>
      <ContactOriginalHistoryOfMargedComponent
        originalContactData={originalContactData}
      />
      <ContactAbsolouteHistoryOfMargedComponent
        absoluteContactData={absoluteContactData}
      />
    </>
  );
};

export default ContactHistoryOfMergedComponent;
