import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerTimelinePrefixContent: {
      minWidth: "60px",
      marginInlineEnd: "12px",
      marginBottom:'15px'
    },
    dayTitle: {
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "28px",
      color: theme.palette.foreground.senary,
    },
    yearTitle: {
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "30px",
      color: theme.palette.foreground.senary,
    },
    timeTitle: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      color: theme.palette.foreground.senary,
    },
    wrapperHistoryTimelineItemContent: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      padding: "12px 0px 12px 24px",
      width: "468px",
      marginBottom: "10px",
    },
    HistoryTimelineItemContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    containerHistoryInformation: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    containerHeaderHistory: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      alignItems: "center",
    },
    containerTypeHistory: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      alignItems: "center",
    },
    containerImage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid rgba(0, 0, 0, 0.08)`,
      borderRadius: "99999px",
    },
    imageWrapper: {
      height: "24px",
      width: "24px",
      borderRadius: "99999px",
    },
    createdByTitle: {
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "24px",
      color: theme.palette.text.secondary,
    },
    typeValue: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      color: theme.palette.text.primary,
    },
    typeTitle: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
    containerTableContactFieldChange: {
      width: "455px",
      border: `1px solid rgba(0, 0, 0, 0.08)`,
      borderRadius: "8px",
    },
    headerTable: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      minWidth: "70px",
      backgroundColor: theme.palette.background.secondary,
      padding: "12px 24px",
      textAlign: "center",
    },
    bodyTable: {
      display: "flex",
      flexDirection: "row",
      padding: "24px 24px",
      borderBottom: `1px solid rgba(0, 0, 0, 0.08)`,
    },
    tableCellTitle: {
      flex: "1",
      minWidth: "70px",
      textAlign: "center",
    },
    tableCellValue: {
      flex: "1",
      minWidth: "70px",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  };
});
