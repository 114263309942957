export const SalesTransactionsWithAMLPermissions = {
  ViewSalesTransactionsWithAML: {
    permissionsId: "a584947a-e5b7-4523-ec35-08dd2fb527c1",
    permissionsName: " View sales transactions with AML",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewTransactionDetails: {
    permissionsId: "b3db5541-06d6-40ae-ec36-08dd2fb527c1",
    permissionsName: "View transaction Details",
    description: null,
    componentsId: null,
    components: null,
  },
  EditTransactions: {
    permissionsId: "5251ead1-ab35-48c0-ec37-08dd2fb527c1",
    permissionsName: "Edit Transactions",
    description: null,
    componentsId: null,
    components: null,
  },
};
