import React from "react";

function Star01({ fill, ...restProps }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M7.72 1.049c-.333.111-.52.286-.748.704-.086.158-.382.663-.657 1.123-.276.46-.515.886-.531.947a.77.77 0 0 0 .264.756.74.74 0 0 0 .74.099c.191-.08.299-.219.723-.928.229-.383.43-.712.448-.731.024-.026.182.272.727 1.374.383.774.733 1.451.778 1.504.113.134.336.277.51.326.08.023.811.138 1.624.257.814.118 1.482.218 1.487.222.004.005-.371.422-.834.927-.517.563-.869.969-.912 1.051a.744.744 0 0 0 .166.862c.168.147.249.177.482.177.357.001.346.01 1.354-1.089.485-.53 1.016-1.102 1.178-1.271.212-.222.32-.359.388-.492.089-.176.093-.197.093-.466 0-.249-.008-.298-.071-.432-.14-.299-.406-.505-.769-.594a60.31 60.31 0 0 0-1.889-.294 91.667 91.667 0 0 1-1.723-.259 200.47 200.47 0 0 1-.75-1.515c-.409-.829-.785-1.583-.837-1.676-.215-.388-.527-.594-.92-.609a1.09 1.09 0 0 0-.321.027m-5.912.24a.738.738 0 0 0-.421 1.115c.042.064 2.799 2.837 6.125 6.162 6.645 6.643 6.139 6.162 6.488 6.162a.71.71 0 0 0 .73-.728c0-.346.479.157-6.204-6.526-4.862-4.861-6.11-6.096-6.206-6.138a.81.81 0 0 0-.512-.047m.299 4.029c-.578.103-.863.282-1.036.651-.063.133-.071.183-.071.432 0 .264.005.291.09.466.083.17.164.258.934 1.012.463.455 1.068 1.049 1.344 1.322l.502.495-.293 1.719a168.07 168.07 0 0 0-.31 1.852c-.04.32.096.711.321.918.309.285.699.356 1.119.204.088-.032.86-.427 1.716-.877.855-.45 1.565-.819 1.577-.819.011 0 .646.329 1.41.731.764.401 1.437.749 1.497.773.251.101.575.03.774-.169a.708.708 0 0 0 .212-.535c0-.196-.122-.452-.264-.555-.202-.146-3.215-1.708-3.375-1.75-.383-.1-.44-.078-2.011.747-.739.389-1.35.7-1.358.692-.008-.008.098-.665.235-1.461.168-.971.25-1.512.249-1.646a.978.978 0 0 0-.22-.633c-.056-.07-.595-.606-1.198-1.192a148.178 148.178 0 0 1-1.098-1.071c0-.003.038-.045.084-.095a.736.736 0 0 0-.184-1.149c-.169-.095-.359-.113-.646-.062"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default Star01;
