import React, { useCallback, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DialogComponent, Inputs, Spinner } from "../../../../../../Components";
import { UpdateLeadContactNameAPI } from "../../../../../../Services";
import {
  GlobalTranslate,
  showError,
  showSuccess,
  CheckContactWithSomeConditions,
  showWarn,
} from "../../../../../../Helper";
import { NewValuesSection } from "../../../../UnitsBulkAssignView/Sections";

const parentTranslationPath = "";
const translationPath = "LeadsView:utilities.contactleadsDialog.";
export const ContactLeadDialog = ({
  isOpen,
  onClose,
  onSave,
  leadId,
  contactId,
  contactName,
}) => {
  const { t } = useTranslation("LeadsView");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [helperText, setHelperText] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
  });
  const [contactFields, setContactFields] = useState([]);
  const [newContactValues, setNewContactValues] = useState({
    firstName: null,
    lastName: null,
    companyName: null,
    leadId: null,
  });
  const [isLoading, setIsLoading] = useState({
    contactInfo: false,
    updateContact: false,
  });

  const onSubmit = async () => {
    setIsLoading((item) => ({ ...item, updateContact: true }));
    const res = await UpdateLeadContactNameAPI({
      ...newContactValues,
    });
    if (!(res && res.status && res.status !== 200)) {
      if (res) {
        showSuccess(
          t(`${translationPath}lead-contact-name-updated-successfully`)
        );
        onSave(true);
      } else
        showWarn(
          t(
            `${translationPath}this-contact-cannot-be-edited-because-it-has-related-transactions-approved-or-pending`
          )
        );
    } else {
      if (
        res?.data?.Message &&
        res.data.Message.includes(
          "Same Contact Info Is Duplicated With Contact Id"
        )
      ) {
        let splittedArray = res.data.Message.split(
          "/CrmDfm/MyLead/UpdateLeadContactName : Same Contact Info Is Duplicated With Contact Id ",
          2
        );
        showError(
          t(
            `${translationPath}same-contact-info-Is-duplicated-with-contact-id`
          ) +
            ((splittedArray &&
              splittedArray?.length === 2 &&
              splittedArray[1]) ||
              "")
        );
      } else
        showError(
          t(`${translationPath}cant-update-contact-name-on-this-contact`)
        );
    }

    setIsLoading((item) => ({ ...item, updateContact: false }));
  };

  const getContactData = useCallback(async () => {
    setIsLoading((item) => ({ ...item, contactInfo: true }));
    const res = await CheckContactWithSomeConditions(contactId);
    setIsLoading((item) => ({ ...item, contactInfo: true }));

    if (res && res?.activeContact) {
      const oldValues =
        res.activeContact.contact.contact_type_id === 2
          ? {
              companyName: res.activeContact.contact.company_name,
              leadId: leadId,
            }
          : {
              firstName: res.activeContact.contact.first_name,
              lastName: res.activeContact.contact.last_name,
              leadId: leadId,
            };

      setNewContactValues(oldValues);

      Object.entries(oldValues).map(([key, value]) => {
        if (key !== "leadId") {
          const itemRegex = new RegExp("^(?:[a-zA-Z\\s]+|[ء-ي\\s]+|\\*{8})$");
          if (!itemRegex.test(value))
            setHelperText((item) => ({
              ...item,
              [key]: "fill-name-in-correct-way",
            }));
        }
      });

      setContactFields(res.contactFields);
    } else {
      setNewContactValues({
        companyName: null,
        leadId: null,
        firstName: null,
        lastName: null,
      });
      setContactFields([]);
    }

    setIsLoading((item) => ({ ...item, contactInfo: false }));
  }, []);

  console.log("@@@@@@@@@@@@@@@@@@@@@@@@", helperText);

  useEffect(() => {
    if (contactId) getContactData();
  }, [contactId]);

  return (
    <>
      <DialogComponent
        titleText={t(`update-contact-name`)}
        saveText={GlobalTranslate.t("Shared:confirm")}
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <Spinner
              isActive={isLoading.contactInfo || isLoading.updateContact}
              isAbsolute
            />

            <span className="w-100 mr-1-reversed mt-3">
              {" "}
              {t(`${translationPath}update-contact-name`)} : {contactName || ""}
            </span>
            {contactFields &&
              contactFields.map((element) => (
                <div className="w-100 mr-1-reversed mt-3" key={`${element.id}`}>
                  <Inputs
                    idRef={`${element.id}Ref`}
                    labelValue={t(`${element.title}`)}
                    value={newContactValues[element?.id] || ""}
                    helperText={
                      helperText &&
                      helperText[element?.id] !== "" &&
                      t(`${translationPath}${element.errorMessage}`)
                    }
                    isWithError
                    error={helperText && helperText[element?.id] !== ""}
                    isSubmitted={isSubmitted}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputChanged={(e) => {
                      let { value } = e.target;
                      setHelperText((item) => ({ ...item, [element.id]: "" }));
                      const itemRegex = new RegExp(element.validation);
                      if (!itemRegex.test(value) || value === "")
                        setHelperText((item) => ({
                          ...item,
                          [element.id]: element?.errorMessage,
                        }));
                      setNewContactValues((item) => ({
                        ...item,
                        [element.id]: value,
                      }));
                    }}
                  />
                </div>
              ))}
          </div>
        }
        saveClasses="btns theme-solid w-100 mx-2 mb-2"
        isOpen={isOpen}
        saveIsDisabled={
          Object.values(isLoading).some((x) => x) ||
          Object.values(newContactValues).some((x) => x === "") ||
          Object.values(helperText).some((x) => x !== "") ||
          Object.values(newContactValues).some((x) => x === null)
        }
        onSaveClicked={() => onSubmit()}
        onCancelClicked={onClose}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
};

ContactLeadDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  leadId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
};
