export const PaymentPlanPermissions = {
  AddPropertyPaymentPlan: {
    permissionsId: 'cc6b59e7-fffb-4eca-2e51-08db604be055',
    permissionsName: 'Add Property Payment Plan',
    description: null,
    componentsId: null,
    components: null,
  },
  EditPropertyPaymentPlan: {
    permissionsId: '43e6022a-1f54-4435-2e52-08db604be055',
    permissionsName: 'Edit Property Payment Plan',
    description: null,
    componentsId: null,
    components: null,
  },
  DeletePropertyPaymentPlan: {
    permissionsId: 'f543d6de-e931-4fe4-2e53-08db604be055',
    permissionsName: 'Delete Property Payment Plan',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewPropertyPaymentPlans: {
    permissionsId: '28f18b58-a6ae-4d70-703d-08dbf65badbd',
    permissionsName: 'View Property Payment Plans',
    description: null,
    componentsId: null,
    components: null,
  },
};
