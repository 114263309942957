import React, { useState, useEffect } from "react";
import { PermissionsComponent, TabsComponent } from "../../../Components";
import { DashboardTabs } from "./DashboardTabs/DashboardTabs";
import { tabsPermissionsHelper } from "../../../Helper/ExceptionPermissions.Helper";
import { Settings } from "@material-ui/icons";
import { Button, makeStyles } from "@material-ui/core";
import { GlobalHistory } from "../../../Helper";
import "../Dashboard/MasterStyleDashborads/MasterStyleDashboards.scss";
import { AdminSalesDashboards } from "../../../Permissions/DashboardsPermissions/AdminSalesDashboards/AdminSalesDashboards";
import { useTranslation } from "react-i18next";
import { AdminLeaseDashboards } from "../../../Permissions/DashboardsPermissions/AdminLeaseDashboards/AdminLeaseDashboards";
import { AdminCallCenterDashboards } from "../../../Permissions/DashboardsPermissions/AdminCallCenterDashboards/AdminCallCenterDashboards";
import { AdminMarketingDashboards } from "../../../Permissions/DashboardsPermissions/AdminMarketingDashboards/AdminMarketingDashboards";
import { AdminPSIDailyDashboards } from "../../../Permissions/DashboardsPermissions/AdminPSIDailyDashboards/AdminPSIDailyDashboards";
import { AdminSalesListingDashboards } from "../../../Permissions/DashboardsPermissions/AdminSalesListingDashboards/AdminSalesListingDashboards";
import { AdminLeaseListingDashboards } from "../../../Permissions/DashboardsPermissions/AdminLeaseListingDashboards/AdminLeaseListingDashboards";
import { AdminListingUnitsDashboards } from "../../../Permissions/DashboardsPermissions/AdminListingUnitsDashboards/AdminListingUnitsDashboards";
import { AdminIntentionalDashboards } from "../../../Permissions/DashboardsPermissions/AdminIntentionalDashboards/AdminIntentionalDashboards";
import { AdminBranchesDashboards } from "../../../Permissions/DashboardsPermissions/AdminBranchesDashboards/AdminBranchesDashboards";
import { AdminLeasedetailsDashboards } from "../../../Permissions/DashboardsPermissions/AdminLeasedetailsDashboards/AdminLeasedetailsDashboards";
import { AdminCustomersRiskDashboards } from "../../../Permissions/DashboardsPermissions/AdminCustomersRiskDashboards/AdminCustomersRiskDashboards";
import { AdminCampaignCostDashboards } from "../../../Permissions/DashboardsPermissions/AdminCampaignCostDashboards/AdminCampaignCostDashboards";
import { AdminLeadOwnerDashboards } from "../../../Permissions/DashboardsPermissions/AdminLeadOwnerDashboards/AdminLeadOwnerDashboards";
import { AdminLeadAutomationPermissions } from "../../../Permissions/DashboardsPermissions/AdminLeadAutomationPermissions/AdminLeadAutomationPermissions";
import { AdminUnqualifiedLeadsPermissions } from "../../../Permissions/DashboardsPermissions/AdminUnqualifiedLeadsPermissions/AdminUnqualifiedLeadsPermissions";
import { AdminInterytdPermissions } from "../../../Permissions/DashboardsPermissions/AdminInterytdPermissions/AdminInterytdPermissions";
import { AdminUsersDetailsPermissions } from "../../../Permissions/DashboardsPermissions/AdminUsersDetailsPermissions/AdminUsersDetailsPermissions";
import { AdminAccountsPermissions } from "../../../Permissions/DashboardsPermissions/AdminAccountsPermissions/AdminAccountsPermissions";
import { AdminCampaignRequestPermissions } from "../../../Permissions/DashboardsPermissions/AdminCampaignRequestPermissions/AdminCampaignRequestPermissions";
import { AdminLeadPoolPermissions } from "../../../Permissions/DashboardsPermissions/AdminLeadPoolPermissions/AdminLeadPoolPermissions";
import { AdminZeromatchingDashboards } from "../../../Permissions/DashboardsPermissions/AdminZeromatchingDashboards/AdminZeromatchingDashboards";
import { AdminDeveloperDashboards } from "../../../Permissions/DashboardsPermissions/AdminDeveloperDashboards/AdminDeveloperDashboards";
import { AdminActivityDashboards } from "../../../Permissions/DashboardsPermissions/AdminActivityDashboards/AdminActivityDashboards";
import { AdminCallInsightsDashboards } from "../../../Permissions/DashboardsPermissions/AdminCallInsightsDashboards/AdminCallInsightsDashboards";
import { ConvoloDashboard } from "../../../Permissions/DashboardsPermissions/ConvoloDashboard/ConvoloDashboard";
import { AdminProposalTracking } from "../../../Permissions/DashboardsPermissions/AdminProposalTracking/AdminProposalTracking";
import { Admininventory } from "../../../Permissions/DashboardsPermissions/Admininventory/Admininventory";
const parentTranslationPath = "Dashboard";
const translationPath = "";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    whiteSpace: "nowrap",
    backgroundColor: "#ffffff",
    color: "#3b72d9",
    "&:hover": {
      backgroundColor: "#3b72d9",
      color: "#ffffff",
    },
  },
  icon: {
    marginRight: 8,
  },
}));

export const Dashboard = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [activeTab, setActiveTab] = useState(0);
  const [dashboardTabsList, setDashboardTabsList] = useState([]);
  const classes = useStyles();
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  useEffect(() => {
    const list = tabsPermissionsHelper(DashboardTabs).map(
      (field, fieldIndex) => ({
        ...field,
        index: fieldIndex,
      })
    );
    setDashboardTabsList(list);
  }, [DashboardTabs]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="adminMenu">
        <div className="adminbutton">
          <span
            className={`mdi mdi-chart-bell-curve-cumulative mdi-24px ${classes.icon}`}
          ></span>
          <span style={{ fontSize: "large" }}>
            {t(`${translationPath}admindashboard`)}
          </span>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <PermissionsComponent
            permissionsList={Object.values(AdminSalesDashboards)}
            permissionsId={
              AdminSalesDashboards.AdminSalesDashboards.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/salesAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-chart-bar-stacked mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}admin-sales`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(AdminLeaseDashboards)}
            permissionsId={
              AdminLeaseDashboards.AdminLeaseDashboards.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/leaseAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-chart-bar-stacked mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}admin-lease`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(AdminCallCenterDashboards)}
            permissionsId={
              AdminCallCenterDashboards.AdminCallCenterDashboards.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/callCenterAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-cellphone-link mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}contact-center`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(AdminMarketingDashboards)}
            permissionsId={
              AdminMarketingDashboards.AdminMarketingDashboards.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/marketingAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-bullhorn-outline mdi-24px  `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}marketing`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(AdminPSIDailyDashboards)}
            permissionsId={
              AdminPSIDailyDashboards.AdminPSIDailyDashboards.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/psidailyAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-bullhorn-outline mdi-24px  `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}PSI-Daily`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminSalesListingDashboards)}
            permissionsId={
              AdminSalesListingDashboards.AdminSalesListingDashboards
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/saleslistingAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-account-group-outline mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>
                          {t(`${translationPath}saleslistingmanager`)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminLeaseListingDashboards)}
            permissionsId={
              AdminLeaseListingDashboards.AdminLeaseListingDashboards
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/leaselistingAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-account-group-outline mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>
                          {t(`${translationPath}leaselistingmanager`)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminListingUnitsDashboards)}
            permissionsId={
              AdminListingUnitsDashboards.AdminListingUnitsDashboards
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/ListingUnitsAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-account-group-outline mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}ListingUnits`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminIntentionalDashboards)}
            permissionsId={
              AdminIntentionalDashboards.AdminIntentionalDashboards
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/intentionalAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-salesforce mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}intentionallead`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminBranchesDashboards)}
            permissionsId={
              AdminBranchesDashboards.AdminBranchesDashboards.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/brancheAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-account-group-outline mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}branches`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminLeasedetailsDashboards)}
            permissionsId={
              AdminLeasedetailsDashboards.AdminLeasedetailsDashboards
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/LeasedetailsAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-account-group-outline mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}leasecontracts`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminCustomersRiskDashboards)}
            permissionsId={
              AdminCustomersRiskDashboards.AdminCustomersRiskDashboards
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/customersriskAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-asterisk mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}customersRisk`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminCampaignCostDashboards)}
            permissionsId={
              AdminCampaignCostDashboards.AdminCampaignCostDashboards
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/campaigncostAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-asterisk mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}adminCampaignCost`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminLeadOwnerDashboards)}
            permissionsId={
              AdminLeadOwnerDashboards.AdminLeadOwnerDashboards.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/leadownerAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-account-group-outline mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}leadower-dashboard`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminLeadAutomationPermissions)}
            permissionsId={
              AdminLeadAutomationPermissions.AdminLeadAutomationPermissions
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/leadautomationAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-account-group-outline mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}LeadAutomation`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminUnqualifiedLeadsPermissions)}
            permissionsId={
              AdminUnqualifiedLeadsPermissions.AdminUnqualifiedLeadsPermissions
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/unqualifiedleadAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-account-group-outline mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}UnqualifiedLeads`)}</span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminInterytdPermissions)}
            permissionsId={
              AdminInterytdPermissions.AdminInterytdPermissions.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() =>
                  GlobalHistory.push(`/home/internationalsalesYtdAdmin`)
                }
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-earth mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>
                          {t(`${translationPath}InternationalSalesYTDMTD`)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminUsersDetailsPermissions)}
            permissionsId={
              AdminUsersDetailsPermissions.AdminUsersDetailsPermissions
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/usersdetailsAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-contacts-outline mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}UsersDetails`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminAccountsPermissions)}
            permissionsId={
              AdminAccountsPermissions.AdminAccountsPermissions.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/accountAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-account-cash-outline mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}accounts`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminCampaignRequestPermissions)}
            permissionsId={
              AdminCampaignRequestPermissions.AdminCampaignRequestPermissions
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() =>
                  GlobalHistory.push(`/home/allcampaignsrequestsAdmin`)
                }
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-frequently-asked-questions mdi-24px `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>
                          {t(`${translationPath}campaign-request-dashboard`)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(AdminLeadPoolPermissions)}
            permissionsId={
              AdminLeadPoolPermissions.AdminLeadPoolPermissions.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/leadpoolsAdmin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-vector-intersection mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}admin-leadpool`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(AdminZeromatchingDashboards)}
            permissionsId={
              AdminZeromatchingDashboards.AdminZeromatchingDashboards
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/zero-matching-admin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-animation-outline mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>
                          {t(`${translationPath}zero-matching-dashboards`)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(AdminDeveloperDashboards)}
            permissionsId={
              AdminDeveloperDashboards.AdminDeveloperDashboards.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() =>
                  GlobalHistory.push(`/home/Developer-Property-Admin`)
                }
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-office-building mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>
                          {t(`${translationPath}developer-dashboards`)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(AdminActivityDashboards)}
            permissionsId={
              AdminActivityDashboards.AdminActivityDashboards.permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/activity-Admin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-bullhorn-outline mdi-24px  `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>
                          {t(`${translationPath}activity-dashboards`)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(AdminCallInsightsDashboards)}
            permissionsId={
              AdminCallInsightsDashboards.AdminCallInsightsDashboards
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/ai-call-Admin`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-phone-in-talk mdi-24px  `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}aicall-dashboards`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(AdminProposalTracking)}
            permissionsId={
              AdminProposalTracking.AdminProposalTracking
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/proposal-dashboard`)}
              >
                <div class="infos">
                  <div class="image">
                    <span
                      className={`mdi mdi-format-align-center mdi-24px  `}
                    />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}proposal-tracking`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(Admininventory)}
            permissionsId={
              Admininventory.Admininventory
                .permissionsId
            }
          >
            <div className="adminbutton">
              <div
                class="cardChart"
                onClick={() => GlobalHistory.push(`/home/inventory-dashboard`)}
              >
                <div class="infos">
                  <div class="image">
                    <span className={`mdi mdi-office-building mdi-24px `} />
                  </div>
                  <div class="info">
                    <div>
                      <div class="name">
                        <span>{t(`${translationPath}inventory`)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PermissionsComponent>
          {/* <PermissionsComponent
            permissionsList={Object.values(ConvoloDashboard)}
            permissionsId={
              ConvoloDashboard.ConvoloDashboard.permissionsId
            }
          >
          <div className="adminbutton">
            <div
              class="cardChart"
              onClick={() => GlobalHistory.push(`/home/convolo-speed-lead-calls`)}
            >
              <div class="infos">
                <div class="image">
                <span className={`mdi mdi-phone-in-talk mdi-24px  `} />
                </div>
                <div class="info">
                  <div>
                    <div class="name">
                      <span>{t(`convolo-dashboard`)}</span>
 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </PermissionsComponent> */}
        </div>
        {/* <TabsComponent
        data={dashboardTabsList}
        labelInput='label'
        themeClasses='theme-curvedpowerbi'
        currentTab={activeTab}  
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          setActiveTab,
          dynamicComponentProps: Object.values(dashboardTabsList).find(
            (element) => element.index === activeTab
          ),
        }}
      /> */}
      </div>
    </div>
  );
};
