import AgentCourseQualifications from '../../../../../../Layouts/Home/AgentCourseQualifications/AgentCourseQualifications';
import { PropertiesPermissionsCRM, PropertyManagementListPermissions } from '../../../../../../Permissions';
import {
  PropertiesFinanceCompanyComponent,
  PropertiesDocumentsComponent,
  PropertiesProfileMarketingComponent,
  PropertiesProfileSpecificationComponent,
  PropertiesProfileMapComponent,
  PropertiesProfileGalleryComponent,
  PropertiesInformationComponent,
  OperatingCostsViewComponent,
  PropertiesUnitComponent,
  PropertiesPaymetPlanComponent,
  UnitsModelsViewComponent,
  VicinitiesViewComponent,
} from '../../../../PropertiesView/PropertiesProfileManagementView/Sections';
import { PropertyProfileMPIComponent } from '../../../../PropertiesView/PropertiesProfileManagementView/Sections/PropertyProfileMPIComponent/PropertyProfileMPIComponent';
import {ProjectTasksView} from '../../../../TaskConfiguration/ProjectTasks/Utilities';

export const PropertiesVerticalTabsData = [
  {
    label: 'Property-info',
    component: PropertiesInformationComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertyDetails.permissionsId,
  },
  {
    label: 'Unit',
    component: PropertiesUnitComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.GetAllUnitsForProperty.permissionsId,

  },
  {
    label: 'Gallery',
    component: PropertiesProfileGalleryComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertyImages.permissionsId,
  },
  {
    label: 'Finance-Company',
    component: PropertiesFinanceCompanyComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewFinancialCompany.permissionsId, 
  },
  {
    label: 'marketing',
    component: PropertiesProfileMarketingComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertyMarketingInfo.permissionsId,
  },
  {
    label: 'Specification',
    component: PropertiesProfileSpecificationComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertySpecifications.permissionsId,
  },
  {
    label: 'MPI',
    component: PropertyProfileMPIComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertyMPI.permissionsId,
  },
  {
    label: 'Map',
    component: PropertiesProfileMapComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertyLocationonMap.permissionsId,
  },
  {
    label: 'Documents',
    component: PropertiesDocumentsComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertyDocuments.permissionsId,
  },
  {
    label: 'operating-costs',
    component: OperatingCostsViewComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewOperatingCostRecordsForProperty.permissionsId,
  },
  {
    label: 'payment-plan',
    component: PropertiesPaymetPlanComponent , 
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertyPaymentPlans.permissionsId,

  },
  {
    label: 'units-models',
    component: UnitsModelsViewComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewUnitModel.permissionsId,
  },
  {
    label: 'tasks',
    component: ProjectTasksView,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewTasksForProperty.permissionsId,
  },
  {
   label: 'vicinities',
    component: VicinitiesViewComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertyVicinities.permissionsId,
  },
  {
   label: 'agent_course_qualifications',
    component: AgentCourseQualifications,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewAgentCoursesTab.permissionsId,
  },
  
];

export const PropertiesVerticalTabsData2 = [
  {
    label: 'Property-info',
    component: PropertiesInformationComponent,
    permissionsList: Object.values(PropertyManagementListPermissions),
    permissionsId: PropertyManagementListPermissions.ViewPropertyDetails.permissionsId,

  },
  {
    label: 'Unit',
    component: PropertiesUnitComponent,
    permissionsList: Object.values(PropertyManagementListPermissions)

  },
  {
    label: 'Gallery',
    component: PropertiesProfileGalleryComponent,
    permissionsList: Object.values(PropertyManagementListPermissions),
    permissionsId: PropertyManagementListPermissions.ViewPropertyImageLocation.permissionsId,
  },
  {
    label: 'Finance-Company',
    component: PropertiesFinanceCompanyComponent,
    permissionsList: Object.values(PropertyManagementListPermissions),
    permissionsId: PropertyManagementListPermissions.ViewAllCompanyFinance.permissionsId,
  },
  {
    label: 'marketing',
    component: PropertiesProfileMarketingComponent,
    permissionsList: Object.values(PropertyManagementListPermissions),
    permissionsId: PropertyManagementListPermissions.ViewPropertyMarketingDetails.permissionsId,
  },
  {
    label: 'Specification',
    component: PropertiesProfileSpecificationComponent,
    permissionsList: Object.values(PropertyManagementListPermissions),
    permissionsId: PropertyManagementListPermissions.ViewPropertySpecificationDetails.permissionsId,

  },
  {
    label: 'MPI',
    component: PropertyProfileMPIComponent,
    permissionsList: Object.values(PropertiesPermissionsCRM),
    permissionsId: PropertiesPermissionsCRM.ViewPropertyMPI.permissionsId,
  },
  {
    label: 'Map',
    component: PropertiesProfileMapComponent,

  },
  {
    label: 'Documents',
    component: PropertiesDocumentsComponent

  },
  {
    label: 'operating-costs',
    component: OperatingCostsViewComponent,
    permissionsList: Object.values(PropertyManagementListPermissions),
    permissionsId: PropertyManagementListPermissions.ViewOperatingCostDetails.permissionsId,
  },
  {
    label: 'payment-plan',
    component: PropertiesPaymetPlanComponent , 
    permissionsList: Object.values(PropertyManagementListPermissions),
    permissionsId: PropertyManagementListPermissions.ViewPropertyPaymentPlans.permissionsId,
  },
  {
    label: 'vicinities',
    component: VicinitiesViewComponent,
  },
  {
    label: 'agent_course_qualifications',
     component: AgentCourseQualifications,
     permissionsList:[],
     permissionsId: undefined,
   },
];
