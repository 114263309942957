import React, { useCallback, useEffect, useState ,useReducer  } from 'react';
import { SelectComponet, Tables } from '../../../../../../../../Components';
import { TableActions } from '../../../../../../../../Enums';
import Chip from '@material-ui/core/Chip';
import { GetUnitMPI, lookupItemsGetId , GetMpiGaugeChartDataAPI } from '../../../../../../../../Services';
import { GetParams } from '../../../../../../../../Helper';
import headerData from './MPIViewHeaderData';
import './MPIView.scss';
import { useTranslation } from 'react-i18next';
import { StaticLookupsIds } from '../../../../../../../../assets/json/StaticLookupsIds';

const parentTranslationPath = 'UnitsProfileManagementView';

export const MPIView = ({ translationPath, type }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    unitMPIDataList: null,
    medianPrice: null,
  });
  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };
  const [defaultState, setDefaultState] = useState({
    minPrice: null,
    maxPrice: null,
    medianPrice: null,
    psiPrice: null,
  });

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });
  
  const [list, setList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const getMPIList = async () => {
    setIsListLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.MPI,
    });
    if (!(res && res.status && res.status !== 200)) {
      setList(res);
    }
    setIsListLoading(false);
  };
  const getUnitMPI = async () => {
    setIsLoading(true);
    const res = await GetUnitMPI({
      unitId: +GetParams('id'),
      operationTypeId:
        type === 'lease' ? StaticLookupsIds.Rent : StaticLookupsIds.Sales,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    }
    setIsLoading(false);
  };
   const GetUnitMpiGaugeChartInfo = useCallback(async () => {
      setIsLoading(true);
      const rs = await GetMpiGaugeChartDataAPI({
        unitId: +GetParams('id'),
        operationType:  type === 'lease' ? StaticLookupsIds.Rent : StaticLookupsIds.Sales,
      });
      if (!(rs && rs.status && rs.status !== 200)) {
        if (rs)
          setState({
            id: "edit",
            value: { ...rs },
          });
      } else if (rs && rs.status && rs.status === 400) {
        setState({ id: "noMPIDataFoundForTheUnit", value: rs?.data });
      } else setState({ id: "edit", value: defaultState });
  
      setIsLoading(false);
    });
  
    useEffect(() => {
      if (GetParams('id') && type !== undefined)
        GetUnitMpiGaugeChartInfo();
    }, [GetParams('id'), type]);

  useEffect(() => {
    getUnitMPI();
    getMPIList();
  }, []);

  return (
    <div className='mt-5 p-3'>
      {data.medianPrice && (
        <div className='median-container mb-1'>
          <Chip
            label={
              <p>
                <strong>{t('median')}: </strong>
                {`${new Intl.NumberFormat('en-US').format(data.medianPrice)} AED`}
              </p>
            }
          />
        </div>
      )}
      { state.medianPrice !== 0 &&    (
        <div className='median-container mb-2'>
          <Chip
            label={
              <p>
                <strong>{t('')}
                {t(`${translationPath}median-price-at-last-6-months`)}
                  : </strong>
                {`${new Intl.NumberFormat('en-US').format(state.medianPrice)} AED`}
              </p>
            }
          />
        </div>
      )}
      {data.unitMPIDataList?.length > 0 ? (
        <Tables
          data={data.unitMPIDataList}
          headerData={headerData}
          defaultActions={[]}
          isLoading={isLoading}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          itemsPerPage={data.unitMPIDataList?.length}
        />
      ) : (
        <>
          <h3>{t('no-match')}</h3>
          <SelectComponet
            data={list}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            valueInput={'lookupItemId'}
            textInput={'lookupItemName'}
            wrapperClasses={'w-25'}
            isLoading={isListLoading}
          />
        </>
      )}
    </div>
  );
};
