import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    headingContainer: {
      display: "flex",
      padding: "24px 24px 0",
      borderBottom: (props) => !props.headerBorder ? "none" : `1px solid ${theme.palette.border.secondary}`,
      paddingBottom: (props) => !props.headerBorder ? "0" : "20px",
    },
    titlesWrapper: {
      width: "100% !important",
    },
    dialogTitleWrapper: {
      padding: "0 !important",
    },
    dialogTitle: {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: 600,
      color: theme.palette.text.primary,
      display: "flex", 
      alignItems: "center", 
      justifyContent: "space-between",
    },
    subtitle: {
      marginTop: "4px",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
    dialogContent: {
      padding: "20px 24px",
    },
    dialogActions: {
      justifyContent: 'flex-end',
      padding: "24px !important",
      gap: '12px',

      border: (props) => props.hideActionsBorder ? "none" : `1px solid ${theme.palette.border.secondary}`,

    },
    dialogPaper: (props) => ({
      width: props.width || "600px",
      maxWidth: "none",
      borderRadius: "12px !important",
      background: `${theme.palette.background.primary} !important`,
    }),
  };
});
