import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  filtersContainer: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
    gap: "12px",
  },
  flex: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  selectedCount: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: "18px",
  },
  quickFilters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px",
    gap: "12px",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  fieldWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    flexWrap: "wrap",
    gap: "12px",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },

    [theme.breakpoints.down("426")]: {
      width: "100%",
      justifyContent: "flex-start",
      "& .MuiBox-root": {
        width: "100%",
      },
    },
  },
  customInputContainer: {
    width: "400px !important",
    [theme.breakpoints.down("426")]: {
      width: "100% !important",
    },
  },
  selectFields: {
    display: "flex",
    gap: "12px",
    [theme.breakpoints.down("769")]: {
      width: "100%",
      flexWrap: "wrap",

      "& .MuiBox-root": {
        width: "auto",
      },
    },
  },
  customSelect: {
    [theme.breakpoints.up("426")]: {
      width: "191px !important",
    },
    [theme.breakpoints.down("426")]: {
      width: "100% !important",
    },
  },
  contactOpportunity: {
    [theme.breakpoints.up("426")]: {
      width: "236px !important",
    },
    [theme.breakpoints.down("426")]: {
      width: "100% !important",
    },
  },
}));
