import React, { Children } from 'react';
import { DialogComponent } from '../../../../../Components';
import LabeledField from '../../../InquiryRotationView/InquirySchemeManagement/components/LabeledField';
import { useTranslation } from 'react-i18next';

const details = [
  {
    label: 'call-has-consent',
    input: 'callHasConsent',
  },
  {
    label: 'is-approved-consent',
    input: 'isApprovedConsent',
  },
  {
    label: 'sentiment-analysis',
    input: 'sentimentAnalysis',
  },
  {
    label: 'agent-performance',
    input: 'agentPerformance',
  },
  {
    label: 'sentiment-evaluation',
    input: 'sentimentEvaluation',
  },
  {
    label: 'agent-evaluations',
    input: 'agentEvaluations',
  },
  {
    label: 'agent-evaluations-reason',
    input: 'agentEvaluations',
  },
  {
    label: 'is-risk',
    input: 'agentEvaluations',
  },
  {
    label: 'risk-reason',
    input: 'agentEvaluations',
  },
  {
    label: 'advice-for-agent',
    input: 'adviceForAgent',
  },
  {
    label: 'call-assessment',
    input: 'callAssessment',
  },
  {
    label: 'lead-as-opportunity-percentage',
    input: 'leadAsOpportunityPer',
  },
  {
    label: 'lead-assessment',
    input: 'leadAssessment',
  },
  {
    label: 'additional-notes',
    input: 'additionalNotes',
  },
];

const renderAgentEvaluations = (evaluations) => {
  const categories = evaluations.flatMap((row) =>
    row.evaluations.map((item) => item.categoryName.toLowerCase())
  );
  if (categories.includes('negative')) return 'Negative';
  if (categories.includes('neutral')) return 'Neutral';
  if (categories.includes('positive')) return 'Positive';
  return null;
};

const renderAgentEvaluationReasons = (evaluations, selectedCategory) => {
  if (!selectedCategory) return '';
  const reasons = evaluations
    .flatMap((row) => row.evaluations)
    .filter((item) => item.categoryName === selectedCategory)
    .map((item) => item.reason);
  return reasons.join(', ');
};

const getEvaluationColor = (category) => {
  switch (category) {
    case 'Negative':
      return '#E74C3C';
    case 'Neutral':
      return '#F39C12';
    case 'Positive':
      return '#2ECC71';
    default:
      return 'inherit';
  }
};

const renderRiskValue = (value) => {
  if (value.length === 0) {
    return { isRisk: 'No', riskReason: '' };
  }

  const { isRisk, riskReason } = value[0];
  return {
    isRisk: isRisk ? 'Yes' : 'No',
    riskReason: riskReason || '',
  };
};

function MoreDetails({ call, parentTranslationPath, open, onClose }) {
  const { t } = useTranslation(parentTranslationPath);

  const InputRender = ({ value, label }) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (label === 'agent-evaluations') {
      const evaluation = Array.isArray(value)
        ? renderAgentEvaluations(value)
        : '';
      return (
        <span style={{ color: getEvaluationColor(evaluation) }}>
          {evaluation}
        </span>
      );
    }
    if (label === 'agent-evaluations-reason' && Array.isArray(value)) {
      const selectedCategory = renderAgentEvaluations(value);
      return renderAgentEvaluationReasons(value, selectedCategory);
    }
    if (label === 'sentiment-evaluation') {
      return <span style={{ color: getEvaluationColor(value) }}>{value}</span>;
    }
    if (label === 'is-risk' && Array.isArray(value)) {
      const riskValue = renderRiskValue(value);
      return (
        <span style={{ color: riskValue === 'Yes' ? '#b42318' : '#2ECC71' }}>
          {riskValue.isRisk}
        </span>
      );
    }
    if (label === 'risk-reason' && Array.isArray(value)) {
      const riskValue = renderRiskValue(value);
      return <span>{riskValue.riskReason}</span>;
    }
    return value ?? '';
  };
  return (
    <DialogComponent
      titleText={'call-analysis'}
      parentTranslationPath={parentTranslationPath}
      isOpen={open}
      onCloseClicked={onClose}
      dialogContent={
        <div className='pb-4 call-analysis'>
          {Children.toArray(
            details.map(
              (detail) =>
                call?.[detail.input] !== null && (
                  <LabeledField
                    label={<strong>{t(detail.label)}</strong>}
                    component={
                      <InputRender
                        value={call?.[detail.input]}
                        label={detail.label}
                      />
                    }
                  />
                )
            )
          )}
        </div>
      }
    />
  );
}

export default MoreDetails;
