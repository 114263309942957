import React from "react";

function ArrowNarrowUp({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.64 3.068c-.155.053-.604.485-3.293 3.167C6.57 8.008 5.188 9.417 5.127 9.52c-.091.152-.107.226-.107.48 0 .257.016.327.113.492.13.222.369.409.603.473.203.054.531.024.709-.066.074-.037 1.129-1.059 2.345-2.272l2.209-2.205.011 6.942.01 6.942.121.197a.998.998 0 0 0 1.718 0l.121-.197.01-6.942.011-6.942 2.209 2.205c1.216 1.213 2.271 2.235 2.345 2.272.455.229 1.046.045 1.312-.407.097-.165.113-.235.113-.492 0-.254-.016-.328-.107-.48-.061-.103-1.442-1.512-3.22-3.285-2.836-2.828-3.131-3.11-3.31-3.17a1.053 1.053 0 0 0-.703.003"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ArrowNarrowUp;
