import React, { useCallback, useEffect, useRef, useState } from "react";
import "./LeasingTransactionsView.scss";
import moment from "moment";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  Inputs,
  Spinner,
  Tables,
  PermissionsComponent,
  AutocompleteComponent,
  SelectComponet,
} from "../../../Components";
import { TableActions, TableFilterTypesEnum } from "../../../Enums";
import {
  GlobalHistory,
  returnPropsByPermissions,
  preventCopy,
} from "../../../Helper";
import {
  GetAllLeaseTransactions,
  lookupItemsGet,
  getProperties,
  GetAllLeaseAgentsServices,
  GetAllBranches,
} from "../../../Services";
import { LeasingTransactionsPermissions } from "../../../Permissions";
import { GlobalOrderFilterActions } from "../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { ActiveItemActions } from "../../../store/ActiveItem/ActiveItemActions";
import { useTitle } from "../../../Hooks";
import { DateRangePickerComponent } from "../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";

const parentTranslationPath = "LeasingTransactionsView";
const translationPath = "";
export const LeasingTransactions = () => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  useTitle(t(`${translationPath}leasing-transactions`));
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState({
    leasingTransactions: false,
    properties: false,
    communitie: false,
    leaseAgents: false,
    branch: false,
  });
  const [properties, setProperties] = useState({ result: [], totalCount: 0 });
  const [communities, setCommunities] = useState({ result: [], totalCount: 0 });
  const [leaseAgents, setLeaseAgents] = useState({ result: [], totalCount: 0 });
  const [branchList, setBranchList] = useState({
    result: [],
    totalCount: 0,
  });
  const [leasingTransactions, setLeasingTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy:
      (orderFilter.leasingTransactionsFilter &&
        orderFilter.leasingTransactionsFilter.filterBy) ||
      null,
    orderBy:
      (orderFilter.leasingTransactionsFilter &&
        orderFilter.leasingTransactionsFilter.orderBy) ||
      null,
  });
  const [sortBy, setSortBy] = useState(null);
  const headerData = [
    {
      id: 1,
      label: "ref-no",
      input: "unitRefNumber",
      component: (item) => <span>{item && item.unitReferenceNo}</span> || "",
    },
    {
      id: 2,
      label: "property",
      input: "propertyName",
    },
    {
      id: 3,
      label: "status",
      input: "status",
    },
    {
      id: 4,
      label: "community",
      input: "communityName",
    },
    {
      id: 5,
      label: "unit-no",
      input: "unitNumber",
    },
    {
      id: 6,
      label: "tenant",
      input: "tenantName",
      component: (item) => (
        <span>
          {item.tenantNames &&
            item.tenantNames.map((element, index) => (
              <span key={`tenantNamesRef${index + 1}`}>
                {`${element}${
                  (index < item.tenantNames.length - 1 && ", ") || ""
                }`}
              </span>
            ))}
        </span>
      ),
    },
    {
      id: 7,
      label: "tenant-agent",
      input: "tenantAgent",
      component: (item) =>
        (
          <span>
            {item &&
              item.tenantAgents &&
              item.tenantAgents.map((item) => `${item.agentName} , `)}
          </span>
        ) || "",
    },
    {
      id: 8,
      label: "tenant-agent-staff-id",
      input: "tenantStaffId",
      component: (item) =>
        (
          <span>
            {item &&
              item.tenantAgents &&
              item.tenantAgents.map((item) =>
                item.staffId ? `${item.staffId} ,  ` : "N/A ,  "
              )}
          </span>
        ) || "",
    },
    {
      id: 9,
      label: "landlord",
      input: "landlordName",
    },
    {
      id: 10,
      label: "landlord-agent",
      input: "landlordAgent",
      component: (item) =>
        (
          <span>
            {item &&
              item.landlordAgents &&
              item.landlordAgents.map((item) => `${item.listingAgentName} , `)}
          </span>
        ) || "",
    },
    {
      id: 11,
      label: "landlord-agent-staff-id",
      input: "landlordStaffId",
      component: (item) =>
        (
          <span>
            {item &&
              item.landlordAgents &&
              item.landlordAgents.map((item) =>
                item.staffId ? `${item.staffId} ,  ` : "N/A ,  "
              )}
          </span>
        ) || "",
    },
    {
      id: 12,
      isSortable: true,
      label: "rent/year",
      input: "rentPerYear",
    },
    {
      id: 13,
      input: "startDate",
      label: "contract-start-date",
      dateFormat: "DD/MM/YYYY",
      isDate: true,
    },
    {
      id: 14,
      input: "endDate",
      label: "contract-end-date",
      dateFormat: "DD/MM/YYYY",
      isDate: true,
    },
    // component: (item) =>
    //   ((item.startDate || item.endDate) && (
    //     <>
    //       <span>
    //         {(item.startDate &&
    //           moment(item.startDate).format('DD-MMM-YYYY')) ||
    //           'N/A'}
    //       </span>
    //       <span className='px-1'>-</span>
    //       <span>
    //         {(item.endDate &&
    //           moment(item.endDate).format('DD-MMM-YYYY')) ||
    //           'N/A'}
    //       </span>
    //     </>
    //   )) || <span />,
    // },
    {
      id: 15,
      isSortable: true,
      label: "contract-rent",
      input: "contractRent",
    },
    {
      id: 16,
      isSortable: true,
      label: "transaction-date",
      input: "transactionEntryDate",
      isHiddenFilter: true,
      isDate: true,
    },
    {
      id: 17,
      isSortable: true,
      label: "Transaction ID",
      input: "unitTransactionId",
    },
    {
      id: 18,
      label: "tenant-branch",
      input: "tenantBranch",
    },
    {
      id: 19,
      label: "landlord-branch",
      input: "landlordBranch",
    },
    {
      id: 20,
      label: "notes",
      input: "invoiceNotes",
    },
  ];

  const [tableFilterData, setTableFilterData] = useState([]);
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: null, orderBy: null }
  );
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    unitRefNumber: "",
    propertyId: null,
    communityId: null,
    tenantAgentId: null,
    landLordAgentId: null,
    buyerAgentId: null,
    sellerAgentId: null,
    orderBy: (orderBy && orderBy.orderBy) || null,
    filterBy: (orderBy && orderBy.filterBy) || null,
    landlordBranchId: null,
    tenantBranchId: null,
  });
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };

  const [dateRanges, setDateRanges] = useState(dateRangeDefault);

  const [selected, setSelected] = useState({
    property: null,
    community: null,
    tenantAgent: null,
    landLordAgent: null,
    buyerAgentId: null,
    sellerAgent: null,
    landlordBranch: null,
    tenantBranch: null,
  });
  const onFilterValuesChanged = (newValue) => {
    const localFilterDto = filter || {};

    if (localFilterDto && localFilterDto.createdOn) {
      // localFilterDto.dateTo = moment(localFilterDto.createdOn).format('YYYY-MM-DDTHH:mm:ss');
      // localFilterDto.dateFrom = moment(localFilterDto.createdOn).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.displayPath])
            localFilterDto[item.displayPath] = item.value;
          else if (item.value) localFilterDto[item.displayPath] = item.value;

          return undefined;
        });
    }
    setFilter(() => ({ ...localFilterDto }));
  };
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        leasingTransactionsFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.openFile.key) {
        localStorage.setItem(
          "leaseTransactionDetailsId",
          item.leaseTransactionDetailsId
        );
        dispatch(ActiveItemActions.activeItemRequest(item));
        GlobalHistory.push(
          `/home/leasing-transactions/transaction-profile?unitId=${item.unitId}&unitTransactionId=${item.unitTransactionId}&actionType=2`
        );
      } else if (actionEnum === TableActions.editText.key) {
        localStorage.setItem(
          "leaseTransactionDetailsId",
          item.leaseTransactionDetailsId
        );
        dispatch(ActiveItemActions.activeItemRequest(item));
        GlobalHistory.push(
          `/home/leasing-transactions/transaction-profile?unitId=${item.unitId}&unitTransactionId=${item.unitTransactionId}&actionType=1`
        );
      }
    },
    []
  );

  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 0, unitRefNumber: search }));
    }, 700);
    setSearchInput(search);
  };

  const getAllLeasingTransactions = useCallback(async () => {
    setIsLoading((loading) => ({ ...loading, leasingTransactions: true }));
    const res = await GetAllLeaseTransactions({
      ...filter,
      pageIndex: filter.pageIndex + 1,
      transactionEntryDate: dateRanges?.startDate
        ? moment(dateRanges?.startDate).format("YYYY-MM-DD")
        : null,
      transactionCompletionDate: dateRanges?.endDate
        ? moment(dateRanges?.endDate).format("YYYY-MM-DD")
        : null,
    });
    if (!(res && res.status && res.status !== 200)) {
      setLeasingTransactions({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setLeasingTransactions({
        result: [],
        totalCount: 0,
      });
    }

    setIsLoading((loading) => ({ ...loading, leasingTransactions: false }));
  }, [filter, dateRanges]);
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex, search: searchInput }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      pageSize,
      search: "",
    }));
    setSearchInput("");
  };
  useEffect(() => {
    getAllLeasingTransactions();
  }, [filter, dateRanges, getAllLeasingTransactions]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );
  const GetAllow = () => {
    if (
      returnPropsByPermissions(
        LeasingTransactionsPermissions.ViewContactDetails.permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        LeasingTransactionsPermissions.ViewChequeRequestDetails.permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        LeasingTransactionsPermissions.ViewInvoicesInLeaseTransactions
          .permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        LeasingTransactionsPermissions.ViewTransactionsDetails.permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        LeasingTransactionsPermissions.ViewEarning.permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        LeasingTransactionsPermissions.ViewReferenceDetails.permissionsId
      )
    )
      return true;
    return false;
  };

  const getAllProperties = useCallback(async (searchItem) => {
    setIsLoading((loading) => ({ ...loading, properties: true }));
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchItem || "",
    });
    if (!(res && res.status && res.status !== 200)) {
      setProperties({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setProperties({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, properties: false }));
  }, []);

  const getAllCommunitie = useCallback(async (searchItem) => {
    setIsLoading((loading) => ({ ...loading, communitie: true }));
    const res = await lookupItemsGet({
      pageIndex: 1,
      pageSize: 10,
      lookupTypeId: 19,
      lookupTypeName: "community",
      searchedItem: searchItem || "",
    });

    if (!(res && res.status && res.status !== 200)) {
      setCommunities({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setCommunities({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, communitie: false }));
  }, []);

  const getAllLeaseAgents = useCallback(async (searchItem) => {
    setIsLoading((loading) => ({ ...loading, leaseAgents: true }));
    const res = await GetAllLeaseAgentsServices({
      pageSize: 10,
      pageIndex: 0,
      search: searchItem || "",
    });
    if (!(res && res.status && res.status !== 200)) {
      setLeaseAgents({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setLeaseAgents({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, leaseAgents: false }));
  }, []);

  const getBranch = async (branchName) => {
    setIsLoading((loading) => ({ ...loading, branch: true }));
    const res = await GetAllBranches({
      pageSize: 25,
      pageIndex: 1,
      branchName,
    });
    if (!(res && res.status && res.status !== 200)) {
      setBranchList({
        result: res.result,
        totalCount: res.totalCount,
      });
    } else {
      setBranchList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, branch: false }));
  };
  const onClearedAllFiltersClick = useCallback(() => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: null, orderBy: 2 });
    setFilter({
      pageIndex: 0,
      pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
      unitRefNumber: "",
      propertyId: null,
      communityId: null,
      tenantAgentId: null,
      landLordAgentId: null,
      buyerAgentId: null,
      sellerAgentId: null,
      landlordBranchId: null,
      tenantBranchId: null,
    });
    setSelected({
      property: null,
      community: null,
      tenantAgent: null,
      landLordAgent: null,
      buyerAgentId: null,
      sellerAgent: null,
      landlordBranch: null,
      tenantBranch: null,
    });
    setSearchInput("");
    setDateRanges(dateRangeDefault);
  });

  useEffect(() => {
    getAllCommunitie();
    getAllProperties();
    getAllLeaseAgents();
    getBranch();
  }, []);

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
  }, [sortBy]);
  useEffect(() => {
    if (orderBy)
      setFilter((item) => ({
        ...item,
        filterBy: orderBy.filterBy,
        orderBy: orderBy.orderBy,
      }));
  }, [orderBy]);
  useEffect(() => {
    setTableFilterData(
      headerData.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
      }))
    );
  }, []);

  return (
    <div className="view-wrapper">
      <Spinner isActive={isLoading.leasingTransactions} />
      <div className="d-flex-column">
        <div className="header-section">
          <div className="filter-section">
            <div className="section" />
            <div className="section autocomplete-section">
              <div className="d-flex-column px-2 w-100 p-relative">
                <PermissionsComponent
                  permissionsList={Object.values(
                    LeasingTransactionsPermissions
                  )}
                  permissionsId={
                    LeasingTransactionsPermissions
                      .ViewAndSearchInMainAccountsLeaseTransactionsPage
                      .permissionsId
                  }
                >
                  <div className="d-flex-column w-100">
                    <Inputs
                      idRef="leasingTransactionsSearchRef"
                      value={searchInput}
                      beforeIconClasses="mdi mdi-magnify mdi-24px c-gray-primary"
                      onInputChanged={(e) => {
                        searchHandler(e.target.value);
                      }}
                      inputPlaceholder={t(`${translationPath}seacrh_ref_No`)}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                  <div className="d-inline-flex-column pl-4-reversed">
                    <div className="d-flex mb-1">
                      <div className="autocomplete-wrapper mr-1-reversed">
                        <AutocompleteComponent
                          selectedValues={selected.property}
                          idRef="propertyIdRef"
                          isLoading={isLoading.properties}
                          multiple={false}
                          data={(properties && properties.result) || []}
                          displayLabel={(option) =>
                            (option &&
                              option.property &&
                              `${option.property.property_name}`) ||
                            ""
                          }
                          withoutSearchButton
                          inputPlaceholder={t(`${translationPath}property`)}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onChange={(event, newValue) => {
                            setFilter((item) => ({
                              ...item,
                              propertyId:
                                (newValue && newValue.propertyId) || null,
                              pageIndex: 0,
                            }));
                            setSelected((item) => ({
                              ...item,
                              property: (newValue && newValue) || "",
                            }));
                          }}
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllProperties(value);
                            }, 700);
                          }}
                        />
                      </div>
                      <AutocompleteComponent
                        selectedValues={selected.community}
                        idRef="communityRef"
                        isLoading={isLoading.communitie}
                        multiple={false}
                        data={(communities && communities.result) || []}
                        displayLabel={(option) =>
                          (option && option.lookupItemName) || ""
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        inputPlaceholder={t(`${translationPath}community`)}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            communityId:
                              (newValue && newValue.lookupItemId) || null,
                            pageIndex: 0,
                          }));
                          setSelected((item) => ({
                            ...item,
                            community: (newValue && newValue) || "",
                          }));
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllCommunitie(value);
                          }, 700);
                        }}
                      />
                      <DateRangePickerComponent
                        onClearClicked={() => {
                          setDateRanges({
                            startDate: null,
                            endDate: null,
                            key: "selection",
                          });
                        }}
                        ranges={[dateRanges]}
                        onDateChanged={(selectedDate) => {
                          setDateRanges({
                            startDate: selectedDate?.selection?.startDate,
                            endDate: new Date(
                              moment(selectedDate?.selection?.endDate).endOf(
                                "day"
                              )
                            ),
                            key: "selection",
                          });
                        }}
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="autocomplete-wrapper mr-1-reversed">
                        <AutocompleteComponent
                          selectedValues={selected.tenantAgent}
                          idRef="tenantAgentIdRef"
                          isLoading={isLoading.leaseAgents}
                          multiple={false}
                          inputPlaceholder={t(`${translationPath}tenantAgent`)}
                          data={(leaseAgents && leaseAgents.result) || []}
                          chipsLabel={(option) =>
                            (option && option.agentName) || ""
                          }
                          displayLabel={(option) =>
                            (option && option.agentName) || ""
                          }
                          withoutSearchButton
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onChange={(event, newValue) => {
                            setFilter((item) => ({
                              ...item,
                              tenantAgentId:
                                (newValue && newValue.agentId) || null,
                              pageIndex: 0,
                            }));
                            setSelected((item) => ({
                              ...item,
                              tenantAgent: (newValue && newValue) || "",
                            }));
                          }}
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllLeaseAgents(value);
                            }, 700);
                          }}
                        />
                      </div>
                      <AutocompleteComponent
                        selectedValues={selected.landLordAgent}
                        idRef="landLordAgentRef"
                        isLoading={isLoading.leaseAgents}
                        multiple={false}
                        inputPlaceholder={t(`${translationPath}landLordAgent`)}
                        data={(leaseAgents && leaseAgents.result) || []}
                        chipsLabel={(option) =>
                          (option && option.agentName) || ""
                        }
                        displayLabel={(option) =>
                          (option && option.agentName) || ""
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            landLordAgentId:
                              (newValue && newValue.agentId) || null,
                            pageIndex: 0,
                          }));
                          setSelected((item) => ({
                            ...item,
                            landLordAgent: (newValue && newValue) || "",
                          }));
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllLeaseAgents(value);
                          }, 700);
                        }}
                      />
                      <AutocompleteComponent
                        selectedValues={selected.tenantBranch}
                        idRef="buyerBranchRef"
                        multiple={false}
                        isLoading={isLoading.saleAgentsAPI}
                        inputPlaceholder={t(`${translationPath}tenant-branch`)}
                        data={branchList.result || []}
                        chipsLabel={(option) =>
                          (option && option.branchName) || ""
                        }
                        displayLabel={(option) =>
                          (option && option.branchName) || ""
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputKeyUp={(event) => {
                          const { value } = event.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getBranch(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            tenantBranchId:
                              (newValue && newValue.branchId) || null,
                          }));
                          setSelected((item) => ({
                            ...item,
                            tenantBranch: (newValue && newValue) || "",
                          }));
                        }}
                      />
                      <AutocompleteComponent
                        selectedValues={selected.landlordBranch}
                        idRef="sellerBranchRef"
                        multiple={false}
                        isLoading={isLoading.saleAgentsAPI}
                        inputPlaceholder={t(
                          `${translationPath}landlord-branch`
                        )}
                        data={branchList.result || []}
                        chipsLabel={(option) =>
                          (option && option.branchName) || ""
                        }
                        displayLabel={(option) =>
                          (option && option.branchName) || ""
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputKeyUp={(event) => {
                          const { value } = event.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getBranch(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            landlordBranchId:
                              (newValue && newValue.branchId) || null,
                          }));
                          setSelected((item) => ({
                            ...item,
                            landlordBranch: (newValue && newValue) || "",
                          }));
                        }}
                      />
                    </div>
                  </div>
                </PermissionsComponent>
              </div>
              <ButtonBase
                onClick={onClearedAllFiltersClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-danger clear-all-btn max-height"
              >
                <span className="mdi mdi-filter-remove m-1" />
                {t(`${translationPath}clear-filters`)}
              </ButtonBase>
            </div>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(LeasingTransactionsPermissions)}
            permissionsId={
              LeasingTransactionsPermissions
                .ViewAndSearchInMainAccountsLeaseTransactionsPage.permissionsId
            }
          >
            <div className="d-flex px-2">
              <span className="mx-2 mt-1">{t(`${translationPath}select`)}</span>
              <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
              <span className="px-2 d-flex">
                <span className="texts-large mt-1">
                  {t(`${translationPath}order-by`)}:
                </span>
                <div className="px-2">
                  <SelectComponet
                    idRef="filterByRef"
                    data={[
                      {
                        id: "TransactionEntryDate",
                        filterBy: "transaction-date",
                      },
                    ]}
                    value={selectedOrderBy.filterBy}
                    wrapperClasses="mb-3"
                    isRequired
                    onSelectChanged={filterByChanged}
                    emptyItem={{
                      value: null,
                      text: "select-filter-by",
                      isDisabled: false,
                    }}
                    valueInput="id"
                    textInput="filterBy"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="px-2">
                  <SelectComponet
                    idRef="orderByRef"
                    data={[
                      { id: 1, orderBy: "ascending" },
                      { id: 2, orderBy: "descending" },
                    ]}
                    emptyItem={{
                      value: null,
                      text: "select-sort-by",
                      isDisabled: false,
                    }}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="id"
                    textInput="orderBy"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="mt-1">
                  <ButtonBase
                    id="action_apply"
                    className="btns theme-solid"
                    onClick={orderBySubmitted}
                    disabled={
                      !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                    }
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>

        <div className="w-100 px-3" onCopy={preventCopy}>
          <PermissionsComponent
            permissionsList={Object.values(LeasingTransactionsPermissions)}
            permissionsId={
              LeasingTransactionsPermissions
                .ViewAndSearchInMainAccountsLeaseTransactionsPage.permissionsId
            }
          >
            <Tables
              data={leasingTransactions.result}
              headerData={headerData}
              defaultActions={
                GetAllow()
                  ? [
                      {
                        enum: TableActions.openFile.key,
                      },
                      {
                        enum: TableActions.editText.key,
                      },
                    ]
                  : []
              }
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              actionsOptions={{
                onActionClicked: tableActionClicked,
              }}
              itemsPerPage={filter.pageSize}
              activePage={filter.pageIndex}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              totalItems={leasingTransactions.totalCount}
              setSortBy={setSortBy}
              onFilterValuesChanged={onFilterValuesChanged}
              filterData={tableFilterData}
              isWithFilter
              FilterDisabledButton
            />
          </PermissionsComponent>
        </div>
      </div>
    </div>
  );
};
