import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FBLabelWrapper } from "../FBLabelWrapper";
import { useSelectedTheme } from "../../../../Hooks";
import { FBLabelPosition } from "../Constant";

// Styles
import useStyles from "./styles";

// Icons
import { CheckIcon, ChevronDown, CloseXIcon } from "../../../../assets/icons";

function FBAutocomplete(props) {
  const {
    options,
    value,
    onChange,
    label,
    placeholder,
    helperText,
    error,
    disabled,
    variant,
    disableClearable,
    renderOption,
    groupBy,
    filterOptions,
    labelPosition,
    required,
    getOptionLabel,
    helperInfo,
    errorMessage,
    ...restProps
  } = props;

  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  const isLabelSidePosition = labelPosition === FBLabelPosition.SIDE && label;

  return (
    <Box
      display={"flex"}
      flexDirection={isLabelSidePosition ? "row" : "column"}
      alignItems={isLabelSidePosition ? "center" : "flex-start"}
      className={clsx({
        [styles.wrapperSide]: isLabelSidePosition,
      })}
    >
      <FBLabelWrapper
        label={label}
        required={required}
        helperInfo={helperInfo}
        isLabelSidePosition={isLabelSidePosition}
      />

      <Autocomplete
        id="autocomplete-input"
        aria-labelledby={`${label}-id`}
        options={options}
        value={value}
        onChange={onChange}
        getOptionLabel={getOptionLabel} // Input shows || Must return a string
        renderOption={ // Dropdown shows detailed info || Can return any JSX to define how the option is rendered.
          renderOption ||
          ((option, { selected }) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box display="flex" alignItems="center">
                <Box>{getOptionLabel(option)}</Box>
              </Box>
              {selected && (
                <CheckIcon
                  width="20"
                  height="20"
                  fill={palette.foreground.brandPrimary}
                />
              )}
            </Box>
          ))
        }
        groupBy={groupBy}
        filterOptions={filterOptions}
        disableClearable={false} // Allows clearing via keyboard or programmatically
        // Prevent clearing the input field when pressing the Escape key,
        // as we already have a custom clear button (`CloseXIcon`) for this purpose.
        clearOnEscape={false}
        disabled={disabled}
        popupIcon={
          <ChevronDown
            width="24"
            height="24"
            fill={palette.foreground.quarterary}
          />
        }
        classes={{
          root: styles.root,
          paper: styles.popover, // Apply the custom popover styling
          option: styles.option, // Apply the custom option styling
          listbox: styles.listbox, // Styles for the <ul> element
          popupIndicator: styles.popupIndicator, // Override popup icon styles
          clearIndicator: styles.clearIndicator, // Hides the default clear button
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            helperText={!!error && errorMessage}
            error={error}
            variant={variant}
            className={styles.input}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {value && (
                    <span
                      onClick={() => onChange(null, null)}
                      className={styles.closeIconWrapper}
                    >
                      <CloseXIcon
                        width="16"
                        height="16"
                        fill={palette.foreground.quinary}
                        style={{ marginInlineEnd: "8px" }}
                      />
                    </span>
                  )}
                  {/* Retain the default adornments */}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...restProps}
      />
    </Box>
  );
}

FBAutocomplete.defaultProps = {
  options: [],
  value: null,
  label: "",
  labelPosition: FBLabelPosition.SIDE,
  placeholder: "",
  helperText: "",
  error: false,
  disabled: false,
  variant: "outlined",
  disableClearable: false,
  renderOption: undefined,
  groupBy: undefined,
  filterOptions: undefined,
  getOptionLabel: (option) =>
    typeof option === "string" || typeof option === "number" ? option : option.label || "",
};

FBAutocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      }),
    ])
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "standard", "filled"]),
  disableClearable: PropTypes.bool,
  renderOption: PropTypes.func,
  groupBy: PropTypes.func,
  filterOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
};

export default FBAutocomplete;
