import React from 'react';
import { useSelectedTheme } from '../../../../../Hooks';
import { CloseXIcon } from '../../../../../assets/icons';

// Styles
import useStyles from './styles';


function CustomChipInput(item) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  return (
    <div className={styles.customChipWrapper}>
      <div className={styles.customChipContent}>{item.item.text}</div>
      <CloseXIcon
        width='12'
        hight='12'
        fill={palette.foreground.quinary}
        onClick={item.item.handleDelete}
        className={styles.closeXIcon}
      />
    </div>
  );
}

export default CustomChipInput;
