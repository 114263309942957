export const NewKYCFormHTMLTemplate = `
<html>

<head>
<link rel="stylesheet" href="styles.css"/>
<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
<style>

:root {
  --colors-foreground-fg-secondary-700: #344054;
  --colors-border-border-secondary: #eaecf0;
  --colors-text-secondary: #344054;
  --colors-foreground-fg-primary-900: #101828;
  --colors-background-bg-brand-section-subtle: #635235;
  --colors-background-secondaryColor_bg_hover: #F4F1ED;
  --component-colors-utility-gray-utility-gray-100: #f2f4f7;
  --colors-foreground-fg-brand-tertiary-600: #7F6944;
  --colors-background-brand-solid: #7F6944;
}
body{font-family:"Inter"}

.actions {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  justify-content: flex-end;
  padding: 0 32px 32px;

  .approveButton, .rejectButton {
    padding: 10px var(--spacing-xl, 16px);
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--Component-colors-Components-Buttons-Primary-button-primary-border, #7F6944);
    background: var(--Component-colors-Components-Buttons-Primary-button-primary-bg, #7F6944);
    color: var(--Component-colors-Components-Buttons-Primary-button-primary-fg, #FFF);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .rejectButton {
    border: 1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD);
    background: var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF);
    color: var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054);
  }
}

.newLayoutClass {

    input:not([type="checkbox"]):not([type="radio"]) {
      height: 24px !important;
      width: 100% !important;
      border-radius: var(--radius-md, 8px);
      border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 8px;
    }

    input[type="checkbox"]{
        accent-color: #7F6944 !important;
    }


    .radio-btn-group input {
      accent-color: #7F6944 !important;
    }

    .KycForm {
      .Form_Table {
        font-family: inter;
        width: 100%;
        table-layout: fixed;
        margin-inline: unset;
        border-collapse: collapse;
      }
  
      .Nested_Table,
      .Dynamic_Table {
        width: 100%;
        table-layout: fixed;
        margin-bottom: 16px;
  
        &.tow-col-table {
          table-layout: auto;
          td:nth-of-type(2) {
            width: 65%;
            padding: 11px;
          }
        }
      }
  
      th,
      td {
        border: 1px solid var(--colors-border-border-secondary);
      }
  
      td {
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        color: var(--colors-foreground-fg-primary-900);
        line-height: 24px;
        overflow: hidden;
      }
  
      .Spaced_Table_Header {
        th {
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding: 12px;
          background-color: var(--colors-background-secondaryColor_bg_hover);
          color: unset;
          border-radius: 4px;
          border-color: transparent;
        }
  
        .Header_Title {
          display: block;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          color: var(--colors-background-bg-brand-section-subtle);
        }
        .Header_Sub_Title {
          display: block;
          font-size: 16px;
          font-weight: 400;
          color: var(--colors-foreground-fg-brand-tertiary-600);
        }
        .Header_Button {
          display: none;
        }
      }
  
      .Space_After {
        &::after {
          content: "";
          display: block;
          height: 16px;
          background-color: transparent;
        }
      }
      .Row_Text {
        border: unset;
        padding: 15px 0px;
      }
  
      .Dynamic_Table {
        th {
          padding: 8px 20px;
          font-weight: 400;
          font-size: 16px;
          color: var(--colors-foreground-fg-primary-900);
        }
      }
  
      //Components_Style_Override
      .Cell_Value {
        padding: 12px;
  
        .autocomplete-wrapper,
        .text-field-wrapper {
          height: 18px;
        }
        .MuiInputBase-root {
          min-height: 20px !important;
          display: flex;
          width: 100% !important;
        }
        .MuiFormControl-root {
          margin: 0px !important;
          padding: 0px !important;
          margin-bottom: 0px !important;
        }

        .radio-group-wrapper {
          .MuiFormControlLabel-label {
            font-size: 14px;
            color: var(--colors-text-secondary) !important;
          }
          &.Mui-checked {
            .radio-icon,
            + .MuiTypography-root {
              color: var(--colors-background-brand-solid);
            }
          }
          .Mui-checked .radio-icon {
            border-color: var(--colors-background-brand-solid) !important;
            color: var(--colors-background-brand-solid) !important;
          }
        }
  
        .-groups-wrapper .MuiFormControlLabel-label {
          font-size: 16px !important;
          padding: 4px;
          color: var(--colors-foreground-fg-secondary-700);
        }
      }

      .MuiInputBase-root {
        min-height: 20px !important;
        display: flex;
        width: 100% !important;
      }
  
      #signature_text_Ref {
        width: 94% !important;

      }

      .signature_input {
        border: none;
        position: relative;
        color: #8c8c8c;
        background-color: #f2f4f7;
        font-weight: 400;
        margin-bottom: 12px;
        padding-bottom: 12px;
      }
  
      .signature_input::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 0.717px solid transparent;
        border-image: repeating-linear-gradient(
          140deg,
          #98a2b3 0,
          #98a2b3 5px,
          transparent 5px,
          transparent 10px
        );
        border-image-slice: 1;
        pointer-events: none;
      }

      .signature_button {
        align-items: center;
        background: rgb(127, 105, 68);
        border-radius: 8px;
        border: 1px solid;
        box-shadow: none;
        color: rgb(255, 255, 255);
        cursor: pointer;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        justify-self: center;
        line-height: 20px;
        min-height: 36px;
        padding: 6px 10px;
        place-self: auto;
        width: auto;
        justify-self: center;
      }
    }

    .tow-col-table {
        margin-bottom: 16px;

      td {
        padding: 16px 20px;
      }
    }
  
    .MandatoryInformation {
      .Row_Text {
        p {
          font-size: 18px;
          font-weight: 700;
        }
  
        li {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  
    .Confirmation,
    .ForOfficeUseOnly {
      .Row_Text {
        p {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  
    .Gray_Cell_Value {
      background-color: var(--component-colors-utility-gray-utility-gray-100);
      height: 40px;
    }
  
    .individualKycForm {
      td {
        padding: 12px;
      }
    }
  
    .Dynamic_Table {
      td {
        padding: 0 12px;
      }
  
      .Cell_Value {
        padding: 12px 0;
      }
    }

    .selectedCount {
      color: var(--colors-background-bg-brand-section-subtle);
      font-weight: 600;
      font-size: 16px;
    }
  
    .add_Record {
      display: flex;
      gap: 12px;
      align-items: center;
      cursor: pointer;

      button {
        border: none;
        background: transparent;
        padding: 0;
      }
    }

    .padding_12 {
      padding: 12px !important;
    }

    .solid {
        color: rgb(255, 255, 255);
        width: auto;
        display: flex;
        padding: 8px 12px;
        font-size: 14px;
        background: rgb(127, 105, 68);
        box-shadow: none;
        min-height: 36px;
        align-items: center;
        font-weight: 600;
        line-height: 20px;
        border-radius: 8px;
    }

    .outlined {
      color: rgb(52, 64, 84);
      width: auto;
      border: 1px solid rgb(208, 213, 221);
      display: flex;
      padding: 8px 12px;
      font-size: 14px;
      background: rgb(255, 255, 255);
      box-shadow: none;
      min-height: 36px;
      align-items: center;
      font-weight: 600;
      line-height: 20px;
      border-radius: 8px;
    }
  }
@page { margin: 0; }
@media print {body{-webkit-print-color-adjust:exact; margin: 0; padding: 0;  }}
</style>

</head>

<body>
<div>
{{{innerHTML}}}
{{#showActionButtons}}
<div class="form_actions actions">
<button id="reject-button" tabindex="0" class="rejectButton" type="button">Reject<span class="MuiTouchRipple-root"></span></button>
<button id="approve-button" class="approveButton" tabindex="0" type="button">Approve<span class="MuiTouchRipple-root"></span></button>
</div>
{{/showActionButtons}}
</div>
</div>
</body>

</html>
`;
