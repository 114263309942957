import React, {
  useEffect,
  useCallback ,
  useState 
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Spinner,
  Tables , 
  NoContentComponent 
} from '../../../../../../../../Components';
import { GetSaleReservationClient } from '../../../../../../../../Services';
import {
  bottomBoxComponentUpdate,
} from '../../../../../../../../Helper';

export const AssignedAgentsDetails = ({
  parentTranslationPath,
  translationPath,
  unitId 
}) => {

    const { t } = useTranslation(parentTranslationPath);
    const [isLoading, setIsLoading] = useState(false);
        const [saleReservations, setSaleReservations] = useState({
          assignedAgents: [],
        });
        const [filter] = useState({
          pageSize: 9999999,
          pageIndex: 0,
          search: '',
        });
      
    const getSaleReservationClient = useCallback(async () => {
      setIsLoading(true);
      const saleTransactionDetailsId = localStorage.getItem('saleTransactionDetailsId');
    const res = await GetSaleReservationClient({ id: saleTransactionDetailsId, isForAccountTab: true });
      if (!((res && res.data && res.data.ErrorId) || !res)) {
      let list = [] ; 
      res?.buyers &&  res.buyers.map((t)=> {
        console.log('@@@@ 111' , t );

        if(t.transactionBuyerDetails &&  t.transactionBuyerDetails?.transactionReferralDetails )
          {

            console.log('@@@@ 222' , t.transactionBuyerDetails?.transactionReferralDetails  );

            t.transactionBuyerDetails.transactionReferralDetails.map((s)=>
            {
              console.log('@@@@' , t );
              
              list.push({...s}) ;

            })



          }
      });

      res?.sellers &&  res.sellers.map((t)=> {
        console.log('33333333  11 1 transactionSellerDetails ' , t );

        if(t.transactionSellerDetails &&  t.transactionSellerDetails?.transactionReferralDetails )
          {

            console.log('@@@@ 222 transactionSellerDetails ' , t.transactionSellerDetails?.transactionReferralDetails  );

            t.transactionSellerDetails.transactionReferralDetails.map((s)=>
            {
              console.log('@@@@  transactionSellerDetails' , t );
              list.push({...s}) ;

            })



          }
      });
    
        setSaleReservations({
          assignedAgents: list }) ; 
           
          
      } else {
        setSaleReservations({
          assignedAgents: [],
        });
      }
      setIsLoading(false);
    }, [unitId]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  
    useEffect(() => {
      if (unitId) getSaleReservationClient();
    }, [getSaleReservationClient, unitId]);
  return (
    <div className="transactions-details-wrapper childs-wrapper mt-3">
      <Spinner isActive={isLoading} isAbsolute />  
       {saleReservations &&
            saleReservations.assignedAgents &&
            saleReservations.assignedAgents.length === 0 ? (
              <NoContentComponent />
            ) : (
              <Tables
                data={saleReservations?.assignedAgents || []}
                headerData={[
                  {
                    id: 1,
                    isSortable: false,
                    label: t(`${translationPath}sale-agent`),
                    input: "saleAgent",
                  },
                  {
                    id: 2,
                    isSortable: false,
                    label:t(`${translationPath}sale-listing-agent`),
                    input: "saleListingAgent",
                  },
                  {
                    id: 3,
                    isSortable: false,
                    label: "agent-team-manager",
                    input: "teamName",
                  },
                  {
                    id: 4,
                    isSortable: false,
                    label: "agent-team-lead",
                    input: "teamLeadName",
                  },
                  {
                    id: 6,
                    isSortable: false,
                    label: "agent-branch",
                    input: "agentBranch",
                  },
                ]}
                defaultActions={[]}
                activePage={1}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                totalItems={saleReservations?.assignedAgents?.length}
              />
            )}
    </div>
  );
};

AssignedAgentsDetails.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
AssignedAgentsDetails.defaultProps = {
};
