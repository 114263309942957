export const LeadsMortgagesPermissions = {
  ViewMortgageLeads: {
    components: null,
    componentsId: null,
    permissionsId: "f2ff0924-c55a-4bc8-f5ba-08da8ab4c335",
    permissionsName: "View Mortgage Leads",
  },

  SendToRotation: {
    permissionsId: "66ef17c5-1e15-4358-839b-08db0f594284",
    permissionsName: "Send To Rotation",
    componentsId: null,
    components: null,
  },
  AddNewActivity: {
    permissionsId: "51b0d7ea-5481-4eb6-2877-08dbaf79a1bb",
    permissionsName: "Add New Activity",
    description: null,
    componentsId: null,
    components: null,
  },
  sendToLeadPool: {
    permissionsId: "d305d07c-6746-4686-c4c5-08dc683b0c43",
    permissionsName: "Send To The Leads Pool",
    description: null,
    componentsId: null,
    components: null,
  },
  sendToLeadPoolAssets: {
    permissionsId: "aad043bc-810f-4b01-5070-08dce2e2dc64",
    permissionsName: "Send To The Leads Pool (Assets)",
    description: null,
    componentsId: null,
    components: null,
  },
  EditContactName: {
    permissionsId: "6176e2e4-f632-42db-ec32-08dd2fb527c1",
    permissionsName: "Edit Contact Name",
    description: null,
    componentsId: null,
    components: null,
  },
};
