import React, { useState } from 'react';
import './Style.scss';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Description } from '@material-ui/icons';
import { ButtonBase } from '@material-ui/core';
import statues from '../statuses';
import moment from 'moment';
import { DialogComponent, Inputs } from '../../../../../Components';
import { UpdateContactOpportunityStatus } from '../../../../../Services';
import { showError } from '../../../../../Helper';

function ContactOpportunityDetailsSlider({ item, reload, t }) {
  const [confirmStatusDialogOpen, setConfirmStatusDialogOpen] = useState(false);
  const [targetedStatus, setTargetedStatus] = useState(null);
  const [reason, setReason] = useState('');
  const formattedDate = moment(item?.createdOn).format('DD MMM YYYY, hh:mmA');
  const formattedStatusUpdate = moment(item?.amlStatus).format(
    'DD MMM YYYY, hh:mmA'
  );
  const steps = [
    { title: 'Pulling date and time', time: formattedDate },
    { title: 'Status update', time: formattedStatusUpdate },
  ];

  const updateStatusHandler = async (e) => {
    e.preventDefault();
    try {
      await UpdateContactOpportunityStatus({
        contactOpportunityStatusType: targetedStatus,
        contactOpportunityId: item?.contactOpportunityId,
        reason,
      });
      setConfirmStatusDialogOpen(false);
      reload();
    } catch (error) {
      showError(t('error-message'));
    }
  };
  return (
    <div className='contact-details-main-container'>
      <div className='contact-details-wrapper'>
        <div className='contact-details-title-wrapper'>
          <span className='contact-details-title'>Details</span>
          <div className='divider-title'></div>
        </div>
        <div className='contact-details-section1'>
          <span className='section1-title'>Contact</span>
          <ul className='section1-stepper-wrapper'>
            {steps.map((step, index) => (
              <li
                className='breadcrumb-level-contact'
                key={index}
              >
                <div className='step-content-wrapper'>
                  <div className='step-icon'>
                    <svg viewBox='0 0 24 24'>
                      <path d='M9 16.2l-3.5-3.5L4 14l5 5 10-10-1.5-1.5L9 16.2z'></path>
                    </svg>
                  </div>
                  <div className='step-text-wrapper'>
                    <div className='step-title'>{step.title}</div>
                    <div className='step-time'>{step.time}</div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='contact-details-section2'>
          <div className='pulling-details-wrapper'>
            <span className='section2-title'>Pulling details</span>
            <div className='pulling-details'>
              <div className='pulling-wrapper'>
                <span className='pulling-type'>Pulling type</span>
                <span className='pulling-value'>{item?.pullType}</span>
              </div>
              <div className='pulling-wrapper'>
                <span className='pulling-type'>Pulled by</span>
                <span className='pulling-value'>{item?.createdBy}</span>
              </div>
              <div className='pulling-wrapper'>
                <span className='pulling-type'>Lead ID</span>
                <span className='pulling-value'>12345</span>
              </div>
              <div className='pulling-wrapper'>
                <span className='pulling-type'>Screening status</span>
                <span
                  className={`pulling-status ${item?.amlStatus?.toLowerCase()} `}
                >
                  {item?.amlStatus}
                </span>
              </div>
            </div>
          </div>
          <div className='documents-wrapper-contact'>
            <span className='section2-title'>Documents</span>
            <div className='documents-content-contact'>
              <div className='description-icon'>
                <Description />
                {item?.contactDocumentsCount}
              </div>

              <Link
                target='_blank'
                to={`/home/Contacts-CRM/contact-profile-edit?formType=${
                  item?.contactsType === 'Individuals' ? 1 : 2
                }&id=${item?.contactId}&tab=documents`}
                className='description-link'
              >
                Read more
                <div className='read-more-icon'>
                  <svg
                    viewBox='0 0 16 16'
                    fill='none'
                    width='16px'
                    height='16px'
                  >
                    <path
                      d='M4 11.3334L7.33333 8.00002L4 4.66669M8.66667 11.3334L12 8.00002L8.66667 4.66669'
                      stroke='#4050B5'
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {item?.status.toLowerCase() === statues[0].name.toLowerCase() && (
        <div className='action-section3'>
          <ButtonBase
            id='ApproveRef'
            className='btns theme-solid approve-btn'
            onClick={() => {
              setTargetedStatus(2);
              setConfirmStatusDialogOpen(true);
            }}
          >
            Approve
          </ButtonBase>
          <ButtonBase
            id='RejectRef'
            className='btns reject-btn '
            onClick={() => setTargetedStatus(3)}
          >
            Reject
          </ButtonBase>
        </div>
      )}

      {confirmStatusDialogOpen && (
        <DialogComponent
          // parentTranslationPath={parentTranslationPath}
          // translationPath={''}
          maxWidth={'sm'}
          saveText='Confirm'
          isOpen
          titleText={'reason'}
          dialogContent={
            <div>
              <Inputs
                rows={4}
                multiline
                idRef='reason'
                labelValue={'Type your reason'}
                value={reason}
                onInputChanged={(e) => setReason(e.target.value)}
                // parentTranslationPath={parentTranslationPath}
                translationPath={''}
              />
            </div>
          }
          onCancelClicked={() => setConfirmStatusDialogOpen(false)}
          onCloseClicked={() => setConfirmStatusDialogOpen(false)}
          onSaveClicked={updateStatusHandler}
          // saveIsDisabled={}
        />
      )}
    </div>
  );
}

export default ContactOpportunityDetailsSlider;
