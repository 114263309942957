import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { TextField, Box } from "@material-ui/core";
import { FBLabelWrapper } from "../FBLabelWrapper";
import { FBLabelPosition } from "../Constant";

// Styles
import useStyles from "./styles";

/**
 * FBTextField is a reusable input component built using Material-UI's TextField.
 * It supports flexible label positioning, validation, and customization through props.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.label - The label text for the input field.
 * @param {"above" | "side"} [props.labelPosition="side"] - The position of the label relative to the input field.
 *        Use "above" for a vertical layout or "side" for a horizontal layout.
 * @param {string|number} [props.value=""] - The current value of the input field.
 * @param {string} [props.fieldCustomClasses] - Custom CSS classes to apply to the input field.
 * @param {function} props.onChange - Callback function triggered when the input value changes.
 *        It receives the event as an argument.
 * @param {function} [props.onBlur] - Callback function triggered when the input loses focus.
 *        It receives the event as an argument.
 * @param {function} [props.onFocus] - Callback function triggered when the input gains focus.
 *        It receives the event as an argument.
 * @param {string} [props.placeholder=""] - Placeholder text displayed in the input field when it is empty.
 * @param {boolean} [props.error=false] - If true, the input field will display an error state.
 * @param {string} [props.errorMessage=""] - The error message to display when the input field in error state.
 * @param {string} [props.helperInfo=""] - Additional text displayed below the label,
 *        often used for hints or additional information and this is the replacment of helperText.
 * @param {boolean} [props.required=false] - If true, appends an asterisk (*) to the label to indicate a required field.
 * @param {boolean} [props.disabled=false] - If true, the input field is disabled and not editable.
 * @param {"standard" | "outlined" | "filled"} [props.variant="outlined"] - The variant/style of the input field.
 * @param {boolean} [props.fullWidth=false] - If true, the input field will expand to fill the width of its container.
 * @param {boolean} [props.multiline=false] - If true, the input field will allow multiline text.
 * @param {number} [props.rows=1] - The number of visible text lines when `multiline` is true.
 * @param {number} [props.maxLength] - The maximum number of characters allowed in the input field.
 * @param {Object} [props.InputProps={}] - Additional props to be passed directly to the Material-UI TextField `InputProps`.
 * @param {...Object} restProps - Any additional props to be passed to the underlying TextField component.
 *
 * @returns {JSX.Element} A styled Material-UI TextField component with custom label positioning.
 *
 * @example
 * // Usage example for a simple text field:
 * <FBTextField
 *   label="Name"
 *   value={name}
 *   onChange={(e) => setName(e.target.value)}
 *   placeholder="Enter your name"
 *   required
 *   helperText="helper text for the input field"
 *   error
 *   errorMessage="This is an error message."
 * />
 *
 * @example
 * // Example with side label and multiline input:
 * <FBTextField
 *   label="Description"
 *   labelPosition="side"
 *   value={description}
 *   onChange={(e) => setDescription(e.target.value)}
 *   multiline
 *   rows={4}
 *   maxLength={200}
 *   placeholder="Enter a description"
 *   helperInfo="helper text for the input field"
 * />
 */

function FBTextField({
  label,
  labelPosition,
  fieldCustomClasses,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  error,
  helperText,
  required,
  disabled,
  variant,
  fullWidth,
  multiline,
  rows,
  maxLength,
  InputProps,
  helperInfo,
  errorMessage,
  ...restProps
}) {
  const styles = useStyles();

  const isLabelSidePosition = labelPosition === FBLabelPosition.SIDE;

  return (
    <Box
      display={"flex"}
      flexDirection={isLabelSidePosition ? "row" : "column"}
      alignItems={isLabelSidePosition ? "center" : "flex-start"}
      className={clsx({
        [styles.wrapperSide]: isLabelSidePosition,
      })}
    >
      <FBLabelWrapper
        label={label}
        required={required}
        helperInfo={helperInfo}
        isLabelSidePosition={isLabelSidePosition}
      />

      <TextField
        className={clsx(styles.textField, fieldCustomClasses)}
        value={value}
        onChange={(e) => {
          if (maxLength && e.target.value.length > maxLength) return;
          onChange && onChange(e);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        error={error}
        helperText={!!error && errorMessage}
        required={required}
        disabled={disabled}
        variant={variant}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        InputProps={InputProps}
        {...restProps}
      />
    </Box>
  );
}

FBTextField.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(["above", "side"]),
  fieldCustomClasses: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  helperInfo: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  InputProps: PropTypes.object,
};

FBTextField.defaultProps = {
  label: "",
  labelPosition: FBLabelPosition.SIDE,
  value: "",
  placeholder: "",
  error: false,
  errorMessage: "",
  required: false,
  disabled: false,
  variant: "outlined",
  fullWidth: false,
  multiline: false,
  rows: 1,
  maxLength: undefined,
  InputProps: {},
};

export default FBTextField;
