import { contactsDetailsGet } from "../Services";

export const CheckContactWithSomeConditions = async (contactId) => {
  const contactData = await contactsDetailsGet({ id: contactId });
  if (!(contactData && contactData.status && contactData.status !== 200)) {
    if (contactData && contactData.contact) {
      let contactFields = null;
      if (
        contactData.contact.contact_type_id === 1 ||
        contactData.contact.contact_type_id === "1"
      )
        contactFields = {
          fieldsData: [
            {
              key: "first_name",
              title: "first-name",
              id: "firstName",
              value: contactData?.contact?.first_name,
              validation: "^(?:[a-zA-Z\\s]+|[ء-ي\\s]+|\\*{8})$",
              errorMessage: "fill-first-name-in-a-correct-way",
            },
            {
              key: "last_name",
              title: "last-name",
              id: "lastName",
              value: contactData?.contact?.last_name,
              validation: "^(?:[a-zA-Z\\s]+|[ء-ي\\s]+|\\*{8})$",
              errorMessage: "fill-last-name-in-a-correct-way",
            },
          ],
        };
      else if (
        contactData?.contact.contact_type_id === 2 ||
        contactData?.contact.contact_type_id === "2"
      )
        contactFields = {
          fieldsData: [
            {
              key: "company_name",
              title: "company-name",
              id: "companyName",
              value: contactData?.contact?.company_name,
              validation: "^(?:[a-zA-Z\\s]+|[ء-ي\\s]+|\\*{8})$",
              errorMessage: "fill-company-name-in-a-correct-way",
            },
          ],
        };

      return {
        activeContact: contactData,
        contactFields: [...contactFields.fieldsData],
      };
    }
  }

  return null;
};
