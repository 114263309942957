import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { PageTitle, FBTextField, FBAutocomplete, FBLabelPosition } from "../../../../Components";
import MultiStepForm from "./MultiStepForm";
import { useTranslate } from "../../../../Hooks";

// Styles
import useStyles from "./styles";

function AddIndividualForm() {
  const styles = useStyles();

  const [inputValue, setInputValue] = useState("");
  const [inputErrorvalue, setInputErrorvalue] = useState("");
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [autocompleteErrorValue, setAutocompleteErrorValue] = useState(null);

  const options = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
    { label: "Option 3", value: 3 },
    { label: "Option 4", value: 4 },
  ];

  const { translate } = useTranslate("NewContactsView");

  return (
    <Box>
      <PageTitle
        title={translate("add-individual-contact-page-title")}
        subTitle={translate("add-individual-contact-page-subtitle")}
        hideDivider
      />
      
      <MultiStepForm />

      <hr />

      <h1>Form Builder Controller</h1>

      <hr />

      <FBTextField
        label="First name"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter your name"
        required
        labelPosition={FBLabelPosition.SIDE}
        helperInfo="helper text for the input field"
        fieldCustomClasses={styles.fieldCustomClasses}
      />

      <hr />

      <FBTextField
        label="First name"
        value={inputErrorvalue}
        onChange={(e) => setInputErrorvalue(e.target.value)}
        placeholder="Enter your name"
        required
        labelPosition={FBLabelPosition.SIDE}
        error
        errorMessage="This is an error message."
        fieldCustomClasses={styles.fieldCustomClasses}
      />

      <hr />

      <FBAutocomplete
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        options={options}
        value={autocompleteValue}
        onChange={(event, newValue) => {
          console.log("Selected Value:", newValue);
          setAutocompleteValue(newValue);
        }}
        label="Select an Option"
        placeholder="Select"
        helperInfo="helper text Pick an option from the list"
        required
        labelPosition={FBLabelPosition.SIDE}
      />

      <hr />
      {/* this case when option not object item */}
      <FBAutocomplete
        options={["Option 1", "Option 2", "Option 3", "Option 4"]}
        value={autocompleteErrorValue}
        onChange={(event, newValue) => {
          console.log("Selected Value:", newValue);
          setAutocompleteErrorValue(newValue);
        }}
        label="Select an Option"
        placeholder="Select"
        helperInfo="helper text for the autocomplete field"
        error
        errorMessage="This is an error message on autocomplete component."
        required
        labelPosition={FBLabelPosition.SIDE}
      />
    </Box>
  );
}

export default AddIndividualForm;
