import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './LeasingTransactionsWithAML';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Inputs,
  Spinner,
  Tables,
  AutocompleteComponent,
  SelectComponet,
  PermissionsComponent,
} from '../../../Components';
import {
  TableActions,
  TableFilterTypesEnum,
  AMLTransactionStatusEnum,
} from '../../../Enums';
import {
  GlobalHistory,
  preventCopy,
  returnPropsByPermissions,
} from '../../../Helper';
import {
  lookupItemsGet,
  getProperties,
  GetAllLeaseAgentsServices,
  GetAllBranches,
  GetAllLeaseTransactionsWithAmlAPI,
} from '../../../Services';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { useTitle } from '../../../Hooks';
import { headerData } from './HeaderData';
import { LeaseTransactionsWithAMLPermissions } from '../../../Permissions';

const parentTranslationPath = 'LeasingTransactionsView';
const translationPath = '';
export const LeasingTransactionsWithAML = () => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  useTitle(t(`${translationPath}leasing-transactions-with-aml`));
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState({
    leasingTransactions: false,
    properties: false,
    communitie: false,
    leaseAgents: false,
    branch: false,
  });
  const [properties, setProperties] = useState({ result: [], totalCount: 0 });
  const [communities, setCommunities] = useState({ result: [], totalCount: 0 });
  const [leaseAgents, setLeaseAgents] = useState({ result: [], totalCount: 0 });
  const [branchList, setBranchList] = useState({
    result: [],
    totalCount: 0,
  });

  const AMLTransactionStatusList = useMemo(
    () => [
      ...Object.values(AMLTransactionStatusEnum).filter(
        (s) => s.key !== 1 && s.key !== 2
      ),
    ],
    []
  );

  const [leasingTransactions, setLeasingTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter.leasingTransactionsWithAMLFilter?.filterBy,
    orderBy: orderFilter.leasingTransactionsWithAMLFilter?.orderBy,
  });
  const [isClearedAllFiltersClick, setIsClearedAllFiltersClick] =
    useState(false);
  const [sortBy, setSortBy] = useState(null);
  const baseActions = useMemo(() => getActionTableWithPermissions(), []);

  const [tableFilterData, setTableFilterData] = useState([]);
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: null, orderBy: null }
  );

  const defaultFilter = {
    sellerAgentId: null,
    landLordAgentId: null,
    buyerAgentId: null,
    tenantAgentId: null,
    buyerBranchId: null,
    sellerBranchId: null,
    tenantBranchId: null,
    landlordBranchId: null,
    communityId: null,
    propertyId: null,
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    unitRefNumber: null,
    orderBy: null,
    filterBy: 'UnitTransactionId',
    statusId: null,
    unitNumber: null,
    buyerName: null,
    sellerName: null,
    sellingPrice: null,
    transactionEntryDate: null,
    unitTransactionId: null,
    tenantName: null,
    landLordName: null,
    rentPerYear: null,
    contractStartDate: null,
    contractEndDate: null,
    contractRent: null,
    propertyName: null,
    communityName: null,
    buyerAgent: null,
    sellerAgent: null,
    buyerBranch: null,
    sellerBranch: null,
    tenantAgent: null,
    tenantBranch: null,
    landlordBranch: null,
    landlordAgent: null,
    tenantStaffId: null,
    landlordStaffId: null,
    buyerStaffId: null,
    sellerStaffId: null,
    transactionStatusId: null,
  };

  const [filter, setFilter] = useState({ ...defaultFilter });
  const [selected, setSelected] = useState({
    property: null,
    community: null,
    tenantAgent: null,
    landLordAgent: null,
    buyerAgentId: null,
    sellerAgent: null,
    landlordBranch: null,
    tenantBranch: null,
    transactionStatus: null,
  });
  const onFilterValuesChanged = (newValue) => {
    const localFilterDto = filter || {};
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.displayPath])
            localFilterDto[item.displayPath] = item.value;
          else if (item.value) localFilterDto[item.displayPath] = item.value;

          return undefined;
        });
    }
    setFilter(() => ({ ...localFilterDto }));
  };
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        leasingTransactionsWithAMLFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };
  function getActionTableWithPermissions() {
    const list = [];
    if (
      returnPropsByPermissions(
        LeaseTransactionsWithAMLPermissions.ViewTransactionDetails.permissionsId
      )
    )
      list.push({
        enum: TableActions.openFile.key,
        isDisabled: false,
        externalComponent: null,
      });
    if (
      returnPropsByPermissions(
        LeaseTransactionsWithAMLPermissions.EditTransactions.permissionsId
      )
    )
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    return list;
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.openFile.key) {
        localStorage.setItem(
          'leaseTransactionDetailsId',
          item.leaseTransactionDetailsId
        );
        dispatch(ActiveItemActions.activeItemRequest(item));
        GlobalHistory.push(
          `/home/leasing-transactions-with-AML/transaction-profile?unitId=${item.unitId}&unitTransactionId=${item.unitTransactionId}&actionType=2`
        );
      } else if (actionEnum === TableActions.editText.key) {
        localStorage.setItem(
          'leaseTransactionDetailsId',
          item.leaseTransactionDetailsId
        );
        dispatch(ActiveItemActions.activeItemRequest(item));
        GlobalHistory.push(
          `/home/leasing-transactions-with-AML/transaction-profile?unitId=${item.unitId}&unitTransactionId=${item.unitTransactionId}&actionType=1`
        );
      }
    },
    []
  );
  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 0, unitRefNumber: search }));
    }, 700);
    setSearchInput(search);
  };
  const getAllLeasingTransactions = useCallback(async () => {
    if (isLoading?.leasingTransactions) return;
    if (isClearedAllFiltersClick) setIsClearedAllFiltersClick(false);
    if (
      returnPropsByPermissions(
        LeaseTransactionsWithAMLPermissions.ViewLeasesTransactionsWithAML
          .permissionsId
      )
    ) {
      setIsLoading((loading) => ({ ...loading, leasingTransactions: true }));
      const res = await GetAllLeaseTransactionsWithAmlAPI({
        ...filter,
        pageIndex: filter.pageIndex + 1,
      });
      if (!(res && res.status && res.status !== 200)) {
        setLeasingTransactions({
          result: (res && res.result) || [],
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setLeasingTransactions({
          result: [],
          totalCount: 0,
        });
      }

      setIsLoading((loading) => ({ ...loading, leasingTransactions: false }));
    }
  }, [filter]);
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex, search: searchInput }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      pageSize,
      search: '',
    }));
    setSearchInput('');
  };
  useEffect(() => {
    getAllLeasingTransactions();
  }, [filter, getAllLeasingTransactions]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );
  const GetAllow = () => {
    //Add  New Permissinons

    return false;
  };

  const getAllProperties = useCallback(async (searchItem) => {
    setIsLoading((loading) => ({ ...loading, properties: true }));
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setProperties({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setProperties({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, properties: false }));
  }, []);

  const getAllCommunitie = useCallback(async (searchItem) => {
    setIsLoading((loading) => ({ ...loading, communitie: true }));
    const res = await lookupItemsGet({
      pageIndex: 1,
      pageSize: 10,
      lookupTypeId: 19,
      lookupTypeName: 'community',
      searchedItem: searchItem || '',
    });

    if (!(res && res.status && res.status !== 200)) {
      setCommunities({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setCommunities({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, communitie: false }));
  }, []);

  const getAllLeaseAgents = useCallback(async (searchItem) => {
    setIsLoading((loading) => ({ ...loading, leaseAgents: true }));
    const res = await GetAllLeaseAgentsServices({
      pageSize: 10,
      pageIndex: 0,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setLeaseAgents({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setLeaseAgents({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, leaseAgents: false }));
  }, []);

  const getBranch = async (branchName) => {
    setIsLoading((loading) => ({ ...loading, branch: true }));
    const res = await GetAllBranches({
      pageSize: 25,
      pageIndex: 1,
      branchName,
    });
    if (!(res && res.status && res.status !== 200)) {
      setBranchList({
        result: res.result,
        totalCount: res.totalCount,
      });
    } else {
      setBranchList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, branch: false }));
  };
  const onClearedAllFiltersClick = useCallback(() => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: null, orderBy: 2 });
    setFilter({ ...defaultFilter });
    setSelected({
      property: null,
      community: null,
      tenantAgent: null,
      landLordAgent: null,
      buyerAgentId: null,
      sellerAgent: null,
      landlordBranch: null,
      tenantBranch: null,
      transactionStatus: null,
    });
    setSearchInput('');
    setIsClearedAllFiltersClick(true);
  });

  useEffect(() => {
    getAllCommunitie();
    getAllProperties();
    getAllLeaseAgents();
    getBranch();
  }, []);

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
  }, [sortBy]);
  useEffect(() => {
    if (orderBy)
      setFilter((item) => ({
        ...item,
        filterBy: orderBy.filterBy,
        orderBy: orderBy.orderBy,
      }));
  }, [orderBy]);
  useEffect(() => {
    setTableFilterData(
      headerData(t, AMLTransactionStatusList).map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
        optionFilterList: column?.optionFilterList,
      }))
    );
  }, []);

  return (
    <div className='view-wrapper'>
      <Spinner isActive={isLoading.leasingTransactions} />
      <PermissionsComponent
        permissionsList={Object.values(LeaseTransactionsWithAMLPermissions)}
        permissionsId={
          LeaseTransactionsWithAMLPermissions.ViewLeasesTransactionsWithAML
            .permissionsId
        }
      >
        <div className='d-flex-column'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='section' />
              <div className='section autocomplete-section'>
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='d-flex-column w-100'>
                    <Inputs
                      idRef='leasingTransactionsSearchRef'
                      value={searchInput}
                      beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                      onInputChanged={(e) => {
                        searchHandler(e.target.value);
                      }}
                      inputPlaceholder={t(`${translationPath}seacrh_ref_No`)}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                  <div className='d-inline-flex-column pl-4-reversed'>
                    <div className='d-flex mb-1'>
                      <div className='autocomplete-wrapper mr-1-reversed'>
                        <AutocompleteComponent
                          selectedValues={selected.transactionStatus}
                          idRef='transactionStatusId'
                          multiple={false}
                          data={[...AMLTransactionStatusList] || []}
                          displayLabel={(option) =>
                            (option && option.name && t(`${option.name}`)) || ''
                          }
                          withoutSearchButton
                          inputPlaceholder={t(
                            `${translationPath}transaction-status`
                          )}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onChange={(event, newValue) => {
                            setFilter((item) => ({
                              ...item,
                              transactionStatusId:
                                (newValue && newValue.key) || null,
                              pageIndex: 0,
                            }));
                            setSelected((item) => ({
                              ...item,
                              transactionStatus: newValue,
                            }));
                          }}
                        />
                      </div>
                      <div className='autocomplete-wrapper mr-1-reversed'>
                        <AutocompleteComponent
                          selectedValues={selected.property}
                          idRef='propertyIdRef'
                          isLoading={isLoading.properties}
                          multiple={false}
                          data={(properties && properties.result) || []}
                          displayLabel={(option) =>
                            (option &&
                              option.property &&
                              `${option.property.property_name}`) ||
                            ''
                          }
                          withoutSearchButton
                          inputPlaceholder={t(`${translationPath}property`)}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onChange={(event, newValue) => {
                            setFilter((item) => ({
                              ...item,
                              propertyId:
                                (newValue && newValue.propertyId) || null,
                              pageIndex: 0,
                            }));
                            setSelected((item) => ({
                              ...item,
                              property: (newValue && newValue) || '',
                            }));
                          }}
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllProperties(value);
                            }, 700);
                          }}
                        />
                      </div>
                      <AutocompleteComponent
                        selectedValues={selected.community}
                        idRef='communityRef'
                        isLoading={isLoading.communitie}
                        multiple={false}
                        data={(communities && communities.result) || []}
                        displayLabel={(option) =>
                          (option && option.lookupItemName) || ''
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        inputPlaceholder={t(`${translationPath}community`)}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            communityId:
                              (newValue && newValue.lookupItemId) || null,
                            pageIndex: 0,
                          }));
                          setSelected((item) => ({
                            ...item,
                            community: (newValue && newValue) || '',
                          }));
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllCommunitie(value);
                          }, 700);
                        }}
                      />
                    </div>
                    <div className='d-flex'>
                      <div className='autocomplete-wrapper mr-1-reversed'>
                        <AutocompleteComponent
                          selectedValues={selected.tenantAgent}
                          idRef='tenantAgentIdRef'
                          isLoading={isLoading.leaseAgents}
                          multiple={false}
                          inputPlaceholder={t(`${translationPath}tenantAgent`)}
                          data={(leaseAgents && leaseAgents.result) || []}
                          chipsLabel={(option) =>
                            (option && option.agentName) || ''
                          }
                          displayLabel={(option) =>
                            (option && option.agentName) || ''
                          }
                          withoutSearchButton
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onChange={(event, newValue) => {
                            setFilter((item) => ({
                              ...item,
                              tenantAgentId:
                                (newValue && newValue.agentId) || null,
                              pageIndex: 0,
                            }));
                            setSelected((item) => ({
                              ...item,
                              tenantAgent: (newValue && newValue) || '',
                            }));
                          }}
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllLeaseAgents(value);
                            }, 700);
                          }}
                        />
                      </div>
                      <AutocompleteComponent
                        selectedValues={selected.landLordAgent}
                        idRef='landLordAgentRef'
                        isLoading={isLoading.leaseAgents}
                        multiple={false}
                        inputPlaceholder={t(`${translationPath}landLordAgent`)}
                        data={(leaseAgents && leaseAgents.result) || []}
                        chipsLabel={(option) =>
                          (option && option.agentName) || ''
                        }
                        displayLabel={(option) =>
                          (option && option.agentName) || ''
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            landLordAgentId:
                              (newValue && newValue.agentId) || null,
                            pageIndex: 0,
                          }));
                          setSelected((item) => ({
                            ...item,
                            landLordAgent: (newValue && newValue) || '',
                          }));
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllLeaseAgents(value);
                          }, 700);
                        }}
                      />
                      <AutocompleteComponent
                        selectedValues={selected.tenantBranch}
                        idRef='buyerBranchRef'
                        multiple={false}
                        isLoading={isLoading.saleAgentsAPI}
                        inputPlaceholder={t(`${translationPath}tenant-branch`)}
                        data={branchList.result || []}
                        chipsLabel={(option) =>
                          (option && option.branchName) || ''
                        }
                        displayLabel={(option) =>
                          (option && option.branchName) || ''
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputKeyUp={(event) => {
                          const { value } = event.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getBranch(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            tenantBranchId:
                              (newValue && newValue.branchId) || null,
                          }));
                          setSelected((item) => ({
                            ...item,
                            tenantBranch: (newValue && newValue) || '',
                          }));
                        }}
                      />
                      <AutocompleteComponent
                        selectedValues={selected.landlordBranch}
                        idRef='sellerBranchRef'
                        multiple={false}
                        isLoading={isLoading.saleAgentsAPI}
                        inputPlaceholder={t(
                          `${translationPath}landlord-branch`
                        )}
                        data={branchList.result || []}
                        chipsLabel={(option) =>
                          (option && option.branchName) || ''
                        }
                        displayLabel={(option) =>
                          (option && option.branchName) || ''
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputKeyUp={(event) => {
                          const { value } = event.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getBranch(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            landlordBranchId:
                              (newValue && newValue.branchId) || null,
                          }));
                          setSelected((item) => ({
                            ...item,
                            landlordBranch: (newValue && newValue) || '',
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <ButtonBase
                  onClick={onClearedAllFiltersClick}
                  id='onClearedAllFiltersref'
                  className='btns theme-solid bg-danger clear-all-btn max-height'
                >
                  <span className='mdi mdi-filter-remove m-1' />
                  {t(`${translationPath}clear-filters`)}
                </ButtonBase>
              </div>
            </div>

            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}select`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}:
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[
                      {
                        id: 'TransactionEntryDate',
                        filterBy: 'transaction-date',
                      },
                    ]}
                    value={selectedOrderBy.filterBy}
                    wrapperClasses='mb-3'
                    isRequired
                    onSelectChanged={filterByChanged}
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    valueInput='id'
                    textInput='filterBy'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    emptyItem={{
                      value: null,
                      text: 'select-sort-by',
                      isDisabled: false,
                    }}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    id='action_apply'
                    className='btns theme-solid'
                    onClick={orderBySubmitted}
                    disabled={
                      !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                    }
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </div>

          <div className='w-100 px-3' onCopy={preventCopy}>
            <Tables
              data={leasingTransactions.result}
              headerData={headerData(t, AMLTransactionStatusList)}
              defaultActions={baseActions}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              actionsOptions={{
                onActionClicked: tableActionClicked,
              }}
              itemsPerPage={filter.pageSize}
              activePage={filter.pageIndex}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              totalItems={leasingTransactions.totalCount}
              setSortBy={setSortBy}
              onFilterValuesChanged={onFilterValuesChanged}
              filterData={tableFilterData}
              isWithFilter
              FilterDisabledButton
              isClearFiltersClicked={isClearedAllFiltersClick}
              setIsClearFiltersClicked={setIsClearedAllFiltersClick}
            />
          </div>
        </div>
      </PermissionsComponent>
    </div>
  );
};
