export const ContactValidationContextLocale = {
  en: {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    phone: 'Phone',
    company_name: 'Company Name',
    landline: 'Landline',
    add_new_contact: 'Add New Contact',
    submit: 'Submit',
    full_name: 'Full Name',
    contact_id: 'Contact ID',
    created_at: 'Created At',
    single_matching_contact: '1 matching contact found',
    multiple_matching_contacts: '{{count}} matching contacts found',
    invalid_email: 'Invalid email',
    duplicate_email: 'This email is associated with another contact',
    duplicate_phone: 'This phone number is associated with another contact',
    no_special_characters:
      "{{field}} shouldn't contain special characters or numbers",
  },
  ar: {
    first_name: 'الاسم الأول',
    last_name: 'اسم العائلة',
    email: 'البريد الإلكتروني',
    phone: 'الهاتف',
    company_name: 'اسم الشركة',
    landline: 'هاتف أرضي',
    add_new_contact: 'إضافة جهة اتصال جديدة',
    submit: 'إرسال',
    full_name: 'الاسم الكامل',
    contact_id: 'معرف جهة الاتصال',
    created_at: 'تاريخ الإنشاء',
    single_matching_contact: 'تم العثور على جهة اتصال واحدة مطابقة',
    multiple_matching_contacts: 'تم العثور على {{count}} جهات اتصال مطابقة',
    invalid_email: 'البريد الإلكتروني غير صالح',
    duplicate_email: 'هذا البريد الإلكتروني مرتبط بجهة اتصال أخرى',
    duplicate_phone: 'هذا الرقم مرتبط بجهة اتصال أخرى',
    no_special_characters: '{{field}} يجب ألا يحتوي على أحرف خاصة أو أرقام',
  },
  ru: {
    first_name: 'Имя',
    last_name: 'Фамилия',
    email: 'Электронная почта',
    phone: 'Телефон',
    company_name: 'Название компании',
    landline: 'Стационарный телефон',
    add_new_contact: 'Добавить новый контакт',
    submit: 'Отправить',
    full_name: 'Полное имя',
    contact_id: 'ID контакта',
    created_at: 'Создано',
    single_matching_contact: 'Найден 1 совпадающий контакт',
    multiple_matching_contacts: 'Найдено {{count}} совпадающих контактов',
    invalid_email: 'Неверный адрес электронной почты',
    duplicate_email: 'Этот адрес электронной почты связан с другим контактом',
    duplicate_phone: 'Этот номер телефона связан с другим контактом',
    no_special_characters:
      '{{field}} не должен содержать специальных символов или цифр',
  },
  cn: {
    first_name: '名字',
    last_name: '姓氏',
    email: '电子邮件',
    phone: '电话',
    company_name: '公司名称',
    landline: '座机',
    add_new_contact: '添加新联系人',
    submit: '提交',
    full_name: 'Полное имя',
    contact_id: '联系人 ID',
    created_at: '创建于',
    single_matching_contact: '找到 1 个匹配的联系人',
    multiple_matching_contacts: '找到 {{count}} 个匹配的联系人',
    invalid_email: '无效的电子邮件',
    duplicate_email: '此电子邮件已与其他联系人关联',
    duplicate_phone: '此电话号码已与其他联系人关联',
    no_special_characters: '{{field}} 不应包含特殊字符或数字',
  },
};

export const ContactValidationContextLocalePath = 'ContactValidationContext';
