import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { GlobalTranslate, showError, showSuccess } from "../../../../Helper";
import {
  CloneLead,
  OrganizationUserSearch,
  lookupItemsGetId,
} from "../../../../Services";
import { AutocompleteComponent, DialogComponent } from "../../../../Components";
import { AgentRoleEnum } from "../../../../Enums";

const parentTranslationPath = "";
const translationPath = "";
export const CloneLeadView = ({
  isOpen,
  onClose,
  relodedata,
  setIsLoading,
  ActiveIteam,
  isOwnerLeadClone,
  reset,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [isCloning, setIsCloning] = useState(false);
  const searchTimer = useRef(null);
  const [data, setData] = useState({
    campaignLanguages: [],
    mediaTypes: [],
    mediaDetails: [],
    users: [],
    referredBy: [],
    referredTo: [],
    methodOfContact: [],
    campaignTypes: [],
  });

  const [selected, setSelected] = useState({
    mediaDetails: null,
    methodOfContact: null,
    referredBy: null,
    referredTo: null,
  });

  const [Dto, setDto] = useState({
    leadId: (ActiveIteam && ActiveIteam.id) || null,
    mediaDetailsId: "",
    methodOfContactId: "",
    referredBy: "",
    referredTo: "",
  });

  const getAllMediaDetails = useCallback(async (mediaTypeId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: 1240,
      lookupParentId: mediaTypeId,
    });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, mediaDetails: res }));
    else setData((item) => ({ ...item, mediaDetails: [] }));
  }, []);

  const getAllMethodOfContact = async () => {
    const res = await lookupItemsGetId({ lookupTypeId: 35 });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, methodOfContact: res }));
    else setData((item) => ({ ...item, methodOfContact: [] }));
  };

  const getAllReferred = useCallback(
    async (searchValue) => {
      const res = await OrganizationUserSearch({
        pageIndex: 0,
        pageSize: 10,
        name: searchValue,
        userStatusId: 2,
        userTypeId:
          ((ActiveIteam?.leadClass.toLowerCase() === "buyer" ||
            ActiveIteam?.leadClass.toLowerCase() === "seller") &&
            AgentRoleEnum.SaleAgent.value) ||
          ((ActiveIteam?.leadClass.toLowerCase() === "tenant" ||
            ActiveIteam?.leadClass.toLowerCase() === "landlord") &&
            AgentRoleEnum.LeaseAgent.value) ||
          null,
      });
      if (!(res && res.status && res.status !== 200))
        setData((item) => ({ ...item, referredTo: res && res.result }));
      else setData((item) => ({ ...item, referredTo: [] }));
    },
    [ActiveIteam]
  );

  const getAllReferrby = useCallback(async (searchValue) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2,
    });

    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, referredBy: res && res.result }));
    else setData((item) => ({ ...item, referredBy: [] }));
  }, []);

  const cloneHandler = async () => {
    setIsCloning(true);
    const result = await CloneLead(Dto);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t(`${translationPath}leads-cloned-successfully`));
      if (reset) reset();
      relodedata();
      onClose();
    } else {
      showError(t(`${translationPath}leads-clone-failed`));
      if (setIsLoading) setIsLoading(false);
    }
    setIsCloning(false);
  };

  function findObjectInArraySales(array) {
    if (isOwnerLeadClone) {
      return (
        array.find(
          (item) =>
            item?.lookupItemName ===
              ActiveIteam?.media_detail?.lookupItemName ||
            item?.lookupItemId === ActiveIteam?.media_detail?.lookupItemId
        ) || null
      );
    } else
      return array.find(
        (item) =>
          item.lookupItemName === "Existing Client - Sales" ||
          item.lookupItemId === 57269
      );
  }

  function findObjectInArrayLeasing(array) {
    if (isOwnerLeadClone) {
      return (
        array.find(
          (item) =>
            item?.lookupItemName ===
              ActiveIteam?.media_detail?.lookupItemName ||
            item?.lookupItemId === ActiveIteam?.media_detail?.lookupItemId
        ) || null
      );
    } else
      return array.find(
        (item) =>
          item.lookupItemName === "Existing Client - Leasing" ||
          item.lookupItemId === 57270
      );
  }

  function findMethodOfContact() {
    if (isOwnerLeadClone) {
      return (
        data?.methodOfContact?.find(
          (item) =>
            item?.lookupItemName ===
              ActiveIteam.method_of_contact?.lookupItemName ||
            item?.lookupItemId === ActiveIteam.method_of_contact?.lookupItemId
        ) || null
      );
    } else {
      return (
        data?.methodOfContact?.find(
          (item) =>
            item.lookupItemName === "Existing Client - Leasing" ||
            item.lookupItemId === 57270
        ) || null
      );
    }
  }

  useEffect(() => {
    getAllMediaDetails();
    getAllMethodOfContact();
    getAllReferred();
    getAllReferrby();
  }, []);

  useEffect(() => {
    if (data?.mediaDetails?.length > 0) {
      if (
        ActiveIteam?.leadClass?.toLowerCase() === "buyer" ||
        ActiveIteam?.leadClass?.toLowerCase() === "seller"
      ) {
        const result = findObjectInArraySales(data.mediaDetails);
        const methodOfContact = findMethodOfContact();
        setSelected({
          ...selected,
          mediaDetails: result,
          methodOfContact: methodOfContact,
        });
        setDto((item) => ({
          ...item,
          mediaDetailsId: (result && +result.lookupItemId) || "",
          methodOfContactId: +methodOfContact?.lookupItemId || "",
        }));
      } else if (
        ActiveIteam?.leadClass?.toLowerCase() === "tenant" ||
        ActiveIteam?.leadClass?.toLowerCase() === "landlord"
      ) {
        const result = findObjectInArrayLeasing(data.mediaDetails);
        const methodOfContact = findMethodOfContact();
        setSelected({
          ...selected,
          mediaDetails: result,
          methodOfContact: methodOfContact,
        });
        setDto((item) => ({
          ...item,
          mediaDetailsId: +result?.lookupItemId || "",
          methodOfContactId: +methodOfContact?.lookupItemId || "",
        }));
      }
    }
  }, [data]);

  return (
    <>
      <DialogComponent
        titleText={GlobalTranslate.t("Shared:clone-leads")}
        saveText={GlobalTranslate.t("Shared:confirm")}
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <span>
              {GlobalTranslate.t("Shared:Are-you-sure-to-clone-this-leads")}:
              <span className="fw-bold">
                {ActiveIteam?.contact_name?.name || ""}
              </span>
            </span>
            <div className="w-100 mr-1-reversed mt-3">
              <AutocompleteComponent
                idRef="mediaDetailseRef"
                labelValue={GlobalTranslate.t("Shared:mediaDetails")}
                multiple={false}
                selectedValues={selected?.mediaDetails || null}
                getOptionSelected={(option) =>
                  option?.lookupItemId === selected?.mediaDetails?.lookupItemId
                }
                labelClasses="Requierd-Color"
                data={data?.mediaDetails || []}
                displayLabel={(option) => t(`${option?.lookupItemName || ""}`)}
                withoutSearchButton
                inputPlaceholder={GlobalTranslate.t(
                  "Shared:SelectmediaDetails"
                )}
                isWithError
                onChange={(e, newValue) => {
                  setDto((item) => ({
                    ...item,
                    mediaDetailsId: +newValue?.lookupItemId || "",
                  }));
                  setSelected({
                    ...selected,
                    mediaDetails: newValue,
                  });
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="w-100 mr-1-reversed">
              <AutocompleteComponent
                idRef="methodOfContactRef"
                labelValue={GlobalTranslate.t("Shared:method-Of-Contact")}
                inputPlaceholder={GlobalTranslate.t(
                  "Shared:Selectmethod-Of-Contact"
                )}
                labelClasses="Requierd-Color"
                data={data?.methodOfContact || []}
                displayLabel={(option) => option?.lookupItemName || ""}
                renderOption={(option) => option?.lookupItemName || ""}
                withoutSearchButton
                isWithError
                multiple={false}
                onChange={(e, newValue) => {
                  setDto((item) => ({
                    ...item,
                    methodOfContactId: +newValue?.lookupItemId || "",
                  }));
                  setSelected({
                    ...selected,
                    methodOfContact: newValue || null,
                  });
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="w-100 mr-1-reversed">
              <AutocompleteComponent
                idRef="ReferredByRef"
                labelValue={GlobalTranslate.t("Shared:ReferredBy")}
                inputPlaceholder={GlobalTranslate.t("Shared:SelectReferredBy")}
                labelClasses="Requierd-Color"
                data={data?.referredBy || []}
                multiple={false}
                displayLabel={(option) => option?.fullName || ""}
                chipsLabel={(option) => option?.fullName || ""}
                withoutSearchButton
                onInputKeyUp={(e) => {
                  const newValue = e?.target?.value || "";
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllReferrby(newValue || "");
                  }, 700);
                }}
                onChange={(e, newValue) => {
                  setDto((item) => ({
                    ...item,
                    referredBy: newValue?.id || "",
                  }));
                  setSelected({
                    ...selected,
                    referredBy: newValue,
                  });
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="w-100 mr-1-reversed">
              <AutocompleteComponent
                idRef="ReferredToRef"
                labelValue={GlobalTranslate.t("Shared:ReferredTo")}
                inputPlaceholder={GlobalTranslate.t("Shared:ReferredTo")}
                data={data?.referredTo || []}
                multiple={false}
                displayLabel={(option) => option?.fullName || ""}
                chipsLabel={(option) => option?.fullName || ""}
                withoutSearchButton
                onInputKeyUp={(e) => {
                  const newValue = e?.target?.value || "";
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllReferred(newValue || "");
                  }, 700);
                }}
                onChange={(e, newValue) => {
                  setDto((item) => ({
                    ...item,
                    referredTo: newValue?.id || "",
                  }));
                  setSelected({
                    ...selected,
                    referredTo: newValue,
                  });
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
          </div>
        }
        saveClasses="btns theme-solid w-100 mx-2 mb-2"
        isOpen={isOpen}
        saveIsDisabled={
          !(
            Dto.methodOfContactId !== "" &&
            Dto.referredBy !== "" &&
            Dto.mediaDetailsId !== ""
          ) || isCloning
        }
        onSaveClicked={() => cloneHandler()}
        onCancelClicked={onClose}
        translationPath={translationPath.confirm}
      />
    </>
  );
};
