import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import {
  CustomAutocomplete,
  CustomDialog,
  CustomTextArea,
} from "../../../../../../../../Components";
import { useTranslate } from "../../../../../../../../Hooks";
import { lookupItemsGetId } from "../../../../../../../../Services";
import { LeadCloseReasonsEnum } from "../../../../../../../../Enums";

//styles
import useStyles from "./styles";

function ContactCloseLeadsDialog({ isOpen, onClose, onSave }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");

  const [closeLeadReasons, setCloseLeadReasons] = useState([]);
  const [selected, setSelected] = useState({
    closeReason: null,
  });
  const [Dto, setDto] = useState({
    closeReasonId: null,
    remarks: "",
  });
  const getAllCloseReasons = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: LeadCloseReasonsEnum.lookupTypeId,
    });
    if (!(res && res.status && res.status !== 200)) setCloseLeadReasons(res);
    else setCloseLeadReasons([]);
  }, []);
  const onSubmitHandler = () => {
    onSave(
        Dto
    );
  };

  useEffect(() => {
    getAllCloseReasons();
  }, []);

  return (
    <CustomDialog
      open={isOpen}
      onClose={onClose}
      title={translate("Close-lead")}
      subtitle={translate(
        "Specify-the-reason-for-closing-whether-its-a-successful-deal-or-another-outcome"
      )}
      onConfirm={() => {
        onSubmitHandler();
      }}
      isDisabled={!Dto.closeReasonId}
      confirmText={translate("Submit")}
      width={"688px"}
      content={
        <Box className={styles.containerCloseLeadsDialog}>
          <Box className={styles.containerRowItem}>
            <Typography className={styles.titleItem}>
              {translate("Reason-for-closing")}
            </Typography>
            <CustomAutocomplete
              variant="default"
              options={closeLeadReasons || []}
              selectedValues={selected?.closeReason || null}
              getOptionLabel={(option) =>
                (option && option?.lookupItemName) || ""
              }
              renderOption={(option) =>
                (option && option?.lookupItemName) || ""
              }
              onChange={(e, newValue) => {
                setSelected((item) => ({ ...item, closeReason: newValue }));
                setDto((item) => ({
                  ...item,
                  closeReasonId: newValue && newValue?.lookupItemId,
                }));
              }}
              placeholder="Select"
            />
          </Box>
          <Divider />
          <Box className={styles.containerRowItem}>
            <Typography className={styles.titleItem}>
              {translate("Remarks")}
            </Typography>
            <CustomTextArea
              maxRows={5}
              minRows={5}
              placeholder={translate("Enter-a-remark")}
              onInputChange={(value) => {
                setDto((item) => ({ ...item, remarks: value }));
              }}
            />
          </Box>
        </Box>
      }
 
    />
  );
}
export default ContactCloseLeadsDialog;
